import React, { useEffect, useState } from 'react';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n/src';
import {
  isStockingUnit,
  StockingUnit,
  timeZoneToStockingUnit,
} from '@mobble/models/src/model/Settings';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';
import { useGetUser, useSettings } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { Text } from '@src/components';
import { useGetInvitationMeta } from '@src/hooks/useInvitations';
import { useTitle } from '@src/hooks/useTitle';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { Container } from '@src/stories/Components/Layout/Container';
import { Divider } from '@src/stories/Components/Layout/Divider';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Icon } from '@src/stories/Components/UI/Icon';
import { Logo } from '@src/stories/Components/UI/Logo';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { NoPropertyAccessScreen } from '@src/stories/Views/Misc/NoPropertyAccessScreen';
import {
  PropertyCreateForm,
  PropertyCreateFormValues,
} from '@src/stories/Views/Property/PropertyCreateForm';
import { getIsSubscriptionEnabled } from '@mobble/models/src/model/Subscriptions';

const useDelay = (seconds: number) => {
  const [delaying, setDelaying] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelaying(false);
    }, seconds * 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return delaying;
};

export const SetUpProperty: React.FC = () => {
  const { formatMessage } = useI18n();
  const settings = useSettings();
  const properties = useProperties();
  const user = useGetUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { signOut } = useAuthentication();

  useTitle(
    formatMessage({
      defaultMessage: 'Create property',
      description: 'setup.property.form.submit.label',
    })
  );

  const invitationMeta = useGetInvitationMeta();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const stockingUnit = timeZoneToStockingUnit(timeZone);

  const initialValues: Omit<PropertyCreateFormValues, 'pic'> = {
    name: invitationMeta?.farmName,
    stockingUnit,
  };

  const handleSubmit = (formValues: PropertyCreateFormValues) => {
    setLoading(true);

    const values: Omit<PropertyCreateFormValues, 'stockingUnit'> = {
      name: formValues.name,
      pic: formValues.pic,
    };

    properties
      .create(values)
      .then((newProperty) => {
        const stockingUnit = formValues.stockingUnit;

        if (isStockingUnit(stockingUnit)) {
          settings.update({ stockingUnit });
        } else {
          console.error(
            `Invalid stocking unit value: ${stockingUnit}. Using default value.`
          );
          settings.update({ stockingUnit: StockingUnit.DSE });
        }

        properties.selectProperty(newProperty.id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  const handleCancel = () => {
    signOut();
  };

  const initialLoad = useDelay(10);

  if (!getIsSubscriptionEnabled(user.subscriptionStatus)) {
    if (initialLoad) {
      return <LoadingScreen fullScreen />;
    }

    return <NoPropertyAccessScreen />;
  }

  return (
    <VStack flex>
      <Box spacing={{ top: 16, bottom: 8 }}>
        <VStack alignment="center">
          <Logo />
        </VStack>
      </Box>
      <Container width="small">
        <Box spacing={2}>
          <Text variant="card-title">
            {formatMessage({
              defaultMessage: 'Welcome to Mobble',
              description: 'setup.property.welcome.title',
            })}
          </Text>
          <Spacer y={1} />
          <Text variant="body">
            {formatMessage({
              defaultMessage: `Let's get started by creating your first property.`,
              description: 'setup.property.welcome.blurb',
            })}
          </Text>
        </Box>
        <Spacer y={2} />
        <Divider bold />
        <Spacer y={2} />
        <div role="alert">
          {error && (
            <Box spacing={2}>
              <HStack>
                <Box>
                  <Icon name="error" color={Color.Red} />
                </Box>
                <Text variant="body" color={Color.Red}>
                  {formatMessage({
                    defaultMessage:
                      'Something went wrong.\nThe subscription or trial period may have expired.',
                    description: 'setup.property.error',
                  })}
                </Text>
              </HStack>
            </Box>
          )}
        </div>
        <PropertyCreateForm
          loading={loading}
          initialValues={initialValues}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          requestStockingUnit
        />
      </Container>
    </VStack>
  );
};

export default {
  name: ROUTE_NAME.SETUP_PROPERTY,
  component: SetUpProperty,
};
