import React, { useEffect, useRef } from 'react';

import { useI18n } from '@mobble/i18n';
import { Mob } from '@mobble/models/src/model/Mob';
import {
  getRainGaugeYearsAvailable,
  RainGauge,
} from '@mobble/models/src/model/RainGauge';
import { LengthUnits } from '@mobble/shared/src/core/Quantity';
import { useRainGauge } from '@mobble/store/src/hooks';

import { ListSelect } from '@src/components';
import ChartContainer from '@src/components/ChartContainer';
import ContentContainer from '@src/components/ContentContainer';
import { useSetting } from '@src/context/settings';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { DashboardSuggestionDetails } from '@src/screens/Dashboard/hooks/useDashboardSuggestions';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import {
  ChartLivestockTotals,
  ChartLivestockTotalsProps,
} from '@src/stories/Components/Charts/Components/ChartLivestockTotals';
import { generateRainGaugeValues } from '@src/stories/Components/Charts/Components/ChartRainGaugeMonthlyRainfall';
import { useGenerateRainGaugeMonthlyRainfallChart } from '@src/stories/Components/Charts/generators/generateRainGaugeMonthlyRainfallChart';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  type InfoTileProps,
  InfoTiles,
} from '@src/stories/Components/UI/InfoTile';

import { ChartLivestockHistory } from '../../Components/Charts/Components/ChartLivestockHistory';
import { PropertyStockingRatesData } from '../Reports/usePropertyStockingRatesForRange';

import { DashboardSetUpProperty } from './DashboardSetUpProperty';
import { DashboardTrialCountDown } from './DashboardTrialCountDown';
import { DashboardTrialEnded } from './DashboardTrialEnded';

import styles from './dashboard.scss';

export type DashboardProps = {
  infoTiles: InfoTileProps[];
  mobs: Mob[];
  mobsLoading: boolean;
  rainGauges: RainGauge[];
  rainGaugesLoading: boolean;
  dashboardSuggestions: DashboardSuggestionDetails;
  propertyStockingRatesData: PropertyStockingRatesData;
  displayTrialStatus: boolean;
  trialCountDown: number | false;
} & RainGaugeGraphProps &
  ChartLivestockTotalsProps;

export const Dashboard: React.FC<DashboardProps> = ({
  infoTiles,
  mobs,
  mobsLoading,
  rainGauges,
  rainGaugesLoading,
  dashboardSuggestions,
  propertyStockingRatesData,
  displayTrialStatus,
  trialCountDown,
}) => {
  return (
    <Box>
      {displayTrialStatus ? (
        trialCountDown ? (
          <DashboardTrialCountDown daysLeft={trialCountDown} />
        ) : (
          <DashboardTrialEnded />
        )
      ) : null}
      {dashboardSuggestions.suggestions.length > 0 && (
        <DashboardSetUpProperty dashboardSuggestions={dashboardSuggestions} />
      )}
      <InfoTiles items={infoTiles} />
      <Box className={styles.dashboardLayout}>
        <Box className={styles.dashboardTab}>
          <ChartLivestockTotals mobs={mobs} loading={mobsLoading} />
        </Box>

        <Box className={styles.dashboardTab}>
          <HistoricEventsTab />
        </Box>

        <Box className={styles.dashboardTab}>
          <RainGaugeGraph rainGauges={rainGauges} loading={rainGaugesLoading} />
        </Box>
        <Box className={styles.dashboardTab}>
          <ChartLivestockHistory
            stockingRates={
              propertyStockingRatesData.type === 'ready'
                ? propertyStockingRatesData.raw
                : []
            }
            loading={propertyStockingRatesData.type === 'loading'}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface RainGaugeGraphProps {
  rainGauges?: RainGauge[];
  loading?: boolean;
}

const RainGaugeGraph: React.FC<RainGaugeGraphProps> = ({
  rainGauges,
  loading,
}) => {
  const { formatMessage } = useI18n();

  const currentYear = new Date().getFullYear();
  const [selected, setSelected] = React.useState<RainGauge['id']>(
    RainGaugeGraph[0]?.id || null
  );

  const lengthUnit = useSetting('lengthUnit') as LengthUnits;
  const rainGauge = useRainGauge(selected);
  const fetched = useRef<number[]>([]);

  const modeOptions = rainGauges?.map((rainGauge) => ({
    value: rainGauge.id,
    label: rainGauge.name,
    selected: rainGauge.id === selected,
  }));

  const handleSelect = (option: (string | number)[]) =>
    setSelected(option[0] as any);

  useEffect(() => {
    if (!rainGauges) return;
    setSelected(rainGauges[0]?.id || null);
  }, [rainGauges]);

  useEffect(() => {
    if (rainGauge) {
      const availableYears = rainGauge.entity
        ? getRainGaugeYearsAvailable(rainGauge.entity)
        : null;

      if (!availableYears) return;

      availableYears.forEach((y) => {
        if (!fetched.current.includes(y)) {
          fetched.current.push(y);
          return rainGauge.getYearTotals(y).catch(() => {});
        }
      });
    }
  }, [rainGauge]);

  const rainGaugeChartData = generateRainGaugeValues({
    rainGauge: rainGauge.entity,
    year: currentYear,
    lengthUnit,
  });
  const rainGaugeChartDataExpanded = generateRainGaugeValues({
    rainGauge: rainGauge.entity,
    lengthUnit,
  });

  const eChartData = useGenerateRainGaugeMonthlyRainfallChart({
    data: rainGaugeChartData.yearsData,
  });
  const eChartDataExpanded = useGenerateRainGaugeMonthlyRainfallChart({
    data: rainGaugeChartDataExpanded.yearsData,
    params: {
      average: rainGaugeChartDataExpanded.averageMonthlyRainfall
        ? rainGaugeChartDataExpanded.averageMonthlyRainfall
        : null,
    },
  });

  return (
    <ChartContainer
      title={formatMessage({
        defaultMessage: 'Rain Gauges',
        description: 'dashboard.chart.rain-gauge.title',
      })}
      titleHref={ROUTE_NAME.RAIN_GAUGES_LIST}
      headerOptions={
        modeOptions && modeOptions.length > 1 && rainGauge ? (
          <ListSelect
            id="rain-gauge-select"
            size="small"
            options={modeOptions.map((option) => ({
              label: option.label,
              value: option.value,
              selected: option.value === selected,
            }))}
            onChange={handleSelect}
          />
        ) : null
      }
      data={eChartData}
      dataExpanded={eChartDataExpanded}
      loading={loading}
    />
  );
};

const HistoricEventsTab = () => {
  const { formatMessage } = useI18n();

  return (
    <ContentContainer
      title={formatMessage({
        defaultMessage: 'Historic Events',
        description: 'dashboard.chart.historic-events.title',
      })}
      titleHref={ROUTE_NAME.SUMMARY_EVENTS_LIST}
    >
      <Box
        style={{
          alignContent: 'center',
          justifyContent: 'center',
          height: '400px',
        }}
      >
        <HistoricEvents
          title={formatMessage({
            defaultMessage: 'Historic Events list',
            description: 'dashboard.chart.historic-events.list',
          })}
          className={styles.tabHistoryTimeline}
        />
      </Box>
    </ContentContainer>
  );
};
