import { BillingRegion } from './User';

export enum SubscriptionStatus {
  active = 'active',
  canceled = 'canceled',
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  past_due = 'past_due',
  trialing = 'trialing',
  unpaid = 'unpaid',
}

export enum SubscriptionDisabledStatus {
  trialEnded = 'trial-ended',
  subscriptionExpired = 'subscription-expired',
  inactiveProperty = 'inactive-property',
}

export interface MobbleSubscription {
  id: string;
  status: SubscriptionStatus;
  subscriptionName: string;
  priceID: string;
  productID: string;
  maxUsers: number;
  maxProperties: number;
  billingAmount: number;
  billingInterval: string;
  currentPeriodEnd: string;
  currentPeriodStart: string;
  endedAt: string;
  cancelAt: string;
}

export interface StripeProduct {
  id: string;
  name: string;
  description: string;
  prices: StripePrice[];
}

export interface StripePrice {
  id: string;
  unitAmount: number;
  interval: string;
  maxUsers: number;
  maxProperties: number;
  canPurchase: boolean;
}

export const getProductValues = (product: StripeProduct) => ({
  name: product.name,
  price: product.prices[0].unitAmount / 100,
  interval: product.prices[0].interval,
  canPurchase: product.prices[0].canPurchase,
  description: product.description,
});

const ENABLED_SUBSCRIPTIONS = [
  SubscriptionStatus.active,
  SubscriptionStatus.trialing,
];

export const getIsSubscriptionEnabled = (status: SubscriptionStatus) =>
  ENABLED_SUBSCRIPTIONS.includes(status);

export const findActiveSubscription = (subscriptions: MobbleSubscription[]) =>
  subscriptions.find(
    (sub) =>
      sub.status === SubscriptionStatus.active ||
      sub.status === SubscriptionStatus.trialing
  );

export const findLatestSubscription = (
  subscriptions: MobbleSubscription[]
): MobbleSubscription | null =>
  subscriptions?.length
    ? subscriptions.reduce((acc, curr) => {
        if (
          new Date(curr.currentPeriodStart) > new Date(acc.currentPeriodStart)
        ) {
          return curr;
        }
        return acc;
      })
    : null;

export const getDisabledStatus = ({
  subscription,
  isTrial,
  isPropertySubscriptionEnabled,
}: {
  subscription: MobbleSubscription | null;
  isTrial: boolean;
  isPropertySubscriptionEnabled: boolean;
}): SubscriptionDisabledStatus | null => {
  if (!subscription) {
    return null;
  }

  if (isPropertySubscriptionEnabled) {
    return null;
  }

  if (isTrial) {
    return SubscriptionDisabledStatus.trialEnded;
  }

  if (
    [SubscriptionStatus.past_due, SubscriptionStatus.unpaid].find(
      (status) => status === subscription.status
    )
  ) {
    return SubscriptionDisabledStatus.subscriptionExpired;
  }

  return SubscriptionDisabledStatus.inactiveProperty;
};

export const CONTACT_EMAIL = 'hello@mobble.io';
export const CONTACT_PHONE_AU = '+61 481 614 546';
export const CONTACT_PHONE_NZ = '+64 20 4120 8383';

export const toContactEmailURL = (email: string) => `mailto:${email}`;
export const toContactPhoneURL = (phone: string) =>
  `tel:${phone.replace(/ /g, '')}`;

export const billingRegionToPhoneNumber = (billingRegion: BillingRegion) => {
  switch (billingRegion) {
    case BillingRegion.NZ:
      return CONTACT_PHONE_NZ;

    case BillingRegion.AU:
    default:
      return CONTACT_PHONE_AU;
  }
};
