import { useEffect, useState } from 'react';
import AgWorldIcon from '@assets/dashboard-icons/agworld.svg';
import AppleIcon from '@assets/dashboard-icons/apple.svg';
import GooglePlayIcon from '@assets/dashboard-icons/google-play.svg';

import { useI18n } from '@mobble/i18n';
import { IconName } from '@mobble/icon';
import {
  advisorRoles,
  relationshipToRole,
  UserRole,
  Relations,
} from '@mobble/models/src/model/User';
import {
  useGetUser,
  useMobs,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { useGetMobbleApps } from '@src/screens/Settings/hooks/useGetMobbleApps';
import { useGetInvitationMeta } from '@src/hooks/useInvitations';
import { IntlFormatters } from 'react-intl';
import { toPath } from '@src/interfaces/Routing';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

export enum Suggestions {
  apps = 'apps',
  inviter = 'inviter',
  paddocks = 'paddocks',
  mobs = 'mobs',
  users = 'users',
  partners = 'partners',
  integrations = 'integrations',
}

interface ButtonType {
  label: string;
  href: string;
  icon?: IconName;
  image?: any;
}

export interface DashboardSuggestion {
  id: Suggestions;
  title: string;
  description?: string;
  buttons: ButtonType[];
}

export enum SuggestionStatus {
  active = 'active',
  dismissed = 'dismissed',
}

interface SuggestionStatuses {
  [Suggestions.apps]: SuggestionStatus;
  [Suggestions.inviter]: SuggestionStatus;
  [Suggestions.paddocks]: SuggestionStatus;
  [Suggestions.mobs]: SuggestionStatus;
  [Suggestions.users]: SuggestionStatus;
  [Suggestions.partners]: SuggestionStatus;
  [Suggestions.integrations]: SuggestionStatus;
}

const DASHBOARD_SUGGESTIONS = 'dashboard-suggestions';
const UPLOAD_MAP_FILE_URL =
  'https://www.mobble.io/help/how-to-upload-maps-to-mobble';
const CREATE_PADDOCK_LINK = ROUTE_NAME.MAP_OVERVIEW + '#add-paddock';

const getAllSuggestions = ({
  formatMessage,
  formatRoleLabel,
  inviter,
}: {
  formatMessage: IntlFormatters['formatMessage'];
  formatRoleLabel: (role: UserRole) => string;
  inviter?: {
    email: string;
    name: string;
    role: UserRole;
  };
}): DashboardSuggestion[] => [
  {
    id: Suggestions.apps,
    title: formatMessage({
      defaultMessage: 'Download Mobble App',
      description: 'dashboard.tile.suggestions.apps.card.title',
    }),
    description: formatMessage({
      defaultMessage:
        'Install the Mobble app on your mobile for use out in the paddock',
      description: 'dashboard.tile.suggestions.apps.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Download On IOS',
          description: 'dashboard.tile.suggestions.apps.card.title',
        }),
        href: 'https://apps.apple.com/au/app/mobble-farm-management/id1505306095',
        image: AppleIcon,
      },
      {
        label: formatMessage({
          defaultMessage: 'Download On Android',
          description: 'dashboard.tile.suggestions.apps.card.title',
        }),
        href: 'https://play.google.com/store/apps/details?id=io.mobble.app.production&pcampaignid=web_share',
        image: GooglePlayIcon,
      },
    ],
  },
  ...((inviter
    ? [
        {
          id: Suggestions.inviter,
          title: formatMessage(
            {
              defaultMessage: 'Invite your {relationship} {name}',
              description: 'dashboard.tile.suggestions.inviter.card.title',
            },
            {
              relationship: inviter.role,
              name: inviter.name,
            }
          ),
          description: formatMessage(
            {
              defaultMessage:
                'Invite your {relationship} to help you manage your property',
              description:
                'dashboard.tile.suggestions.inviter.card.description',
            },
            {
              relationship: formatRoleLabel(inviter.role),
            }
          ),
          buttons: [
            {
              label: formatMessage(
                {
                  defaultMessage: 'Invite {email}',
                  description: 'dashboard.tile.suggestions.inviter.card.title',
                },
                {
                  email: inviter.email,
                }
              ),
              href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER_INVITE, {
                role: relationshipToRole(inviter.role),
              }),
              icon: 'plus',
            },
          ],
        },
      ]
    : []) as DashboardSuggestion[]),
  {
    id: Suggestions.paddocks,
    title: formatMessage({
      defaultMessage: 'Add Paddocks',
      description: 'dashboard.tile.suggestions.paddocks.card.title',
    }),
    description: formatMessage({
      defaultMessage: 'Add or upload your paddocks to Mobble',
      description: 'dashboard.tile.suggestions.paddocks.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Add Paddocks',
          description: 'dashboard.tile.suggestions.paddocks.card.title',
        }),
        href: UPLOAD_MAP_FILE_URL,
        icon: 'upload',
      },
      {
        label: formatMessage({
          defaultMessage: 'Add Paddocks',
          description: 'dashboard.tile.suggestions.paddocks.card.title',
        }),
        href: ROUTE_NAME.SETTINGS_INTEGRATIONS,
        image: AgWorldIcon,
      },
      {
        label: formatMessage({
          defaultMessage: 'Add Paddocks',
          description: 'dashboard.tile.suggestions.paddocks.card.title',
        }),
        href: CREATE_PADDOCK_LINK,
        icon: 'edit',
      },
    ],
  },
  {
    id: Suggestions.mobs,
    title: formatMessage({
      defaultMessage: 'Add Mobs',
      description: 'dashboard.tile.suggestions.mobs.card.title',
    }),
    description: formatMessage({
      defaultMessage:
        'Add your first mob via the green plus button on the maps page',
      description: 'dashboard.tile.suggestions.mobs.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Add Mobs',
          description: 'dashboard.tile.suggestions.mobs.card.title',
        }),
        href: ROUTE_NAME.MODAL_MOB_CREATE,
        icon: 'circle',
      },
    ],
  },
  {
    id: Suggestions.users,
    title: formatMessage({
      defaultMessage: 'Invite the team',
      description: 'dashboard.tile.suggestions.users.card.title',
    }),
    description: formatMessage({
      defaultMessage: 'Invite your farming team to Mobble',
      description: 'dashboard.tile.suggestions.users.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Invite the team',
          description: 'dashboard.tile.suggestions.users.card.title',
        }),
        href: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER,
        icon: 'plus',
      },
    ],
  },
  {
    id: Suggestions.partners,
    title: formatMessage({
      defaultMessage: 'Invite your trusted partners',
      description: 'dashboard.tile.suggestions.partners.card.title',
    }),
    description: formatMessage({
      defaultMessage:
        'Give limited access to your trusted partners such as your Farm Advisor, Livestock Agent, Contractors, and Accountant',
      description: 'dashboard.tile.suggestions.partners.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Invite your trusted partners',
          description: 'dashboard.tile.suggestions.partners.card.title',
        }),
        href: ROUTE_NAME.SETTINGS_LIST,
        icon: 'eye',
      },
    ],
  },
  {
    id: Suggestions.integrations,
    title: formatMessage({
      defaultMessage: 'Activate integrations',
      description: 'dashboard.tile.suggestions.integrations.card.title',
    }),
    description: formatMessage({
      defaultMessage: 'Connect Mobble to other apps',
      description: 'dashboard.tile.suggestions.integrations.card.description',
    }),
    buttons: [
      {
        label: formatMessage({
          defaultMessage: 'Activate integrations',
          description: 'dashboard.tile.suggestions.integrations.card.title',
        }),
        href: ROUTE_NAME.SETTINGS_INTEGRATIONS,
        icon: 'grid',
      },
    ],
  },
];

export const useLocalStorageSuggestions = () => {
  const [suggestions, setSuggestions] = useState<SuggestionStatuses | null>(
    null
  );

  useEffect(() => {
    const suggestions = localStorage.getItem(DASHBOARD_SUGGESTIONS);
    if (suggestions) {
      setSuggestions(JSON.parse(suggestions));
      return;
    }
    setSuggestions({
      [Suggestions.apps]: SuggestionStatus.active,
      [Suggestions.inviter]: SuggestionStatus.active,
      [Suggestions.paddocks]: SuggestionStatus.active,
      [Suggestions.mobs]: SuggestionStatus.active,
      [Suggestions.users]: SuggestionStatus.active,
      [Suggestions.partners]: SuggestionStatus.active,
      [Suggestions.integrations]: SuggestionStatus.active,
    });
  }, []);

  const removeSuggestion = (suggestionId: Suggestions) => {
    const newSuggestions = {
      ...suggestions,
      [suggestionId]: SuggestionStatus.dismissed,
    };
    setSuggestions(newSuggestions);
    localStorage.setItem(DASHBOARD_SUGGESTIONS, JSON.stringify(newSuggestions));
  };

  return { suggestions, removeSuggestion };
};

const rolesCanAddItems = [UserRole.Owner, UserRole.Admin, UserRole.User];
const roleCanInvite = [UserRole.Owner, UserRole.Admin];
const roleCanAccessIntegrations = [UserRole.Owner];

const useCheckIfRecommendationIsRelevant = () => {
  const properties = useProperties();
  const user = useGetUser();
  const paddocks = usePaddocks(properties.selected.id);
  const mobs = useMobs(properties.selected.id);
  const { apps } = useGetMobbleApps();
  const hasEnabledApps = apps?.some((app) => app.enabled);
  const hasMobbleConnectUser = properties.selected.users.some((u) =>
    advisorRoles.includes(u.role)
  );
  const hasUsers = properties.selected.users.length > 1;
  const localStorageSuggestions = useLocalStorageSuggestions();

  const isRelevant = (suggestion: DashboardSuggestion) => {
    switch (suggestion.id) {
      case Suggestions.apps:
        return (
          localStorageSuggestions.suggestions?.apps !==
          SuggestionStatus.dismissed
        );
      case Suggestions.inviter:
        return (
          localStorageSuggestions.suggestions?.inviter !==
          SuggestionStatus.dismissed
        );
      case Suggestions.paddocks:
        if (
          paddocks?.entities.length > 1 ||
          !rolesCanAddItems.includes(user.role)
        ) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.paddocks !==
          SuggestionStatus.dismissed
        );
      case Suggestions.mobs:
        if (mobs?.entities.length || !rolesCanAddItems.includes(user.role)) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.mobs !==
          SuggestionStatus.dismissed
        );
      case Suggestions.users:
        if (hasUsers || !roleCanInvite.includes(user.role)) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.users !==
          SuggestionStatus.dismissed
        );
      case Suggestions.partners:
        if (hasMobbleConnectUser || !roleCanInvite.includes(user.role)) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.partners !==
          SuggestionStatus.dismissed
        );
      case Suggestions.integrations:
        if (hasEnabledApps || !roleCanAccessIntegrations.includes(user.role)) {
          return false;
        }
        return (
          localStorageSuggestions.suggestions?.integrations !==
          SuggestionStatus.dismissed
        );
      default:
        return false;
    }
  };

  return {
    isRelevant,
    removeSuggestion: localStorageSuggestions.removeSuggestion,
  };
};

export interface DashboardSuggestionDetails {
  suggestions: DashboardSuggestion[];
  suggestionCount: number;
  totalSuggestionCount: number;
  removeSuggestion: (suggestionId: Suggestions) => void;
}

export const useDashboardSuggestions = (): DashboardSuggestionDetails => {
  const { formatMessage } = useI18n();
  const formatRoleLabel = useRoleLabels();

  const invitationMeta = useGetInvitationMeta();
  const inviter =
    invitationMeta &&
    invitationMeta?.invitersRelationship !== Relations.Acquaintance
      ? {
          email: invitationMeta.invitersEmail,
          name: invitationMeta.invitersName,
          role: relationshipToRole(invitationMeta.invitersRelationship),
        }
      : undefined;

  const { isRelevant, removeSuggestion } = useCheckIfRecommendationIsRelevant();
  const availableSuggestions = getAllSuggestions({
    formatMessage,
    formatRoleLabel,
    inviter,
  });

  const suggestions = availableSuggestions.filter(isRelevant);

  return {
    suggestions,
    suggestionCount: suggestions.length,
    totalSuggestionCount: availableSuggestions.length,
    removeSuggestion,
  };
};
