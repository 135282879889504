import {
  type AreaUnits,
  type VolumeUnits,
  type MassUnits,
  type LengthUnits,
  type MassVolumeUnits,
  type DistanceUnits,
} from '@mobble/shared/src/core/Quantity';
import { roundNumberTo } from '@mobble/shared/src/core/Number';

export interface Settings {
  areaUnit: AreaUnits;
  volumeUnit: VolumeUnits;
  massUnit: MassUnits;
  lengthUnit: LengthUnits;
  distanceUnit: DistanceUnits;
  massVolumeUnit: MassVolumeUnits;
  dateFormat: string;
  timeFormat: string;
  currencySymbol: string;
  stockingUnit: string;

  mobBreed: string;
  mobType: string;
}

export enum StockingUnit {
  DSE = 'dse',
  LSU_AUS = 'lsu_aus',
  AE = 'ae',
  SU = 'su',
  AU = 'au',
}

export const ALL_STOCKING_UNITS = [
  StockingUnit.DSE,
  StockingUnit.LSU_AUS,
  StockingUnit.AE,
  StockingUnit.SU,
  StockingUnit.AU,
];

const stockingUnitConversion = {
  [StockingUnit.DSE]: 1,
  [StockingUnit.LSU_AUS]: 0.145,
  [StockingUnit.AE]: 0.105,
  [StockingUnit.SU]: 0.724,
  [StockingUnit.AU]: 0.085,
};

export const isStockingUnit = (value: string): value is StockingUnit => {
  return ALL_STOCKING_UNITS.includes(value as StockingUnit) || false;
};

export const stockingRateToDSE =
  (stockingUnit: StockingUnit) => (quantity: number) => {
    const conversion =
      stockingUnitConversion[stockingUnit] || stockingUnitConversion.dse;

    return quantity / conversion;
  };

export const stockingUnitDefaultStockingRates = {
  [StockingUnit.DSE]: {
    Sheep: 1,
    Cattle: 8,
    Goats: 1,
    Other: 1,
  },
  [StockingUnit.LSU_AUS]: {
    Sheep: 0.145,
    Cattle: 1,
    Goats: 0.145,
    Other: 1,
  },
  [StockingUnit.AE]: {
    Sheep: 0.105,
    Cattle: 1,
    Goats: 0.105,
    Other: 1,
  },
  [StockingUnit.SU]: {
    Sheep: 1,
    Cattle: 8,
    Goats: 1,
    Other: 1,
  },
  [StockingUnit.AU]: {
    Sheep: 0.085,
    Cattle: 1,
    Goats: 0.085,
    Other: 1,
  },
};

export const livestockTypeToDefaultStockingRate = (
  livestockType: string,
  unit: StockingUnit = StockingUnit.DSE
) => {
  if (!isStockingUnit(unit)) {
    return stockingUnitDefaultStockingRates.dse[livestockType];
  }
  return stockingUnitDefaultStockingRates[unit][livestockType];
};

enum States {
  NSW = 'NSW',
  VIC = 'VIC',
  SA = 'SA',
  QLD = 'QLD',
  NT = 'NT',
  WA = 'WA',
  TAS = 'TAS',

  NZ = 'NZ',
  USA = 'USA',
}

const timeZoneToState = (timeZone: string) => {
  switch (timeZone) {
    case 'Australia/Sydney':
    case 'Australia/Broken_Hill':
    case 'Australia/Lord_Howe':
      return States.NSW;

    case 'Australia/Melbourne':
      return States.VIC;

    case 'Australia/Adelaide':
      return States.SA;

    case 'Australia/Brisbane':
    case 'Australia/Lindeman':
      return States.QLD;

    case 'Australia/Darwin':
      return States.NT;

    case 'Australia/Perth':
    case 'Australia/Eucla':
      return States.WA;

    case 'Australia/Hobart':
    case 'Australia/Currie':
      return States.TAS;

    case 'Pacific/Auckland':
      return States.NZ;

    case 'America/New_York':
    case 'America/Detroit':
    case 'America/Louisville':
    case 'America/Indianapolis':
    case 'America/Kentucky/Monticello':
    case 'America/Indiana/Indianapolis':
    case 'America/Indiana/Vincennes':
    case 'America/Indiana/Winamac':
    case 'America/Indiana/Marengo':
    case 'America/Indiana/Petersburg':
    case 'America/Indiana/Vevay':
    case 'America/Chicago':
    case 'America/Indiana/Tell_City':
    case 'America/Indiana/Knox':
    case 'America/Menominee':
    case 'America/North_Dakota/Center':
    case 'America/North_Dakota/New_Salem':
    case 'America/North_Dakota/Beulah':
    case 'America/Denver':
    case 'America/Boise':
    case 'America/Phoenix':
    case 'America/Los_Angeles':
    case 'America/Metlakatla':
    case 'America/Anchorage':
    case 'America/Juneau':
    case 'America/Sitka':
    case 'America/Yakutat':
    case 'America/Nome':
    case 'America/Adak':
    case 'America/Atka':
    case 'Pacific/Honolulu':
    case 'America/Tijuana':
    case 'America/Santa_Isabel':
    case 'America/Resolute':
      return States.USA;

    default:
      return 'Unknown or non-Australian timezone';
  }
};

export const timeZoneToStockingUnit = (timeZone: string) => {
  const state = timeZoneToState(timeZone);

  switch (state) {
    case States.NSW:
    case States.VIC:
    case States.SA:
    case States.WA:
    case States.TAS:
      return StockingUnit.DSE;

    case States.NT:
      return StockingUnit.AE;

    case States.QLD:
      return StockingUnit.LSU_AUS;

    case States.NZ:
      return StockingUnit.SU;

    case States.USA:
      return StockingUnit.AU;

    default:
      return StockingUnit.DSE;
  }
};

export enum CountryCodes {
  AU = 'AU',
  NZ = 'NZ',
  CA = 'CA',
  US = 'US',
}

export const timeZoneToCountryCode = (timeZone: string): CountryCodes => {
  if (timeZone.startsWith('Australia')) {
    return CountryCodes.AU;
  }
  if (timeZone.startsWith('Pacific/Auckland')) {
    return CountryCodes.NZ;
  }
  if (timeZone.startsWith('Canada')) {
    return CountryCodes.CA;
  }
  if (timeZone.startsWith('America')) {
    return CountryCodes.US;
  }
  return CountryCodes.AU;
};

export const formatStockingRate = (
  stockingRate: number,
  unit: StockingUnit
) => {
  const conversion = stockingUnitConversion[unit] || stockingUnitConversion.dse;
  return Number(roundNumberTo(2)(stockingRate * conversion));
};
