import React from 'react';

import {
  ConfiguredPropertyTypeGroup,
  getConfiguredPropertyTypes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import { base64decode, base64encode } from '@mobble/shared/src/core/String';
import { useSettingsPropertyClassesTypesView } from '@mobble/shared/src/hooks/useSettingsPropertyClassesTypesView';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { NavigationList } from '@src/stories/Components/UX/NavigationList';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { RootStackParamList, ScreenRendererProps } from '../config/types';

export const SettingsPropertyClassesTypesGroupHeader: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const linkTo = useLinking();
  const { strings } = useSettingsPropertyClassesTypesView();
  const { classTypes } = route.params;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));
  const typeLowerCase = livestockType ? livestockType.toLowerCase() : null;
  const groupKey = typeLowerCase
    ? `toc_${typeLowerCase}_${group === 'class' ? 'classe' : group}s`
    : group;

  return (
    <ScreenHeader
      title={strings[groupKey]}
      breadcrumbs={[
        {
          title: strings.settings,
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: strings.classesTypesAndTags,
          href: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES);
      }}
    />
  );
};

export const SettingsPropertyClassesTypesGroup: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const { classTypes } = route.params;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));

  const noEditGroups = [ConfiguredPropertyTypeGroup.livestockType];

  const properties = useProperties();

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const property = properties.selected;
  const parentId = getLivestockParentIdForValue(livestockType, property.types);

  const items = getConfiguredPropertyTypes(property, group, parentId);

  if (!items.length) {
    return null;
  }

  if (noEditGroups.includes(group)) {
    return (
      <NavigationList
        items={[
          {
            items: items.map((type) => ({
              title: type.label,
            })),
          },
        ]}
      />
    );
  }

  const navigationItems = items.map((item) => ({
    title: item.label,
    href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_EDIT, {
      classTypes: base64encode(
        JSON.stringify({
          group,
          livestockType,
          valueType: item.type,
          id: item.id,
        })
      ),
    }) as keyof RootStackParamList,
    color: item.color,
  }));

  return <NavigationList items={navigationItems} />;
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP,
  header: SettingsPropertyClassesTypesGroupHeader,
  component: SettingsPropertyClassesTypesGroup,
};
