import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import {
  filterHistoricEvents,
  type HistoricEvent,
} from '@mobble/models/src/model/HistoricEvent';
import { formatDateAndTime, fromRawDate } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useHistoricEventsFilterItems } from '@mobble/shared/src/hooks/useHistoricEventsFilterItems';
import { useHistoricEvents, useProperties } from '@mobble/store/src/hooks';
import { ExtFilterParams } from '@mobble/store/src/lib/ExtStatus';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { HistoricEventCard } from '@src/stories/Views/HistoricEvent/HistoricEventCard';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';

import * as ROUTE_NAME from '../config/routeNames';
import { RootStackParamList } from '../config/types';

/**
 * HistoricEvents wraps an EntitiesViewer in list view, all other props are passed through
 */
export interface HistoricEventsProps
  extends Partial<EntitiesViewerProps<HistoricEvent>> {
  /**
   * Title should be set for accessibility
   */
  title: string;

  /**
   * Default filter for the events
   */
  extFilter?: ExtFilterParams;

  /**
   * Set false to disable links on the card
   */
  enableLinks?: boolean;
}

const messages = defineMessages({
  refresh: {
    defaultMessage: 'Refresh',
    description: 'generic.refresh',
  },
  time: {
    defaultMessage: 'Time',
    description: 'historic_events.table.column.time',
  },
  eventType: {
    defaultMessage: 'Event type',
    description: 'historic_events.table.column.event_type',
  },
  createdBy: {
    defaultMessage: 'Created by',
    description: 'historic_events.table.column.created_by',
  },
  description: {
    defaultMessage: 'Description',
    description: 'historic_events.table.column.description',
  },
});

const getEventRouteAndProps = (
  event: HistoricEvent
): null | {
  route: keyof RootStackParamList;
  props: { [key: string]: string } | undefined;
} => {
  if (!event.linksTo?.id) {
    return null;
  }

  switch (event.eventType) {
    case 'CasualtyAddition':
    case 'CasualtyEdit':
      return {
        route: ROUTE_NAME.CASUALTY_DETAIL,
        props: { casualtyId: event.linksTo?.id },
      };

    case 'ChemicalAddition':
    case 'ChemicalEdit':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'ChemicalBatchAddition':
    case 'ChemicalBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'FeedAddition':
    case 'FeedEdit':
    case 'FeedFinished':
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo?.id,
        },
      };

    case 'FeedBatchAddition':
    case 'FeedBatchEdit': {
      if (!event.related.inventoryId || !event.linksTo.parentId) {
        return null;
      }
      return {
        route: ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL,
        props: {
          inventoryId: event.related.inventoryId,
          inventoryItemId: event.linksTo.parentId,
          inventoryItemBatchId: event.linksTo.id,
        },
      };
    }

    case 'MapAssetAddition':
    case 'MapAssetEdit':
      return {
        route: ROUTE_NAME.MAP_ASSET_DETAIL,
        props: { mapAssetId: event.linksTo?.id },
      };

    case 'MobActionAddition':
    case 'MobActionEdit':
      return {
        route: ROUTE_NAME.MOB_ACTION_DETAIL,
        props: { mobActionId: event.linksTo?.id },
      };

    case 'MobAddition':
    case 'MobEdit':
      return {
        route: ROUTE_NAME.MOB_DETAIL,
        props: { mobId: event.linksTo?.id },
      };

    case 'PaddockActionAddition':
    case 'PaddockActionEdit':
      return {
        route: ROUTE_NAME.PADDOCK_ACTION_DETAIL,
        props: { paddockActionId: event.linksTo?.id },
      };

    case 'PaddockAddition':
    case 'PaddockEdit':
      return {
        route: ROUTE_NAME.PADDOCK_DETAIL_INFO,
        props: { paddockId: event.linksTo?.id },
      };
    case 'PropertyAddition':
      return null;

    case 'RainGaugeAddition':
    case 'RainGaugeEdit':
    case 'RainGaugeReadingAddition':
    case 'RainGaugeReadingEdit':
      return {
        route: ROUTE_NAME.RAIN_GAUGE_DETAIL,
        props: { rainGaugeId: event.linksTo?.id },
      };

    case 'SaleAddition':
    case 'SaleEdit':
      return {
        route: ROUTE_NAME.SALE_DETAIL,
        props: { saleId: event.linksTo?.id },
      };

    case 'NaturalIncreaseAddition':
      return {
        route: ROUTE_NAME.NATURAL_INCREASE_DETAIL,
        props: { naturalIncreaseId: event.linksTo?.id },
      };

    case 'PurchaseAddition':
      return {
        route: ROUTE_NAME.PURCHASE_DETAIL,
        props: { purchaseId: event.linksTo?.id },
      };

    case 'TaskAddition':
    case 'TaskEdit':
      return {
        route: ROUTE_NAME.TASK_DETAIL,
        props: { taskId: event.linksTo?.id },
      };

    default:
      if (event.linksTo) {
        console.warn('Event type not handled: ', event.eventType);
      }
      return null;
  }
};

export const HistoricEvents: React.FC<HistoricEventsProps> = ({
  extFilter,
  enableLinks = true,
  ...others
}) => {
  const linkTo = useLinking();
  const strings = useMessages(messages);
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const historicEvents = useHistoricEvents(propertyId, extFilter);
  const filterItems = useHistoricEventsFilterItems();

  const { refresh } = useEntitiesRefresher([historicEvents], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, historicEvents.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <EntitiesViewer
      headerButtons={[
        {
          loading: historicEvents.refreshing || historicEvents.loading,
          label: strings.refresh,
          onClick: refresh,
        },
      ]}
      asList
      provider={historicEvents}
      applyFilter={filterHistoricEvents}
      filterItems={filterItems}
      tableColumns={[
        {
          key: 'time',
          label: strings.time,
          totals: false,
          toValue: (historicEvent) =>
            fromRawDate(historicEvent.date).toDate().getTime(),
          valueToString: (_, historicEvent) =>
            formatDateAndTime(fromRawDate(historicEvent.date)),
        },
        {
          key: 'event_type',
          label: strings.eventType,
          totals: false,
          toValue: (historicEvent) => historicEvent.eventType,
        },
        {
          key: 'created_by',
          label: strings.createdBy,
          totals: false,
          toValue: (historicEvent) => historicEvent.createdBy.name,
        },
        {
          key: 'description',
          label: strings.description,
          totals: false,
          toValue: (historicEvent) => historicEvent.description,
        },
      ]}
      renderEntityCard={(item) => {
        const routeAndProps = enableLinks ? getEventRouteAndProps(item) : null;
        const path = routeAndProps
          ? toPath(routeAndProps?.route, routeAndProps?.props)
          : undefined;

        return (
          <HistoricEventCard
            historicEvent={item}
            href={path}
            onClick={
              routeAndProps
                ? () => {
                    linkTo(routeAndProps.route, routeAndProps.props);
                  }
                : undefined
            }
          />
        );
      }}
      {...others}
    />
  );
};
