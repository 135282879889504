import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { EntitiesFilterItem } from '@mobble/models/src/model/BaseEntity';
import { MobAction } from '@mobble/models/src/model/MobAction';
import { useMobActions, useProperties } from '@mobble/store/src/hooks';

const messages = defineMessages({
  dateRange: {
    defaultMessage: 'Date Range',
    description: 'mob_actions.filter.group.type.date_range.label',
  },
  type: {
    defaultMessage: 'Action type',
    description: 'mob_actions.filter.group.type.label',
  },
});

export const useMobActionsFilterItems = () => {
  const strings = useMessages(messages);
  const properties = useProperties();
  const mobActions = useMobActions(properties.selected?.id);

  return (): EntitiesFilterItem<MobAction>[] => [
    {
      title: strings.dateRange,
      type: 'date-range',
      group: 'dateRange',
      meta: {
        fixed: true,
      },
    },
    {
      title: strings.type,
      type: 'select-multiple',
      group: 'type',
      data: Array.from(
        new Set(mobActions.entities.map((entity) => entity.type))
      )
        .map((value) => ({
          value,
        }))
        .filter((value) => value.value !== ''),
    },
  ];
};
