import { InventoryItemUsage } from '@mobble/models/src/model/InventoryItem';
import { Quantity } from '@mobble/shared/src';
import { DateRange, formatDate } from '@mobble/shared/src/core/Date';
import { roundNumber } from '@mobble/shared/src/core/Number';
import {
  makeQuantityOfMassVolume,
  MassVolumeUnits,
} from '@mobble/shared/src/core/Quantity';

const getArrayOfDatesBetweenTwoDays = (startDate: Date, endDate: Date) => {
  const dates = [];
  while (startDate <= endDate) {
    dates.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }
  return dates;
};

export interface InventoryItemUsageData {
  label: string;
  value: number;
}

export const convertInventoryItemUsageData = (
  inventoryItemUsage: InventoryItemUsage,
  range: DateRange,
  unit: MassVolumeUnits
) => {
  const days = getArrayOfDatesBetweenTwoDays(
    new Date(range.start),
    new Date(range.end)
  );

  const data = days
    ?.reduce((acc, date) => {
      const dateFormatted = formatDate(date, 'YYYY-MM-DD');

      const valuesOfDate = inventoryItemUsage?.data
        .filter((curr) => formatDate(curr.date, 'YYYY-MM-DD') === dateFormatted)
        .reduce((highest, current) => {
          const value = Quantity.convertTo(unit)(
            makeQuantityOfMassVolume(current.unit, current.value)
          ).value;

          if (value > highest) {
            return value;
          }

          return highest;
        }, 0);

      const previousValue = acc[acc.length - 1]?.value || 0;

      const yValue = valuesOfDate ? valuesOfDate : previousValue;

      return [
        ...acc,
        {
          label: dateFormatted,
          value: yValue,
        },
      ];
    }, [])
    .map((curr) => ({ ...curr, value: Number(roundNumber(curr.value)) }));

  return data;
};
