import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import styles from './ContentContainer.scss';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import Icon from '../Icon';

const cx = classNames.bind(styles);

export interface ContentContainerProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
  /**
   * Title of the container
   */
  title: string;

  /**
   * Title href to navigate users, accessible via title click event.
   * If not provided, title will not be clickable.
   */
  titleHref?: string | ((ev: any) => void);

  /**
   * Header options to be displayed on the right side of the title
   */
  headerOptions?: React.ReactNode;
}

/**
 * Container component
 */
const ContentContainer: React.FC<ContentContainerProps> = ({
  title,
  headerOptions = null,
  titleHref,
  children,
  className,
}) => {
  const rootClasses = cx(
    {
      ContentContainer: true,
    },
    className
  );

  return (
    <Box className={[styles.container, rootClasses]}>
      <div className={styles.containerHeader}>
        <Clickable
          href={titleHref}
          disabled={!titleHref}
          className={styles.containerTitle}
        >
          <h2 className={styles.title}>{title}</h2>
          {titleHref && (
            <Icon
              name="link"
              size="small"
              className={styles.containerTitleIcon}
            />
          )}
        </Clickable>
        <Box className={styles.containerOptions}>{headerOptions}</Box>
      </div>

      <Box className={[styles.containerContent].join(' ')}>{children}</Box>
    </Box>
  );
};

export default ContentContainer;
