import React, { useEffect, useState } from 'react';

import { useI18n } from '@mobble/i18n/src';

import { Button, Text } from '@src/components';
import { useTitle } from '@src/hooks/useTitle';

import { type MapStateComponentProps } from '../SharedTypes';

import styles from './style.scss';

export const Header: React.FC<MapStateComponentProps> = () => {
  const { formatMessage } = useI18n();
  const title = formatMessage({
    defaultMessage: 'Add a new paddock',
    description: 'screen.title.paddock_create_map',
  });
  useTitle(title);

  return (
    <header className={styles.header}>
      <Text tagName="h1" variant="larger" bold>
        {title}
      </Text>
      <Text tagName="p">
        {formatMessage({
          defaultMessage:
            'Select points on the map to draw the Paddock boundary.',
          description: 'map.overview.add_paddock.instructions',
        })}
      </Text>
    </header>
  );
};

export const Footer: React.FC<MapStateComponentProps> = ({
  stateRef,
  onSubmit,
  onCancel,
}) => {
  const { formatMessage } = useI18n();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const onUpdate = ({ points }) => {
      setReady(points.length >= 3);
    };

    stateRef.current?.addListener(onUpdate);

    return () => {
      stateRef.current?.removeListener(onUpdate);
    };
  }, [stateRef]);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerButtons}>
        <Button
          label={formatMessage({
            defaultMessage: 'Cancel',
            description: 'map.overview.add_paddock.footer.cancel.label',
          })}
          intent="destructive"
          outline
          type="reset"
          onClick={onCancel}
        />
        <Button
          label={formatMessage({
            defaultMessage: 'Add Paddock',
            description: 'map.overview.add_paddock.footer.submit.label',
          })}
          type="submit"
          disabled={!ready}
          onClick={onSubmit}
        />
      </div>
    </footer>
  );
};
