import { useIntl, type MessageDescriptor } from 'react-intl';

export interface IDefinedMessages {
  [key: string]: MessageDescriptor;
}

export interface IFormattedStrings {
  [key: string]: string;
}

/**
 * Returns an object of intl formatted message strings
 * @param messages
 * @param values
 */
export function useMessages<T extends IDefinedMessages>(
  messages: T,
  values?: any
): { [K in keyof T]: string } {
  const intl = useIntl();
  const strings = {} as { [K in keyof T]: string };

  // Return empty object if no messages provided
  if (!messages) {
    console.warn('messages not provided');
    return strings;
  }

  // Format messages to locale
  for (const key of Object.keys(messages)) {
    (strings as any)[key] = intl.formatMessage(messages[key], values);
  }
  return strings;
}
