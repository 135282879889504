import { useI18n, useMessages } from '@mobble/i18n/src';
import { UserRole } from '@mobble/models/src';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  owner: {
    defaultMessage: 'Owner',
    description: 'user_role.owner',
  },
  admin: {
    defaultMessage: 'Admin',
    description: 'user_role.admin',
  },
  view_only: {
    defaultMessage: 'View Only',
    description: 'user_role.view_only',
  },
  accountant: {
    defaultMessage: 'Accountant',
    description: 'user_role.accountant',
  },
  auditor: {
    defaultMessage: 'Auditor',
    description: 'user_role.auditor',
  },
  farm_advisor: {
    defaultMessage: 'Farm Advisor',
    description: 'user_role.farm_advisor',
  },
  stock_agent: {
    defaultMessage: 'Stock Agent',
    description: 'user_role.stock_agent',
  },
  map_view: {
    defaultMessage: 'Contractor',
    description: 'user_role.contractor',
  },
  user: {
    defaultMessage: 'User',
    description: 'user_role.user',
  },
});

export const useRoleLabels = () => {
  const strings = useMessages(messages);
  return (role: UserRole) => strings[role] || null;
};
