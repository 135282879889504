import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { Property } from '@mobble/models/src/model/Property';
import { CountryCodes } from '@mobble/models/src/model/Settings';
import { User, UserRole } from '@mobble/models/src/model/User';
import {
  formatDate,
  fromRawDate,
  fromRawDateWithTime,
  getDateRange,
} from '@mobble/shared/src/core/Date';
import { useGetUser, useProperties } from '@mobble/store/src/hooks';

import { DateRange, Text } from '@src/components';
import { useDateRange } from '@src/hooks/useDateRange';
import { useTimeZone } from '@src/hooks/useTimeZone';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { type ScreenRendererProps } from '@src/screens/config/types';
import { ChartPropertiesLivestockHistory } from '@src/stories/Components/Charts/Components/ChartLivestockHistory';
import { Box } from '@src/stories/Components/Layout/Box';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { LivestockReconciliationReport } from '@src/stories/Views/Reports/LivestockReconciliationReport';
import { LivestockReconciliationTiles } from '@src/stories/Views/Reports/LivestockReconciliationTiles';
import { LivestockSalesReport } from '@src/stories/Views/Reports/LivestockSalesReport';
import { LivestockSalesTiles } from '@src/stories/Views/Reports/LivestockSalesTiles';
import { PropertySelector } from '@src/stories/Views/Reports/PropertySelector';
import { PropertyTotalsGraphs } from '@src/stories/Views/Reports/PropertyTotalsGraphs';
import {
  mergeReportItems,
  usePropertyAggregateReportForRange,
} from '@src/stories/Views/Reports/usePropertyReportForRange';
import { usePropertiesStockingRatesForRange } from '@src/stories/Views/Reports/usePropertyStockingRatesForRange';

import styles from './reports.scss';

const getPropertiesWithReportingAccess = (
  properties: Property[],
  userId: User['id'],
  organisationId: Property['organisationId']
) => {
  return properties.filter(
    (property) =>
      property.organisationId === organisationId &&
      property.users.find(
        (user) =>
          user.id === userId &&
          (user.role === UserRole.Admin ||
            user.role === UserRole.Owner ||
            user.role === UserRole.Accountant ||
            user.role === UserRole.FarmAdvisor ||
            user.role === UserRole.StockAgent)
      )
  );
};

const getRangeFromParams = (
  searchParams: URLSearchParams,
  region: CountryCodes
) => {
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');

  if (startParam && endParam) {
    const start = fromRawDate(startParam).startOf('day').toDate();
    const end = fromRawDateWithTime(endParam).toDate();

    if (start instanceof Date && end instanceof Date) {
      return {
        start,
        end,
      };
    }
  }

  const defaultDateRange = getDateRange('last-12-months', region);
  return {
    start: defaultDateRange.start,
    end: defaultDateRange.end,
  };
};

export const ReportsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Reports',
        description: 'screen.title.reports',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Reports: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const properties = useProperties();
  const selectedProperty = properties.selected;
  const user = useGetUser();

  const propertyIdFromParams = searchParams.getAll('propertyId');
  const initialPropertyIds =
    propertyIdFromParams.length > 0
      ? propertyIdFromParams
      : [selectedProperty?.id];
  const [selectedPropertyIds, setSelectedPropertyIds] =
    useState<Property['id'][]>(initialPropertyIds);

  const reportableProperties = getPropertiesWithReportingAccess(
    properties.entities,
    user.id,
    selectedProperty?.organisationId
  );

  const moreThanOnePropertyExists = reportableProperties.length > 1;

  const { region } = useTimeZone();
  const { range, changeRange } = useDateRange({
    defaultRange: getRangeFromParams(searchParams, region),
  });

  useEffect(() => {
    const startFormatted = `${formatDate(range.start, 'YYYY-MM-DD')}`;
    const endFormatted = `${formatDate(range.end, 'YYYY-MM-DD')}`;

    setSearchParams(
      {
        start: startFormatted,
        end: endFormatted,
        propertyId: selectedPropertyIds,
      },
      {
        replace: true,
      }
    );
  }, [range.start, range.end, selectedPropertyIds]);

  const propertiesReports = usePropertyAggregateReportForRange({
    propertyIds: selectedPropertyIds,
    range,
  });
  const propertyStockingRatesData = usePropertiesStockingRatesForRange({
    propertyIds: selectedPropertyIds,
    range,
  });

  if (!selectedProperty || !user) {
    return null;
  }

  const selectedPropertyNames = [];
  selectedPropertyIds.forEach((propertyId) => {
    selectedPropertyNames.push(
      reportableProperties.find((p) => p.id === propertyId)?.name
    );
  });
  const selectedPropertyTypes = selectedProperty?.types ?? [];

  const reportData =
    propertiesReports.type === 'ready'
      ? mergeReportItems(propertiesReports)
      : null;

  const hasSales = reportData && reportData.map((a) => a.sales.sold).length > 0;

  return (
    <Box className={styles.report}>
      <header className={styles.reportHeader}>
        {moreThanOnePropertyExists && (
          <PropertySelector
            properties={reportableProperties}
            selectedPropertyIds={selectedPropertyIds}
            setSelectedPropertyIds={setSelectedPropertyIds}
            className={styles.propertySelector}
          />
        )}
        <DateRange
          id="reports-date-range"
          aria-label={formatMessage({
            defaultMessage: 'Report date range',
            description: 'reports.date_range.label',
          })}
          value={range}
          countryCode={region}
          showSelect
          onChange={changeRange}
          className={styles.dateRangeInput}
        />
      </header>
      {selectedPropertyIds.length ? (
        <>
          <Box className={styles.tilesContainer}>
            <ChartPropertiesLivestockHistory
              loading={propertyStockingRatesData.type === 'loading'}
              stockingRates={
                propertyStockingRatesData.type === 'loading'
                  ? []
                  : propertyStockingRatesData.raw
              }
            />
          </Box>

          <Box className={styles.tilesContainer}>
            <LivestockReconciliationTiles reportData={reportData} />
          </Box>

          <Box className={styles.reportContainer}>
            <LivestockReconciliationReport
              propertyNames={selectedPropertyNames}
              propertyTypes={selectedPropertyTypes}
              reportData={reportData}
            />
          </Box>
          {propertiesReports.type === 'ready' &&
            propertiesReports.report.length > 1 && (
              <Box className={styles.dashboardLayout}>
                <PropertyTotalsGraphs
                  propertiesReports={propertiesReports}
                  properties={properties.entities}
                />
              </Box>
            )}
          {hasSales && (
            <>
              <Box className={styles.tilesContainer}>
                <LivestockSalesTiles reportData={reportData} />
              </Box>

              <Box className={styles.reportContainer}>
                <LivestockSalesReport
                  propertyNames={selectedPropertyNames}
                  propertyTypes={selectedPropertyTypes}
                  reportData={reportData}
                />
              </Box>
            </>
          )}
        </>
      ) : (
        <Box className={styles.tilesContainer}>
          <Text bold>
            {formatMessage({
              defaultMessage: 'No properties selected',
              description: 'reports.noPropertiesSelected',
            })}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default {
  name: ROUTE_NAME.REPORTS,
  header: ReportsHeader,
  component: Reports,
};
