import React from 'react';
import isEqual from 'lodash/isEqual';

import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Sale } from '@mobble/models/src/model/Sale';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { useSetting } from '@mobble/store/src/hooks/settings';
import { Color } from '@mobble/theme';

import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Text } from '@src/stories/Components/UI/Text';
import { TimelineEvent } from '@src/stories/Components/UI/TimelineEvent';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface SaleCardProps {
  sale: Sale;
  last?: boolean;
  onClick: (sale: Sale) => void;
}

export const SaleCard: React.FC<SaleCardProps> = ({ sale, onClick, last }) => {
  const currencySymbol = useSetting('currencySymbol') as string;
  return (
    <Clickable
      fullWidth
      href={typeof onClick === 'function' ? () => onClick(sale) : onClick}
    >
      <TimelineEvent
        title={`${sale.number} ${toMobDisplayName(sale.mob)}`}
        date={sale.date}
        last={last}
        color={Color.Yellow}
      >
        <Text
          i18n={{
            key: 'summary.card.price_per_head',
            params: {
              '%VALUE': formatMonetary(sale.pricePerHeadCents, currencySymbol),
            },
          }}
        />
        <Spacer y={1} />
        <Text
          i18n={{
            key: 'summary.card.total_received',
            params: {
              '%VALUE': formatMonetary(
                sale.pricePerHeadCents * sale.number,
                currencySymbol
              ),
            },
          }}
        />
        {sale.soldToName ? (
          <>
            <Spacer y={1} />
            <Text
              i18n={{
                key: 'summary.card.sold_to',
                params: {
                  '%VALUE': sale.soldToName,
                },
              }}
            />
          </>
        ) : null}
        <Spacer y={2} />
        <HStack alignment="center">
          <Avatar size="tiny" name={sale.createdBy.name} />
          <Spacer x={0.5} />
          <Text color={Color.Grey} variant="tiny">
            {sale.createdBy.name}
          </Text>
        </HStack>
      </TimelineEvent>
    </Clickable>
  );
};

export const MemoisedSaleCard = React.memo(SaleCard, (prev, next) => {
  return isEqual(prev.sale, next.sale) && prev.last === next.last;
});
