import { getMonths } from '@mobble/shared/src/core/Date';
import { EChartData } from '@src/components/EChart/EChart';
import { CasualtyChartData } from '../converters/casualtiesChartDataConverter';

interface CasualtyHistoryGeneratorParams {
  data: CasualtyChartData[];
}

export const generateCasualtiesHistoryChart = ({
  data,
}: CasualtyHistoryGeneratorParams) => {
  if (!data || data.length === 0) {
    return null;
  }

  const months = getMonths({ short: true });

  const xAxisLabels = months.map((m) => m);

  const seriesData = data.reduce((acc, month) => {
    month.values.forEach((v) => {
      if (!acc[v.label]) {
        acc[v.label] = new Array(months.length).fill(0);
      }
      acc[v.label][xAxisLabels.indexOf(month.index)] = v.value;
    });
    return acc;
  }, {});

  const series: any = Object.keys(seriesData).map((mobType) => ({
    name: mobType,
    type: 'bar',
    stack: 'total',
    data: seriesData[mobType],
  }));

  const eChartData: EChartData = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const tooltipContent = params.map(
          (item) => `${item.marker} ${item.seriesName}: ${item.data}`
        );
        return tooltipContent.join('<br/>');
      },
    },
    legend: {
      data: Object.keys(seriesData),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: xAxisLabels,
    },
    yAxis: {
      type: 'value',
    },
    series,
  };

  return eChartData;
};
