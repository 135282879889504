import { Casualty } from '@mobble/models/src';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { fromRawDate, getMonths } from '@mobble/shared/src/core/Date';

interface CasualtyHistoryGeneratorParams {
  casualties: Casualty[];
  curYear: number;
  year: number;
}

export interface CasualtyChartData {
  index: string;
  values: { label: string; value: number }[];
}

export const casualtiesChartDataConverter = ({
  casualties,
  year,
}: CasualtyHistoryGeneratorParams): CasualtyChartData[] => {
  const months = getMonths({ short: true });

  const data = months.map((m, i) => {
    const casualtiesThisMonth = casualties.filter((a) => {
      const date = fromRawDate(a.date);
      return date.year() === year && date.month() === i;
    });

    return {
      index: m,
      values: casualtiesThisMonth.map((c) => ({
        label: toMobDisplayName(c.mob),
        value: c.number,
      })),
    };
  });

  return data;
};
