import React from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';

import { useTitle } from '@src/hooks/useTitle';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { AuthHelp } from '@src/stories/Views/Auth/AuthHelp';
import { AuthWrapper } from '@src/stories/Views/Auth/AuthWrapper';
import {
  ForgotPasswordForm,
  type ForgotPasswordFormValues,
} from '@src/stories/Views/Auth/ForgotPasswordForm';

import styles from './forgotPassword.scss';

export const ForgotPassword: React.FC = () => {
  const { formatMessage } = useI18n();
  const [error, setError] = React.useState<null | string>(null);
  const [formLoading, setFormLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const { forgotPassword } = useAuthentication();
  const navigate = useNavigate();

  useTitle(
    formatMessage({
      defaultMessage: 'Reset password',
      description: 'auth.forgot_password.form.submit.label',
    })
  );

  const handleSubmit = ({ email }: ForgotPasswordFormValues) => {
    setFormLoading(true);
    forgotPassword(email).then((result: any) => {
      setFormLoading(false);
      if (result?.error) {
        setError(result.error.message ?? result.error ?? 'Unknown error');
      } else {
        setSubmitted(true);
      }
    });
  };

  const handleCancel = () => {
    navigate(toPath(ROUTE_NAME.AUTH_SIGN_IN));
  };

  return (
    <AuthWrapper>
      {submitted ? (
        <Box className={styles.success}>
          <Text
            tagName="h1"
            variant="larger"
            i18n={formatMessage({
              defaultMessage: 'Password reset email sent',
              description: 'auth.forgot_password.alert.success.title',
            })}
          />
          <Text
            tagName="p"
            i18n={formatMessage({
              defaultMessage:
                'Please check your email for further instructions.',
              description: 'auth.forgot_password.alert.success.description',
            })}
          />
          <Clickable href={toPath(ROUTE_NAME.AUTH_SIGN_IN)}>
            <Text
              tagName="p"
              i18n={formatMessage({
                defaultMessage: 'Back to Sign In',
                description: 'auth.forgot_password.alert.success.confirm',
              })}
            />
          </Clickable>
        </Box>
      ) : (
        <ForgotPasswordForm
          error={error}
          loading={formLoading}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}

      <AuthHelp openHelp={'https://www.mobble.io/help'} />
    </AuthWrapper>
  );
};

export default {
  name: ROUTE_NAME.AUTH_FORGOT_PASSWORD,
  component: ForgotPassword,
};
