import { Relations } from '@mobble/models/src/model/User';
import { type ApiPrelude } from '../types';
import {
  type MobbleSubscription,
  type StripeProduct,
} from '@mobble/models/src/model/Subscriptions';

export interface StartTrial_startTrial_subscription {
  __typename: 'MobbleSubscription';
  id: string;
  status: string;
  subscriptionName: string;
  priceID: string;
  productID: string;
  maxUsers: number;
  maxProperties: number;
  billingAmount: number;
  billingInterval: any;
  currentPeriodEnd: any;
  currentPeriodStart: any;
  endedAt: any;
}

export interface StartTrial_startTrial {
  __typename: 'StartTrialPayload';
  subscription: StartTrial_startTrial_subscription;
}

export interface StartTrial {
  startTrial: StartTrial_startTrial;
}

export interface StartTrialInput {
  productID: string;
  priceID: string;
  paymentMethodID: string;
  country: string;
}

export interface StartTrialResponse {
  success: boolean;
}

export const startTrial =
  (prelude: ApiPrelude) =>
  async (trialId: string, country: string): Promise<StartTrialResponse> => {
    const input = {
      priceId: trialId,
      country,
    };

    const result = await prelude.fetch({
      method: 'POST',
      uri: `/me/start-trial`,
      variables: input,
    });

    return result;
  };

export interface UserInvitation {
  email: string;
  meta: {
    farmersFirstName: string;
    farmersLastName: string;
    invitersRelationship: string;
    farmersRole: string;
    inviteMessage: string;
    farmName: string;

    invitersName: string | null;
    invitersEmail: string | null;
    acceptInviteURL: string;
  };
}

export const CREATE_USER_INVITATION = `
    mutation CreateUserInvitation($input: UserInvitationInput!) {
      createUserInvitation(input: $input) {
        email
        token
        meta
      }
    }
  `;

export const createUserInvitation =
  (prelude: ApiPrelude) =>
  async (input: UserInvitation): Promise<StartTrialResponse> => {
    const result = await prelude.graphql({
      query: CREATE_USER_INVITATION,
      variables: {
        input,
      },
    });

    return result;
  };

export const GET_SUBSCRIPTION = `
   query MobbleSubscriptions {
    subscriptions {
      id
      status
      subscriptionName
      priceID
      productID
      maxUsers
      maxProperties
      billingAmount
      billingInterval
      currentPeriodEnd
      currentPeriodStart
      endedAt
      cancelAt
    }
  }
`;

export const getSubscriptions =
  (prelude: ApiPrelude) =>
  async (): Promise<{ entities: MobbleSubscription[] }> => {
    const result = await prelude.graphql({
      query: GET_SUBSCRIPTION,
    });

    const entities = result?.data?.subscriptions ?? [];

    return { entities };
  };

export const GET_STRIPE_PRODUCTS = `
  query StripeProducts {
    products {
      id
      name
      description
      prices {
        id
        unitAmount
        interval
        maxUsers
        maxProperties
        canPurchase
      }
    }
  }
`;

export const getStripeProducts =
  (prelude: ApiPrelude) => async (): Promise<StripeProduct[]> => {
    const response = await prelude.graphql({
      query: GET_STRIPE_PRODUCTS,
    });

    const products = response?.data?.products ?? [];

    return products;
  };

export const GET_STRIPE_CHECKOUT_URL = `
    mutation GetStripeCheckoutURL($input: GetStripeCheckoutURLInput!) {
      getStripeCheckoutURL(input: $input) {
        sessionId
      }
    }
`;

export interface GetStripeCheckoutURLInput {
  successURL: string;
  cancelURL: string;
  priceId: string;
}

export const getStripeCheckoutURL =
  (prelude: ApiPrelude) =>
  async (input: GetStripeCheckoutURLInput): Promise<string> => {
    const result = await prelude.graphql({
      query: GET_STRIPE_CHECKOUT_URL,
      variables: { input },
    });

    const stripeCheckoutURl = result?.data?.getStripeCheckoutURL.sessionId;

    return stripeCheckoutURl;
  };

export const GET_STRIPE_URL = `
    mutation GetStripeURL($input: GetStripeURLInput!) {
      getStripeURL(input: $input) {
        url
      }
    }
`;

export interface GetStripeURLInput {
  successURL: string;
  cancelURL: string;
}

export const getStripeURL =
  (prelude: ApiPrelude) =>
  async (input: GetStripeURLInput): Promise<string> => {
    const result = await prelude.graphql({
      query: GET_STRIPE_URL,
      variables: { input },
    });

    const stripeCheckoutURl = result?.data?.getStripeURL.url;

    return stripeCheckoutURl;
  };

export const GET_INVITATION_META = `
  query GetEmailByToken($token: String!) {
    getEmailByToken(input: { token: $token }) {
      email
      meta
    }
  }
`;

export interface InvitationMeta {
  email: string;
  farmersFirstName: string;
  farmersLastName: string;
  farmersRole: string;
  inviteMessage: string;
  invitersEmail: string;
  invitersName: string;
  invitersRelationship: Relations;
  token: string;
  farmName: string;
}

export const getInvitationMeta =
  (prelude: ApiPrelude) =>
  async (token: string): Promise<InvitationMeta> => {
    const result = await prelude.graphql({
      query: GET_INVITATION_META,
      variables: {
        token,
      },
      token: false,
    });

    const invitationMeta = {
      email: result?.data?.getEmailByToken?.email,
      ...result?.data?.getEmailByToken?.meta,
    } as InvitationMeta;

    return invitationMeta;
  };
