import React from 'react';

import { useI18n } from '@mobble/i18n';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import {
  type NavigationGroupProps,
  NavigationList,
} from '@src/stories/Components/UX/NavigationList';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const SummariesHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Summaries',
        description: 'screen.title.summaries',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Summaries: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  const navigationItems: NavigationGroupProps[] = [
    {
      title: formatMessage({
        defaultMessage: 'Property',
        description: 'summaries.toc.property',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Event log',
            description: 'screen.title.summary_events',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View events that have occurred',
            description: 'summaries.toc.events_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST)
            ? ROUTE_NAME.SUMMARY_EVENTS_LIST
            : showNoAccessAlert,
          icon: 'activity',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST),
        },
        {
          title: formatMessage({
            defaultMessage: 'Map Assets',
            description: 'screen.title.summary_map_assets',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View map assets list',
            description: 'summaries.toc.map_assets_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.MAP_ASSETS_LIST)
            ? ROUTE_NAME.MAP_ASSETS_LIST
            : showNoAccessAlert,
          icon: 'map-pin',
          disabled: !hasAccessToRoute(ROUTE_NAME.MAP_ASSETS_LIST),
        },
      ],
    },
    {
      title: formatMessage({
        defaultMessage: 'Livestock',
        description: 'summaries.toc.livestock',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Mob actions',
            description: 'screen.title.summary_mob_actions',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View mob action records',
            description: 'summaries.toc.mob_actions_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST)
            ? ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST
            : showNoAccessAlert,
          icon: 'check-circle',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST),
        },
        {
          title: formatMessage({
            defaultMessage: 'Sales',
            description: 'screen.title.summary_sales',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View sale records',
            description: 'summaries.toc.sales_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_SALES_LIST)
            ? ROUTE_NAME.SUMMARY_SALES_LIST
            : showNoAccessAlert,
          icon: 'dollar-sign',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_SALES_LIST),
        },
        {
          title: formatMessage({
            defaultMessage: 'Purchases',
            description: 'screen.title.summary_purchases',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View purchase records',
            description: 'summaries.toc.purchases_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_PURCHASES)
            ? ROUTE_NAME.SUMMARY_PURCHASES
            : showNoAccessAlert,
          icon: 'average',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_PURCHASES),
        },
        {
          title: formatMessage({
            defaultMessage: 'Natural increases',
            description: 'screen.title.summary_natural_increases',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View natural increase records',
            description: 'summaries.toc.natural_increases_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST)
            ? ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST
            : showNoAccessAlert,
          icon: 'arrow-up-circle',
          disabled: !hasAccessToRoute(
            ROUTE_NAME.SUMMARY_NATURAL_INCREASES_LIST
          ),
        },
        {
          title: formatMessage({
            defaultMessage: 'Casualties',
            description: 'screen.title.summary_casualties',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View casualty records',
            description: 'summaries.toc.casualties_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_CASUALTIES)
            ? ROUTE_NAME.SUMMARY_CASUALTIES
            : showNoAccessAlert,
          icon: 'crosshair',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_CASUALTIES),
        },
        {
          title: formatMessage({
            defaultMessage: 'Livestock numbers',
            description: 'screen.title.summary_livestock_numbers',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View total livestock counts',
            description: 'summaries.toc.livestock_numbers_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_LIVESTOCK_NUMBERS)
            ? ROUTE_NAME.SUMMARY_LIVESTOCK_NUMBERS
            : showNoAccessAlert,
          icon: 'trending-up',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_LIVESTOCK_NUMBERS),
        },
      ],
    },
    {
      title: formatMessage({
        defaultMessage: 'Paddocks',
        description: 'summaries.toc.paddocks',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Paddock actions',
            description: 'screen.title.summary_paddock_actions',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View paddock action records',
            description: 'summaries.toc.paddock_actions_description',
          }),
          href: hasAccessToRoute(ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST)
            ? ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST
            : showNoAccessAlert,
          icon: 'paddock-action',
          disabled: !hasAccessToRoute(ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST),
        },
      ],
    },
  ];

  return <NavigationList variant="card" items={navigationItems} />;
};

export default {
  name: ROUTE_NAME.SUMMARIES_LIST,
  header: SummariesHeader,
  component: Summaries,
};
