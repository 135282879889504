import React, { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import {
  InventoryCategory,
  inventoryCategoryToColor,
} from '@mobble/models/src/model/Inventory';
import { InventoryItemStatus } from '@mobble/models/src/model/InventoryItem';
import {
  filterInventoryItemBatches,
  InventoryItemBatch,
  InventoryItemBatchStatus,
} from '@mobble/models/src/model/InventoryItemBatch';
import { ProductApplication } from '@mobble/models/src/model/ProductApplication';
import { fromRawDate, getDateRange } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useInventory,
  useInventoryItem,
  useInventoryItemBatches,
  useInventoryItems,
  useInventoryItemUsage,
  useProductApplications,
  useProperties,
  useSettings,
} from '@mobble/store/src/hooks';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';
import { Color } from '@mobble/theme';

import { Alert, DateRange, TabBar, Tabs } from '@src/components';
import ContentContainer from '@src/components/ContentContainer';
import { useDateRange } from '@src/hooks/useDateRange';
import { useLinking } from '@src/hooks/useLinking';
import { useTabs } from '@src/hooks/useTabs';
import { useTimeZone } from '@src/hooks/useTimeZone';
import { toPath } from '@src/interfaces/Routing';
import { ChartInventoryItemUsage } from '@src/stories/Components/Charts/Components/ChartInventoryItemUsage';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { InventoryItemApplicationCard } from '@src/stories/Views/InventoryItem/InventoryItemApplicationCard';
import { InventoryItemInfoTable } from '@src/stories/Views/InventoryItem/InventoryItemInfoTable';
import { InventoryItemBatchCard } from '@src/stories/Views/InventoryItemBatch/InventoryItemBatchCard';
import { makeInventoryItemBatchesTableColumns } from '@src/stories/Views/InventoryItemBatch/List/inventoryItemBatchesTableColumns';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import {
  ScreenHeader,
  type ScreenHeaderProps,
} from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './inventoryItemDetail.scss';

const DEFAULT_DATE_RANGE = getDateRange('last-12-months');

const messages = defineMessages({
  batches: {
    defaultMessage: 'Batches',
    description: 'screen.title.inventory_item_detail_batches',
  },
  info: {
    defaultMessage: 'Info',
    description: 'screen.title.inventory_item_detail_info',
  },
  history: {
    defaultMessage: 'History',
    description: 'screen.title.inventory_item_detail_history',
  },
  active: {
    defaultMessage: 'Active',
    description: 'screen.title.inventory_item_detail_active',
  },
  finished: {
    defaultMessage: 'Finished',
    description: 'screen.title.inventory_item_detail_finished',
  },
  chemicalUsageOverTime: {
    defaultMessage: 'Product Usage Over Time',
    description: 'inventories.inventory_item_detail.chemical.chart.title',
  },
  feedUsageOverTime: {
    defaultMessage: 'Feed Usage Over Time',
    description: 'inventories.inventory_item_detail.feed.chart.title',
  },
  applicationTableEmptyMessage: {
    defaultMessage: 'No applications to display',
    description: 'inventory_item_applications.table.empty',
  },
  batchesTableEmptyMessage: {
    defaultMessage: 'There are no batches to display',
    description: 'inventory_item_batches.table.empty',
  },
  statusDeletedHeading: {
    defaultMessage: 'Inventory item deleted',
    description: 'Deleted Inventory item status heading',
  },
  statusDeletedMessage: {
    defaultMessage: 'You are unable to edit deleted inventory items.',
    description: 'Deleted Inventory item status message',
  },
});

export const InventoryItemDetailHeader: React.FC<ScreenRendererProps> = (
  props
) => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const { inventoryId = '', inventoryItemId = '' } = props.route.params;
  const properties = useProperties();
  const selectedProperty = properties.selected;
  const propertyId = selectedProperty?.id;
  const inventory = useInventory(inventoryId, propertyId);
  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventoryItems = useInventoryItems(inventoryId);

  if (!inventoryItem?.entity) {
    return null;
  }

  const isDeleted =
    inventoryItem.entity?.status === InventoryItemStatus.Deleted;

  const goBack = () => {
    if (inventory.entity) {
      linkTo(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId: inventory.entity.id,
      });
    } else {
      linkTo(ROUTE_NAME.INVENTORIES_LIST);
    }
  };

  const quickLinks = inventoryItems.entities
    .filter(
      (ii) =>
        ii.status === inventoryItem.entity.status ?? InventoryItemStatus.Active
    )
    .map((ii) => ({
      title: ii.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEM_DETAIL, {
        inventoryId,
        inventoryItemId: ii.id,
      }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const breadcrumbs: ScreenHeaderProps['breadcrumbs'] = [
    {
      title: formatMessage({
        defaultMessage: 'Inventories',
        description: 'screen.title.inventories',
      }),
      href: ROUTE_NAME.INVENTORIES_LIST,
    },
  ];

  // :inventoryId not available when navigating from /summaries/event
  if (inventory.entity?.name) {
    breadcrumbs.push({
      title: inventory.entity?.name,
      href: toPath(ROUTE_NAME.INVENTORY_ITEMS, {
        inventoryId,
      }),
    });
  }

  const title =
    inventoryItem.entity?.name ||
    formatMessage({
      defaultMessage: 'Inventory Item Detail',
      description: 'screen.title.inventory_item_detail',
    });

  return (
    <ScreenHeader
      title={title}
      breadcrumbs={breadcrumbs}
      quickLinks={quickLinks}
      actions={
        !isDeleted
          ? [
              {
                label: formatMessage(
                  {
                    defaultMessage: 'Edit "{TITLE}"',
                    description: 'screen.title.edit_x',
                  },
                  {
                    TITLE: inventoryItem.entity?.name,
                  }
                ),
                icon: 'edit',
                href: toPath(ROUTE_NAME.INVENTORY_ITEM_EDIT, {
                  inventoryId,
                  inventoryItemId,
                }),
              },
            ]
          : []
      }
      onGoBack={goBack}
    />
  );
};

enum InventoryItemDetailMobileTab {
  Info = 'info',
  Batches = 'batches',
  History = 'history',
}

export const InventoryItemDetail: React.FC<ScreenRendererProps> = (props) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const { settings } = useSettings();
  const linkTo = useLinking();
  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: InventoryItemDetailMobileTab.Info,
  });

  const { inventoryItemId = '', inventoryId } = props.route.params;
  const properties = useProperties();
  const selectedProperty = properties.selected;
  const propertyId = selectedProperty?.id;

  const inventoryItem = useInventoryItem(inventoryItemId, inventoryId);
  const inventory = useInventory(
    inventoryId,
    inventoryItem.entity?.propertyId || propertyId
  );
  const inventoryItemBatches = useInventoryItemBatches(
    inventoryItemId,
    inventoryItem?.entity?.propertyId
  );

  const isDeleted =
    inventoryItem.entity?.status === InventoryItemStatus.Deleted;

  const isChemical =
    inventoryItem?.entity?.category === InventoryCategory.Chemicals;

  const getUserFromId = (userId: string) =>
    selectedProperty?.users.find((u) => u.id === userId);

  const productApplications = useProductApplications(
    propertyId,
    inventoryItemId ?? '_',
    isChemical ? 'chemical' : 'feed'
  );

  const inventoryItemUsage = useInventoryItemUsage({
    id: inventoryItemId,
    propertyId,
    type: inventory.entity?.category,
  });

  const handleProductApplicationClick = (
    productApplication: ProductApplication
  ) =>
    productApplication.class === 'mob'
      ? linkTo(ROUTE_NAME.MOB_ACTION_DETAIL, {
          mobActionId: productApplication.mobActionId,
        })
      : linkTo(ROUTE_NAME.PADDOCK_ACTION_DETAIL, {
          paddockActionId: productApplication.paddockActionId,
        });

  const [chemicalStatus, setChemicalStatus] = useState(
    InventoryItemBatchStatus.Active
  );

  const handleChemicalStatusTabChange = (tab: InventoryItemBatchStatus) => {
    setChemicalStatus(tab);
  };

  const chemicalStatusTabs = [
    InventoryItemBatchStatus.Active,
    InventoryItemBatchStatus.Finished,
  ].map((value) => ({
    label: strings[value],
    value,
    active: value === chemicalStatus,
  }));

  const batches: Provider<InventoryItemBatch> = {
    ...inventoryItemBatches,
    entities: inventoryItemBatches.entities.filter(
      (batch) => batch.status === chemicalStatus
    ),
    total: inventoryItemBatches.entities.filter(
      (batch) => batch.status === chemicalStatus
    ).length,
  };

  const { range, changeRange } = useDateRange({
    defaultRange: DEFAULT_DATE_RANGE,
  });

  const { region } = useTimeZone();

  useEffect(() => {
    if (range && inventoryItemId) {
      inventoryItemUsage.getDateRange(
        fromRawDate(range.start).startOf('day').format(),
        fromRawDate(range.end).endOf('day').format()
      );
    }
  }, [range]);

  useEntitiesRefresher(
    [inventory, inventoryItem, inventoryItemBatches, productApplications],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [inventory.prelude, inventoryItem.prelude],
    required: [selectedProperty],
    notFound: [inventoryItem.entity],
  });

  const chemicalApplicationsPrelude = EntitySliceFactoryPrelude({
    preludes: [productApplications.prelude],
  });

  const applyFilter = !showTabBar ? filterInventoryItemBatches : undefined;

  if (Prelude) {
    return Prelude;
  }

  const tableColumns = makeInventoryItemBatchesTableColumns({
    category: inventory.entity?.category ?? InventoryCategory.Feed,
    currencySymbol: settings.currencySymbol,
    formatMessage,
  });

  const viewerProps: EntitiesViewerProps<ProductApplication> = {
    provider: productApplications,
    asList: true,
    onEmpty: strings.applicationTableEmptyMessage,
    renderEntityCard(entity) {
      return (
        <InventoryItemApplicationCard
          inventoryItemApplication={entity}
          category={inventory.entity?.category}
          user={getUserFromId(entity.createdById)}
          onClick={handleProductApplicationClick}
        />
      );
    },
    tableColumns: [],
  };

  const tabs = [
    {
      id: InventoryItemDetailMobileTab.Info,
      active: activeTab === InventoryItemDetailMobileTab.Info,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'Info',
        description: 'screen.title.inventory_item_detail_info',
      }),
      content: (
        <div className={styles.info}>
          <Alert
            open={isDeleted}
            variant="warning"
            title={strings.statusDeletedHeading}
            message={strings.statusDeletedMessage}
            className={styles.alert}
          />

          <InventoryItemInfoTable
            inventory={inventory.entity}
            inventoryItem={inventoryItem.entity}
            onClickInventory={({ id }) => {
              linkTo(ROUTE_NAME.INVENTORY_ITEMS, {
                inventoryId: id,
              });
            }}
          />
        </div>
      ),
    },
    {
      id: InventoryItemDetailMobileTab.Batches,
      active: activeTab === InventoryItemDetailMobileTab.Batches,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'Batches',
        description: 'screen.title.inventory_item_detail_batches',
      }),
      content: (
        <div className={styles.batches}>
          <TabBar
            tabs={chemicalStatusTabs}
            backgroundColor={
              showTabBar
                ? Color.Grey300
                : inventoryCategoryToColor(inventoryItem.entity.category)
            }
            indicatorColor={showTabBar ? Color.Grey500 : Color.Grey100}
            onChange={handleChemicalStatusTabChange}
            className={styles.chemicalStatusTabBar}
          />

          <EntitiesViewer
            provider={batches || []}
            applyFilter={applyFilter}
            renderEntityCard={(entity) => {
              return (
                <Box spacing={1} flex>
                  <InventoryItemBatchCard
                    href={toPath(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
                      inventoryId: inventoryItem.entity.inventoryId,
                      inventoryItemId: inventoryItem.entity?.id,
                      inventoryItemBatchId: entity.id,
                    })}
                    inventoryItemBatch={entity}
                  />
                </Box>
              );
            }}
            onClickTableRow={(entity) => {
              linkTo(ROUTE_NAME.INVENTORY_ITEM_BATCH_DETAIL, {
                inventoryId: inventoryItem.entity.inventoryId,
                inventoryItemId: inventoryItem.entity?.id,
                inventoryItemBatchId: entity.id,
              });
            }}
            title={!showTabBar && strings.batches}
            onEmpty={strings.batchesTableEmptyMessage}
            showTitle={!showTabBar}
            tableColumns={tableColumns}
          />
        </div>
      ),
    },
    {
      id: InventoryItemDetailMobileTab.History,
      active: activeTab === InventoryItemDetailMobileTab.History,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'History',
        description: 'screen.title.inventory_item_detail_history',
      }),
      content: (
        <div className={styles.history}>
          {chemicalApplicationsPrelude ? (
            chemicalApplicationsPrelude
          ) : (
            <EntitiesViewer {...viewerProps} />
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.inventoryItemDetail}>
      <Tabs
        tabs={tabs}
        backgroundColor={inventoryCategoryToColor(
          inventoryItem.entity.category
        )}
        showTabBar={showTabBar}
        onChange={handleTabChange}
        className={styles.tabs}
      />
      {activeTab === InventoryItemDetailMobileTab.Info && !showTabBar ? (
        <ContentContainer
          title={
            isChemical
              ? strings.chemicalUsageOverTime
              : strings.feedUsageOverTime
          }
          className={styles.usageChart}
        >
          <Spacer y={1} />

          <DateRange
            id="inventory-item-detail-date-range"
            aria-label={formatMessage({
              defaultMessage: 'Date range',
              description: 'inventory_item_detail.date_range.label',
            })}
            value={range}
            countryCode={region}
            showSelect
            onChange={changeRange}
          />

          <Spacer y={2} />
          <div style={{ height: '400px' }}>
            <ChartInventoryItemUsage
              inventoryItemUsage={inventoryItemUsage.entity}
              inventoryItem={inventoryItem.entity}
              range={range}
            />
          </div>
        </ContentContainer>
      ) : null}
    </div>
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
  header: InventoryItemDetailHeader,
  component: InventoryItemDetail,
};
