import React, { useEffect } from 'react';

import { useI18n } from '@mobble/i18n';
import { getDateRange } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { usePaddockActions } from '@mobble/store/src/hooks/paddockActions';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useTimeZone } from '@src/hooks/useTimeZone';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { PaddockActionsViewer } from '@src/stories/Views/PaddockAction/List/PaddockActionsViewer';

import { useLinking } from '../../hooks/useLinking';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryPaddockActionsHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Paddock actions',
        description: 'screen.title.summary_paddock_actions',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryPaddockActions: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddockActions = usePaddockActions(propertyId);
  const { region } = useTimeZone();

  useEffect(() => {
    const last12Months = getDateRange('last-12-months', region);
    paddockActions.setFilter({
      group: 'dateRange',
      filter: {
        type: 'dateRange',
        value: [last12Months.start, last12Months.end],
      },
    });
  }, []);

  useEntitiesRefresher([paddockActions], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddockActions.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <PaddockActionsViewer
      title={formatMessage({
        defaultMessage: 'Paddock actions',
        description: 'screen.title.summary_paddock_actions',
      })}
      provider={paddockActions}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_PADDOCK_ACTIONS_LIST,
  header: SummaryPaddockActionsHeader,
  component: SummaryPaddockActions,
};
