import React from 'react';
import { useNavigate } from 'react-router';

import { Color } from '@mobble/colors';
import { type I18nItem } from '@mobble/i18n';

import { useAccessHelper } from '@src/hooks/useAccessHelper';

import { Box } from '../Layout/Box';
import { Spacer } from '../Layout/Spacer';
import { HStack } from '../Layout/Stack';
import { Icon, IconName } from '../UI/Icon';
import { Text } from '../UI/Text';

import styles from './actions.scss';

export interface ActionsProps {
  onClose: () => void;
  options: ActionsOption[];
}

export interface ActionsOption {
  icon: IconName;
  label: I18nItem;
  description: I18nItem;
  disabled?: boolean;
  invalid?: boolean;
  href: string;
  state?: Record<string, any>;
}

export const Actions: React.FC<ActionsProps> = ({ onClose, options }) => {
  const navigate = useNavigate();
  const { showNoAccessAlert } = useAccessHelper();

  return (
    <ul className={styles.Actions}>
      {options.map((option) => (
        <li key={JSON.stringify(option.label)}>
          <a
            href={option.href}
            onClick={(e) => {
              e.preventDefault();
              if (option.disabled) {
                showNoAccessAlert();
                return;
              } else if (option.invalid) {
                return;
              }
              onClose();
              navigate(e.currentTarget.getAttribute('href'), {
                state: option.state,
              });
            }}
          >
            <HStack>
              <Icon
                name={option.icon}
                color={
                  option.disabled || option.invalid ? Color.Grey : Color.Green
                }
              />
              <Box spacing={{ left: 2 }}>
                <Text
                  color={
                    option.disabled || option.invalid ? Color.Grey : undefined
                  }
                  bold
                  variant="small"
                  i18n={option.label}
                />
                <Spacer y={0.5} />
                <Text
                  color={Color.Grey}
                  variant="tiny"
                  i18n={option.description}
                />
              </Box>
            </HStack>
          </a>
        </li>
      ))}
    </ul>
  );
};
