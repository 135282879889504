import React from 'react';
import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { type User } from '@mobble/models';

import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { InfoRow, type InfoRowProps } from '@src/stories/Components/UI/InfoRow';

import styles from './settingsPropertyUserInfoTable.scss';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

export interface SettingsPropertyUserInfoTableProps {
  user: User;
}

const messages = defineMessages({
  email: {
    description: 'settings.property.users.user.info.email.label',
    defaultMessage: 'Email',
  },
  name: {
    description: 'settings.property.users.user.info.name.label',
    defaultMessage: 'Name',
  },
  role: {
    description: 'settings.property.users.user.info.role.label',
    defaultMessage: 'Role',
  },
});

export const SettingsPropertyUserInfoTable: React.FC<
  SettingsPropertyUserInfoTableProps
> = ({ user }) => {
  const strings = useMessages(messages);
  const roleLabel = useRoleLabels()(user.role);

  const rows: InfoRowProps[] = [
    {
      // @ts-ignore
      label: strings.name,
      value: user.name,
    },
    {
      // @ts-ignore
      label: strings.email,
      value: user.email,
    },
    {
      // @ts-ignore
      label: strings.role,
      value: roleLabel,
    },
  ];

  return (
    <Box className={styles.settingsPropertyUserInfoTable}>
      <Box>
        <HStack>
          <Spacer flex />
          <Avatar name={user.name} />
          <Spacer flex />
        </HStack>
      </Box>
      {rows.map((row) => (
        <InfoRow key={row.label} {...row} />
      ))}
    </Box>
  );
};
