import React from 'react';

import { useI18n } from '@mobble/i18n';
import { mobbleAppIds, MobbleApps } from '@mobble/models/src/model/MobbleApps';
import { UserRole } from '@mobble/models/src/model/User';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { alertOwnerAccessOnly } from '@src/hooks/useAccessHelper';
import { useDialog } from '@src/hooks/useDialog';
import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobbleAppCard } from '@src/stories/Views/Settings/MobbleAppCard';

import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenRendererProps } from '../config/types';

import {
  getMobbleAppsDisplayItems,
  useGetMobbeAppAction,
} from './hooks/useGetMobbleAppAction';
import { useGetMobbleApps } from './hooks/useGetMobbleApps';

import styles from './settingsPropertyMobbleApps.scss';

export const SettingsPropertyMobbleAppsHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Integrations',
        description: 'screen.title.integrations',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyMobbleApps: React.FC<ScreenRendererProps> = () => {
  const { alert, close } = useDialog();
  const properties = useProperties();
  const user = useGetUser();
  const { formatMessage } = useI18n();

  const { apps: mobbleApps, refetch } = useGetMobbleApps();
  const apps = getMobbleAppsDisplayItems(mobbleApps)(mobbleAppIds);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const getMobbleAppAction = useGetMobbeAppAction(apps);

  const onClick = (appId: MobbleApps) => {
    if (user?.role === UserRole.Owner) {
      getMobbleAppAction(appId).then(refetch);
      return;
    }
    alertOwnerAccessOnly(formatMessage, alert, close);
  };

  return (
    <>
      <Box className={styles.appTiles}>
        {apps &&
          apps.map((app) => (
            <MobbleAppCard
              key={app.id}
              app={app}
              onClick={() => onClick(app.id)}
            />
          ))}
      </Box>
      <Spacer y={16} />
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_INTEGRATIONS,
  header: SettingsPropertyMobbleAppsHeader,
  component: SettingsPropertyMobbleApps,
};
