export const chartColors: string[] = [
  '#F66D44',
  '#FEAE65',
  '#E6F69D',
  '#AADEA7',
  '#64C2A6',
  '#2D87BB',
  '#55A7AA',
  '#8303E7',
  '#DF9C9B',
  '#54C077',
  '#EAB026',
  '#8D0119',
  '#8D8F01',
  '#AE4880',
  '#D759F7',
  '#E5960E',
  '#C2C73C',
  '#E0A795',
  '#843AAA',
  '#FF685E',
  '#17BAC5',
  '#F482E2',
  '#B40CC0',
  '#DB034D',
  '#2AE2E7',
  '#4ECF00',
  '#49CBAB',
  '#C7E63A',
  '#F1793A',
];

export const chartLivestockColors = {
  sheep: '#7AD82B',
  goats: '#35A2EB',
  cattle: '#D335EB',
  other: '#F66D44',
};
