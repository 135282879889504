import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models/src/model/Filter';
import {
  filterTasks,
  sortTasks,
  type Task,
} from '@mobble/models/src/model/Task';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useTasks } from '@mobble/store/src/hooks/tasks';
import { Color } from '@mobble/theme';

import { TabBar } from '@src/components';
import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useLinking } from '@src/hooks/useLinking';
import { useTabs } from '@src/hooks/useTabs';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { makeTasksTableColumns } from '@src/stories/Views/Task/List/tasksTableColumns';
import { TaskCard } from '@src/stories/Views/Task/TaskCard';

enum TasksMobileTab {
  Pending = 'pending',
  MyTasks = 'my_tasks',
  Completed = 'completed',
}

export const TasksHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute } = useAccessHelper();
  const displayActivity = hasAccessToRoute(ROUTE_NAME.SUMMARY_EVENTS_LIST);

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Tasks',
        description: 'screen.title.tasks',
      })}
      actions={
        displayActivity
          ? [
              {
                label: formatMessage({
                  defaultMessage: 'Event log',
                  description: 'screen.title.summary_events',
                }),
                icon: 'activity',
                href: toPath(ROUTE_NAME.SUMMARY_EVENTS_LIST),
              },
            ]
          : []
      }
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Tasks: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const user = useGetUser();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const tasks = useTasks(propertyId);
  const paddocks = usePaddocks(propertyId);

  const { refresh } = useEntitiesRefresher([tasks, paddocks], propertyId);

  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: TasksMobileTab.Pending,
  });

  const tabs = [
    {
      label: formatMessage({
        defaultMessage: 'Incomplete',
        description: 'screen.title.tasks_incomplete',
      }),
      active: activeTab === TasksMobileTab.Pending,
      disabled: !showTabBar,
      value: TasksMobileTab.Pending,
    },
    {
      label: formatMessage({
        defaultMessage: 'My tasks',
        description: 'screen.title.tasks_my_tasks',
      }),
      active: activeTab === TasksMobileTab.MyTasks,
      disabled: !showTabBar,
      value: TasksMobileTab.MyTasks,
    },
    {
      label: formatMessage({
        defaultMessage: 'Completed',
        description: 'screen.title.tasks_completed',
      }),
      active: activeTab === TasksMobileTab.Completed,
      disabled: !showTabBar,
      value: TasksMobileTab.Completed,
    },
  ];
  const activeTabItem = tabs.find((t) => t.active);

  const activeTabFilter = {
    type: activeTabItem?.value,
    userId:
      activeTabItem?.value === TasksMobileTab.MyTasks ? user.id : undefined,
  };

  const applyFilter = (_, filter: FilterItem[]) => {
    return filterTasks(paddocks.entities)(activeTabFilter)(
      tasks.entities,
      filter
    );
  };

  const handleClick = (task: Task) => {
    linkTo(ROUTE_NAME.TASK_DETAIL, { taskId: task.id });
  };

  const tableColumns = makeTasksTableColumns({ paddocks: paddocks.entities });

  const viewerProps: EntitiesViewerProps<Task> = {
    provider: tasks,
    hideSortAndFilter: true,
    applyFilter,
    applySort: sortTasks,
    onRefresh: refresh,
    renderEntityCard: (task) => {
      return (
        <Box spacing={1}>
          <TaskCard onClick={handleClick} task={task} />
        </Box>
      );
    },
    tableColumns,
    onEmpty: formatMessage({
      defaultMessage: 'Add a task below',
      description: 'tasks.list.empty.add',
    }),
    onClickTableRow: handleClick,
  };

  return (
    <>
      <TabBar
        tabs={tabs}
        backgroundColor={Color.Red500}
        onChange={handleTabChange}
      />
      <EntitiesViewer {...viewerProps} />
    </>
  );
};

export default {
  name: ROUTE_NAME.TASKS_LIST,
  header: TasksHeader,
  component: Tasks,
};
