import React from 'react';

import { useI18n } from '@mobble/i18n';
import { InventoryCategory, User } from '@mobble/models/src';
import { inventoryCategoryToColor } from '@mobble/models/src/model/Inventory';
import { ProductApplication } from '@mobble/models/src/model/ProductApplication';
import { Color } from '@mobble/theme';

import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Text } from '@src/stories/Components/UI/Text';
import { TimelineEvent } from '@src/stories/Components/UI/TimelineEvent';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface InventoryItemApplicationCardProps {
  inventoryItemApplication: ProductApplication;
  category?: InventoryCategory;
  user: User | null;
  last?: boolean;
  onClick: (application: ProductApplication) => void;
}

export const InventoryItemApplicationCard: React.FC<
  InventoryItemApplicationCardProps
> = ({ inventoryItemApplication, category, onClick, user, last }) => {
  const { formatMessage } = useI18n();

  const title =
    formatMessage(
      ...makeQuantityFormatMessageItem(inventoryItemApplication.quantity)
    ) +
    ' ' +
    formatMessage({
      description: `inventories.list.inventory_item_applied.card.title.connector.label`,
      defaultMessage: 'Applied',
    });

  return (
    <Clickable fullWidth href={() => onClick(inventoryItemApplication)}>
      <TimelineEvent
        title={title}
        date={inventoryItemApplication.date}
        last={last}
        color={category ? inventoryCategoryToColor(category) : Color.Blue700}
      >
        <Spacer y={1} />
        <HStack alignment="center">
          <Avatar size="tiny" name={user?.name} />
          <Spacer x={0.5} />
          <Text color={Color.Grey} variant="tiny">
            {user?.name}
          </Text>
        </HStack>
      </TimelineEvent>
    </Clickable>
  );
};
