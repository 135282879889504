// Update RootStackParamList in types when editing
export const ACTION_QUEUE_LIST = '/action_queue_list';
export const AUTH_FORGOT_PASSWORD = '/forgot-password';
export const AUTH_SIGN_IN = '/';
export const AUTH_SIGN_UP = '/sign-up';
export const AUTH_INVITE = '/invite';
export const CALLBACK = '/oauth/callback';
export const CASUALTY_DETAIL = '/summaries/casualties/:casualtyId';
export const CASUALTY_EDIT = '/summaries/casualties/:casualtyId/edit';
export const CHATS_LIST = '/chat';
export const CUSTOM_MAP_LAYER_DETAIL = '/settings/property/custom-map-layers/:customMapLayerId';
export const CUSTOM_MAP_LAYER_EDIT = '/settings/property/custom-map-layers/:customMapLayerId/edit';
export const DASHBOARD = '/dashboard';
export const IMPORT_AGWORLD_PADDOCKS = '/oauth/import-paddocks';
export const INVENTORIES_LIST = '/inventories';
export const INVENTORY_EDIT = '/inventories/:inventoryId/edit';
export const INVENTORY_ITEM_DETAIL =
  '/inventories/:inventoryId/items/:inventoryItemId';
export const INVENTORY_ITEM_EDIT =
  '/inventories/:inventoryId/items/:inventoryItemId/edit';
export const INVENTORY_ITEMS = '/inventories/:inventoryId/items';
export const INVENTORY_ITEM_BATCH_DETAIL =
  '/inventories/:inventoryId/items/:inventoryItemId/batches/:inventoryItemBatchId';
export const INVENTORY_ITEM_BATCH_EDIT =
  '/inventories/:inventoryId/items/:inventoryItemId/batches/:inventoryItemBatchId/edit';
  export const MODAL_INVENTORY_ITEM_BATCH_CREATE =
  '/inventories/:inventoryId/items/create/batch/:inventoryItemId?';
export const MAP_ASSET_DETAIL = '/summaries/map-assets/:mapAssetId';
export const MAP_ASSET_EDIT = '/summaries/map-assets/:mapAssetId/edit';
export const MAP_ASSETS_LIST = '/summaries/map-assets';
export const MAP_OVERVIEW = '/map/:goto?';
export const MOB_ACTION_DETAIL = '/mobs/mob-actions/:mobActionId';
export const MOB_ACTION_EDIT = '/mobs/mob-actions/:mobActionId/edit';
export const MOB_DETAIL = '/mobs/:paddockId/:mobId';
export const MOB_DETAIL_EDIT = '/mobs/:paddockId/:mobId/edit';
export const MOB_DETAIL_HISTORY = '/mobs/:paddockId/:mobId/#history'; // not used
export const MOB_DETAIL_INFO = '/mobs/:paddockId/:mobId/#info'; // not used
export const MOBBLE_CONNECT_USERS = '/settings/mobble-connect';
export const MOBBLE_CONNECT_USER = '/settings/mobble-connect/:role';
export const MOBBLE_CONNECT_USER_EDIT =
  '/settings/mobble-connect/:role/user/:userId/edit';
export const MOBBLE_CONNECT_USER_INVITE =
  '/settings/mobble-connect/:role/invite';
export const MOBS_LIST = '/mobs';
export const MODAL_CASUALTY_CREATE = '/mobs/:paddockId/:mobId/create-casualty';
export const MODAL_INVENTORY_ITEM_CREATE =
  '/inventories/:inventoryId/items/create';
export const MODAL_MAP_ASSET_CREATE =
  '/map/map-assets/create/:mapAssetType?/:points?';
export const MODAL_MOB_ACTION_CREATE = '/mobs/mob-actions/create/:mobIds?';
export const MODAL_MOB_CREATE = '/mobs/create/:paddockId?';
export const MODAL_MOB_MERGE = '/paddocks/:paddockId/merge-mob/:mobIds';
export const MODAL_MOB_MOVE = '/paddocks/:paddockId/move-mob/:mobIds/:toPaddockId?';
export const MODAL_PADDOCK_ACTION_CREATE =
  '/paddocks/:paddockId?/paddock-action/create';
export const MODAL_PADDOCK_CREATE = '/paddocks/create/:points?';
export const MODAL_PADDOCK_GROUP_CREATE =
  '/paddocks/:paddockId?/paddock-group/create';
export const MODAL_PADDOCK_GROUP_EDIT =
  '/paddocks/paddock-group/:paddockGroupId/edit';
export const MODAL_PROPERTY_CREATE = '/properties/create';
export const MODAL_RAIN_GAUGE_CREATE = '/rain-gauges/create';
export const MODAL_RAIN_GAUGE_READING_CREATE =
  '/rain-gauges/:rainGaugeId/create';
export const MODAL_RAIN_GAUGE_READING_EDIT =
  '/rain-gauges/:rainGaugeId/:rainGaugeReadingId/edit';
export const MODAL_SALE_CREATE = '/mobs/:paddockId/:mobId/create-sale';
export const MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE =
  '/settings/property/classes-types/group/:classTypes/create';
export const MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER =
  '/settings/property/user/invite_user';
export const MODAL_TASK_CREATE = '/tasks/create/:paddockId?/:point?';
export const NATURAL_INCREASE_DETAIL =
  '/summaries/natural-increases/:naturalIncreaseId';
export const NATURAL_INCREASE_EDIT =
  '/summaries/natural-increases/:naturalIncreaseId/edit';
export const NO_PROPERTY_ACCESS = '/setup/no-property-access';
export const PADDOCK_ACTION_DETAIL =
  '/paddocks/paddock-action/:paddockActionId';
export const PADDOCK_ACTION_EDIT =
  '/paddocks/paddock-action/:paddockActionId/edit';
export const PADDOCK_DETAIL = '/paddocks/:paddockId';
export const PADDOCK_DETAIL_EDIT = '/paddocks/:paddockId/edit';
export const PADDOCK_DETAIL_HISTORY = '/paddock/history';
export const PADDOCK_DETAIL_INFO = '/paddocks/:paddockId/#info';
export const PADDOCK_DETAIL_MOBS = '/paddocks/:paddockId/#mobs';
export const PADDOCK_DETAIL_TASKS = '/paddocks/:paddockId/#tasks';
export const PADDOCKS_LIST = '/paddocks';
export const PURCHASE_DETAIL = '/summaries/purchases/:purchaseId';
export const PURCHASE_EDIT = '/summaries/purchases/:purchaseId/edit';
export const RAIN_GAUGE_DETAIL = '/rain-gauges/:rainGaugeId';
export const RAIN_GAUGE_DETAIL_EDIT = '/rain-gauges/:rainGaugeId/edit';
export const RAIN_GAUGE_DETAIL_HISTORY = '/rain-gauges/history';
export const RAIN_GAUGE_DETAIL_INFO = '/rain-gauges/info';
export const RAIN_GAUGE_READING =
  '/rain-gauges/:rainGaugeId/:rainGaugeReadingId';
export const RAIN_GAUGES_LIST = '/rain-gauges';
export const REFER_FARMER = '/refer-farmer';
export const REPORTS = '/reports';
export const ROOT_DRAWER = '/drawer';
export const ROOT_DRAWER_CHAT = '/drawer/chat';
export const ROOT_DRAWER_TABS = '/drawer/tabs';
export const ROOT_DRAWER_TABS_APP = '/drawer/tabs/app';
export const SALE_DETAIL = '/summaries/sales/:saleId';
export const SALE_EDIT = '/summaries/sales/:saleId/edit';
export const SETTINGS_BILLING = '/settings/billing';
export const SETTINGS_HELP = '/settings/help';
export const SETTINGS_LIST = '/settings';
export const SETTINGS_LOGGER = '/settings/logger';
export const SETTINGS_INTEGRATIONS = '/settings/integrations';
export const SETTINGS_OFFLINE = '/settings/offline';
export const SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS = '/settings/property/custom-map-layers';
export const SETTINGS_PROPERTY_CLASSES_TYPES =
  '/settings/property/classes-types';
export const SETTINGS_PROPERTY_CLASSES_TYPES_EDIT =
  '/settings/property/classes-types/:classTypes/edit';
export const SETTINGS_PROPERTY_CLASSES_TYPES_GROUP =
  '/settings/property/classes-types/group/:classTypes';
export const SETTINGS_PROPERTY_INFORMATION = '/settings/property/information';
export const SETTINGS_PROPERTY_USERS = '/settings/property/users';
export const SETTINGS_PROPERTY_USERS_USER_EDIT =
  '/settings/property/user/:userId/edit';
export const SETTINGS_UNITS = '/settings/units';
export const SETTINGS_USER = '/settings/user';
export const SETUP_DISPLAY_NAME = '/setup/display-name';
export const SETUP_PROPERTY = '/setup/property';
export const SUMMARIES_LIST = '/summaries';
export const SUMMARY_CASUALTIES = '/summaries/casualties';
export const SUMMARY_EVENTS_LIST = '/summaries/events';
export const SUMMARY_LIVESTOCK_NUMBERS = '/summaries/livestock-numbers';
export const SUMMARY_MOB_ACTIONS_LIST = '/summaries/mob-actions';
export const SUMMARY_NATURAL_INCREASES_LIST = '/summaries/natural-increases';
export const SUMMARY_PADDOCK_ACTIONS_LIST = '/summaries/paddock-actions';
export const SUMMARY_PURCHASES = '/summaries/purchases';
export const SUMMARY_SALES_LIST = '/summaries/sales';
export const TASK_DETAIL = '/tasks/:taskId';
export const TASK_EDIT = '/tasks/:taskId/edit';
export const TASKS_LIST = '/tasks';
export const TASKS_LIST_MY_COMPLETED = '/tasks/completed';
export const TASKS_LIST_MY_TASKS = '/tasks/my-tasks';
export const TASKS_LIST_PENDING = '/tasks/pending';
