import React from 'react';
import { convertInventoryItemUsageData } from '../converters/convertInventoryItemUsageData';
import { Color, colorToHex } from '@mobble/colors/src';
import { InventoryCategory, InventoryItem } from '@mobble/models/src';
import { InventoryItemUsage } from '@mobble/models/src/model/InventoryItem';
import { DateRange } from '@mobble/shared/src/core/Date';
import { generateInventoryItemUsageChart } from '../generators/generateInventoryItemUsageChart';
import { EChart } from '@src/components';

export interface ChartInventoryItemUsageProps {
  inventoryItemUsage: InventoryItemUsage;
  inventoryItem: InventoryItem;
  range: DateRange;
}

export const ChartInventoryItemUsage = ({
  inventoryItemUsage,
  inventoryItem,
  range,
}: ChartInventoryItemUsageProps) => {
  const unit = inventoryItem?.quantity?.unit;

  const color = colorToHex(
    inventoryItem.category === InventoryCategory.Chemicals
      ? Color.Blue
      : Color.Orange
  );

  const data = convertInventoryItemUsageData(inventoryItemUsage, range, unit);

  const eChartData = generateInventoryItemUsageChart({
    data,
    params: { unit, color },
  });

  return <EChart data={eChartData} />;
};
