import React from 'react';

import { Property } from '@mobble/models/src/model/Property';

import {
  combineTotals,
  ItemTotals,
  propertyReportForRangeToRootItems,
  ReportData,
} from './usePropertyReportForRange';
import ChartContainer from '@src/components/ChartContainer';
import { generateLivestockPieChart } from '@src/stories/Components/Charts/generators/generateLivestockPieChart';
import { useI18n } from '@mobble/i18n/src';

export const PropertyTotalsGraphs = ({
  propertiesReports,
  properties,
}: {
  propertiesReports: ReportData;
  properties: Property[];
}) => {
  const { formatMessage } = useI18n();

  if (
    propertiesReports.type === 'loading' ||
    propertiesReports.report.length <= 1 ||
    !properties.length
  ) {
    return null;
  }

  const getPropertyName = (id: Property['id']) =>
    properties.find((a) => a.id === id)?.name;

  const propertyTotals = propertiesReports.report
    .map((a) => ({
      data: propertyReportForRangeToRootItems(a.raw),
      propertyId: a.propertyId,
    }))
    .map((a) => ({
      data: a.data.reduce((a, b) => combineTotals(a, b.totals), {
        startingHead: 0,
        closingHead: 0,
        naturalIncrease: 0,
        purchased: 0,
        casualties: 0,
        sold: 0,
      }),
      propertyId: a.propertyId,
    }));

  const constructLivestockTotalDataFromAttribute = (item: keyof ItemTotals) =>
    propertyTotals
      .map((a) => ({
        id: a.propertyId,
        value: a.data[item],
        label: `${getPropertyName(a.propertyId)} (${a.data[item]})`,
      }))
      .filter((a) => a.value > 0);

  const livestockTotalsData =
    constructLivestockTotalDataFromAttribute('closingHead');
  const livestockTotalSalesData =
    constructLivestockTotalDataFromAttribute('sold');
  const livestockTotalCasualtiesData =
    constructLivestockTotalDataFromAttribute('casualties');
  const livestockPurchasedData =
    constructLivestockTotalDataFromAttribute('purchased');
  const livestockTotalNaturalIncreases =
    constructLivestockTotalDataFromAttribute('naturalIncrease');

  return (
    <>
      {livestockTotalsData.length ? (
        <ChartContainer
          title={formatMessage({
            defaultMessage: 'Livestock totals',
            description: 'reports.totals.chart.closingHead.title',
          })}
          data={generateLivestockPieChart({
            data: livestockTotalsData,
          })}
        />
      ) : null}
      {livestockTotalSalesData.length ? (
        <ChartContainer
          title={formatMessage({
            defaultMessage: 'Sales totals',
            description: 'reports.totals.chart.sold.title',
          })}
          data={generateLivestockPieChart({
            data: livestockTotalSalesData,
          })}
        />
      ) : null}
      {livestockTotalCasualtiesData.length ? (
        <ChartContainer
          title={formatMessage({
            defaultMessage: 'Casualties totals',
            description: 'reports.totals.chart.casualties.title',
          })}
          data={generateLivestockPieChart({
            data: livestockTotalCasualtiesData,
          })}
        />
      ) : null}
      {livestockPurchasedData.length ? (
        <ChartContainer
          title={formatMessage({
            defaultMessage: 'Purchases totals',
            description: 'reports.totals.chart.purchased.title',
          })}
          data={generateLivestockPieChart({
            data: livestockPurchasedData,
          })}
        />
      ) : null}
      {livestockTotalNaturalIncreases.length ? (
        <ChartContainer
          title={formatMessage({
            defaultMessage: 'Natural Increases totals',
            description: 'reports.totals.chart.naturalIncrease.title',
          })}
          data={generateLivestockPieChart({
            data: livestockTotalNaturalIncreases,
          })}
        />
      ) : null}
    </>
  );
};
