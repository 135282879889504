import React from 'react';
import { Link } from 'react-router';

import { toPath } from '@src/interfaces/Routing';

import { type BoxProps } from '../Layout/Box';
import {
  type Cardinal,
  classNamePadding,
  type Spacing,
} from '../Layout/Spacing';

import styles from './clickableArea.scss';

type ClickableAreaProps = BoxProps & {
  children?: React.ReactNode;
  href: string | (() => void);
  spacing?: Spacing | Cardinal<Spacing>;
  flex?: boolean;
  center?: boolean;
  fullWidth?: boolean;
};

export const ClickableArea: React.FC<ClickableAreaProps> = ({
  children,
  href,
  spacing,
  flex,
  center,
  fullWidth,
}) => {
  const classNames = [
    styles.clickableArea,
    spacing ? classNamePadding(spacing) : false,
    flex ? styles.flex : false,
    center ? styles.center : false,
    fullWidth ? styles.fullWidth : false,
  ]
    .filter(Boolean)
    .join(' ');

  const externalLink = typeof href === 'string' && href.startsWith('http');

  if (typeof href === 'function') {
    return (
      <button type="button" className={classNames} onClick={() => href()}>
        {children}
      </button>
    );
  } else if (externalLink) {
    return (
      <a
        className={classNames}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  } else if (typeof href === 'string') {
    return (
      <Link className={classNames} to={toPath(href)}>
        {children}
      </Link>
    );
  }

  return <a className={classNames}>{children}</a>;
};
