import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';

const messages = defineMessages({
  settings: {
    defaultMessage: 'Settings',
    description: 'screen.title.settings',
  },
  classesTypesAndTags: {
    defaultMessage: 'Classes, types & tags',
    description: 'screen.title.settings_property_classes_types',
  },
  breed: {
    defaultMessage: 'Breeds',
    description: 'screen.title.settings_property_classes_types_group.breed',
  },
  classes: {
    defaultMessage: 'Classes',
    description: 'screen.title.settings_property_classes_types_group.class',
  },
  gender: {
    defaultMessage: 'Genders',
    description: 'screen.title.settings_property_classes_types_group.gender',
  },
  livestockType: {
    defaultMessage: 'Livestock Types',
    description:
      'screen.title.settings_property_classes_types_group.livestockType',
  },
  mobAction: {
    defaultMessage: 'Mob actions',
    description: 'screen.title.settings_property_classes_types_group.mobAction',
  },
  paddockAction: {
    defaultMessage: 'Paddock actions',
    description:
      'screen.title.settings_property_classes_types_group.paddockAction',
  },
  paddockType: {
    defaultMessage: 'Paddock types',
    description:
      'screen.title.settings_property_classes_types_group.paddockType',
  },
  tags: {
    defaultMessage: 'Tags',
    description: 'screen.title.settings_property_classes_types_group.tag',
  },
  toc_actions_and_treatments: {
    defaultMessage: 'Actions & treatments',
    description: 'settings.property.classes_types.toc.actions_and_treatments',
  },
  toc_breeds: {
    defaultMessage: 'Breeds',
    description: 'settings.property.classes_types.toc.breeds',
  },
  toc_cattle_breeds: {
    defaultMessage: 'Cattle breeds',
    description: 'settings.property.classes_types.toc.cattle_breeds',
  },
  toc_cattle_classes: {
    defaultMessage: 'Cattle classes',
    description: 'settings.property.classes_types.toc.cattle_classes',
  },
  toc_cattle_genders: {
    defaultMessage: 'Cattle genders',
    description: 'settings.property.classes_types.toc.cattle_genders',
  },
  toc_cattle_tags: {
    defaultMessage: 'Cattle tags',
    description: 'settings.property.classes_types.toc.cattle_tags',
  },
  toc_classes: {
    defaultMessage: 'Classes',
    description: 'settings.property.classes_types.toc.classes',
  },
  toc_genders: {
    defaultMessage: 'Genders',
    description: 'settings.property.classes_types.toc.genders',
  },
  toc_goats_breeds: {
    defaultMessage: 'Goat breeds',
    description: 'settings.property.classes_types.toc.goats_breeds',
  },
  toc_goats_classes: {
    defaultMessage: 'Goat classes',
    description: 'settings.property.classes_types.toc.goats_classes',
  },
  toc_goats_genders: {
    defaultMessage: 'Goat genders',
    description: 'settings.property.classes_types.toc.goats_genders',
  },
  toc_goats_tags: {
    defaultMessage: 'Goat tags',
    description: 'settings.property.classes_types.toc.goats_tags',
  },
  toc_livestock_types: {
    defaultMessage: 'Livestock types',
    description: 'settings.property.classes_types.toc.livestock_types',
  },
  toc_mob_actions: {
    defaultMessage: 'Mob actions',
    description: 'settings.property.classes_types.toc.mob_actions',
  },
  toc_other_breeds: {
    defaultMessage: 'Other breeds',
    description: 'settings.property.classes_types.toc.other_breeds',
  },
  toc_other_classes: {
    defaultMessage: 'Other classes',
    description: 'settings.property.classes_types.toc.other_classes',
  },
  toc_other_genders: {
    defaultMessage: 'Other genders',
    description: 'settings.property.classes_types.toc.other_genders',
  },
  toc_other_tags: {
    defaultMessage: 'Other tags',
    description: 'settings.property.classes_types.toc.other_tags',
  },
  toc_paddock_actions: {
    defaultMessage: 'Paddock actions',
    description: 'settings.property.classes_types.toc.paddock_actions',
  },
  toc_paddock_types: {
    defaultMessage: 'Paddock types',
    description: 'settings.property.classes_types.toc.paddock_types',
  },
  toc_sheep_breeds: {
    defaultMessage: 'Sheep breeds',
    description: 'settings.property.classes_types.toc.sheep_breeds',
  },
  toc_sheep_classes: {
    defaultMessage: 'Sheep classes',
    description: 'settings.property.classes_types.toc.sheep_classes',
  },
  toc_sheep_genders: {
    defaultMessage: 'Sheep genders',
    description: 'settings.property.classes_types.toc.sheep_genders',
  },
  toc_sheep_tags: {
    defaultMessage: 'Sheep tags',
    description: 'settings.property.classes_types.toc.sheep_tags',
  },
  toc_tags: {
    defaultMessage: 'Tags (tag colours)',
    description: 'settings.property.classes_types.toc.tags',
  },
  toc_types: {
    defaultMessage: 'Types',
    description: 'settings.property.classes_types.toc.types',
  },
});

export const useSettingsPropertyClassesTypesView = () => {
  const strings = useMessages(messages);

  return {
    strings,
  };
};
