import React from 'react';

import { UserRole } from '@mobble/models/src/model/User';
import { Button } from '@src/components';
import { Text } from '@src/components';
import { useI18n } from '@mobble/i18n/src';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { Icon } from '@mobble/icon/src';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

import styles from './SettingsPropertyMobbleConnectInviteSuggestedUser.scss';

export interface SettingsPropertyMobbleConnectInviteSuggestedUserProps {
  name: string;
  role: UserRole;

  onInvite: () => void;
  onClose: () => void;
}

export const SettingsPropertyMobbleConnectInviteSuggestedUser: React.FC<
  SettingsPropertyMobbleConnectInviteSuggestedUserProps
> = ({
  name,
  role,

  onInvite,
  onClose,
}) => {
  const { formatMessage } = useI18n();
  const roleLabel = useRoleLabels()(role);

  return (
    <Box className={styles.container} spacing={2}>
      <HStack flex alignment="center">
        <Text>
          {formatMessage(
            {
              description:
                'settings.property.users.invite_user.form.suggested_user',
              defaultMessage: 'Invite <b>{name}</b> as <b>{role}</b>',
            },
            {
              name,
              role: roleLabel,
            }
          )}
        </Text>
        <Spacer flex />
        <Clickable onClick={onClose}>
          <Icon name="close" className={styles.closeButton} />
        </Clickable>
      </HStack>
      <Spacer y={2} />
      <Button onClick={onInvite} flex size="small">
        {formatMessage({
          description: 'settings.property.users.invite_user.form.invite',
          defaultMessage: 'Invite',
        })}
      </Button>
    </Box>
  );
};
