import React, { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import * as echarts from 'echarts';

import styles from './EChart.scss';
const cx = classNames.bind(styles);

export type EChartData = echarts.EChartsOption;

export interface EChartProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
  /**
   * EChart options
   */
  data: EChartData;
}

/**
 * EChart is a component that renders an EChart instance
 * To see the full list of options, visit the official documentation
 * https://echarts.apache.org/examples/en/index.html
 */
const EChart: React.FC<EChartProps> = ({ data, className, ...others }) => {
  const chartRef = useRef(null);
  const rootClasses = cx(
    {
      EChart: true,
    },
    className
  );

  useEffect(() => {
    let chartInstance;
    let resizeObserver;

    if (chartRef.current) {
      chartInstance = echarts.init(chartRef.current);

      if (data) {
        chartInstance.setOption(data);

        if (typeof ResizeObserver !== 'undefined') {
          resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
              chartInstance.resize();
            });
          });
          resizeObserver.observe(chartRef.current);
        }
      }
    }

    return () => {
      chartInstance?.dispose();
      resizeObserver?.disconnect();
    };
  }, [JSON.stringify(data)]);

  return <div ref={chartRef} className={rootClasses} {...others}></div>;
};

export default EChart;
