import mobbleService from '@mobble/service';
import { SortDirection } from '@mobble/models/src/model/Sort';
import { type FilterPerProperty } from '../lib/filter';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { SortPerProperty } from '../lib/sort';
import { CustomMapLayer } from '@mobble/models';

export interface CustomMapLayersState {
  entities: CustomMapLayer[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: SortPerProperty;
}

export const defaultState: CustomMapLayersState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<CustomMapLayersState>({
  name: 'customMapLayers',
  initialState: defaultState,
  reducers: {},
  toParentId: ({ propertyId }) => propertyId,
  defaultSort: [{ direction: SortDirection.Ascending, column: 'name' }],
  onGetAll: mobbleService.api.customMapLayers.get,
  onFind: () => Promise.reject(),
  onCreate: () => Promise.reject(),
  onUpdate: mobbleService.api.customMapLayers.update,
  onDelete: () => Promise.reject(),
});

export const {
  thunkGetAll,
  thunkFind,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
