import React, { useState } from 'react';

import {
  ConfiguredPropertyTypeGroup,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import { base64decode, base64encode } from '@mobble/shared/src/core/String';
import { useSettingsPropertyClassesTypesView } from '@mobble/shared/src/hooks/useSettingsPropertyClassesTypesView';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  SettingsPropertyTypesCreateForm,
  SettingsPropertyTypesCreateFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyTypeCreateForm';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SettingsPropertyClassesTypesCreateHeader: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const linkTo = useLinking();
  const { strings } = useSettingsPropertyClassesTypesView();
  const { classTypes } = route.params;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));

  const typeLowerCase = livestockType ? livestockType.toLowerCase() : null;
  const groupKey = typeLowerCase
    ? `toc_${typeLowerCase}_${group === 'class' ? 'classe' : group}s`
    : group;

  return (
    <ScreenHeader
      title={strings[groupKey]}
      breadcrumbs={[
        {
          title: strings.settings,
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: strings.classesTypesAndTags,
          href: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
        },
        {
          title: strings[groupKey],
          href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
            classTypes: base64encode(JSON.stringify({ group, livestockType })),
          }),
        },
      ]}
      modal
      onClose={() =>
        linkTo(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
          classTypes: base64encode(JSON.stringify({ group, livestockType })),
        })
      }
    />
  );
};

export const SettingsPropertyClassesTypesCreate: React.FC<
  ScreenRendererProps
> = ({ route }) => {
  const goBack = useNavigateBack();
  const { classTypes } = route.params;
  const { group, livestockType } = JSON.parse(base64decode(classTypes));

  const properties = useProperties();
  const property = properties.selected;

  const [formLoading, setFormLoading] = useState(false);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const parentId = getLivestockParentIdForValue(livestockType, property.types);

  const typeGroup = group as ConfiguredPropertyTypeGroup;
  const groupsWithColor = [
    ConfiguredPropertyTypeGroup.paddockType,
    ConfiguredPropertyTypeGroup.tag,
  ];

  const handleSubmit = (formValues: SettingsPropertyTypesCreateFormValues) => {
    setFormLoading(true);
    properties
      .addPropertyType({
        parentId,
        group: group as ConfiguredPropertyTypeGroup,
        label: formValues.label,
        type: formValues.label,
        color: formValues.color,
      })
      .then(() => {
        setFormLoading(false);
        goBack();
      });
  };

  const handleCancel = () => {
    goBack();
  };

  return (
    <SettingsPropertyTypesCreateForm
      showColor={groupsWithColor.includes(typeGroup)}
      loading={formLoading}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};

export default {
  name: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
  header: SettingsPropertyClassesTypesCreateHeader,
  component: SettingsPropertyClassesTypesCreate,
};
