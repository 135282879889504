import React from 'react';

import { useI18n } from '@mobble/i18n';
import { Property } from '@mobble/models/src/model/Property';

import { ListSelect } from '@src/components';

export interface PropertySelectorProps {
  properties: Property[];
  selectedPropertyIds: Property['id'][];
  setSelectedPropertyIds: (ids: Property['id'][]) => void;
  className?: string;
}

export const PropertySelector: React.FC<PropertySelectorProps> = ({
  properties,
  selectedPropertyIds,
  setSelectedPropertyIds,
  className,
}) => {
  const { formatMessage } = useI18n();

  const handleSelectProperty = (propertyId: Property['id']) => {
    setSelectedPropertyIds(
      selectedPropertyIds.includes(propertyId)
        ? selectedPropertyIds.filter((id) => id !== propertyId)
        : [...selectedPropertyIds, propertyId]
    );
  };

  return (
    <ListSelect
      id="reports-property-selector"
      placeholder="Properties"
      selectionLabel={formatMessage(
        {
          defaultMessage:
            '{count, plural, one {<b>1 property</b> selected} other {<b>{count} properties</b> selected}}',
          description: 'Reports Property Selector label',
        },
        {
          count: selectedPropertyIds.length,
        }
      )}
      icon="farm"
      multiple
      options={properties.map((property) => ({
        id: `property-selector-${property.id}`,
        label: property.name,
        value: property.id,
        selected: selectedPropertyIds.includes(property.id),
        onClick: () => handleSelectProperty(property.id),
      }))}
      onChange={setSelectedPropertyIds}
      className={className}
    />
  );
};
