import { UserRole } from '@mobble/models/src';
import { getDisabledStatus } from '@mobble/models/src/model/Subscriptions';
import { useAuth, useProperties } from '@mobble/store/src/hooks';
import { useSubscription } from '@mobble/store/src/hooks/subscriptions';
import { useState } from 'react';
import { useLinking } from './useLinking';
import * as ROUTE_NAME from '../screens/config/routeNames';
import { useLocation } from 'react-router';

export const useSubscriptionBannerValues = () => {
  const { selected } = useProperties();
  const me = useAuth();
  const linkTo = useLinking();

  const [isOpen, setIsOpen] = useState(false);

  const currentRoute = useLocation();
  const isDashboardPage = currentRoute.pathname === ROUTE_NAME.DASHBOARD;
  const isBillingPage = currentRoute.pathname === ROUTE_NAME.SETTINGS_BILLING;

  const subscription = useSubscription();
  const subscriptionDisabledStatus = getDisabledStatus(subscription);

  const alertStatus =
    (isDashboardPage && subscription.isTrial) || isBillingPage
      ? null
      : subscriptionDisabledStatus;
  const billingRegion = me?.user?.billingRegion;
  const isOwner = selected?.currentUserRole === UserRole.Owner;

  const handleNavigateToBillingPage = () => linkTo(ROUTE_NAME.SETTINGS_BILLING);

  return {
    alertStatus,
    billingRegion,
    isOwner,
    isOpen,
    setIsOpen,
    handleNavigateToBillingPage,
  };
};
