import React, { useEffect } from 'react';

import { Property } from '@mobble/models/src/model/Property';
import mobbleService from '@mobble/service';
import {
  type PropertyReportForRange,
  type PropertyReportForRangeRow,
} from '@mobble/service/src/ext/reports';
import { type DateRange } from '@mobble/shared/src/core/Date';

const FLAG_FILTER_EMPTY_CLASSES = true;

export type ReportLoading = {
  type: 'loading';
  range: string;
  propertyIds: Property['id'][];
};
export type ReportReady = {
  type: 'ready';
  report: {
    propertyId: string;
    raw: PropertyReportForRange;
  }[];
  range: string;
  propertyIds: Property['id'][];
};

export type ReportData = ReportLoading | ReportReady;

const fetchPropertiesReportForRange = (
  propertyIds: Property['id'][],
  range: DateRange
) =>
  new Promise<
    {
      propertyId: string;
      raw: PropertyReportForRange;
    }[]
  >((resolve) => {
    const reports = propertyIds.map((propertyId) => {
      return mobbleService.api.reports
        .get({
          propertyId,
          range,
        })
        .then((raw) => {
          return {
            propertyId,
            raw,
          };
        });
    });

    Promise.all(reports).then((raw) => {
      resolve(raw);
    });
  });

export const checkPropertyIdsMatch = (a: string[], b: string[]) => {
  if (a.length !== b.length) {
    return false;
  }

  return a.every((id) => b.includes(id));
};

export const usePropertyAggregateReportForRange = ({
  propertyIds,
  range,
}: {
  propertyIds: Property['id'][];
  range: DateRange;
}) => {
  const [data, setData] = React.useState<ReportData>({
    type: 'loading',
    range: '',
    propertyIds: [],
  });

  useEffect(() => {
    const strRange = JSON.stringify(range);
    if (
      strRange &&
      propertyIds.length &&
      (!checkPropertyIdsMatch(propertyIds, data.propertyIds) ||
        strRange !== data.range)
    ) {
      if (data.type === 'loading' && data.range === strRange) {
        return;
      }

      setData({ type: 'loading', range: strRange, propertyIds });

      fetchPropertiesReportForRange(propertyIds, range).then(
        (propertyReport) => {
          setData({
            type: 'ready',
            range: strRange,
            report: propertyReport,
            propertyIds,
          });
        }
      );
    }
  }, [range, propertyIds, data.type]);

  return data;
};

export const mergeReportItems = (reports: ReportReady) => {
  const merged = reports.report.reduce(
    (merged: PropertyReportForRange, item) => ({
      start: merged.start.concat(item.raw.start),
      end: merged.end.concat(item.raw.end),
    }),
    {
      start: [],
      end: [],
    }
  );

  const result: PropertyReportForRange = {
    start: aggregateData(merged.start),
    end: aggregateData(merged.end),
  };

  return propertyReportForRangeToRootItems(result);
};

const aggregateData = (
  data: PropertyReportForRangeRow[]
): PropertyReportForRangeRow[] => {
  const aggregationMap: { [key: string]: PropertyReportForRangeRow } = {};

  data.forEach((item) => {
    const key = `${item.type}_${item.breed}_${item.gender}_${item.ages}_${item.classes}`;

    if (!aggregationMap[key]) {
      aggregationMap[key] = { ...item }; // Create a shallow copy to avoid mutating the original object
    } else {
      const group = aggregationMap[key];
      group.ages = Array.from(new Set([...group.ages, ...item.ages]));
      group.classes = Array.from(new Set([...group.classes, ...item.classes]));

      // Ensure all other numeric fields are added up correctly
      aggregationMap[key] = {
        ...group,
        totalCasualties: group.totalCasualties + item.totalCasualties,
        totalDse: group.totalDse + item.totalDse,
        totalIncome: group.totalIncome + item.totalIncome,
        totalNaturalIncrease:
          group.totalNaturalIncrease + item.totalNaturalIncrease,
        totalNumber: group.totalNumber + item.totalNumber,
        totalPurchased: group.totalPurchased + item.totalPurchased,
        totalPurchasedPrice:
          group.totalPurchasedPrice + item.totalPurchasedPrice,
        totalSold: group.totalSold + item.totalSold,
        averagePricePerHead: group.totalIncome / group.totalSold,
        averagePurchasePricePerHead:
          group.totalPurchasedPrice / group.totalPurchased,
      };
    }
  });

  return Object.values(aggregationMap);
};

export type ItemTotals = {
  startingHead: number;
  closingHead: number;
  naturalIncrease: number;
  purchased: number;
  casualties: number;
  sold: number;
};

export type ItemSales = {
  averagePerHead: number;
  sold: number;
  total: number;
};

export type Item<T = {}> = T & {
  type: string;
  breed: string;
  gender: string;
  //
  classes: string[];
  ages: number[];
  //
  totals: ItemTotals;
  sales: ItemSales;
};

export type RootItem = Item<{
  byClasses: {
    [key: string]: Item & {
      byAges: {
        [key: string]: Item<{}>;
      };
    };
  };
}>;

export type RowType = 'start' | 'end';

const rowToItem =
  (type: RowType) =>
  (item: PropertyReportForRangeRow): Item<{}> => ({
    type: item.type,
    breed: item.breed,
    gender: item.gender,
    //
    classes: item.classes,
    ages: item.ages,
    //
    totals: rowToItemTotals(type)(item),
    sales: rowToItemSales(type)(item),
  });

const rowToItemTotals =
  (type: RowType) =>
  (row: PropertyReportForRangeRow): ItemTotals => ({
    startingHead: type === 'start' ? row.totalNumber : 0,
    closingHead: type === 'end' ? row.totalNumber : 0,
    naturalIncrease: row.totalNaturalIncrease,
    purchased: row.totalPurchased,
    casualties: row.totalCasualties,
    sold: row.totalSold,
  });

const rowToItemSales =
  (type: RowType) =>
  (row: PropertyReportForRangeRow): ItemSales => ({
    averagePerHead: row.averagePricePerHead,
    sold: row.totalSold,
    total: row.totalIncome,
  });

export const combineTotals = (a: ItemTotals, b: ItemTotals): ItemTotals => ({
  startingHead: a.startingHead + b.startingHead,
  closingHead: a.closingHead + b.closingHead,
  naturalIncrease: a.naturalIncrease + b.naturalIncrease,
  purchased: a.purchased + b.purchased,
  casualties: a.casualties + b.casualties,
  sold: a.sold + b.sold,
});

export const combineSales = (a: ItemSales, b: ItemSales): ItemSales => {
  const total = a.total + b.total;
  const sold = a.sold + b.sold;
  const averagePerHead = sold > 0 ? total / sold : 0;

  return {
    averagePerHead,
    sold,
    total,
  };
};

const isTheSameItem =
  (a: Item | PropertyReportForRangeRow) =>
  (b: Item | PropertyReportForRangeRow): boolean =>
    a.type === b.type && a.breed === b.breed && a.gender === b.gender;

const flattenClasses = (classes: string[]) =>
  JSON.stringify(
    FLAG_FILTER_EMPTY_CLASSES
      ? classes.filter(Boolean).sort()
      : classes.map((a) => (a === '' ? '_' : a)).sort()
  );

const flattenAges = (ages: number[]) => JSON.stringify(ages.sort());

export const propertyReportForRangeToRootItems = (
  data: PropertyReportForRange
): RootItem[] => {
  const rootItems: RootItem[] = [];

  const expandRow = (type: RowType) => (row: PropertyReportForRangeRow) => {
    const classes = flattenClasses(row.classes);
    const ages = flattenAges(row.ages);

    const rootItem = rootItems.find(isTheSameItem(row));

    if (rootItem) {
      // item exists ? update totals and bubble down child items
      const item = rowToItem(type)(row);

      rootItem.totals = combineTotals(rootItem.totals, item.totals);
      rootItem.sales = combineSales(rootItem.sales, item.sales);

      if (rootItem.byClasses[classes]) {
        rootItem.byClasses[classes].totals = combineTotals(
          rootItem.byClasses[classes].totals,
          item.totals
        );
        rootItem.byClasses[classes].sales = combineSales(
          rootItem.byClasses[classes].sales,
          item.sales
        );

        if (rootItem.byClasses[classes].byAges[ages]) {
          rootItem.byClasses[classes].byAges[ages].totals = combineTotals(
            rootItem.byClasses[classes].byAges[ages].totals,
            item.totals
          );
          rootItem.byClasses[classes].byAges[ages].sales = combineSales(
            rootItem.byClasses[classes].byAges[ages].sales,
            item.sales
          );
        } else {
          rootItem.byClasses[classes].byAges[ages] = item;
        }
      } else {
        rootItem.byClasses[classes] = {
          ...item,
          byAges: {
            [ages]: item,
          },
        };
      }
      return;
    }

    // doesn't exist yet? easy just add the item and new root item
    const baseItem: Item = rowToItem(type)(row);

    const newRootItem = {
      ...baseItem,
      byClasses: {
        [classes]: {
          ...baseItem,
          byAges: {
            [ages]: baseItem,
          },
        },
      },
    };

    rootItems.push(newRootItem);
  };

  data.start.forEach(expandRow('start'));
  data.end.forEach(expandRow('end'));

  return rootItems;
};
