import Fuse from 'fuse.js';
import { type RawDate } from '@mobble/shared/src/core/Date';
import { type AppliedInventoryItem } from './AppliedInventoryItem';
import { type Paddock } from './Paddock';
import { type User } from './User';
import { type Property } from './Property';
import { type FilterItem, filterMatches, groupFilter } from './Filter';

export interface PaddockAction {
  id: string;
  propertyId: Property['id'];
  date: RawDate;
  createdBy: User;
  type: string;
  title: string;
  description: string;
  note?: string;
  paddocks: PaddockActionPaddock[];
  appliedInventoryItems: AppliedInventoryItem[];
}

export interface PaddockActionPaddock {
  name: string;
  paddockId: Paddock['id'];
}

export const filterPaddockActions = (
  paddockActions: PaddockAction[],
  filter?: FilterItem[]
): PaddockAction[] => {
  if (!filter || filter.length === 0) {
    return paddockActions;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredPaddockActions =
    searchQuery && searchQuery.type === 'search'
      ? searchPaddockActions(paddockActions, searchQuery.value)
      : paddockActions;

  return preFilteredPaddockActions.filter((paddockAction) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesPaddockAction(paddockAction))
    )
  );
};

export const searchPaddockActions = (
  paddockActions: PaddockAction[],
  searchQuery: string
): PaddockAction[] => {
  const fuse = new Fuse(paddockActions, {
    keys: [
      { name: 'name', weight: 5 },
      { name: 'description', weight: 3 },
      { name: 'type', weight: 3 },
      { name: 'paddocks.name', weight: 3 },
      { name: 'appliedInventoryItems.name', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });

  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesPaddockAction =
  (paddockAction: PaddockAction) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'type':
        return matches(paddockAction.type);
      case 'dateRange':
        return matches(paddockAction.date as any);
      case 'search':
        return true;
      default:
        return true;
    }
  };
