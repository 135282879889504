import { CONTEXTS } from '@mobble/models/src/model/MobbleApps';
import mobbleService from '@mobble/service';
import { useProperties } from '@mobble/store/src/hooks/properties';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

export const useGetPostAuthenticationActions = () => {
  const properties = useProperties();

  const postAuthenticationAction = (context: CONTEXTS) => {
    switch (context) {
      case CONTEXTS.IMPORT_PADDOCKS:
        window.location.href = ROUTE_NAME.IMPORT_AGWORLD_PADDOCKS;
        return;
      case CONTEXTS.AUTHORISE_AGWORLD:
        mobbleService.api.mobbleApps
          .enableMobbleApp({
            organisationId: properties.selected?.organisationId,
            integrationId: 'agworld',
          })
          .then(() => {
            window.location.href = ROUTE_NAME.SETTINGS_INTEGRATIONS;
          });
        return;
      default:
        window.location.href = ROUTE_NAME.PADDOCKS_LIST;
        return;
    }
  };

  return postAuthenticationAction;
};
