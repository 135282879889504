import React, { useState } from 'react';

import { useI18n } from '@mobble/i18n/src';
import { type Inventory } from '@mobble/models/src/model/Inventory';
import {
  useInventories,
  useInventory,
} from '@mobble/store/src/hooks/inventories';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { toPath } from '@src/interfaces/Routing';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { InventoryEditSharingForm } from '../../stories/Views/Inventory/InventoryEditSharingForm';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const InventoryEditHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();
  const { inventoryId = '' } = props.route.params;
  const inventory = useInventory(inventoryId);
  const goBack = useNavigateBack();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.inventory_edit',
        defaultMessage: 'Edit inventory',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Inventories',
            description: 'screen.title.inventories',
          }),
          href: ROUTE_NAME.INVENTORIES_LIST,
        },
        {
          title: inventory?.entity?.name || '',
          href: toPath(ROUTE_NAME.INVENTORY_ITEMS, { inventoryId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const InventoryEdit: React.FC<ScreenRendererProps> = (props) => {
  const { inventoryId = '' } = props.route.params;
  const properties = useProperties();
  const inventory = useInventory(inventoryId);
  const inventories = useInventories(String(inventory.entity?.propertyId));
  const goBack = useNavigateBack();

  const [formLoading, setFormLoading] = useState(false);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, inventories.prelude, inventory.prelude],
    required: [properties.selected, inventory.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (updatedInventory: Inventory) => {
    setFormLoading(true);

    inventory.update(updatedInventory).then(() => {
      inventories.refresh().then(() => {
        setFormLoading(false);
        goBack();
      });
    });
  };

  return (
    <InventoryEditSharingForm
      property={properties.selected}
      inventory={inventory.entity}
      loading={formLoading}
      onCancel={goBack}
      onTouched={() => {}}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.INVENTORY_EDIT,
  header: InventoryEditHeader,
  component: InventoryEdit,
};
