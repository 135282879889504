import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useAuthentication } from '@mobble/shared/src/hooks/useAuthentication';

import { useTitle } from '@src/hooks/useTitle';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { VStack } from '@src/stories/Components/Layout/Stack';
import { Logo } from '@src/stories/Components/UI/Logo';
import { Text } from '@src/stories/Components/UI/Text';
import { Button } from '@src/stories/Components/UX/Button';

import styles from './NoPropertyAccessScreen.scss';

export const NoPropertyAccessScreen = () => {
  const { formatMessage } = useI18n();
  const { signOut } = useAuthentication();

  useTitle(
    formatMessage({
      defaultMessage: 'You are not currently part of any property',
      description: 'no_property_access.screen.title',
    })
  );

  return (
    <Box className={styles.noPropertyAccessScreen} flex>
      <VStack flex>
        <VStack alignment="center" className={styles.logoWrapper}>
          <VStack alignment="center">
            <Logo />
            <Spacer y={8} />
            <Text variant="larger" tagName="h1" bold align="center">
              {formatMessage({
                defaultMessage: 'You are not currently part of any property',
                description: 'no_property_access.screen.title',
              })}
            </Text>
            <Spacer y={3} />
            <Box style={{ maxWidth: 400 }} spacing={3}>
              <VStack alignment="center">
                <Text variant="small" align="center">
                  {formatMessage({
                    defaultMessage:
                      'If you want to use the app, either ask the subscription owner to invite you to a property or start a Mobble trial.',
                    description: 'no_property_access.screen.description',
                  })}
                </Text>
                <Spacer y={3} />
                <Button
                  label={formatMessage({
                    defaultMessage: 'Sign out',
                    description: 'settings.sign_out.button.label',
                  })}
                  onClick={signOut}
                />
              </VStack>
            </Box>
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};
