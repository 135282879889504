import { MicroAgent } from '@newrelic/browser-agent/loaders/micro-agent';

import { type User } from '@mobble/models/src/model/User';

import { newRelicOptions } from '@src/ext/newRelic';

const newRelicMicroAgent = new MicroAgent(newRelicOptions);

const NEW_RELIC_ENABLED = process.env.REACT_APP_ENVIRONMENT !== 'development';

export const newRelicSetUser = (user: User) => {
  if (NEW_RELIC_ENABLED) {
    newRelicMicroAgent.setUserId(user.id);
    newRelicMicroAgent.setCustomAttribute('email', user.email);
    newRelicMicroAgent.setCustomAttribute('username', user.email);
  }
};

export const newRelicSetVersion = (version: string) => {
  if (NEW_RELIC_ENABLED) {
    newRelicMicroAgent.setApplicationVersion(version);
  }
};

export const newRelicRecordEvent = (eventName: string, attributes: any) => {
  if (NEW_RELIC_ENABLED) {
    newRelicMicroAgent.log(eventName, {
      level: 'INFO',
      customAttributes: attributes,
    });
  }
};

export const newRelicRecordError = (e: Error) => {
  if (NEW_RELIC_ENABLED) {
    newRelicMicroAgent.noticeError(e);
  }
};
