import { useMessages } from '@mobble/i18n/src';
import { getMonths } from '@mobble/shared/src/core/Date';
import { roundNumber } from '@mobble/shared/src/core/Number';
import { EChartData } from '@src/components/EChart/EChart';
import { defineMessages } from 'react-intl';

const formatValue = (values: number[]) => values.map(roundNumber).map(Number);

interface RainGaugeMonthlyRainfallGeneratorParams {
  data: {
    year: number;
    data: number[];
  }[];
  params?: {
    average?: number[] | null;
    strings?: any;
  };
}

export const generateRainGaugeMonthlyRainfallChart = ({
  data,
  params: { average, strings },
}: RainGaugeMonthlyRainfallGeneratorParams) => {
  if (!data || data.length === 0) {
    return null;
  }

  const months = getMonths({ short: true });

  const eChartData: EChartData = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: [
        ...data.map((i) => String(i.year)),
        average ? strings.average : null,
      ].filter(Boolean),
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
    },
    xAxis: {
      type: 'category',
      data: months,
    },
    series: [
      ...data.map(
        (y) =>
          ({
            name: String(y.year),
            yAxisIndex: 0,
            type: 'bar',
            data: formatValue(y.data),
          } as any)
      ),
      average
        ? {
            id: 'average',
            name: 'Average',
            type: 'line',
            yAxisIndex: 0,
            data: formatValue(average),
            smooth: true,
          }
        : null,
    ].filter(Boolean),
  };

  return eChartData;
};

const messages = defineMessages({
  average: {
    description: 'chart.rain-gauge.graph.average',
    defaultMessage: 'Average',
  },
});

interface RainGaugeMonthlyRainfallChartGeneratorParams {
  data: {
    year: number;
    data: number[];
  }[];
  params?: {
    average: number[] | null;
  };
}

export const useGenerateRainGaugeMonthlyRainfallChart = ({
  data,
  params,
}: RainGaugeMonthlyRainfallChartGeneratorParams) => {
  const average = params?.average;
  const strings = useMessages(messages);

  return generateRainGaugeMonthlyRainfallChart({
    data,
    params: {
      average,
      strings,
    },
  });
};
