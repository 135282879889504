import { type User } from '@mobble/models/src/model/User';

import * as Auth from './ext/auth';
import * as Casualties from './ext/casualties';
import * as Chats from './ext/chats';
import * as Devices from './ext/devices';
import * as HistoricEvents from './ext/historic-events';
import * as Inventories from './ext/inventories';
import * as InventoryItems from './ext/inventory-items';
import * as InventoryItemBatches from './ext/inventory-item-batches';
import * as MapAssets from './ext/map-assets';
import * as CustomMapLayers from './ext/custom-map-layers';
import * as Me from './ext/me';
import * as MobActions from './ext/mob-actions';
import * as MobbleApps from './ext/mobble-apps';
import * as MobMovements from './ext/mob-movements';
import * as Mobs from './ext/mobs';
import * as NaturalIncreases from './ext/natural-increases';
import * as Organisation from './ext/organisation';
import * as MapTileSources from './ext/map-tile-sources';
import * as Paddock from './ext/paddocks';
import * as PaddockActions from './ext/paddock-actions';
import * as PaddockGeometries from './ext/paddock-geometries';
import * as PaddockGroupedStockingRates from './ext/paddock-grouped-stocking-rates';
import * as PaddockGroupGeometries from './ext/paddock-group-geometries';
import * as PaddockGroups from './ext/paddock-groups';
import * as PaddockStockingRates from './ext/paddock-stocking-rates';
import * as Properties from './ext/properties';
import * as PropertyStockingRates from './ext/property-stocking-rates';
import * as PropertyTypes from './ext/property-types';
import * as Purchases from './ext/purchases';
import * as ProductApplications from './ext/product-applicaitons';
import * as RainGaugeReadings from './ext/rain-gauge-readings';
import * as RainGauges from './ext/rain-gauges';
import * as Reports from './ext/reports';
import * as Sales from './ext/sales';
import * as TaskGeometries from './ext/task-geometries';
import * as Tasks from './ext/tasks';

import { Fetcher } from './lib/Fetcher';

export { type AuthProxy, type AuthProxyUser } from './ext/auth';

export type MobbleServiceConfiguration = {
  uri: string;
  restUri: string;
  getIsOnline: () => Promise<boolean>;
  getClientVersion: () => string;
  getRequestId: () => Promise<string>;
  getCustomHeaders: () => Record<string, string>;
  onResponse?: (ev: {
    uri: string;
    method: string;
    operationName: string;
    variables: Record<string, any>;
    status: number;
  }) => void;
  // auth
  auth: Auth.AuthProxy;
  onUserAuthenticated: (user: User) => void;
};

export type ModelParams = {
  parentId: string;
  cursor?: string;
};

export type ModelResult<Entity = any> = Promise<{
  entities: Entity[];
  extCursor?: string;
}>;

export class MobbleService {
  private static instance: MobbleService;
  private fetcher: Fetcher = new Fetcher();
  private config: MobbleServiceConfiguration | null = null;
  public auth: Auth.MobbleAuth = Auth.defaultAuth;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new MobbleService();
    }
    return this.instance;
  }

  public configure = (config: MobbleServiceConfiguration) => {
    this.config = config;

    this.fetcher.configure({
      ...config,
      getAuthToken: () => this.auth.getToken(),
      getUserId: () => this.auth.getUserId(),
    });

    this.auth = Auth.createAuth({
      auth: config.auth,
      fetch: this.fetcher.fetch,
      isOnline: () => config.getIsOnline(),
      getUser: (token?: string) => this.api.me.get(token),
      setUser: (user: User) => {
        config.onUserAuthenticated(user);
      },
    });
  };

  public updateConfig = (config: Partial<MobbleServiceConfiguration>) => {
    this.config = {
      ...this.config,
      ...config,
    };
    this.configure(this.config);
  };

  public get api() {
    const prelude = {
      graphql: this.fetcher.graphql,
      fetch: this.fetcher.fetch,
      config: this.config,
    };

    return {
      me: {
        get: Me.get(prelude),
        update: Me.update(prelude),
        getEmailByToken: Me.getEmailByToken(prelude),
      },
      casualties: {
        get: Casualties.get(prelude),
        create: Casualties.create(prelude),
        update: Casualties.update(prelude),
        remove: Casualties.remove(prelude),
      },
      chats: {
        get: Chats.get(prelude),
        create: Chats.create(prelude),
      },
      historicEvents: {
        get: HistoricEvents.get(prelude),
      },
      inventoryItems: {
        get: InventoryItems.get(prelude),
        find: InventoryItems.find(prelude),
        create: InventoryItems.create(prelude),
        update: InventoryItems.update(prelude),
        remove: InventoryItems.remove(prelude),
        getInventoryItemUsage: InventoryItems.getInventoryItemUsage(prelude),
      },
      inventories: {
        get: Inventories.get(prelude),
        update: Inventories.update(prelude),
      },
      inventoryItemBatches: {
        get: InventoryItemBatches.get(prelude),
        create: InventoryItemBatches.create(prelude),
        update: InventoryItemBatches.update(prelude),
        remove: InventoryItemBatches.remove(prelude),
      },
      mapAssets: {
        get: MapAssets.get(prelude),
        find: MapAssets.find(prelude),
        create: MapAssets.create(prelude),
        update: MapAssets.update(prelude),
        remove: MapAssets.remove(prelude),
      },
      customMapLayers: {
        get: CustomMapLayers.get(prelude),
        update: CustomMapLayers.update(prelude),
      },
      mobActions: {
        get: MobActions.get(prelude),
        find: MobActions.find(prelude),
        create: MobActions.create(prelude),
        update: MobActions.update(prelude),
        remove: MobActions.remove(prelude),
        getIndividualMobActions: MobActions.getIndividualMobActions(prelude),
      },
      mobMovements: {
        merge: MobMovements.merge(prelude),
        splitAndMove: MobMovements.splitAndMove(prelude),
      },
      mobs: {
        get: Mobs.get(prelude),
        create: Mobs.create(prelude),
        update: Mobs.update(prelude),
        remove: Mobs.remove(prelude),
      },
      naturalIncreases: {
        get: NaturalIncreases.get(prelude),
        update: NaturalIncreases.update(prelude),
        remove: NaturalIncreases.remove(prelude),
      },
      paddockActions: {
        get: PaddockActions.get(prelude),
        find: PaddockActions.find(prelude),
        create: PaddockActions.create(prelude),
        update: PaddockActions.update(prelude),
        remove: PaddockActions.remove(prelude),
      },
      paddockGeometries: {
        get: PaddockGeometries.get(prelude),
        create: PaddockGeometries.create(prelude),
        update: PaddockGeometries.update(prelude),
        remove: PaddockGeometries.remove(prelude),
      },
      paddockGroupGeometries: {
        get: PaddockGroupGeometries.get(prelude),
      },
      paddockGroupedStockingRates: {
        get: PaddockGroupedStockingRates.get(prelude),
      },
      paddockGroups: {
        get: PaddockGroups.get(prelude),
        create: PaddockGroups.create(prelude),
        update: PaddockGroups.update(prelude),
        delete: PaddockGroups.remove(prelude),
      },
      paddockStockingRates: {
        get: PaddockStockingRates.get(prelude),
      },
      paddocks: {
        get: Paddock.get(prelude),
        find: Paddock.find(prelude),
        create: Paddock.create(prelude),
        update: Paddock.update(prelude),
        remove: Paddock.remove(prelude),
      },
      productApplications: {
        get: ProductApplications.get(prelude),
      },
      propertyTypes: {
        create: PropertyTypes.create(prelude),
        update: PropertyTypes.update(prelude),
        remove: PropertyTypes.remove(prelude),
      },
      properties: {
        get: Properties.get(prelude),
        find: Properties.find(prelude),
        create: Properties.create(prelude),
        update: Properties.update(prelude),
        delete: Properties.remove(prelude),
        getOrganisation: Properties.getPropertyOrganisation(prelude),
        getUsers: Properties.getUsers(prelude),
        addUser: Properties.addUser(prelude),
        editUser: Properties.editUser(prelude),
        deleteUser: Properties.deleteUser(prelude),
      },
      propertyStockingRates: {
        get: PropertyStockingRates.get(prelude),
      },
      purchases: {
        get: Purchases.get(prelude),
        update: Purchases.update(prelude),
        remove: Purchases.remove(prelude),
      },
      rainGaugeReadings: {
        get: RainGaugeReadings.get(prelude),
        create: RainGaugeReadings.create(prelude),
        update: RainGaugeReadings.update(prelude),
        remove: RainGaugeReadings.remove(prelude),
      },
      rainGauges: {
        get: RainGauges.get(prelude),
        create: RainGauges.create(prelude),
        update: RainGauges.update(prelude),
        remove: RainGauges.remove(prelude),
        getYearMonthTotal: RainGauges.getYearMonthTotal(prelude),
      },
      reports: {
        get: Reports.get(prelude),
      },
      devices: {
        register: Devices.register(prelude),
      },
      sales: {
        get: Sales.get(prelude),
        create: Sales.create(prelude),
        update: Sales.update(prelude),
        remove: Sales.remove(prelude),
      },
      tasks: {
        get: Tasks.get(prelude),
        find: Tasks.find(prelude),
        create: Tasks.create(prelude),
        update: Tasks.update(prelude),
        remove: Tasks.remove(prelude),
      },
      taskGeometries: {
        get: TaskGeometries.get(prelude),
      },
      mobbleApps: {
        get: MobbleApps.get(prelude),
        createAuthURI: MobbleApps.createMobbleAuthURI(prelude),
        enableMobbleApp: MobbleApps.enableMobbleApp(prelude),
        disableMobbleApp: MobbleApps.disableMobbleApp(prelude),
        authenticateStateAndCode: MobbleApps.authenticateStateAndCode(prelude),
        getAgWorldPaddocks: MobbleApps.getAgWorldPaddocks(prelude),
      },
      organisation: {
        startTrial: Organisation.startTrial(prelude),
        createUserInvitation: Organisation.createUserInvitation(prelude),
        getStripeProducts: Organisation.getStripeProducts(prelude),
        getSubscriptions: Organisation.getSubscriptions(prelude),
        getStripeCheckoutURL: Organisation.getStripeCheckoutURL(prelude),
        getStripeURL: Organisation.getStripeURL(prelude),
        getInvitationMeta: Organisation.getInvitationMeta(prelude),
      },
      mapTileSources: {
        get: MapTileSources.get(prelude),
        getGoogleMapsSessionToken: MapTileSources.getGoogleMapsSessionToken(),
      },
    };
  }

  public get uri() {
    return this.config?.uri;
  }
}

export default MobbleService.getInstance();
