import React, { useEffect, useState } from 'react';

import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { replaceParams } from '@mobble/shared/src/core/String';

import Icon from '@src/components/Icon';
import { useMapStyles } from '@src/hooks/useMapStyles';
import { useMap } from '@src/stories/Mapbox/lib/React/Context';

import styles from './mapAttribution.scss';

export interface MapAttributionProps {
  mapStyle: MapStyle;
}

export const MapAttribution: React.FC<MapAttributionProps> = ({ mapStyle }) => {
  const map = useMap();
  const { baseMaps, googleSession } = useMapStyles();
  const year = new Date().getFullYear();
  const activeBaseMap = baseMaps?.find((baseMap) => baseMap.id === mapStyle);
  const attributionLink = activeBaseMap?.attribution?.link;
  const [additionalCopyright, setAdditionalCopyright] = useState('');
  const [copyrightCollapsed, setCopyrightCollapsed] = useState(false);

  useEffect(() => {
    if (!attributionLink) {
      setAdditionalCopyright('');
      return;
    }

    if (googleSession?.session && map) {
      const getMapProperties = async () => {
        const bounds = map.getBounds();
        const params = {
          north: bounds.getNorth(),
          south: bounds.getSouth(),
          east: bounds.getEast(),
          west: bounds.getWest(),
          zoom: Math.floor(map.getZoom()),
          session: googleSession?.session,
          key: activeBaseMap?.properties?.key,
        };

        try {
          const parsedLink = replaceParams(attributionLink, params);
          const response = await fetch(parsedLink);
          const json = await response.json();
          const copyright = json?.copyright;
          setAdditionalCopyright(copyright);
        } catch (error) {
          console.error(error);
        }
      };

      if (attributionLink && !additionalCopyright) {
        getMapProperties();
      }
    }
  }, [
    attributionLink,
    additionalCopyright,
    googleSession?.session,
    activeBaseMap?.properties?.key,
  ]);

  let mapImageSrc = require('@src/assets/images/mapbox-logo-white.png');
  let mapText = (
    <>
      <a
        href="https://www.mapbox.com/about/maps"
        target="_blank"
        rel="noreferrer"
      >
        &copy; Mapbox
      </a>{' '}
      <a
        href="http://www.openstreetmap.org/copyright"
        target="_blank"
        rel="noreferrer"
      >
        &copy; OpenStreetMap
      </a>{' '}
      <strong>
        <a
          href="https://apps.mapbox.com/feedback/"
          target="_blank"
          rel="noreferrer"
        >
          Improve this map
        </a>{' '}
      </strong>
      <a href="https://www.maxar.com/" target="_blank" rel="noreferrer">
        &copy; Maxar
      </a>
    </>
  );

  switch (mapStyle) {
    case MapStyle.SatelliteHere:
      mapImageSrc = '';
      mapText = (
        <a href="http://www.here.com" target="_blank" rel="noreferrer">
          &copy; {year} HERE
        </a>
      );
      break;
    case MapStyle.SatelliteGoogle:
      mapImageSrc = require('@src/assets/images/google-logo-white.png');
      mapText = (
        <a href="http://www.google.com" target="_blank" rel="noreferrer">
          &copy; {year} Google
        </a>
      );
      break;
    case MapStyle.SatelliteMapTiler:
      mapImageSrc = require('@src/assets/images/maptiler-logo-white.png');
      mapText = (
        <>
          <a
            href="https://www.maptiler.com/copyright/"
            target="_blank"
            rel="noreferrer"
          >
            &copy; MapTiler
          </a>{' '}
          <a
            href="https://www.openstreetmap.org/copyright"
            target="_blank"
            rel="noreferrer"
          >
            &copy; OpenStreetMap contributors
          </a>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div className={styles.mapAttribution}>
      {mapImageSrc ? <img src={mapImageSrc} /> : null}
      <div
        className={styles.mapAttributionText}
        onClick={() => {
          setCopyrightCollapsed(!copyrightCollapsed);
        }}
      >
        <Icon
          name="info"
          size="small"
          className={styles.infoIcon}
          style={{
            display: copyrightCollapsed ? 'none' : undefined,
          }}
        />
        <p
          style={{
            display: copyrightCollapsed ? 'block' : undefined,
          }}
        >
          {additionalCopyright || mapText}
        </p>
      </div>
    </div>
  );
};
