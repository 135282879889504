import { type PaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';

import { thunkCreate as thunkCreatePaddock } from './paddocks';
import { uniqueId } from '@mobble/shared/src/core/UniqueId';

export interface PaddockGeometryState {
  extStatus: ExtStatusPerActionPerParent;
  entities: PaddockGeometry[];
  filter: {};
  sort: {};
}

export const defaultState: PaddockGeometryState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<PaddockGeometryState>({
  name: 'paddockGeometry',
  initialState: defaultState,
  toParentId: ({ propertyId }: PaddockGeometry) => propertyId,
  onGetAll: mobbleService.api.paddockGeometries.get,
  onCreate: mobbleService.api.paddockGeometries.create,
  onUpdate: mobbleService.api.paddockGeometries.update,
  onDelete: mobbleService.api.paddockGeometries.remove,
  afterMutation: async ({ entity }, dispatch) => {
    dispatch(thunkGetAll({ parentId: entity.propertyId }));
  },
  extraReducers: (builder) => {
    // insert the paddock geometry of the new paddock directly to the store
    // this enables the paddock geometry to be displayed immediately after creating a paddock
    builder.addCase(thunkCreatePaddock.fulfilled, (state, action) => {
      const { id } = action.payload;
      const { propertyId, geometry } = action.meta.arg;
      if (geometry.polygon) {
        const newPaddockGeometry = {
          id: uniqueId(),
          propertyId,
          paddockId: id,
          polygon: geometry.polygon,
        };
        state.entities.push(newPaddockGeometry);
      }
    });
  },
});

export const { thunkGetAll, proxyUseEntities, proxyUseEntity } = entitySlice;
export const { flush } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
