import React from 'react';
import { defineMessages } from 'react-intl';
import classNames from 'classnames/bind';

import { useMessages } from '@mobble/i18n/src';
import { MapStyle, mapStyleToString } from '@mobble/models/src/model/MapStyle';
import { Color } from '@mobble/theme';

import { Text } from '@src/components';

import styles from './settingsMapStyleButton.scss';
const cx = classNames.bind(styles);

export interface SettingsMapStyleButtonProps {
  mapStyle: MapStyle;
  selected?: boolean;
  onClick: () => void;
}

const messages = defineMessages({
  'map_style.light': {
    defaultMessage: 'Light',
    description: 'map.map_style.light.label',
  },
  'map_style.outdoor': {
    defaultMessage: 'Terrain',
    description: 'map.map_style.outdoor.label',
  },
  'map_style.satellite': {
    defaultMessage: 'Satellite Mapbox',
    description: 'map.map_style.satellite.label',
  },
  'map_style.satellite-google': {
    defaultMessage: 'Satellite Google',
    description: 'map.map_style.satellite-google.label',
  },
  'map_style.satellite-maptiler': {
    defaultMessage: 'Satellite MapTiler',
    description: 'map.map_style.satellite-maptiler.label',
  },
  'map_style.satellite-here': {
    defaultMessage: 'Satellite HERE',
    description: 'map.map_style.satellite-here.label',
  },
  'map_style.street': {
    defaultMessage: 'Streets',
    description: 'map.map_style.street.label',
  },
});

export const SettingsMapStyleButton: React.FC<SettingsMapStyleButtonProps> = ({
  mapStyle,
  selected,
  onClick,
}) => {
  const strings = useMessages(messages);
  const rootClasses = cx({
    SettingsMapStyleButton: true,
    selected: selected,
  });

  const mapTitle =
    strings[`map_style.${mapStyleToString(mapStyle)}`] ?? mapStyle;
  let mapImageSrc = require('../../../assets/images/satellite-imagery.png');
  switch (mapStyle) {
    case MapStyle.Satellite:
      mapImageSrc = require('../../../assets/images/mapbox-imagery.png');
      break;
    case MapStyle.SatelliteGoogle:
      mapImageSrc = require('../../../assets/images/google-imagery.png');
      break;
    case MapStyle.SatelliteMapTiler:
      mapImageSrc = require('../../../assets/images/maptiler-imagery.png');
      break;
    case MapStyle.SatelliteHere:
      mapImageSrc = require('../../../assets/images/here-imagery.png');
      break;
    case MapStyle.Outdoor:
      mapImageSrc = require('../../../assets/images/mapbox-outdoor.png');
      break;
    case MapStyle.Street:
      mapImageSrc = require('../../../assets/images/mapbox-streets.png');
      break;
    case MapStyle.Light:
      mapImageSrc = require('../../../assets/images/mapbox-light.png');
      break;
  }

  return (
    <button className={rootClasses} onClick={onClick}>
      <div className={styles.imageContainer}>
        <img alt={mapTitle} src={mapImageSrc} />
      </div>
      <Text
        color={selected ? Color.PrimaryDefault : Color.NeutralsCoreDefault}
        variant="small"
        align="center"
      >
        {mapTitle}
      </Text>
    </button>
  );
};
