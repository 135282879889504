import React from 'react';

import { useI18n } from '@mobble/i18n';
import { filterUsersOfUserRoles } from '@mobble/models/src/model/User';
import { useAuth } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { SettingsBillingOverview } from '@src/stories/Views/Settings/SettingsBilling';
import {
  useSubscription,
  useSubscriptions,
} from '@mobble/store/src/hooks/subscriptions';
import {
  getManageSubscriptionUrl,
  navigateToCheckout,
  useProducts,
} from './hooks/useProducts';
import {
  billingRegionToPhoneNumber,
  CONTACT_EMAIL,
  SubscriptionStatus,
} from '@mobble/models/src/model/Subscriptions';

export const SettingsBillingHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const goBack = () => {
    linkTo(ROUTE_NAME.SETTINGS_LIST);
  };

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.billing',
        defaultMessage: 'Billing',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const SettingsBilling: React.FC<ScreenRendererProps> = () => {
  const properties = useProperties();
  const propertyUsers = filterUsersOfUserRoles(properties.selected.users);
  const [error, setError] = React.useState<boolean>(false);

  const me = useAuth();
  const currentUserCount = propertyUsers.length || 0;

  const subscriptions = useSubscriptions();

  const products = useProducts();

  const {
    subscription,
    isPropertySubscriptionEnabled,
    isTrial,
    trialEnded,
    daysUntilTrialEnd,
  } = useSubscription();

  const displayOptions = isTrial || !isPropertySubscriptionEnabled;

  const handleNavigateToCheckout = navigateToCheckout(me.user.billingRegion);

  const contactEmail = CONTACT_EMAIL;
  const contactPhone = billingRegionToPhoneNumber(me.user.billingRegion);

  if (subscriptions.loading) {
    return <LoadingScreen />;
  }

  const handleManageSubscription = () =>
    getManageSubscriptionUrl().catch(() => setError(true));
  const handleCheckout = (priceId: string) =>
    handleNavigateToCheckout(priceId).catch(() => setError(true));

  return (
    <SettingsBillingOverview
      currentUserCount={currentUserCount}
      subscription={subscription}
      billingActionError={error}
      products={products}
      onClickManageSubscription={handleManageSubscription}
      getCheckoutLink={handleCheckout}
      trialEnded={trialEnded}
      daysUntilTrialEnd={daysUntilTrialEnd}
      displayOptions={displayOptions}
      contactEmail={contactEmail}
      contactPhone={contactPhone}
    />
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_BILLING,
  header: SettingsBillingHeader,
  component: SettingsBilling,
};
