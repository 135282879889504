import { Color, colorToHex } from '@mobble/colors/src';
import { useMessages } from '@mobble/i18n/src';
import { EChartData } from '@src/components/EChart/EChart';
import { defineMessages } from 'react-intl';
import {
  ChartLivestockHistoryDataAverage,
  ChartLivestockHistoryDataItem,
} from '../converters/useConverStockingRatesData';
import { daysBetween } from '@mobble/shared/src/core/Date';
import { chartLivestockColors } from '../config';

interface DataEntity {
  color: string;
  data: { x: string; y: number }[];
  id: string;
}

interface AverageDataEntity {
  x: Date;
  y: number;
  ymd: string;
}

interface LivestockHistoryGeneratorParams {
  data: DataEntity[];
  params: {
    dataAverage: AverageDataEntity[];
    strings: any;
  };
}

const generateLivestockHistoryChart = ({
  data,
  params: { dataAverage, strings },
}: LivestockHistoryGeneratorParams) => {
  const eChartData: EChartData = {
    legend: {
      data: data.map((i: any) => strings[i.id]),
    },
    xAxis: {
      type: 'time',
      boundaryGap: ['0%', '0%'],
      axisLabel: {
        fontSize: 10,
        rotate: -45,
        formatter: (value: number) => {
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}/${month}/${year}`;
        },
      },
    },
    yAxis: [
      {
        type: 'value',
        position: 'left',
        axisLine: { show: true },
        splitLine: { show: true },
      },
    ],

    series: [
      ...(data.map((e) => createEchartsLine(e, strings[e.id])) as any),
      createEchartsAverageLine(dataAverage, strings.average),
    ],

    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'cross', label: {} },
      textStyle: { fontSize: 12 },
    },
    animation: false,
    grid: {
      top: 30,
      right: 20,
      left: 20,
      bottom: 20,
      containLabel: true,
    },
  };

  return eChartData;
};

const createEchartsLine = (data: DataEntity, name: string) => {
  return {
    id: data.id,
    name,
    yAxisIndex: 0,
    data: data.data.map((d) => ({
      value: [new Date(d.x).getTime(), d.y],
    })),
    areaStyle: {
      opacity: 0.5,
    },
    invert: false,
    type: 'line',
    symbol: 'emptyCircle',
    showSymbol: false,
    symbolSize: 10,
    color: chartLivestockColors[data.id],
    lineStyle: {},
    stack: 'stackGroup',
  };
};

const createEchartsAverageLine = (data: AverageDataEntity[], name: string) => ({
  id: 'average-line',
  name,
  yAxisIndex: 0,
  data: data.map((d) => ({
    value: [d.x.getTime(), d.y],
  })),
  type: 'line',
  symbol: 'circle',
  showSymbol: false,
  symbolSize: 8,
  sampling: 'average',
  color: colorToHex(Color.Red),
  lineStyle: {
    width: 3,
  },
  areaStyle: null,
  stack: null,
  z: 10,
  smooth: true,
});

//

const messages = defineMessages({
  sheep: {
    description: 'chart.livestock.history.graph.sheep',
    defaultMessage: 'Sheep',
  },
  goats: {
    description: 'chart.livestock.history.graph.goats',
    defaultMessage: 'Goats',
  },
  other: {
    description: 'chart.livestock.history.graph.other',
    defaultMessage: 'Other',
  },
  cattle: {
    description: 'chart.livestock.history.graph.cattle',
    defaultMessage: 'Cattle',
  },

  average: {
    description: 'chart.livestock.history.graph.average',
    defaultMessage: 'Average',
  },
});

interface LivestockHistoryChartParams {
  data: ChartLivestockHistoryDataItem[];
  params: { dataAverage: ChartLivestockHistoryDataAverage[]; year?: number };
}

export const useGenerateLivestockHistoryChart = ({
  data,
  params: { dataAverage, year },
}: LivestockHistoryChartParams) => {
  const strings = useMessages(messages);

  if (!data || data.length === 0) {
    return generateLivestockHistoryChartEmpty({
      year: year || new Date().getFullYear(),
      currentDate: new Date(),
    });
  }

  return generateLivestockHistoryChart({
    data,
    params: { dataAverage, strings },
  });
};

// use the chart below to generate a new 'empty' chart, where the data is from the start of the year to the current date
export const generateLivestockHistoryChartEmpty = ({
  year,
  currentDate,
}: {
  year: number;
  currentDate: Date;
}) => {
  const dateRange = (() => {
    if (year === currentDate.getFullYear()) {
      const start = new Date(year, 0, 1);
      const end = currentDate;
      return [start, end];
    }

    const start = new Date(year, 0, 1);
    const end = new Date(year, 11, 31);
    return [start, end];
  })();
  const days = Array.from(
    {
      length: daysBetween(dateRange[0])(dateRange[1]) + 1,
    },
    (_, i) => new Date(dateRange[0].getTime() + i * 86400000)
  );

  const eChartData: EChartData = {
    legend: {
      data: days.map((d) => d.toISOString()),
    },
    xAxis: {
      type: 'time',
      boundaryGap: ['0%', '0%'],
      axisLabel: {
        fontSize: 10,
        rotate: -45,
      },
    },
    yAxis: [
      {
        type: 'value',
        position: 'left',
        axisLine: { show: true },
        splitLine: { show: true },
      },
    ],

    series: [
      {
        id: 'empty',
        name: 'Empty',
        yAxisIndex: 0,
        data: days.map((d) => ({
          value: [d, 0],
        })),
        type: 'line',
        symbol: 'emptyCircle',
        showSymbol: false,
        symbolSize: 10,
        color: chartLivestockColors[0],
        lineStyle: {
          opacity: 0,
        },
        stack: 'stackGroup',
      },
    ],

    tooltip: null,
    animation: false,
    grid: {
      top: 30,
      right: 20,
      left: 20,
      bottom: 20,
      containLabel: true,
    },
  };

  return eChartData;
};
