import React from 'react';

import { useI18n } from '@mobble/i18n';
import { sortAnyContainingPaddocks } from '@mobble/models/src/model/Paddock';
import { findPaddockCenterPoint } from '@mobble/models/src/model/PaddockGeometry';
import { PaddockGroup } from '@mobble/models/src/model/PaddockGroup';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { usePaddockGeometries } from '@mobble/store/src/hooks/paddockGeometries';
import { usePaddockGroupGeometries } from '@mobble/store/src/hooks/paddockGroupGeometries';
import { usePaddockGroup } from '@mobble/store/src/hooks/paddockGroups';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { useExtStatus } from '@mobble/store/src/hooks/root';

import { useFormError } from '@src/hooks/useFormError';
import { useLinking } from '@src/hooks/useLinking';
import { DeleteForm } from '@src/stories/Views/Form/DeleteForm/DeleteForm';

import { usePaddockCardFactory } from '../../../hooks/usePaddockCardFactory';
import { EntitySliceFactoryPrelude } from '../../../stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '../../../stories/Views/Misc/ScreenHeader';
import {
  PaddockGroupEditForm,
  PaddockGroupEditFormValues,
} from '../../../stories/Views/Paddock/PaddockGroup/PaddockGroupEditForm';
import * as ROUTE_NAME from '../../config/routeNames';
import { ScreenRendererProps } from '../../config/types';

export const PaddockGroupEditHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Edit Open Gates',
        description: 'screen.title.paddock_group.edit',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.PADDOCKS_LIST);
      }}
    />
  );
};
export const PaddockGroupEdit: React.FC<ScreenRendererProps> = ({ route }) => {
  const { paddockGroupId } = route.params;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(properties.selected?.id);
  const paddockGroup = usePaddockGroup(paddockGroupId);
  const paddockGeometries = usePaddockGeometries(properties.selected?.id);
  const paddockGroupGeometries = usePaddockGroupGeometries(
    properties.selected?.id
  );
  const paddockOrigin = findPaddockCenterPoint(paddockGeometries.entities)(
    paddockGroup.entity?.paddockIds[0] ?? ''
  );
  const extStatus = useExtStatus('paddockGroups', 'update', paddockGroupId);

  const extStatusDelete = useExtStatus(
    'paddockGroups',
    'delete',
    paddockGroupId
  );

  const formError = useFormError({
    entityName: formatMessage({
      defaultMessage: 'Paddock group',
      description: 'screen.title.paddock_group_detail',
    }),
    active: Boolean(extStatus?.error || extStatusDelete?.error),
  });

  useEntitiesRefresher([paddockGroup], propertyId);

  const formLoading = extStatus?.loading || extStatusDelete?.loading;

  const makePaddockCard = usePaddockCardFactory({
    propertyId: properties.selected?.id,
    location: paddockOrigin,
  });
  const paddockSortMeta = {
    origin: paddockOrigin,
    paddockGeometries: paddockGeometries.entities,
    mobs: [],
  };

  const sortPaddockOptions = sortAnyContainingPaddocks((o) => o.entity)(
    paddockSortMeta
  );

  const paddockOptions = paddocks.entities.map((p) => ({
    label: p.name,
    value: p.id,
    entity: p,
    component: makePaddockCard(p),
  }));

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      paddocks.prelude,
      paddockGroup.prelude,
      paddockGeometries.prelude,
      paddockGroupGeometries.prelude,
    ],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleSubmit = (formValues: PaddockGroupEditFormValues) => {
    const updatedEntity = {
      id: paddockGroup.entity?.id,
      propertyId: properties?.selected?.id,
      paddockIds: formValues.paddocks,
    } as PaddockGroup;

    paddockGroup
      .update(updatedEntity)
      .then((paddockGroup) => {
        // TODO: replace with Toast/Alert
        console.log(`Paddock group successfully edited`);
        console.log(paddockGroup);
        linkTo(ROUTE_NAME.PADDOCKS_LIST);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    paddockGroup
      .remove()
      .then(() => {
        linkTo(ROUTE_NAME.PADDOCKS_LIST);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <PaddockGroupEditForm
        paddockOptions={paddockOptions}
        sortPaddockOptions={sortPaddockOptions}
        paddockGroup={paddockGroup.entity}
        error={formError}
        loading={formLoading}
        onCancel={() => {
          linkTo(ROUTE_NAME.PADDOCKS_LIST);
        }}
        onSubmit={handleSubmit}
      />
      <DeleteForm
        i18nRootKey="paddocks.paddock_group.delete"
        loading={formLoading}
        icon="paddock-action"
        intent="secondary"
        outline
        onDelete={handleDelete}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.MODAL_PADDOCK_GROUP_EDIT,
  header: PaddockGroupEditHeader,
  component: PaddockGroupEdit,
};
