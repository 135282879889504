export enum MapStyle {
  Satellite = 'satellite',
  SatelliteGoogle = 'satellite-google',
  SatelliteHere = 'satellite-here',
  SatelliteMapTiler = 'satellite-maptiler',
  Light = 'light',
  Outdoor = 'outdoor',
  Street = 'street',
}

export interface MapTileSource {
  id: MapStyle;
  type: 'raster' | 'geojson';
  tiles: string[];
  tileSize: number;
  title?: string;
  provider?: string;
  properties?: {
    key?: string;
  };
  attribution?: {
    text?: string;
    link?: string;
  };
}

export const mapStyleIsSatellite = (mapStyle: MapStyle) =>
  mapStyle === MapStyle.Satellite ||
  mapStyle === MapStyle.SatelliteGoogle ||
  mapStyle === MapStyle.SatelliteHere ||
  mapStyle === MapStyle.SatelliteMapTiler;

export const mapStyleToString = (mapStyle: MapStyle) => {
  switch (mapStyle) {
    case MapStyle.Satellite:
      return 'satellite';
    case MapStyle.SatelliteGoogle:
      return 'satellite-google';
    case MapStyle.SatelliteHere:
      return 'satellite-here';
    case MapStyle.SatelliteMapTiler:
      return 'satellite-maptiler';
    case MapStyle.Light:
      return 'light';
    case MapStyle.Outdoor:
      return 'outdoor';
    case MapStyle.Street:
      return 'street';
    default:
      return mapStyle;
  }
};
