import React from 'react';

import { useI18n } from '@mobble/i18n';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { Color } from '@mobble/theme';

import { Text } from '@src/components';
import { useLinking } from '@src/hooks/useLinking';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { LivestockNumbersReport } from '@src/stories/Views/Reports/LivestockNumbersReport';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryLivestockNumbersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Livestock numbers',
        description: 'screen.title.summary_livestock_numbers',
      })}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryLivestockNumbers: React.FC<ScreenRendererProps> = () => {
  const properties = useProperties();
  const { formatMessage } = useI18n();
  const propertyTypes = properties.selected?.types || [];
  const mobs = useMobs(properties.selected?.id);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobs.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <>
      <Clickable href={`${ROUTE_NAME.REPORTS}`} spacing={{ bottom: 2 }}>
        <Text color={Color.PrimaryDefault}>
          {formatMessage({
            defaultMessage:
              'View livestock reconciliation report on the Reports page.',
            description: 'Link to livestock reconciliation report',
          })}
        </Text>
      </Clickable>
      <LivestockNumbersReport
        reportData={mobs.entities}
        propertyTypes={propertyTypes}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_LIVESTOCK_NUMBERS,
  header: SummaryLivestockNumbersHeader,
  component: SummaryLivestockNumbers,
};
