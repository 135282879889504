import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import styles from './ChartContainer.scss';
import { Clickable } from '@src/stories/Components/UX/Clickable';
import Icon from '../Icon';
import ContentContainer from '../ContentContainer';
import EChart from '../EChart';
import { EChartData } from '../EChart/EChart';
import Dialog from '../Dialog';
import { LoadingScreen } from '@src/stories/Views/Misc/LoadingScreen';
import { useI18n } from '@mobble/i18n/src';

const cx = classNames.bind(styles);

export interface ChartContainerProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
  /**
   * Title of the container
   */
  title: string;

  /**
   * Title href to navigate users, accessible via title click event.
   * If not provided, title will not be clickable.
   */
  titleHref?: string | ((ev: any) => void);

  /**
   * EChart options
   */
  data: EChartData;

  /**
   * EChart options for maximised view
   */
  dataExpanded?: EChartData;

  /**
   * Show loading screen
   */
  loading?: boolean;

  /**
   * Header options to be displayed on the right side of the title
   */
  headerOptions?: React.ReactNode;
}

const downloadOption = {
  toolbox: {
    right: 20,
    top: 0,
    feature: {
      saveAsImage: {},
    },
  },
};

const NoDataScreen = () => {
  const { formatMessage } = useI18n();
  return (
    <div className={styles.noData}>
      <Icon className={styles.noDataIcon} name="bar-chart" />
      <p>
        {formatMessage({
          id: 'chart.noData',
          defaultMessage: 'Graph Empty',
        })}
      </p>
    </div>
  );
};

/**
 * Container component
 */
const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  headerOptions = null,
  titleHref,
  data,
  dataExpanded,
  loading,
  className,
}) => {
  const rootClasses = cx(
    {
      ChartContainer: true,
    },
    className
  );

  const [dialogOpen, setIsDialogOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const chartHeaderOptions = (
    <div className={styles.headerOptions}>
      {headerOptions}
      <div className={styles.dialogButton}>
        <Clickable onClick={handleDialogOpen}>
          <Icon name="maximise" />
        </Clickable>
      </div>
    </div>
  );

  const dialog = (
    <Dialog
      isModal
      isOpen={dialogOpen}
      onRequestClose={handleDialogClose}
      header={{ heading: title }}
      className={styles.dialog}
    >
      {headerOptions && (
        <div className={styles.dialogOptions}>{headerOptions}</div>
      )}
      {loading ? (
        <LoadingScreen />
      ) : dataExpanded || data ? (
        <div className={styles.expandedChart}>
          <EChart
            data={{
              ...downloadOption,
              ...(dataExpanded || data),
            }}
          />
        </div>
      ) : (
        <NoDataScreen />
      )}
    </Dialog>
  );

  return (
    <ContentContainer
      title={title}
      titleHref={titleHref}
      headerOptions={chartHeaderOptions}
      className={rootClasses}
    >
      <div style={{ height: '400px' }}>
        {loading ? (
          <LoadingScreen />
        ) : dataExpanded || data ? (
          <EChart data={data} />
        ) : (
          <NoDataScreen />
        )}
      </div>
      {dialog}
    </ContentContainer>
  );
};

export default ChartContainer;
