import { EChartData } from '@src/components/EChart/EChart';
import { InventoryItemUsageData } from '../converters/convertInventoryItemUsageData';

interface InventoryItemUsageGeneratorParams {
  data: InventoryItemUsageData[];
  params: { unit: string; color: string };
}
export const generateInventoryItemUsageChart = ({
  data,
  params: { unit, color },
}: InventoryItemUsageGeneratorParams) => {
  if (!data || data.length === 0) {
    return null;
  }

  const eChartData: EChartData = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const [tooltipData] = params;
        const date = new Date(tooltipData.axisValue).toLocaleDateString();
        const value = tooltipData.data[1];
        return `<strong>${date}</strong><br>${value} ${unit}`;
      },
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        rotate: -45,
      },
    },
    yAxis: {
      type: 'value',
    },
    grid: {
      top: 10,
      right: 10,
      bottom: 50,
      left: 40,
    },
    series: [
      {
        name: 'Usage',
        type: 'line',
        step: 'end',
        areaStyle: { color, opacity: 0.6 },
        lineStyle: { color, width: 3 },

        showSymbol: false,
        data: data.map((point) => [point.label, point.value]),
      },
    ],
  };

  return eChartData;
};
