import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import styles from './FixedHeader.scss';
const cx = classNames.bind(styles);

export interface FixedHeaderProps extends ComponentPropsWithoutRef<'div'> {}

const FixedHeader: React.FC<FixedHeaderProps> = ({
  children,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      FixedHeader: true,
    },
    className
  );

  return (
    <div className={rootClasses} {...others}>
      {children}
    </div>
  );
};

export default FixedHeader;
