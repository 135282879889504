import { useEffect } from 'react';

import { useI18n } from '@mobble/i18n';

let APP_NAME = 'Mobble';
if (window.location.hostname.endsWith('netlify.app')) {
  APP_NAME += ' (staging)';
}

/**
 * Sets page title with app name appended
 * @param title
 */
export const useTitle = (title?: string) => {
  const { translate } = useI18n();

  useEffect(() => {
    const prevTitle = document.title;

    let newTitle = '';
    if (typeof title === 'string') {
      newTitle = title;
    } else {
      console.warn('Legacy page title: ', title);
      newTitle = translate(title); // TODO: remove usage
    }

    document.title = newTitle ? `${newTitle} · ${APP_NAME}` : APP_NAME;

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
};
