import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import styles from './TabPanel.scss';
const cx = classNames.bind(styles);

export interface TabPanelProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'id' | 'role' | 'tabIndex'> {
  /**
   * Unique identifier for the tab panel, `-tabpanel` is appended to the id
   * It should match the value of a corresponding `TabBar` tab
   */
  id: string;

  /**
   * Whether the tab panel is currently active
   */
  active: boolean;

  /**
   * Disables the tab-related attributes
   */
  disabled?: boolean;
}

/**
 * TabPanel component for accessible tab interfaces
 */
export const TabPanel: React.FC<TabPanelProps> = ({
  id,
  active,
  disabled,
  children,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      TabPanel: true,
    },
    className
  );

  return (
    <div
      id={`${id}-tabpanel`}
      role={!disabled ? 'tabpanel' : undefined}
      hidden={!disabled && !active}
      tabIndex={!disabled ? (active ? 0 : -1) : undefined}
      className={rootClasses}
      {...others}
    >
      <a id={`${id}`} />
      {children}
    </div>
  );
};

export default TabPanel;

export interface TabPanelsProps {
  children?: React.ReactNode;
  panels?: TabPanelProps[];
}

/**
 * Optional wrapper component for TabPanel components
 */
export const TabPanels: React.FC<TabPanelsProps> = ({ children, panels }) => {
  return (
    <>
      {children}
      {panels?.map((panel) => (
        <TabPanel key={panel.id} {...panel} />
      ))}
    </>
  );
};
