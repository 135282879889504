import React, { ComponentPropsWithoutRef, useRef } from 'react';
import { defineMessages } from 'react-intl';
import classNames from 'classnames/bind';

import { useMessages } from '@mobble/i18n';
import { CountryCodes } from '@mobble/models/src/model/Settings';
import {
  DateRange as IDateRange,
  DateRangeOption as IDateRangeOption,
  dateRangeOptions,
  formatDate,
  getDateRange,
} from '@mobble/shared/src/core/Date';

import Icon from '@src/components/Icon';
import Input, { InputSize } from '@src/components/Input';
import ListSelect, {
  ListSelectOption,
  ListSelectProps,
} from '@src/components/ListSelect';

import styles from './DateRange.scss';
const cx = classNames.bind(styles);

export interface DateRangeProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  /**
   * Used to bind input and label
   */
  id: string;

  /**
   * The label to display
   */
  label?: string;

  /**
   * Override the default placeholder text
   */
  placeholder?: ListSelectProps['placeholder'];

  /**
   * Size of the checkbox, defaults to `medium`
   */
  size?: InputSize;

  /**
   * The date range, expected to be in the format `{ start: Date, end: Date }`
   */
  value: IDateRange;

  /**
   * The minimum date that can be selected, defaults to `2000-01-01`
   */
  min?: Date;

  /**
   * The maximum date that can be selected
   */
  max?: Date;

  /**
   * Show the select dropdown with preset date ranges
   */
  showSelect?: boolean;

  /**
   * The country code is used to determine the financial year options for the preset date ranges
   */
  countryCode?: CountryCodes;

  /**
   * Returns the changed date range when one of the inputs change
   */
  onChange: (range: IDateRange) => void;
}

type DateRangeOption = ListSelectOption & {
  range?: IDateRange;
  value: IDateRangeOption | 'custom';
};

// TODO: move to core/Date
const toDateInputFormat = (date: Date) => formatDate(date, 'YYYY-MM-DD');

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Select date range',
    description: 'Date Range placeholder',
  },
  start: {
    defaultMessage: 'Start date',
    description: 'Date Range default start date label',
  },
  end: {
    defaultMessage: 'End date',
    description: 'Date Range default end date label',
  },
  today: {
    defaultMessage: 'Today',
    description: 'Date Range today date range option',
  },
  'last-7-days': {
    defaultMessage: 'Last 7 days',
    description: 'Date Range last 7 days date range option',
  },
  'last-4-weeks': {
    defaultMessage: 'Last 4 weeks',
    description: 'Date Range last 4 weeks date range option',
  },
  'last-3-months': {
    defaultMessage: 'Last 3 months',
    description: 'Date Range last 3 months date range option',
  },
  'last-12-months': {
    defaultMessage: 'Last 12 months',
    description: 'Date Range last 12 months date range option',
  },
  'month-to-date': {
    defaultMessage: 'Month to date',
    description: 'Date Range month to date date range option',
  },
  'quarter-to-date': {
    defaultMessage: 'Quarter to date',
    description: 'Date Range quarter to date date range option',
  },
  'year-to-date': {
    defaultMessage: 'Year to date',
    description: 'Date Range year to date date range option',
  },
  'last-financial-year': {
    defaultMessage: 'Last financial year',
    description: 'Date Range last financial year date range option',
  },
  'current-financial-year': {
    defaultMessage: 'Current financial year',
    description: 'Date Range current financial year date range option',
  },
  custom: {
    defaultMessage: 'Custom',
    description: 'Date Range custom date range option',
  },
});

/**
 * DateRange displays two date inputs that represent a date range
 */
const DateRange: React.FC<DateRangeProps> = ({
  id,
  label,
  placeholder,
  value = { start: undefined, end: undefined },
  size = 'medium',
  min = new Date('2000-01-01'),
  max,
  showSelect,
  countryCode,
  onChange,
  className,
  ...others
}) => {
  const strings = useMessages(messages);
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const minDate = min ? toDateInputFormat(min) : undefined;
  const maxDate = max ? toDateInputFormat(max) : undefined;
  const valueStartDateString = value.start
    ? toDateInputFormat(value.start)
    : undefined;
  const valueEndDateString = value.end
    ? toDateInputFormat(value.end)
    : undefined;

  const rootClasses = cx(
    {
      DateRange: true,
      [size]: true,
      hasSelect: showSelect,
    },
    className
  );

  const selectedOption = dateRangeOptions.find((option) => {
    const range = getDateRange(option, countryCode);
    return (
      toDateInputFormat(range.start) === valueStartDateString &&
      toDateInputFormat(range.end) === valueEndDateString
    );
  });

  const options: DateRangeOption[] = [
    ...dateRangeOptions.map((value) => ({
      range: getDateRange(value, countryCode),
      value,
      label: strings[value],
      selected: selectedOption === value,
    })),
    {
      value: 'custom',
      label: strings.custom,
      selected: !selectedOption && Boolean(value?.start) && Boolean(value?.end),
    },
  ];

  const handleStartInputChange = (val: string) => {
    if (!startInputRef.current?.validity.valid) {
      return;
    }
    const start = val ? new Date(val) : undefined;
    const end = value.end ? new Date(value.end) : undefined;
    onChange({ start, end });
  };

  const handleEndInputChange = (val: string) => {
    if (!endInputRef.current?.validity.valid) {
      return;
    }
    const start = value.start ? new Date(value.start) : undefined;
    const end = val ? new Date(val) : undefined;
    onChange({ start, end });
  };

  const handleStartInputBlur = () => {
    if (endInputRef.current && value.start && !value.end) {
      endInputRef.current.focus();
    }
  };

  // don't sort the options
  const handleListSort = (options: DateRangeOption[]) => {
    return options;
  };

  const handleSelectChange = (values: (string | number)[]) => {
    const selectedOption = options.find((option) => option.value === values[0]);

    if (selectedOption?.value === 'custom') {
      window.requestAnimationFrame(() => {
        startInputRef.current?.focus();
      });

      onChange({
        start: undefined,
        end: undefined,
      });
    } else if (selectedOption) {
      onChange(selectedOption.range);
    }
  };

  return (
    <div id={id} className={rootClasses} {...others}>
      <fieldset>
        {label && <legend>{label}</legend>}

        <div className={styles.container}>
          {showSelect && (
            <ListSelect
              id={`${id}-select`}
              hideHeader
              placeholder={placeholder ?? strings.placeholder}
              options={options}
              sortFunction={handleListSort}
              className={styles.select}
              onChange={handleSelectChange}
            />
          )}

          <div className={styles.inputWrapper}>
            <label htmlFor={`${id}-start`}>{strings.start}</label>
            <Input
              id={`${id}-start`}
              ref={startInputRef}
              type="date"
              aria-label={strings.start}
              min={minDate}
              max={value.end ? toDateInputFormat(value.end) : maxDate}
              value={value.start ? toDateInputFormat(value.start) : undefined}
              size={size}
              onChange={handleStartInputChange}
              onBlur={handleStartInputBlur}
              className={styles.startInput}
            />
          </div>

          <Icon name="arrow-right" size="small" className={styles.arrow} />

          <div className={styles.inputWrapper}>
            <label htmlFor={`${id}-end`}>{strings.end}</label>
            <Input
              id={`${id}-end`}
              ref={endInputRef}
              type="date"
              aria-label={strings.end}
              min={value.start ? toDateInputFormat(value.start) : minDate}
              max={maxDate}
              value={value.end ? toDateInputFormat(value.end) : undefined}
              size={size}
              onChange={handleEndInputChange}
              className={styles.endInput}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default DateRange;
