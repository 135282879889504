import React from 'react';

import { useI18n } from '@mobble/i18n';
import { Mob, toMobDisplayName } from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import {
  formatStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { toInteger } from '@mobble/shared/src/core/Number';
import { useSetting } from '@mobble/store/src/hooks';
import { Color } from '@mobble/theme';

import { Text } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { VStack } from '@src/stories/Components/Layout/Stack';
import {
  DataItemExtended,
  Table,
  type TableProps,
} from '@src/stories/Components/Layout/Table';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';
import { Spinner } from '@src/stories/Components/UI/Spinner';

import { useReportsRenderHelpers } from './useReportsRenderHelpers';

export interface LivestockNumbersReportProps {
  reportData: Mob[] | null;
  propertyTypes: ConfiguredPropertyType[];
}

export const LivestockNumbersReport: React.FC<LivestockNumbersReportProps> = ({
  reportData,
  propertyTypes,
}) => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));
  const convertStockingRate = (stockingRate: number) =>
    formatStockingRate(stockingRate, stockingUnit);

  const { renderClasses, renderAges } = useReportsRenderHelpers({
    propertyTypes,
  });

  if (!reportData?.length) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const tableData = mobsToTableExtendedData(reportData, convertStockingRate);

  const tableProps: TableProps<DataItemExtended<ExtendedMob>> = {
    title: formatMessage({
      defaultMessage: 'Livestock numbers',
      description: 'summaries.livestock_numbers.table.title',
    }),
    showTitle: true,
    columns: [
      {
        key: 'type',
        label: formatMessage({
          defaultMessage: 'Type',
          description: 'summaries.livestock_numbers.table.heading.column.type',
        }),
        toValue: (item) => item.type,
      },
      {
        key: 'breed',
        label: formatMessage({
          defaultMessage: 'Breed',
          description: 'summaries.livestock_numbers.table.heading.column.breed',
        }),
        toValue: (item) => item.breed,
        hidden: true,
      },
      {
        key: 'gender',
        label: formatMessage({
          defaultMessage: 'Gender',
          description:
            'summaries.livestock_numbers.table.heading.column.gender',
        }),
        toValue: (item) => item.gender,
        hidden: true,
      },
      {
        key: 'ages',
        label: formatMessage({
          defaultMessage: 'Ages',
          description: 'summaries.livestock_numbers.table.heading.column.ages',
        }),
        toValue: (item) => JSON.stringify(item.ages),
        hidden: true,
      },
      {
        key: 'classes',
        label: formatMessage({
          defaultMessage: 'Classes',
          description:
            'summaries.livestock_numbers.table.heading.column.classes',
        }),
        toValue: (item) => item.classes.toString(),
        hidden: true,
      },
      {
        key: 'head',
        label: formatMessage({
          defaultMessage: 'Head',
          description: 'summaries.livestock_numbers.table.heading.column.head',
        }),
        toValue: (item) => item.head,
      },
      {
        key: 'dse',
        label: stockingUnitLabel,
        toValue: (item) => toInteger(item.stocking_rate),
      },
    ],
    data: tableData,
    renderColSpan: (item, depth) => {
      if (item._title) {
        if (Array.isArray(item._title)) {
          if (depth === 2) {
            return renderClasses(item._title);
          } else if (depth === 3) {
            return renderAges(item.type, item._title);
          } else {
            return <Text variant="body">{item._title.join(', ')}</Text>;
          }
        }
      }
      return <Text variant="body">{item._title}</Text>;
    },
  };

  return <Table {...tableProps} />;
};

interface ExtendedMob extends Mob {
  head: number;
  stocking_rate: number;

  _title: string | string[];
  _span: number;
  _children: Record<string, ExtendedMob>;
}

const getOrCreateNode = (parent, key, title, mob = null) => {
  if (!parent[key]) {
    parent[key] = {
      ...(mob || {}), // Include full mob data only at the first level
      head: 0,
      stocking_rate: 0,
      _title: title,
      _span: 1,
      _children: {},
    };
  }
  return parent[key];
};

const mobsToTableExtendedData = (
  mobs: Mob[],
  convertStockingRate: (stockingRate: number) => number
): DataItemExtended<ExtendedMob>[] => {
  const data = {};

  mobs.forEach((mob) => {
    const mobName = toMobDisplayName(mob);
    const mobClasses = mob.classes.length ? [...mob.classes].sort() : [];
    const mobClassesKey = mobClasses.join(',');
    const mobAges = mob.ages.length ? [...mob.ages].sort().map(String) : ['0'];
    const mobAgesKey = mobAges.join(',');

    const stockingRate = convertStockingRate(mob.DSE) * mob.size;

    const typeNode = getOrCreateNode(data, mob.type, mob.type, mob);
    const nameNode = getOrCreateNode(typeNode._children, mobName, mobName, mob);
    const classNode = getOrCreateNode(
      nameNode._children,
      mobClassesKey,
      mobClasses,
      mob
    );
    const ageNode = getOrCreateNode(
      classNode._children,
      mobAgesKey,
      mobAges,
      mob
    );

    // Update aggregated data at each level
    [typeNode, nameNode, classNode, ageNode].forEach((node) => {
      node.head += mob.size;
      node.stocking_rate += stockingRate;
    });
  });

  const convertChildrenToArrayAndSort = (node) => {
    if (node._children) {
      node._children = Object.values(node._children)
        .map(convertChildrenToArrayAndSort)
        .sort((a, b) => {
          return b.head - a.head;
        });
    }
    return node;
  };

  const sortData = (data) => {
    const sortedData = Object.values(data)
      .map(convertChildrenToArrayAndSort)
      .sort((a, b) => b.head - a.head);

    // sort un-classed to top of list
    sortedData.forEach((typeNode) => {
      typeNode._children.forEach((nameNode) => {
        nameNode._children.sort((a, b) => {
          if (!a.classes.length) return -1;
          if (!b.classes.length) return 1;
          return 0;
        });
      });
    });

    return sortedData;
  };

  return sortData(data);
};
