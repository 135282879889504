import { timeZoneToCountryCode } from '@mobble/models/src/model/Settings';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { getLocalTimeZone } from '@src/interfaces/Timezone';

export const useTimeZone = () => {
  const properties = useProperties();
  const tz = properties.selected?.tz || getLocalTimeZone();
  const region = timeZoneToCountryCode(tz);

  return {
    tz,
    region,
  };
};
