import React from 'react';

import {
  getRainGaugeTotalsPerMonthForYearInMm,
  getRainGaugeYearsAvailable,
  RainGauge,
} from '@mobble/models/src/model/RainGauge';
import {
  LengthUnits,
  makeQuantityOfLength,
} from '@mobble/shared/src/core/Quantity';

import { EChart } from '@src/components';
import { useSetting } from '@src/context/settings';
import { Quantity } from '@mobble/shared/src';
import { useGenerateRainGaugeMonthlyRainfallChart } from '@src/stories/Components/Charts/generators/generateRainGaugeMonthlyRainfallChart';

interface RainGaugeValuesGeneratorProps {
  rainGauge: RainGauge;
  year?: number;
  lengthUnit: LengthUnits;
}

export const generateRainGaugeValues = ({
  rainGauge,
  year,
  lengthUnit,
}: RainGaugeValuesGeneratorProps) => {
  if (!rainGauge)
    return {
      yearsData: [],
      averageMonthlyRainfall: [],
    };

  const years = year ? [year] : getRainGaugeYearsAvailable(rainGauge);
  const readingsPerYearPerMonth = years.reduce((acc, y) => {
    const { readingsPerMonth } =
      getRainGaugeTotalsPerMonthForYearInMm(rainGauge)(y);
    return { ...acc, [y]: readingsPerMonth };
  }, {});

  const earliestYear = years[0];
  const earliestReadingMonthIndex = readingsPerYearPerMonth[years[0]].findIndex(
    (m) => m.total > 0
  );

  const latestYear = years[years.length - 1];
  const latestReadingMonthIndex = readingsPerYearPerMonth[latestYear].reduce(
    (latest, month, index) => {
      return month.total > 0 ? index : latest;
    },
    0
  );

  const yearsData = years.map((y) => {
    return {
      year: y,
      data: readingsPerYearPerMonth[y].map((i) => {
        const q_mm = makeQuantityOfLength('mm', i.total);
        return Quantity.convertTo(lengthUnit)(q_mm).value;
      }) as number[],
    };
  });

  const averageMonthlyRainfall =
    years.length > 1
      ? new Array(12).fill(null).map((_, i) => {
          const total = yearsData.reduce(
            ({ acc, count }, y) =>
              (y.year === earliestYear && i < earliestReadingMonthIndex) ||
              (y.year === latestYear && i > latestReadingMonthIndex)
                ? { acc, count }
                : { acc: acc + y.data[i], count: count + 1 },
            { acc: 0, count: 0 }
          );
          return total.count > 0 ? total.acc / total.count : 0;
        })
      : [];

  return {
    yearsData,
    averageMonthlyRainfall,
  };
};

export interface ChartRainGaugeMonthlyRainfallProps {
  rainGauge: RainGauge;
  year?: number;
}

export const ChartRainGaugeMonthlyRainfall: React.FC<
  ChartRainGaugeMonthlyRainfallProps
> = ({ rainGauge, year }) => {
  const lengthUnit = useSetting('lengthUnit') as LengthUnits;

  const { yearsData, averageMonthlyRainfall } = generateRainGaugeValues({
    rainGauge,
    year,
    lengthUnit,
  });

  const eChartData = useGenerateRainGaugeMonthlyRainfallChart({
    data: yearsData,
    params: {
      average: averageMonthlyRainfall.length ? averageMonthlyRainfall : null,
    },
  });

  return <EChart data={eChartData} />;
};
