import Fuse from 'fuse.js';
import { type RawDate } from '@mobble/shared/src/core/Date';
import { type AppliedInventoryItem } from './AppliedInventoryItem';
import { type User } from './User';
import { type Mob } from './Mob';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
import { Property } from './Property';

export interface MobAction {
  id: string;
  propertyId: Property['id'];
  date: RawDate;
  createdBy: User;
  type: string;
  title: string;
  description: string;
  note?: string;
  mobIds: string[];
  mobs?: Mob[];
  count?: number | null;
  appliedInventoryItems: AppliedInventoryItem[];
}

export const filterMobActions = (
  mobActions: MobAction[],
  filter?: FilterItem[]
): MobAction[] => {
  if (!filter || filter.length === 0) {
    return mobActions;
  }
  const grouped = [...groupFilter(filter)];

  const searchQuery = filter.find((a) => a.group === 'search')?.filter;

  const preFilteredMobActions =
    searchQuery && searchQuery.type === 'search'
      ? searchMobActions(mobActions, searchQuery.value)
      : mobActions;

  return preFilteredMobActions.filter((mobAction) =>
    grouped.every(([_, filters]) =>
      filters.some(filterItemMatchesMobAction(mobAction))
    )
  );
};

export const searchMobActions = (
  mobActions: MobAction[],
  searchQuery: string
): MobAction[] => {
  const fuse = new Fuse(mobActions, {
    keys: [
      { name: 'name', weight: 5 },
      { name: 'description', weight: 3 },
      { name: 'type', weight: 3 },
      { name: 'paddocks.name', weight: 3 },
      { name: 'appliedInventoryItems.name', weight: 3 },
    ],
    threshold: 0.5,
    shouldSort: true,
  });

  return fuse.search(searchQuery).map((a) => a.item);
};

export const filterItemMatchesMobAction =
  (mobAction: MobAction) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'type':
        return matches(mobAction.type);
      case 'dateRange':
        return matches(mobAction.date as any);
      case 'search':
        return true;
      default:
        return true;
    }
  };
