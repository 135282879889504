import React from 'react';

import { useI18n } from '@mobble/i18n';
import { flush } from '@mobble/store';
import { useProperties } from '@mobble/store/src/hooks';
import { Color } from '@mobble/theme';

import { Button, Icon, Text } from '@src/components';
import { store } from '@src/store';
import { Box } from '@src/stories/Components/Layout/Box';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import { Well } from '@src/stories/Components/Layout/Well';

export interface ErrorFallbackComponentProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallbackComponent: React.FC<ErrorFallbackComponentProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();

  const handleRetry = () => {
    resetErrorBoundary();
  };

  const handleReload = () => {
    flush(store.dispatch).then(() => {
      window.location.reload();
    });
  };

  return (
    <Box flex spacing={2} background={Color.AlmostWhite}>
      <VStack flex>
        <Spacer flex />
        <VStack alignment="center">
          <Icon size="large" name="error" color={Color.Orange} />
          <Spacer y={1} />
          <Text variant="small" color={Color.DarkGrey}>
            {formatMessage({
              defaultMessage: 'Crikey! Something went wrong.',
              description: 'App crashed error message',
            })}
          </Text>
        </VStack>
        {error && (
          <Box>
            <Spacer y={1} />
            <Well>
              <Text variant="tiny" align="center" color={Color.DarkGrey}>
                {error.toString()}
              </Text>
            </Well>
          </Box>
        )}
        <Spacer y={2} />
        <Box>
          <HStack>
            <Button
              flex
              outline
              label={formatMessage({
                defaultMessage: 'Reload',
                description: 'Web app crashed error - Reload button label',
              })}
              onClick={handleReload}
            />
            <Spacer x={2} />
            <Button
              flex
              iconAfter="undo"
              outline
              label={formatMessage({
                defaultMessage: 'Retry',
                description: 'App crashed error - Retry button label',
              })}
              onClick={handleRetry}
            />
          </HStack>
        </Box>
        <Spacer y={16} />
        <Spacer flex />
      </VStack>
    </Box>
  );
};
