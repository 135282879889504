import React, { useState } from 'react';

import { useI18n } from '@mobble/i18n';
import {
  availableSortOptions,
  filterMobs,
  findMobs,
  getTotalHead,
  Mob,
  sortMobs,
} from '@mobble/models/src/model/Mob';
import { paddockForMob } from '@mobble/models/src/model/Paddock';
import { StockingUnit } from '@mobble/models/src/model/Settings';
import { formatNumber } from '@mobble/shared/src/core/Number';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMobsFilterItems } from '@mobble/shared/src/hooks/useMobsFilterItems';
import {
  useMobs,
  usePaddocks,
  useProperties,
  useSetting,
} from '@mobble/store/src/hooks';

import { MobCard } from '@src/components';
import { ActionSheetOnMobSelect } from '@src/screens/Mobs/ActionSheetOnMobSelect';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';
import { makeMobsTableColumns } from '@src/stories/Views/Mob/List/mobsTableColumns';

import { SelectedMobsOptions } from './SelectedMobsOptions';

export interface MobsViewerProps {
  title?: string;
  provider: Provider<Mob>;
  showTitle?: boolean;
  hideFixedFilters?: boolean;
  renderEntityCard?: (mob: Mob) => React.ReactNode;
}

export const MobsViewer: React.FC<MobsViewerProps> = ({
  title,
  provider,
  showTitle,
  hideFixedFilters,
  renderEntityCard,
}) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(properties.selected?.id);
  const mobs = useMobs(properties.selected?.id);
  const filterItems = useMobsFilterItems(mobs.entities);
  const { refresh } = useEntitiesRefresher([mobs, paddocks], propertyId);

  const [selectedMob, setSelectedMob] = useState<Mob | null>(null);

  const handleClick = (mob: Mob) => {
    setSelectedMob(mob);
  };

  const summaryCounter = (entities: Mob[]) =>
    formatMessage(
      {
        description: 'mobs.list.result.total_head',
        defaultMessage: '<b>{TOTAL}</b> head',
      },
      {
        TOTAL: formatNumber(getTotalHead(entities)),
      }
    );

  const withSelection = (selected?: Mob['id'][]) => {
    const selectedMobs = findMobs(mobs.entities)(selected);

    return <SelectedMobsOptions mobs={selectedMobs} aboveNavigation />;
  };

  const baseViewerProps: EntitiesViewerProps<Mob> = {
    provider,
    title,
    showTitle,
    hideFixedFilters,
    onEmpty: formatMessage({
      defaultMessage: 'There are no mobs to display',
      description: 'mobs.table.empty',
    }),
    onRefresh: undefined,
    summaryCounter,
    onClickTableRow: handleClick,
    renderEntityCard:
      renderEntityCard ??
      ((mob) => (
        <Box spacing={1}>
          <MobCard
            mob={mob}
            paddockName={paddockForMob(paddocks.entities)(mob)?.name}
            propertyTypes={properties.selected?.types ?? []}
            size="small"
            onClick={handleClick}
          />
        </Box>
      )),
    tableColumns: makeMobsTableColumns({
      propertyTypes: properties.selected?.types ?? [],
      paddocks: paddocks.entities,
      stockingUnit,
      formatMessage,
    }),
    withSelection,
  };

  const render = (props) => {
    return (
      <>
        <EntitiesViewer key={properties.selected?.id} {...props} />
        {selectedMob ? (
          <>
            <ActionSheetOnMobSelect
              mob={selectedMob}
              onClose={() => setSelectedMob(null)}
            />
          </>
        ) : null}
      </>
    );
  };

  if (Array.isArray(provider)) {
    return render(baseViewerProps);
  }

  const applyFilter = filterMobs(paddocks.entities);
  const applySort = sortMobs({
    paddocks: paddocks.entities,
  });

  const extendedViewerProps: EntitiesViewerProps<Mob> = {
    ...baseViewerProps,
    filterItems,
    applyFilter,
    sortOptions: () => availableSortOptions,
    applySort,
    onRefresh: refresh,
  };

  return render(extendedViewerProps);
};
