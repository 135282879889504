import { useState } from 'react';
import { useLocation } from 'react-router';

import { MediaQuerySize, useMediaQuery } from './useMediaQuery';

export const useTabs = ({ defaultTab }: { defaultTab: string }) => {
  const { hash } = useLocation();
  const mediaSize = useMediaQuery();
  const showTabBar =
    mediaSize === MediaQuerySize.Small || mediaSize === MediaQuerySize.Medium;

  const [activeTab, setActiveTab] = useState<string>(
    hash.slice(1) || defaultTab
  );

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    window.location.hash = `#${tab}`;
  };

  return {
    activeTab,
    setActiveTab,
    showTabBar,
    handleTabChange,
  };
};
