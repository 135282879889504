import React from 'react';

import { Color } from '@mobble/colors';
import { useI18n } from '@mobble/i18n';
import {
  filterUsersOfUserRoles,
  sortByName,
} from '@mobble/models/src/model/User';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  type RootStackParamList,
  type ScreenRendererProps,
} from '@src/screens/config/types';
import { Box } from '@src/stories/Components/Layout/Box';
import { Text } from '@src/stories/Components/UI/Text';
import {
  type NavigationGroupProps,
  NavigationList,
} from '@src/stories/Components/UX/NavigationList';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import styles from './settingsPropertyUsers.scss';

export const SettingsPropertyUsersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        description: 'screen.title.settings_property_users',
        defaultMessage: 'Users',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyUsers: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  const formatRoleLabel = useRoleLabels();

  useEntitiesRefresher([properties], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const property = properties.selected;
  const { users, organisation } = property;
  const propertyUsers = filterUsersOfUserRoles(users);

  const totalUsers =
    organisation?.maxUsers - organisation?.remainingUsers ||
    propertyUsers.length;
  const maxUsers = organisation?.maxUsers;

  const showMessage = totalUsers >= 0 && maxUsers >= 0;
  const message = formatMessage(
    {
      description: 'settings.property.users.invite_user.user_count_info',
      defaultMessage:
        'There {totalUsers, plural, one {is} other {are}} currently <b>{totalUsers}</b> {totalUsers, plural, one {user} other {users}} active in your organisation, your subscription allows for <b>{maxUsers}</b>.',
    },
    {
      totalUsers,
      maxUsers,
    }
  );

  const navigationItems: NavigationGroupProps[] = [
    {
      items: [...propertyUsers].sort(sortByName).map((user) => ({
        title: user.name,
        subtitle: (user?.role && formatRoleLabel(user.role)) ?? undefined,
        href: toPath(ROUTE_NAME.SETTINGS_PROPERTY_USERS_USER_EDIT, {
          userId: user.id,
        }) as keyof RootStackParamList,
      })),
    },
  ];

  return (
    <>
      {showMessage ? (
        <Box className={styles.userCountDescription}>
          <Text color={Color.DarkGrey}>{message}</Text>
        </Box>
      ) : null}
      <NavigationList items={navigationItems} />
    </>
  );
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_USERS,
  header: SettingsPropertyUsersHeader,
  component: SettingsPropertyUsers,
};
