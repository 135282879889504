import React from 'react';
import { defineMessages } from 'react-intl';
import { useSearchParams } from 'react-router';

import { useMessages } from '@mobble/i18n';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { Color } from '@mobble/theme';

import { Icon, Text } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { HStack, VStack } from '@src/stories/Components/Layout/Stack';
import {
  DataItemExtended,
  Table,
  type TableProps,
} from '@src/stories/Components/Layout/Table';
import { Spinner } from '@src/stories/Components/UI/Spinner';

import { type RootItem } from './usePropertyReportForRange';
import { useReportsRenderHelpers } from './useReportsRenderHelpers';

const messages = defineMessages({
  title: {
    defaultMessage: 'Livestock reconciliation report',
    description: 'reports.livestock_reconciliation.table.title',
  },
  empty: {
    defaultMessage: 'un-classed',
    description: 'reports.livestock_reconciliation.table.item.empty',
  },
  'column.breed': {
    defaultMessage: 'Breed',
    description: 'reports.livestock_reconciliation.table.heading.colum.breed',
  },
  'column.casualties': {
    defaultMessage: 'Casualties',
    description:
      'reports.livestock_reconciliation.table.heading.colum.casualties',
  },
  'column.closing_head': {
    defaultMessage: 'Closing head',
    description:
      'reports.livestock_reconciliation.table.heading.colum.closing_head',
  },
  'column.diff_head': {
    defaultMessage: 'Difference',
    description:
      'reports.livestock_reconciliation.table.heading.colum.diff_head',
  },
  'column.gender': {
    defaultMessage: 'Gender',
    description: 'reports.livestock_reconciliation.table.heading.colum.gender',
  },
  'column.ages': {
    defaultMessage: 'Ages',
    description: 'reports.livestock_reconciliation.table.heading.colum.ages',
  },
  'column.classes': {
    defaultMessage: 'Classes',
    description: 'reports.livestock_reconciliation.table.heading.colum.classes',
  },
  'column.natural_increase': {
    defaultMessage: 'Natural increase',
    description:
      'reports.livestock_reconciliation.table.heading.colum.natural_increase',
  },
  'column.other': {
    defaultMessage: 'Other',
    description: 'reports.livestock_reconciliation.table.heading.colum.other',
  },
  'column.purchased': {
    defaultMessage: 'Purchased',
    description:
      'reports.livestock_reconciliation.table.heading.colum.purchased',
  },
  'column.sold': {
    defaultMessage: 'Sold',
    description: 'reports.livestock_reconciliation.table.heading.colum.sold',
  },
  'column.starting_head': {
    defaultMessage: 'Starting head',
    description:
      'reports.livestock_reconciliation.table.heading.colum.starting_head',
  },
  'column.type': {
    defaultMessage: 'Type',
    description: 'reports.livestock_reconciliation.table.heading.colum.type',
  },
});

export interface LivestockReconciliationReportProps {
  propertyNames: string[];
  propertyTypes: ConfiguredPropertyType[];
  reportData: RootItem[] | null;
}

export const LivestockReconciliationReport: React.FC<
  LivestockReconciliationReportProps
> = ({ propertyTypes, reportData, propertyNames }) => {
  const strings = useMessages(messages);
  const [searchParams] = useSearchParams();

  const { renderClasses, renderAges } = useReportsRenderHelpers({
    propertyTypes,
  });

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const FIELDS = ['type', 'breed', 'gender'];
  const sortRootItemsAlphabetically = (rootItems: RootItem[]) =>
    rootItems.sort((a, b) => {
      for (const field of FIELDS) {
        if (a[field] > b[field]) return 1;
        if (a[field] < b[field]) return -1;
      }
      return 0;
    });

  const tableData = toTableExtendedData(
    sortRootItemsAlphabetically(reportData)
  );

  const tableProps: TableProps<RootItem> = {
    title: strings.title,
    showTitle: true,
    downloadFileName: `${propertyNames?.toString()} ${
      strings.title
    } ${searchParams.get('start')}--${searchParams.get('end')}`,
    columns: [
      {
        key: 'type',
        toValue: (item) => item.type,
      },
      {
        key: 'breed',
        toValue: (item) => item.breed,
      },
      {
        key: 'gender',
        toValue: (item) => item.gender,
      },
      {
        key: 'ages',
        toValue: (item) => item.ages,
        hidden: true,
      },
      {
        key: 'classes',
        toValue: (item) => item.classes.toString(),
        hidden: true,
      },
      {
        key: 'starting_head',
        toValue: (item) => item.totals.startingHead,
        totals: true,
      },
      {
        key: 'natural_increase',
        toValue: (item) => item.totals.naturalIncrease,
        totals: true,
      },
      {
        key: 'purchased',
        toValue: (item) => item.totals.purchased,
        totals: true,
      },
      {
        key: 'casualties',
        toValue: (item) => item.totals.casualties,
        totals: true,
      },
      {
        key: 'sold',
        toValue: (item) => item.totals.sold,
        totals: true,
      },
      {
        key: 'closing_head',
        toValue: (item) => item.totals.closingHead,
        totals: true,
      },
      {
        key: 'diff_head',
        toValue: (item) => {
          return item.totals.closingHead - item.totals.startingHead;
        },
        render: (value: number) => {
          if (value === 0) {
            return null;
          }
          const isIncrease = value >= 0;
          const color = isIncrease
            ? Color.SuccessCoreDefault
            : Color.DangerCoreDefault;
          const icon = isIncrease ? 'arrow-up' : 'arrow-down';
          return (
            <HStack alignment="center">
              <Icon size="small" name={icon} color={color} />
              <Text bold color={color} variant="body">
                {value}
              </Text>
            </HStack>
          );
        },
      },
    ].map((c) => ({
      ...c,
      label: strings[`column.${c.key}`],
    })),
    data: tableData as DataItemExtended<RootItem>[],
    renderColSpan: (item, depth) => {
      if (item._title) {
        if (Array.isArray(item._title)) {
          if (depth === 1) {
            return renderClasses(item._title);
          } else if (depth === 2) {
            return renderAges(item.type, item._title);
          } else {
            return <Text variant="body">{item._title.join(', ')}</Text>;
          }
        }
      }
      return <Text variant="body">{item._title}</Text>;
    },
  };

  return <Table {...tableProps} />;
};

const toTableExtendedData = (data: RootItem[]): DataItemExtended[] => {
  return data.map((rootItem) => {
    return {
      ...rootItem,
      _children: [
        ...Object.entries(rootItem.byClasses).map(([key, val]) => ({
          ...val,
          _title: JSON.parse(key),
          _span: 3,
          _children: [
            ...Object.entries(val.byAges).map(([key, val]) => ({
              ...val,
              _title: JSON.parse(key),
              _span: 3,
            })),
          ],
        })),
      ],
    };
  }, []);
};
