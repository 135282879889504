import React, { useEffect } from 'react';

import { useI18n } from '@mobble/i18n';
import { getDateRange } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useCasualties } from '@mobble/store/src/hooks/casualties';
import { useMobs } from '@mobble/store/src/hooks/mobs';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useTimeZone } from '@src/hooks/useTimeZone';
import { CasualtiesViewer } from '@src/stories/Views/Casualty/List/CasualtiesViewer';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { useLinking } from '../../hooks/useLinking';
import { EntitySliceFactoryPrelude } from '../../stories/Views/Misc/EntitySliceFactoryPrelude';
import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';

export const SummaryCasualtiesHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Casualties',
        description: 'screen.title.summary_casualties',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryCasualties: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const casualties = useCasualties(propertyId);
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const { region } = useTimeZone();

  useEffect(() => {
    const last12Months = getDateRange('last-12-months', region);
    casualties.setFilter({
      group: 'dateRange',
      filter: {
        type: 'dateRange',
        value: [last12Months.start, last12Months.end],
      },
    });
  }, []);

  useEntitiesRefresher([casualties, mobs, paddocks], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      mobs.prelude,
      casualties.prelude,
      paddocks.prelude,
    ],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <CasualtiesViewer
      title={formatMessage({
        defaultMessage: 'Casualties',
        description: 'screen.title.summary_casualties',
      })}
      provider={casualties}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_CASUALTIES,
  header: SummaryCasualtiesHeader,
  component: SummaryCasualties,
};
