import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from '@reduxjs/toolkit';
import mobbleService from '@mobble/service';
import { MapPortalContent } from '@mobble/models/src/model/Map';
import { MapDetails, mapDetails } from '@mobble/models/src/model/MapDetail';
import { MapStyle, MapTileSource } from '@mobble/models/src/model/MapStyle';
import { FilterItem } from '@mobble/models';
import { select as selectProperty } from './properties';

import { thunkDelete as thunkDeletePaddock, thunkMoveMobs } from './paddocks';
import { thunkDelete as thunkDeleteTask } from './tasks';
import { thunkDelete as thunkDeleteMapAsset } from './mapAssets';
import { thunkDelete as thunkDeleteCustomMapLayer } from './customMapLayers';
export type MapSettings = {
  mapStyle: MapStyle;
  mapDetails: MapDetails;
  mapMobsFilter: FilterItem[];
  mapCustomLayersFilter: FilterItem[];
};

export type MapState = MapSettings & {
  baseMaps: MapTileSource[];
  overlays: MapTileSource[];
  portalContent: MapPortalContent;

  googleSession: {
    expiry: string;
    session: string;
    imageFormat?: string;
    tileHeight?: number;
    tileWidth?: number;
  };
  googleSessionError: string | null;
};

export const defaultState: MapState = {
  baseMaps: [],
  overlays: [],
  portalContent: null,
  googleSession: {
    expiry: '',
    session: '',
  },
  googleSessionError: null,

  mapStyle: MapStyle.Satellite,
  mapDetails: [...mapDetails],
  mapMobsFilter: [],
  mapCustomLayersFilter: [],
};

const slice = createSlice({
  name: 'map',
  initialState: defaultState,
  reducers: {
    flush: () => defaultState,
    updateSettings: (
      state: MapState,
      action: PayloadAction<Partial<MapState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getMapTileSources.fulfilled, (state, action) => {
      state.baseMaps = action.payload.baseMaps;
      state.overlays = action.payload.overlays;
    });
    builder.addCase(getGoogleMapsSessionToken.fulfilled, (state, action) => {
      state.googleSession = action.payload;
      state.googleSessionError = null;
    });
    builder.addCase(getGoogleMapsSessionToken.rejected, (state, action) => {
      state.googleSession = defaultState.googleSession;
      state.googleSessionError = action.error.message;
    });

    // clear settings when property changes
    builder.addCase(selectProperty, (state, action) => {
      state.portalContent = null;
      state.mapMobsFilter = [];
      state.mapCustomLayersFilter = [];
    });

    // clear portal content on certain actions
    builder.addCase(thunkMoveMobs.fulfilled, (state, action) => {
      state.portalContent = null;
    });
    builder.addCase(thunkDeletePaddock.fulfilled, (state, action) => {
      state.portalContent = null;
    });
    builder.addCase(thunkDeleteTask.fulfilled, (state, action) => {
      state.portalContent = null;
    });
    builder.addCase(thunkDeleteMapAsset.fulfilled, (state, action) => {
      state.portalContent = null;
    });
    builder.addCase(thunkDeleteCustomMapLayer.fulfilled, (state, action) => {
      state.portalContent = null;
    });
  },
});

export const { updateSettings, flush } = slice.actions;

export const getMapTileSources = createAsyncThunk<any, any>(
  'map/getMapTileSources',
  mobbleService.api.mapTileSources.get
);

export const getGoogleMapsSessionToken = createAsyncThunk<any, any>(
  'map/getGoogleMapsSessionToken',
  mobbleService.api.mapTileSources.getGoogleMapsSessionToken
);

export const { reducer } = slice;
