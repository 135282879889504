import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { type StripeProduct } from '@mobble/models/src/model/Subscriptions';
import { BillingRegion } from '@mobble/models/src/model/User';
import mobbleService from '@mobble/service';

import * as ROUTE_NAME from '@src/screens/config/routeNames';

const SUBSCRIPTION_RETURN_URL = `${window.location.origin}${ROUTE_NAME.SETTINGS_BILLING}`;

const REACT_APP_STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ;

export const useProducts = () => {
  const [products, setProducts] = React.useState<StripeProduct[] | null>(null);

  React.useEffect(() => {
    mobbleService.api.organisation.getStripeProducts().then((res) => {
      setProducts(res);
    });
  }, []);

  return products;
};

export const navigateToCheckout =
  (billingRegion: BillingRegion) => async (priceId: string) =>
    mobbleService.api.organisation
      .getStripeCheckoutURL({
        priceId,
        successURL: SUBSCRIPTION_RETURN_URL,
        cancelURL: SUBSCRIPTION_RETURN_URL,
      })
      .then(async (res) => {
        const stripePromise = loadStripe(
          billingRegion === BillingRegion.NZ
            ? REACT_APP_STRIPE_PUBLISHABLE_KEY_NZ
            : REACT_APP_STRIPE_PUBLISHABLE_KEY
        );
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
          sessionId: res,
        });
      });

export const getManageSubscriptionUrl = async () =>
  mobbleService.api.organisation
    .getStripeURL({
      successURL: SUBSCRIPTION_RETURN_URL,
      cancelURL: SUBSCRIPTION_RETURN_URL,
    })
    .then((res) => {
      window.open(res, '_blank');
    });
