import { useEffect, useState } from 'react';
import { useI18n, makeQuantityFormatMessageItem } from '@mobble/i18n';
import { Point } from '@mobble/models';
import {
  availableSortOptions,
  filterPaddocks,
  getTotalArea,
  sortPaddocks,
  type Paddock,
} from '@mobble/models/src/model/Paddock';
import {
  useMobs,
  usePaddockGeometries,
  usePaddockGroupedStockingRates,
  usePaddockGroups,
  usePaddocks,
  useProperties,
  useSettings,
  useTasks,
} from '@mobble/store/src/hooks';
import { useEntitiesRefresher } from './useEntitiesRefresher';
import { usePaddocksFilterItems } from './usePaddocksFilterItems';

export type PaddocksView = {
  EntitySliceFactoryPrelude: any;
  location: Point | null;
};

export const usePaddocksView = (props: PaddocksView) => {
  const { EntitySliceFactoryPrelude, location } = props;
  const { formatMessage } = useI18n();
  const { settings } = useSettings();

  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddockGroupedStockingRates =
    usePaddockGroupedStockingRates(propertyId);
  const mobs = useMobs(propertyId);
  const tasks = useTasks(propertyId);
  const filterItems = usePaddocksFilterItems();

  const [selectedPaddock, setSelectedPaddock] = useState<Paddock | null>(null);

  const { refresh, refreshIfExpired } = useEntitiesRefresher(
    [
      mobs,
      paddockGeometries,
      paddockGroupedStockingRates,
      paddockGroups,
      paddocks,
      tasks,
    ],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      mobs.prelude,
      paddockGeometries.prelude,
      paddockGroupedStockingRates.prelude,
      paddockGroups.prelude,
      paddocks.prelude,
      properties.prelude,
    ],
    required: [properties.selected],
    type: 'paddocks',
  });

  const applyFilter = filterPaddocks(mobs.entities);

  const applySort = sortPaddocks({
    paddockGeometries: paddockGeometries.entities,
    mobs: mobs.entities,
    origin: location ?? undefined,
  });

  const summaryCounter = (entities: Paddock[]) => {
    const message = makeQuantityFormatMessageItem(
      getTotalArea(entities),
      settings.areaUnit
    );
    return formatMessage(message[0], message[1]);
  };

  const handleSelectPaddock = (paddock: Paddock | null) => {
    setSelectedPaddock(paddock);
  };

  return {
    Prelude,
    properties,
    propertyId,
    paddocks,
    paddockGeometries,
    paddockGroups,
    paddockGroupedStockingRates,
    mobs,
    tasks,

    applyFilter,
    applySort,
    filterItems,
    handleSelectPaddock,
    refresh,
    refreshIfExpired,
    selectedPaddock,
    summaryCounter,
    sortOptions: availableSortOptions,
  };
};
