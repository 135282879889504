import { useState } from 'react';
import { MapAssetType } from '@mobble/models/src/model/MapAsset';
import { MapDetail } from '@mobble/models/src/model/MapDetail';
import { MapEvent, OnClickMapItemEvent } from '@mobble/models/src/model/Map';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { findPaddockGeometryByPoint } from '@mobble/models/src/model/PaddockGeometry';
import {
  useMapSettings,
  usePaddockGeometries,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

interface SharedState {
  selectedMapAssetType: MapAssetType;
  overwrittenMapDetails: null | MapDetail[];
}

export const useMapOverview = () => {
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const { update } = useMapSettings();
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddocks = usePaddocks(propertyId);

  const [state, setState] = useState<SharedState>({
    selectedMapAssetType: MapAssetType.Bore,
    overwrittenMapDetails: null,
  });

  const updateState = (newState: Partial<SharedState>) =>
    setState((s) => ({ ...s, ...newState }));

  const handleMapItemClick = (ev: OnClickMapItemEvent) => {
    update({
      portalContent: {
        ...ev,
      },
    });
  };

  const handleEvent = (event: MapEvent) => {
    if (event.type === 'movement') {
      if (event.final && event.id) {
        const paddockGeometry = findPaddockGeometryByPoint(
          paddockGeometries.entities
        )(event.b);
        const fromPaddock = paddockGeometry
          ? findPaddock(paddocks.entities)(event.id)
          : null;
        const toPaddock = paddockGeometry
          ? findPaddock(paddocks.entities)(paddockGeometry.paddockId)
          : null;

        if (fromPaddock && toPaddock) {
          update({
            portalContent: {
              type: 'move-mob',
              fromPaddock,
              toPaddock,
            },
          });
        }
      }
    }
  };

  return {
    state,
    updateState,
    handleEvent,
    handleMapItemClick,
    handleMapItemPress: handleMapItemClick,
  };
};
