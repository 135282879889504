import Logger from '@mobble/shared/src/core/Logger';
import { type ApiPrelude } from '../types';
import { type MapTileSource } from '@mobble/models/src/model/MapStyle';

export const get =
  (prelude: ApiPrelude) =>
  async (
    parentId: string
  ): Promise<{
    entities: MapTileSource[];
  }> => {
    const response = await prelude.fetch({
      uri: `/property/${parentId}/map/tile-sources`,
      method: 'GET',
    });
    return response;
  };

export const getGoogleMapsSessionToken =
  () =>
  async ({
    key,
    requestHeaders,
  }): Promise<{
    entities: MapTileSource[];
  }> => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (requestHeaders) {
      Object.entries(requestHeaders).forEach(
        ([key, value]: [key: string, value: string]) => {
          headers.append(key, value);
        }
      );
    }

    const response: Response = await fetch(
      `https://tile.googleapis.com/v1/createSession?key=${key}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({
          mapType: 'satellite',
        }),
      }
    );
    const json = await response.json();
    if (json.error) {
      Logger.error('getGoogleMapsSessionToken:', JSON.stringify(json.error));
      return Promise.reject(json.error);
    }
    return json;
  };
