import React from 'react';

import { useI18n } from '@mobble/i18n';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  type NavigationGroupProps,
  NavigationList,
} from '@src/stories/Components/UX/NavigationList';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { type RouteName, type ScreenRendererProps } from '../config/types';

const HELP_URI = 'https://mobble.io/help';

export const SettingsHeader: React.FC<ScreenRendererProps> = (props) => {
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Settings',
        description: 'screen.title.settings',
      })}
      onToggleDrawer={props.drawer.toggle}
    />
  );
};

export const Settings: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  const checkAccess = (route: string) => {
    if (route === HELP_URI) {
      return true;
    }
    return hasAccessToRoute(route as RouteName);
  };

  const navigationItems: NavigationGroupProps[] = [
    {
      title: formatMessage({
        defaultMessage: 'General',
        id: 'settings.general.title',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Profile',
            description: 'screen.title.settings_user',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Update your profile details',
            description: 'settings.toc.user_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_USER)
            ? ROUTE_NAME.SETTINGS_USER
            : showNoAccessAlert,
          icon: 'user',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_USER),
        },
        {
          title: formatMessage({
            defaultMessage: 'Units and formatting',
            description: 'screen.title.settings_units',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Set your preferred units and formatting',
            description: 'settings.toc.units_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_UNITS)
            ? ROUTE_NAME.SETTINGS_UNITS
            : showNoAccessAlert,
          icon: 'ruler',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_UNITS),
        },
      ],
    },
    {
      title: formatMessage({
        defaultMessage: 'Organisation',
        description: 'settings.toc.organisation',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Integrations',
            description: 'screen.title.integrations',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Manage your integrations',
            description: 'settings.toc.integrations',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_INTEGRATIONS)
            ? ROUTE_NAME.SETTINGS_INTEGRATIONS
            : showNoAccessAlert,
          icon: 'grid',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_INTEGRATIONS),
        },
        {
          title: formatMessage({
            defaultMessage: 'Billing',
            description: 'screen.title.billing',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Manage your billing details',
            description: 'settings.toc.billing_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_BILLING)
            ? ROUTE_NAME.SETTINGS_BILLING
            : showNoAccessAlert,
          icon: 'credit-card',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_BILLING),
        },
      ],
    },
    {
      title: formatMessage({
        defaultMessage: 'Property',
        description: 'settings.toc.property',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Property information',
            description: 'screen.title.settings_property_information',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Update your property details',
            description: 'settings.toc.property_information_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_INFORMATION)
            ? ROUTE_NAME.SETTINGS_PROPERTY_INFORMATION
            : showNoAccessAlert,
          icon: 'farm',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_INFORMATION),
        },
        {
          title: formatMessage({
            defaultMessage: 'Users',
            description: 'screen.title.settings_property_users',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Manage your property users',
            description: 'settings.toc.property_users_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_USERS)
            ? ROUTE_NAME.SETTINGS_PROPERTY_USERS
            : showNoAccessAlert,
          icon: 'hamburger',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_USERS),
        },
        {
          title: formatMessage({
            defaultMessage: 'Mobble Connect',
            description: 'settings.toc.mobble_connect',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Add and manage third party access',
            description: 'settings.toc.mobble_connect_description',
          }),
          href: checkAccess(ROUTE_NAME.MOBBLE_CONNECT_USERS)
            ? ROUTE_NAME.MOBBLE_CONNECT_USERS
            : showNoAccessAlert,
          icon: 'mail',
          disabled: !checkAccess(ROUTE_NAME.MOBBLE_CONNECT_USERS),
        },
        {
          title: formatMessage({
            defaultMessage: 'Classes, types & tags',
            description: 'screen.title.settings_property_classes_types',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Customise the way you organise your livestock',
            description: 'settings.toc.property_classes_types_description',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES)
            ? ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES
            : showNoAccessAlert,
          icon: 'split',
          disabled: !checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES),
        },
        {
          title: formatMessage({
            defaultMessage: 'Custom map layers',
            description: 'screen.title.settings_property_custom_map_layers',
          }),
          subtitle: formatMessage({
            defaultMessage: 'View custom map layers',
            description: 'settings.toc.custom_map_layers',
          }),
          href: checkAccess(ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS)
            ? ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS
            : showNoAccessAlert,
          icon: 'layers',
          disabled: !checkAccess(
            ROUTE_NAME.SETTINGS_PROPERTY_CUSTOM_MAP_LAYERS
          ),
        },
      ],
    },
    {
      title: formatMessage({
        defaultMessage: 'About',
        description: 'settings.toc.about',
      }),
      items: [
        {
          title: formatMessage({
            defaultMessage: 'Help',
            description: 'screen.title.settings_help',
          }),
          subtitle: formatMessage({
            defaultMessage: 'Get help',
            description: 'settings.toc.help_description',
          }),
          // @ts-expect-error - only exteral link
          href: HELP_URI,
          icon: 'help-circle',
          disabled: false,
        },
      ],
    },
  ];

  return <NavigationList variant="card" items={navigationItems} />;
};

export default {
  name: ROUTE_NAME.SETTINGS_LIST,
  header: SettingsHeader,
  component: Settings,
};
