import React from 'react';
import { defineMessages } from 'react-intl';
import { useSearchParams } from 'react-router';

import { useMessages } from '@mobble/i18n';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';
import { formatMonetary } from '@mobble/shared/src/core/Monetary';
import { useSettings } from '@mobble/store/src/hooks/settings';
import { Color } from '@mobble/theme';

import { Text } from '@src/components';
import { Box } from '@src/stories/Components/Layout/Box';
import { VStack } from '@src/stories/Components/Layout/Stack';
import {
  DataItemExtended,
  Table,
  type TableProps,
} from '@src/stories/Components/Layout/Table';
import { Spinner } from '@src/stories/Components/UI/Spinner';

import { Item, type RootItem } from './usePropertyReportForRange';
import { useReportsRenderHelpers } from './useReportsRenderHelpers';

const messages = defineMessages({
  title: {
    defaultMessage: 'Livestock sales report',
    description: 'reports.livestock_sales.table.title',
  },
  empty: {
    defaultMessage: 'un-classed',
    description: 'reports.livestock_sales.table.item.empty',
  },
  'column.type': {
    defaultMessage: 'Type',
    description: 'reports.livestock_sales.table.heading.colum.type',
  },
  'column.breed': {
    defaultMessage: 'Breed',
    description: 'reports.livestock_sales.table.heading.colum.breed',
  },
  'column.gender': {
    defaultMessage: 'Gender',
    description: 'reports.livestock_sales.table.heading.colum.gender',
  },
  'column.ages': {
    defaultMessage: 'Ages',
    description: 'reports.livestock_sales.table.heading.colum.ages',
  },
  'column.classes': {
    defaultMessage: 'Classes',
    description: 'reports.livestock_sales.table.heading.colum.classes',
  },
  'column.sold': {
    defaultMessage: 'Head sold',
    description: 'reports.livestock_sales.table.heading.colum.sold',
  },
  'column.average_per_head': {
    defaultMessage: 'Average per head',
    description: 'reports.livestock_sales.table.heading.colum.average_per_head',
  },
  'column.total': {
    defaultMessage: 'Total received',
    description: 'reports.livestock_sales.table.heading.colum.total',
  },
});

export interface LivestockSalesReportProps {
  reportData: RootItem[];
  propertyNames: string[];
  propertyTypes: ConfiguredPropertyType[];
}

export const LivestockSalesReport: React.FC<LivestockSalesReportProps> = ({
  propertyTypes,
  reportData,
  propertyNames,
}) => {
  const strings = useMessages(messages);
  const { settings } = useSettings();
  const [searchParams] = useSearchParams();

  const { renderClasses, renderAges } = useReportsRenderHelpers({
    propertyTypes,
  });

  if (!reportData) {
    return (
      <VStack alignment="center">
        <Box spacing={4}>
          <Spinner color={Color.Black} />
        </Box>
      </VStack>
    );
  }

  const tableData = toTableExtendedData(reportData);

  const tableProps: TableProps<RootItem> = {
    title: strings.title,
    showTitle: true,
    downloadFileName: `${propertyNames?.toString()} ${
      strings.title
    } ${searchParams.get('start')}--${searchParams.get('end')}`,
    columns: [
      {
        key: 'type',
        toValue: (item) => item.type,
      },
      {
        key: 'breed',
        toValue: (item) => item.breed,
      },
      {
        key: 'gender',
        toValue: (item) => item.gender,
      },
      {
        key: 'ages',
        toValue: (item) => item.ages,
        hidden: true,
      },
      {
        key: 'classes',
        toValue: (item) => item.classes.toString(),
        hidden: true,
      },
      {
        key: 'sold',
        toValue: (item) => item.sales.sold,
        totals: true,
      },
      {
        key: 'average_per_head',
        toValue: (item) => item.sales.averagePerHead,
        totals: () => {
          const totalReceived = tableData.reduce(
            (a, b: DataItemExtended<Item>) => a + b.sales.total,
            0
          );
          const totalSold = tableData.reduce(
            (a, b: DataItemExtended<Item>) => a + b.sales.sold,
            0
          );
          return totalReceived / totalSold;
        },
        valueToString: (value) =>
          formatMonetary(value, settings.currencySymbol),
      },
      {
        key: 'total',
        toValue: (item) => item.sales.total,
        totals: true,
        valueToString: (value) =>
          formatMonetary(value, settings.currencySymbol),
      },
    ].map((c) => ({
      ...c,
      label: strings[`column.${c.key}`],
    })),
    data: tableData as DataItemExtended<RootItem>[],
    renderColSpan: (item, depth) => {
      if (item._title) {
        if (Array.isArray(item._title)) {
          if (depth === 1) {
            return renderClasses(item._title);
          } else if (depth === 2) {
            return renderAges(item.type, item._title);
          } else {
            return <Text variant="body">{item._title.join(', ')}</Text>;
          }
        }
      }
      return <Text variant="body">{item._title}</Text>;
    },
  };

  return <Table {...tableProps} />;
};

const toTableExtendedData = (data: RootItem[]): DataItemExtended[] => {
  return data
    .map((rootItem) => {
      return {
        ...rootItem,
        _children: [
          ...Object.entries(rootItem.byClasses).map(([key, val]) => ({
            ...val,
            _title: JSON.parse(key),
            _span: 3,
            _children: [
              ...Object.entries(val.byAges).map(([key, val]) => ({
                ...val,
                _title: JSON.parse(key),
                _span: 3,
              })),
            ],
          })),
        ],
      };
    })
    .reduce((acc, item) => {
      if (item.sales.sold === 0) {
        return acc;
      }

      return [
        ...acc,
        {
          ...item,
          _children: item._children.filter((item) => item.sales.sold),
        },
      ];
    }, []);
};
