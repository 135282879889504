import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { Sale } from '@mobble/models/src';
import { EntitiesFilterItem } from '@mobble/models/src/model/BaseEntity';
import { getMobParamValues } from '@mobble/models/src/model/Mob';
import { useProperties, useSales } from '@mobble/store/src/hooks';
import { fromRawDate } from '../core/Date';

const messages = defineMessages({
  dateRange: {
    defaultMessage: 'Date range',
    description: 'sales.filter.group.type.date_range.label',
  },
  breed: {
    defaultMessage: 'Breed',
    description: 'sales.filter.group.breed.label',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'sales.filter.group.gender.label',
  },
  number: {
    defaultMessage: 'Number',
    description: 'sales.filter.group.type.number.label',
  },
});

export const useSalesFilterItems = () => {
  const strings = useMessages(messages);
  const properties = useProperties();
  const sales = useSales(properties.selected?.id);

  const toMobParamValue = getMobParamValues(sales.entities.map((e) => e.mob));

  const years = Array.from(
    new Set(sales.entities.map((entity) => fromRawDate(entity.date).year()))
  ).slice(0, 5);

  return (): EntitiesFilterItem<Sale>[] => [
    {
      type: 'date-range',
      group: 'dateRange',
      title: strings.dateRange,
      meta: {
        fixed: true,
        years,
      },
    },
    {
      title: strings.breed,
      type: 'select-multiple',
      group: 'breed',
      data: toMobParamValue('breed'),
    },
    {
      title: strings.gender,
      type: 'select-multiple',
      group: 'gender',
      data: toMobParamValue('gender'),
    },
    {
      type: 'range',
      group: 'number',
      title: strings.number,
    },
  ];
};
