import React, { useEffect } from 'react';

import { useI18n } from '@mobble/i18n';
import { getDateRange } from '@mobble/shared/src/core/Date';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { useMobActions, useProperties } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import { useTimeZone } from '@src/hooks/useTimeZone';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobActionsViewer } from '@src/stories/Views/MobAction/List/MobActionsViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

export const SummaryMobActionsHeader: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Mob actions',
        description: 'screen.title.summary_mob_actions',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Summaries',
            description: 'screen.title.summaries',
          }),
          href: ROUTE_NAME.SUMMARIES_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SUMMARIES_LIST);
      }}
    />
  );
};

export const SummaryMobActions: React.FC<ScreenRendererProps> = () => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobActions = useMobActions(propertyId);
  const { region } = useTimeZone();

  useEffect(() => {
    const last12Months = getDateRange('last-12-months', region);
    mobActions.setFilter({
      group: 'dateRange',
      filter: {
        type: 'dateRange',
        value: [last12Months.start, last12Months.end],
      },
    });
  }, []);

  useEntitiesRefresher([mobActions], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, mobActions.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <MobActionsViewer
      title={formatMessage({
        defaultMessage: 'Mob actions',
        description: 'screen.title.summary_mob_actions',
      })}
      provider={mobActions}
    />
  );
};

export default {
  name: ROUTE_NAME.SUMMARY_MOB_ACTIONS_LIST,
  header: SummaryMobActionsHeader,
  component: SummaryMobActions,
};
