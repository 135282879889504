import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import classNames from 'classnames/bind';

import Icon, { IconName } from '../Icon/Icon';

import styles from './BlankSlate.scss';
const cx = classNames.bind(styles);

export interface BlankSlateProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onClick'> {
  /**
   * Sets the title text
   */
  title?: string;

  /**
   * Sets the description text
   */
  text: string;

  /**
   * Sets the actions area
   */
  actions?: ReactNode;

  /**
   * Sets the icon, defaults to 'info'
   */
  icon?: IconName;

  /**
   * Sets the colour of the icon
   */
  intent?: 'info' | 'success' | 'warning' | 'danger';
}

/**
 * The BlankSlate component is used to display an empty state for a full page.
 */
const BlankSlate: React.FC<BlankSlateProps> = ({
  title,
  text,
  actions,
  icon = 'info',
  intent = 'info',
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      BlankSlate: true,
      [intent]: true,
    },
    className
  );

  return (
    <div className={rootClasses} {...others}>
      <Icon name={icon} size="xlarge" className={styles.icon} />
      {title ? <h1>{title}</h1> : null}
      <p>{text}</p>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default BlankSlate;
