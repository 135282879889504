import React, {
  ComponentPropsWithoutRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';

import { toContrastingTextColor } from '@mobble/shared/src/core/ColorUtils';
import { Color } from '@mobble/theme';

import { Button } from '@src/components';

import styles from './TabBar.scss';
const cx = classNames.bind(styles);

export interface TabBarTab {
  /**
   * Label to display for the tab
   */
  label: string;

  /**
   * Value associated with the tab, should match the `id` prop of the corresponding `TabPanel`
   */
  value: string;

  /**
   * Whether the tab is currently active
   */
  active?: boolean;

  /**
   * Click handler for the tab
   */
  onClick?: (value: string) => void;
}

export interface TabBarProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'onChange'> {
  /**
   * Array of tab configurations
   */
  tabs: TabBarTab[];

  /**
   * Background color of the tab bar
   */
  backgroundColor?: Color;

  /**
   * Color of the active tab indicator
   */
  indicatorColor?: Color;

  /**
   * Click handler for the tab, overrides `TabBarTab` onClick handler
   */
  onChange?: (value: string) => void;
}

/**
 * TabBar component for navigation between tabbed content
 * TODO: handle keyboard navigation
 */
export const TabBar = ({
  tabs,
  backgroundColor = Color.PrimaryDefault,
  indicatorColor = Color.NeutralsBorderMedium,
  onChange,
  className,
  ...others
}: TabBarProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [tabWidth, setTabWidth] = useState(window.innerWidth / tabs.length);

  useEffect(() => {
    const handleResize = () => {
      setTabWidth(
        (containerRef?.current?.getBoundingClientRect().width ??
          window.innerWidth) / tabs.length
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tabs.length]);

  const activeIndex = tabs.findIndex((tab) => tab.active);
  const textColor = toContrastingTextColor(
    backgroundColor,
    Color.White,
    Color.Black
  );

  const rootClasses = cx(
    {
      TabBar: true,
    },
    className
  );

  const handleClick = (value: string) => {
    onChange?.(value);
    tabs.find((tab) => tab.value === value)?.onClick?.(value);
  };

  return (
    <div ref={containerRef} role="tablist" className={rootClasses} {...others}>
      {tabs.map((tab, index) => (
        <Button
          key={`${tab.value}${index}`}
          aria-controls={`${tab.value}-tabpanel`}
          aria-selected={tab.active ? 'true' : 'false'}
          role="tab"
          tabIndex={tab.active ? 0 : -1}
          onClick={() => handleClick(tab.value)}
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
          }}
        >
          {tab.label}
        </Button>
      ))}

      <div
        role="presentation"
        className={styles.indicator}
        style={{
          width: `${tabWidth}px`,
          left: `${activeIndex * tabWidth}px`,
          backgroundColor: indicatorColor,
        }}
      />
    </div>
  );
};

export default TabBar;
