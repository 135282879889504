import React from 'react';

import {
  InventoryCategory,
  InventoryItemBatch,
  User,
} from '@mobble/models/src';
import { InventoryItem } from '@mobble/models/src/model/InventoryItem';
import {
  InventoryItemBatchChemical,
  InventoryItemBatchFeed,
  InventoryItemBatchStatus,
} from '@mobble/models/src/model/InventoryItemBatch';
import {
  type QuantityOfMassVolume,
  Type,
  unitToQuantityType,
} from '@mobble/shared/src/core/Quantity';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { QuantityTypes } from '../Form/FormBuilderField';

export interface InventoryItemBatchCreateFormProps<
  T extends InventoryItemBatch
> {
  inventoryItem: InventoryItem;
  inventoryItems: InventoryItem[];
  user: User;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (inventoryItemBatch: Omit<T, 'id'>) => void;
}

export const InventoryItemBatchCreateForm: React.FC<
  InventoryItemBatchCreateFormProps<InventoryItemBatch>
> = (props) => {
  switch (props.inventoryItem.category) {
    case InventoryCategory.Chemicals:
      return <InventoryItemBatchChemicalCreateForm {...props} />;
    case InventoryCategory.Feed:
      return <InventoryItemBatchFeedCreateForm {...props} />;
  }
};

export interface InventoryItemChemicalBatchCreateFormValues {
  chemicalItem: string;
  quantity: QuantityOfMassVolume;
  name: string;
  dateReceived: string;
  expiryDate: string;
  dateOfManufacture: string;
  notes: string;
}

export const InventoryItemBatchChemicalCreateForm: React.FC<
  InventoryItemBatchCreateFormProps<InventoryItemBatchChemical>
> = ({
  inventoryItem,
  inventoryItems,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (
    formValues: InventoryItemChemicalBatchCreateFormValues
  ) => {
    onSubmit({
      inventoryItemId: inventoryItem.id,
      propertyId: inventoryItem.propertyId,

      quantity: formValues.quantity,

      dateReceived: formValues.dateReceived,
      created: new Date().toISOString(),

      status: InventoryItemBatchStatus.Active,
      notes: formValues.notes,

      category: InventoryCategory.Chemicals,
      name: formValues.name,
      dateOfManufacture: formValues.dateOfManufacture,
      dateExpiry: formValues.expiryDate,
    });
  };

  const chemicalItemOptions = inventoryItems.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const quantityType = unitToQuantityType(inventoryItem.quantity.unit);
  let quantityInputType: QuantityTypes = 'quantity-mass-volume';
  switch (quantityType) {
    case Type.Mass:
      quantityInputType = 'quantity-mass';
      break;
    case Type.Volume:
      quantityInputType = 'quantity-volume';
      break;
    default:
      break;
  }

  const form: FormBuilderProps<InventoryItemChemicalBatchCreateFormValues> = {
    i18nRootKey:
      'inventory-item-batch.inventory-item-batch.chemical.create.form',
    flex: true,
    fields: [
      {
        name: 'chemicalItem',
        type: 'select',
        options: chemicalItemOptions,
        initialValue: inventoryItem?.id,
        required: true,
      },
      {
        name: 'name',
        type: 'text',
        required: true,
      },
      {
        name: 'quantity',
        type: quantityInputType,
        initialValue: inventoryItem?.quantity
          ? ({
              value: 0,
              unit: inventoryItem.quantity.unit,
            } as QuantityOfMassVolume)
          : null,
        min: 0,
        required: true,
        step: 0.01,
      },
      {
        name: 'dateReceived',
        type: 'date',
      },
      {
        name: 'expiryDate',
        type: 'date',
      },
      {
        name: 'dateOfManufacture',
        type: 'date',
      },
      {
        name: 'notes',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};

export interface InventoryItemFeedBatchCreateFormValues {
  inventoryItem: string;
  quantity: QuantityOfMassVolume;
  supplierName: string;
  pricePerUnitCents: number;
  dateReceived: string;
  notes: string;
}

const InventoryItemBatchFeedCreateForm: React.FC<
  InventoryItemBatchCreateFormProps<InventoryItemBatchFeed>
> = ({
  inventoryItem,
  inventoryItems,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (formValues: InventoryItemFeedBatchCreateFormValues) => {
    onSubmit({
      inventoryItemId: inventoryItem.id,
      propertyId: inventoryItem.propertyId,

      quantity: formValues.quantity,

      dateReceived: formValues.dateReceived,
      created: new Date().toISOString(),

      status: InventoryItemBatchStatus.Active,
      notes: formValues.notes,

      category: InventoryCategory.Feed,
      pricePerUnitCents: formValues.pricePerUnitCents * 100,
      supplierName: formValues.supplierName,
    });
  };

  const feedItemOptions = inventoryItems.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const quantityType = unitToQuantityType(inventoryItem.quantity.unit);
  let quantityInputType: QuantityTypes = 'quantity-mass-volume';
  switch (quantityType) {
    case Type.Mass:
      quantityInputType = 'quantity-mass';
      break;
    case Type.Volume:
      quantityInputType = 'quantity-volume';
      break;
    default:
      break;
  }

  const form: FormBuilderProps<InventoryItemFeedBatchCreateFormValues> = {
    i18nRootKey: 'inventory-item-batch.inventory-item-batch.feed.create.form',
    flex: true,
    fields: [
      {
        name: 'feedItem',
        type: 'select',
        options: feedItemOptions,
        initialValue: inventoryItem?.id,
      },
      {
        name: 'supplierName',
        type: 'text',
        required: true,
      },
      {
        name: 'quantity',
        type: quantityInputType,
        initialValue: inventoryItem?.quantity
          ? ({
              value: 0,
              unit: inventoryItem.quantity.unit,
            } as QuantityOfMassVolume)
          : null,
        min: 0,
        required: true,
        step: 0.01,
      },
      {
        name: 'pricePerUnitCents',
        type: 'number',
      },
      {
        name: 'dateReceived',
        type: 'date',
        initialValue: new Date().toISOString(),
        required: true,
      },
      {
        name: 'notes',
        type: 'textarea',
      },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
