import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { usePaddockActions } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { EntitiesFilterItem } from '@mobble/models/src/model/BaseEntity';

const messages = defineMessages({
  'paddock_actions.filter.group.type.label': {
    defaultMessage: 'Paddock action type',
    description: 'paddock_actions.filter.group.type.label',
  },
});

export const usePaddockActionsFilterItems = () => {
  const strings = useMessages(messages);
  const properties = useProperties();
  const paddockActions = usePaddockActions(properties.selected?.id);

  return (): EntitiesFilterItem<PaddockAction>[] => [
    {
      title: strings['paddock_actions.filter.group.type.date_range.label'],
      type: 'date-range',
      group: 'dateRange',
      meta: {
        fixed: true,
      },
    },
    {
      title: strings['paddock_actions.filter.group.type.label'],
      type: 'select-multiple',
      group: 'type',
      data: Array.from(
        new Set(paddockActions.entities.map((entity) => entity.type))
      )
        .map((value) => ({
          value,
        }))
        .filter((value) => value.value !== ''),
    },
  ];
};
