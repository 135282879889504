import React from 'react';
import { createRoot } from 'react-dom/client';

import 'core-js/stable';

import { initNewRelic } from './ext/newRelic';
import { App } from './App';

import './reset.scss';
import './index.scss';

initNewRelic();

const main = document.querySelector('main');

createRoot(main).render(<App />);
