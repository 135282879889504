import { useRootSelector, useRootDispatch } from './root';
import { proxyUseEntities } from '../reducers/subscriptions';
import { useProperties } from './properties';
import { UserRole } from '@mobble/models/src/model/User';
import {
  findLatestSubscription,
  getIsSubscriptionEnabled,
  SubscriptionStatus,
} from '@mobble/models/src/model/Subscriptions';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import { daysBetween } from '@mobble/shared/src/core/Date';
import { useEffect } from 'react';

export const useSubscriptions = () => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.subscriptions);

  return proxyUseEntities({
    parentId: 'me',
    dispatch,
    state,
  });
};

export const useSubscription = () => {
  const { selected } = useProperties();
  const subscriptions = useSubscriptions();

  const { refreshIfExpired } = useEntitiesRefresher([subscriptions], 'true');

  const refresh = async () => await subscriptions.refresh(true);

  useEffect(() => {
    if (selected?.currentUserRole !== UserRole.Owner) {
      return;
    }

    refreshIfExpired();
  }, [selected]);

  const subscription = findLatestSubscription(subscriptions.entities);

  const isTrial =
    selected?.currentUserRole === UserRole.Owner &&
    subscription?.subscriptionName === 'Trial';

  const propertySubscriptionStatus = selected?.subscriptionStatus;
  const isPropertySubscriptionEnabled = propertySubscriptionStatus
    ? getIsSubscriptionEnabled(propertySubscriptionStatus)
    : true;

  const trialEnded =
    isTrial && subscription?.status === SubscriptionStatus.canceled;

  const daysUntilTrialEnd = !trialEnded
    ? daysBetween(new Date().toDateString())(
        new Date(subscription?.currentPeriodEnd).toDateString()
      )
    : 0;

  return {
    subscription,
    isTrial,
    isPropertySubscriptionEnabled,
    trialEnded,
    daysUntilTrialEnd,
    refresh,
  };
};
