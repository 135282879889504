import React from 'react';

import { useI18n } from '@mobble/i18n';
import { advisorRoles } from '@mobble/models/src/model/User';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

import { Text } from '@src/components';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import {
  type NavigationGroupProps,
  NavigationList,
} from '@src/stories/Components/UX/NavigationList';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import {
  type RootStackParamList,
  type ScreenRendererProps,
} from '../config/types';

export const SettingsPropertyMobbleConnectUsersHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { formatMessage } = useI18n();

  return (
    <ScreenHeader
      title={formatMessage({
        defaultMessage: 'Mobble Connect',
        description: 'screen.title.mobble_connect',
      })}
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Settings',
            description: 'screen.title.settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyMobbleConnectUsers: React.FC<
  ScreenRendererProps
> = () => {
  const { formatMessage } = useI18n();
  const formatRoleLabel = useRoleLabels();

  const navigationItems: NavigationGroupProps[] = [
    {
      items: advisorRoles.map((role) => ({
        title: formatRoleLabel(role),
        href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role,
        }) as keyof RootStackParamList,
      })),
    },
  ];

  return (
    <>
      <Box
        mobileSpacing={{ top: 2, right: 1, bottom: 2, left: 1 }}
        spacing={{ bottom: 2, right: 0, left: 0 }}
      >
        <Text tagName="p">
          {formatMessage({
            defaultMessage:
              'Mobble Connect lets you securely share relevant farm data with your partners, boosting collaboration while keeping you in full control of your information.',
            description: 'settings.property.mobble_connect.description',
          })}
        </Text>
      </Box>
      <NavigationList items={navigationItems} />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USERS,
  header: SettingsPropertyMobbleConnectUsersHeader,
  component: SettingsPropertyMobbleConnectUsers,
};
