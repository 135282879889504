import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  getPropertiesWhereUserHasAdminAccess,
  Property,
} from '@mobble/models/src/model/Property';
import { findUser, UserRole } from '@mobble/models/src/model/User';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobbleConnectEditUserForm,
  MobbleConnectEditUserFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectEditUserForm';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

export const MobbleConnectEditUserHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const userId = route.params.userId;
  const mobbleConnectRole = route.params.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const properties = useProperties();
  const user = findUser(properties?.selected?.users ?? [])(userId);

  const roleLabel = useRoleLabels()(user?.role);

  if (!user) {
    return null;
  }

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'settings.property.mobble_connect.edit_user.title',
          defaultMessage: 'Edit {ROLE}',
        },
        {
          ROLE: roleLabel,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'settings.property.mobble_connect.title',
            defaultMessage: 'Mobble Connect',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
        },
        {
          title: roleLabel,
          href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
            role: mobbleConnectRole,
          }),
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      }}
    />
  );
};

export const MobbleConnectEditUser: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const { userId, role } = route.params;
  const [formLoading, setFormLoading] = React.useState(false);
  const properties = useProperties();
  const me = useGetUser();
  const linkTo = useLinking();

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  if (Prelude) {
    return Prelude;
  }

  const user = findUser(properties?.selected?.users ?? [])(userId);

  const property = properties.selected as Property;
  const propertiesWithAdminAccess = getPropertiesWhereUserHasAdminAccess(
    properties.entities
  )(me?.id ?? '');

  const propertiesWithUser = user
    ? propertiesWithAdminAccess.filter(
        (p) => findUser(p.users)(user?.id)?.role === user?.role
      )
    : [];

  const propertyOptions = propertiesWithUser.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  const handleCancel = () => {
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
      role,
    });
  };

  const handleSubmit = ({ propertyIds }: MobbleConnectEditUserFormValues) => {
    if (!user) return;
    setFormLoading(true);

    properties
      .deleteUser({
        propertyIds: propertyIds,
        organisationId: property.organisationId,
        email: user.email,
      })
      .then(() => {
        setFormLoading(false);
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role,
        });
      });
  };

  return (
    <MobbleConnectEditUserForm
      user={user}
      propertyOptions={propertyOptions}
      loading={formLoading}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USER_EDIT,
  header: MobbleConnectEditUserHeader,
  component: MobbleConnectEditUser,
};
