import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

export const newRelicOptions = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    sa: 1,
  },
  loader_config: {
    accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    agentID: '1386139046',
    applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
    licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
    trustKey: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
  },
};

export const initNewRelic = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return;
  }
  new BrowserAgent(newRelicOptions);
};
