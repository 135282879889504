import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import {
  Casualty,
  findCasualtiesForMob,
} from '@mobble/models/src/model/Casualty';
import { toMobDisplayName } from '@mobble/models/src/model/Mob';
import { Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCasualties,
  useMob,
  useMobs,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';
import { Color } from '@mobble/theme';

import { Tabs } from '@src/components';
import { useGetIndividualMobActions } from '@src/hooks/useIndividualMobActions';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useTabs } from '@src/hooks/useTabs';
import { toPath } from '@src/interfaces/Routing';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import { ChartMonthlyCasualtiesByMob } from '@src/stories/Components/Charts/Components/ChartCasualtiesOverTime';
import { Box } from '@src/stories/Components/Layout/Box';
import { CasualtiesViewer } from '@src/stories/Views/Casualty/List/CasualtiesViewer';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobInfoTable } from '@src/stories/Views/Mob/MobInfoTable';
import { MobActionsViewer } from '@src/stories/Views/MobAction/List/MobActionsViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './mobDetail.scss';

enum MobDetailMobileTab {
  Info = 'info',
  History = 'history',
}

export const MobDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '', paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const mob = useMob(mobId);
  const mobs = useMobs(properties.selected?.id);
  const paddocks = usePaddocks(
    mob.entity?.propertyId ?? properties.selected?.id
  );
  const mobName = mob.entity ? toMobDisplayName(mob.entity) : '';

  const quickLinks = mobs.entities
    .map((m) => {
      const p = paddockForMob(paddocks.entities)(m);
      if (!p) {
        return null;
      }
      return {
        title: toMobDisplayName(m, true),
        href: toPath(ROUTE_NAME.MOB_DETAIL, {
          paddockId: p.id,
          mobId: m.id,
        }),
      };
    })
    .filter(Boolean)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        mobName ||
        formatMessage({
          defaultMessage: 'Mob details',
          description: 'screen.title.mob_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Mobs',
            description: 'screen.title.mobs',
          }),
          href: ROUTE_NAME.MOBS_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          label: formatMessage(
            {
              defaultMessage: 'Edit "{TITLE}"',
              description: 'screen.title.edit_x',
            },
            {
              TITLE: mobName,
            }
          ),
          icon: 'edit',
          href: toPath(ROUTE_NAME.MOB_DETAIL_EDIT, { paddockId, mobId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const MobDetail: React.FC<ScreenRendererProps> = (props) => {
  const { mobId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const navigate = useNavigate();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const casualties = useCasualties(propertyId);
  const mob = useMob(mobId, propertyId);
  const mobActions = useGetIndividualMobActions(mobId, propertyId);
  const paddocks = usePaddocks(propertyId);
  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: MobDetailMobileTab.Info,
  });

  useEntitiesRefresher([casualties, mobActions, paddocks], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
    notFound: [mob.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const paddock = paddockForMob(paddocks.entities)(mob.entity);
  const casualtiesForMob: Provider<Casualty> = {
    ...casualties,
    entities: findCasualtiesForMob(casualties.entities)(mobId),
  };

  const handleClickPaddock = (paddock: Paddock) => {
    navigate(toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: paddock.id }));
  };

  const tabs = [
    {
      id: MobDetailMobileTab.Info,
      active: activeTab === MobDetailMobileTab.Info,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'Info',
        description: 'screen.title.mob_detail_info',
      }),
      content: (
        <Box className={styles.info}>
          <MobInfoTable
            mob={mob.entity}
            paddock={paddock}
            onClickPaddock={handleClickPaddock}
          />
        </Box>
      ),
    },
    {
      id: MobDetailMobileTab.History,
      active: activeTab === MobDetailMobileTab.History,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'History',
        description: 'screen.title.mob_detail_history',
      }),
      content: (
        <HistoricEvents
          title={formatMessage({
            defaultMessage: 'History',
            description: 'screen.title.mob_detail_history',
          })}
          extFilter={{ mobId }}
          className={styles.history}
        />
      ),
    },
  ];

  return (
    <div className={styles.mobDetail}>
      <Tabs
        tabs={tabs}
        backgroundColor={Color.Purple700}
        showTabBar={showTabBar}
        onChange={handleTabChange}
        className={styles.tabs}
      />

      <div className={styles.expandedData}>
        <CasualtiesViewer
          title={formatMessage({
            defaultMessage: 'Casualties',
            description: 'mob_detail.casualties.title',
          })}
          showTitle
          hideFixedFilters
          provider={casualtiesForMob}
        />

        <MobActionsViewer
          title={formatMessage({
            defaultMessage: 'Mob actions',
            description: 'screen.title.summary_mob_actions',
          })}
          showTitle
          hideFixedFilters
          provider={mobActions}
        />

        {casualtiesForMob.entities.length > 0 && (
          <ChartMonthlyCasualtiesByMob
            casualties={casualtiesForMob.entities}
            loading={casualtiesForMob.loading}
          />
        )}
      </div>
    </div>
  );
};

export default {
  name: ROUTE_NAME.MOB_DETAIL,
  header: MobDetailHeader,
  component: MobDetail,
};
