import { useLocation } from 'react-router';
import { type NavigateProps } from 'react-router';

import { UserActionType } from '@mobble/models/src/model/Intentions/UserActions';
import { base64encode } from '@mobble/shared/src/core/String';

import * as ROUTE_NAME from './routeNames';
import { type RouteName } from './types';

type RouteObj = {
  route: RouteName;
  params: Record<string, string | number>;
  state?: NavigateProps['state'];
};

export const userActionToRoute = (
  userActionType: UserActionType,
  params: Record<string, string | number>,
  state?: NavigateProps['state'],
): RouteObj => {
  const location = useLocation();

  if (!params.mobIds && params.mobId) {
    params.mobIds = base64encode(JSON.stringify([params.mobId]));
  }

   const baseRouteObj = (() => {
    switch (userActionType) {
      case UserActionType.InventoryItem_Info:
        return {
          route: ROUTE_NAME.INVENTORY_ITEM_DETAIL,
        };
      case UserActionType.InventoryItem_Edit:
        return {
          route: ROUTE_NAME.INVENTORY_ITEM_EDIT,
        };
      case UserActionType.Inventories_InventoryItem_Create:
        return {
          route: ROUTE_NAME.MODAL_INVENTORY_ITEM_CREATE,
        };
      case UserActionType.InventoryItem_Batch_Create:
        return {
          route: ROUTE_NAME.MODAL_INVENTORY_ITEM_BATCH_CREATE,
        };
      case UserActionType.Map_MapAsset_Create:
        return {
          route: ROUTE_NAME.MAP_OVERVIEW,
          params: { mode: 'add-asset' },
        };
      case UserActionType.Map_Mob_Create:
        return {
          route: ROUTE_NAME.MODAL_MOB_CREATE,
        };
      case UserActionType.Map_Paddock_Create:
        return {
          route: ROUTE_NAME.MAP_OVERVIEW,
          params: { mode: 'add-paddock' },
        };
      case UserActionType.Mobs_MobAction_Create:
        return {
          route: ROUTE_NAME.MODAL_MOB_ACTION_CREATE,
        };
      case UserActionType.Map_Task_Create:
        return {
          route: ROUTE_NAME.MAP_OVERVIEW,
          params: { mode: 'add-task' },
        };
      case UserActionType.MapAsset_Create:
        return {
          route: ROUTE_NAME.MODAL_MAP_ASSET_CREATE,
        };
      case UserActionType.MobActions_Create:
        return {
          route: ROUTE_NAME.MODAL_MOB_ACTION_CREATE,
        };
      case UserActionType.Mobs_Casualty_Create:
        return {
          route: ROUTE_NAME.MODAL_CASUALTY_CREATE,
        };
      case UserActionType.Mobs_Create:
        return {
          route: ROUTE_NAME.MODAL_MOB_CREATE,
        };
      case UserActionType.Mobs_Edit:
        return {
          route: ROUTE_NAME.MOB_DETAIL_EDIT,
        };
      case UserActionType.Mobs_Move:
        return {
          route: ROUTE_NAME.MODAL_MOB_MOVE,
        };
      case UserActionType.Mobs_Sale_Create:
        return {
          route: ROUTE_NAME.MODAL_SALE_CREATE,
        };
      case UserActionType.Mobs_View:
        return {
          route: ROUTE_NAME.MOB_DETAIL,
        };
      case UserActionType.OpenGates_Create:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_GROUP_CREATE,
        };
      case UserActionType.OpenGates_Edit:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_GROUP_EDIT,
        };
      case UserActionType.Paddocks_Mobs_View:
        return {
          route: ROUTE_NAME.PADDOCK_DETAIL_MOBS,
        };
      case UserActionType.PaddockAction_Create:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_ACTION_CREATE,
        };
      case UserActionType.Paddocks_Create:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_CREATE,
        };
      case UserActionType.Paddocks_Map_View:
        return {
          route: ROUTE_NAME.MAP_OVERVIEW,
        };
      case UserActionType.Paddocks_Mob_Create:
        return {
          route: ROUTE_NAME.MODAL_MOB_CREATE,
        };
      case UserActionType.Paddocks_PaddockAction_Create:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_ACTION_CREATE,
        };
      case UserActionType.Paddocks_Task_Create:
        return {
          route: ROUTE_NAME.MODAL_TASK_CREATE,
        };
      case UserActionType.Paddocks_OpenGates_Create:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_GROUP_CREATE,
        };
      case UserActionType.Paddocks_OpenGates_Edit:
        return {
          route: ROUTE_NAME.MODAL_PADDOCK_GROUP_EDIT,
        };
      case UserActionType.Paddocks_View:
        return {
          route: ROUTE_NAME.PADDOCK_DETAIL,
        };
      case UserActionType.RainGauges_Create:
        return {
          route: ROUTE_NAME.MODAL_RAIN_GAUGE_CREATE,
        };
      case UserActionType.RainGauges_RainGaugeReading_Create:
        return {
          route: ROUTE_NAME.MODAL_RAIN_GAUGE_READING_CREATE,
        };
      case UserActionType.RainGauges_RainGaugeReading_Edit:
        return {
          route: ROUTE_NAME.MODAL_RAIN_GAUGE_READING_EDIT,
        };
      case UserActionType.Recommendations_Create:
        return {
          route: ROUTE_NAME.MODAL_TASK_CREATE,
        };
      case UserActionType.Settings_Property_Breed_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'breed' },
        };
      case UserActionType.Settings_Property_Class_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'class' },
        };
      case UserActionType.Settings_Property_Gender_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'gender' },
        };
      case UserActionType.Settings_Property_MobAction_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'mobAction' },
        };
      case UserActionType.Settings_Property_PaddockAction_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'paddockAction' },
        };
      case UserActionType.Settings_Property_PaddockType_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'paddockType' },
        };
      case UserActionType.Settings_Property_Tag_Create:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_CLASSES_TYPES_CREATE,
          params: { group: 'tag' },
        };
      case UserActionType.SettingsUserInvite:
        return {
          route: ROUTE_NAME.MODAL_SETTINGS_PROPERTY_USERS_INVITE_USER,
        };
      case UserActionType.Tasks_Create:
        return {
          route: ROUTE_NAME.MODAL_TASK_CREATE,
        };
    }
  })();

  return {
    route: baseRouteObj.route as RouteName,
    params: {
      ...params,
      ...baseRouteObj.params,
    },
    state: {
      ...state,
      from: location.pathname
    }
  };
};
