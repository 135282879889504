import { toISO8601 } from '@mobble/shared/src/core/Date';
import {
  decodeAppliedInventoryItemChemicals,
  decodeAppliedInventoryItemFeeds,
  type AppliedInventoryItemChemical,
  type AppliedInventoryItemFeed,
} from '@mobble/models/src/model/AppliedInventoryItem';
import { InventoryCategory } from '@mobble/models/src/model/Inventory';
import { MobAction } from '@mobble/models/src/model/MobAction';

import { type ApiPrelude, type ExtCursor } from '../types';
import { decodeMob } from './mobs';

const decodeMobActions =
  (propertyId: string) =>
  (raw: any[]): MobAction[] => {
    return raw.map(decodeMobAction(propertyId));
  };

const decodeMobAction =
  (propertyId: string) =>
  (raw: any): MobAction => {
    return {
      id: raw?.id ?? '',
      propertyId,
      date: raw?.date ?? '',
      createdBy: {
        id: raw?.createdBy?.id ?? '',
        name: raw?.createdBy?.displayName ?? '',
        email: raw?.createdBy?.email ?? '',
      },
      type: raw?.type ?? '',
      title: raw?.title ?? '',
      description: raw?.description ?? '',
      note: raw?.note,
      count: Number(raw?.count) || null,

      mobIds: raw?.mobSnapshots?.map((m: any) => m?.id) ?? [],
      mobs: raw?.mobSnapshots?.map(decodeMob(propertyId)),

      appliedInventoryItems: [
        ...decodeAppliedInventoryItemChemicals(raw?.chemicalApplications ?? []),
        ...decodeAppliedInventoryItemFeeds(raw?.feedApplications ?? []),
      ],
    };
  };

const GET_MOB_ACTIONS = `
  query GetMobActions(
    $propertyId: ID!
    $after: String
    $filterBy: ActionFilters
    $pageSize: Int
  ) {
    mobActions(
      propertyId: $propertyId
      after: $after
      filterBy: $filterBy
      page_size: $pageSize
    ) {
      totalCount
      nodes {
        id
        type
        date
        title
        description
        note
        createdBy {
          id
          displayName
          email
        }
        mobSnapshots {
          id
          type
          breed
          gender
          number
          classes
          ages
          paddockName
          paddockUUID
        }
        chemicalApplications {
          id
          esi
          whp
          quantity {
            unit
            value
          }
          batch {
            id
          }
          chemical {
            id
            batchId
            productName
            propertyId
            category
            status
            lastUsed
            quantity {
              unit
              value
            }
          }
          totalApplied {
            value
            unit
          }
        }
        feedApplications {
          id
          quantity {
            unit
            value
          }
          batch {
            id
          }
          feed {
            id
            name
            propertyId
            status
            lastUsed
            amountReceived {
              value
              unit
            }
          }
          totalApplied {
            value
            unit
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const get =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    cursor,
  }: {
    parentId: string;
    cursor?: string;
  }): Promise<{ entities: MobAction[]; extCursor: ExtCursor }> => {
    const variables = {
      propertyId: parentId,
      after: cursor,
      pageSize: 9999,
    };

    const response = await prelude.graphql({
      query: GET_MOB_ACTIONS,
      variables,
    });

    const entities = decodeMobActions(parentId)(response.data.mobActions.nodes);

    const pageInfo = response?.data?.mobActions?.pageInfo;
    const extCursor = {
      total: response?.data?.mobActions?.totalCount ?? entities.length,
      nextCursor: pageInfo?.hasNextPage && pageInfo.endCursor,
    };

    return {
      entities,
      extCursor,
    };
  };

const GET_INDIVIDUAL_MOB_ACTIONS = `
  query GetIndividualMobActions($propertyId: ID!, $mobId: String!) {
    individualMobActions(
      propertyId: $propertyId
      mobId: $mobId
      page_size: 999
    ) {
      totalCount
      nodes {
        id
        type
        date
        title
        description
        note
        createdBy {
          id
          displayName
          email
        }
        mobSnapshots {
          id
          type
          breed
          gender
          number
          classes
          ages
          paddockName
          paddockUUID
        }
        chemicalApplications {
          id
          esi
          whp
          quantity {
            unit
            value
          }
          chemical {
            id
            productName
            status
          }
        }
        feedApplications {
          id
          quantity {
            unit
            value
          }
          batch {
            id
          }
          feed {
            id
            name
            status
          }
        }
      }
    }
  }
`;

export const getIndividualMobActions =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    mobId,
  }: {
    parentId: string;
    mobId: string;
  }): Promise<{ entities: MobAction[] }> => {
    const variables = {
      propertyId: parentId,
      mobId,
    };

    const response = await prelude.graphql({
      query: GET_INDIVIDUAL_MOB_ACTIONS,
      variables,
    });

    const entities = decodeMobActions(parentId)(
      response.data.individualMobActions.nodes
    );

    return {
      entities,
    };
  };

const GET_MOB_ACTION = `
  query GetMobAction($propertyId: ID!, $id: ID!) {
    mobAction(propertyId: $propertyId, id: $id) {
      id
      type
      date
      title
      description
      note
      count
      createdBy {
        id
        displayName
        email
      }
      mobSnapshots {
        id
        type
        breed
        gender
        number
        classes
        ages
        paddockName
        paddockUUID
      }
      chemicalApplications {
        id
        esi
        whp
        quantity {
          unit
          value
        }
        batch {
          id
        }
        chemical {
          id
          batchId
          productName
          propertyId
          category
          status
          lastUsed
          quantity {
            unit
            value
          }
        }
      }
      feedApplications {
        id
        quantity {
          unit
          value
        }
        batch {
          id
        }
        feed {
          id
          name
          propertyId
          status
          lastUsed
          amountReceived {
            value
            unit
          }
        }
      }
    }
  }
`;

export const find =
  (prelude: ApiPrelude) =>
  async ({
    parentId,
    id,
  }: {
    parentId: string;
    id: string;
  }): Promise<MobAction> => {
    const variables = {
      propertyId: parentId,
      id,
    };

    const response = await prelude.graphql({
      query: GET_MOB_ACTION,
      variables,
    });

    const entity = decodeMobAction(parentId)(response.data.mobAction);

    return entity;
  };

// Create

interface AddMobActionInput {
  propertyId: string;
  date: any;
  type: string;
  mobIds: string[];
  count?: number | null;
  chemicalApplications: AddChemicalApplicationInput[];
  feedApplications?: AddFeedApplicationInput[] | null;
  note: string;
}

interface AddChemicalApplicationInput {
  chemical: string;
  quantity: ValueWithUnitInput;
  esi?: number | null;
  whp?: number | null;
}

interface ValueWithUnitInput {
  unit: string;
  value: number;
}

interface AddFeedApplicationInput {
  feed: string;
  quantity: ValueWithUnitInput;
}

const ADD_MOB_ACTION = `
  mutation AddMobAction($input: AddMobActionInput!) {
    addMobAction(input: $input) {
      mobAction {
        id
        date
        title
        type
        note
        description
        count
        createdBy {
          id
          displayName
          email
          maxProperties
        }
        mobSnapshots {
          id
          type
          breed
          gender
          number
          classes
          ages
          paddockName
          paddockUUID
        }
        chemicalApplications {
          id
          quantity {
            unit
            value
          }
          esi
          whp
          createdBy {
            id
            displayName
            email
            maxProperties
          }
          batch {
            id
          }
          chemical {
            id
            batchId
            category
            productName
            dateReceived
            expiryDate
            dateOfManufacture
            esi
            whp
            woolWhp
            quantity {
              unit
              value
            }
            finished
            status
            notes
          }
        }
        feedApplications {
          id
          quantity {
            unit
            value
          }
          createdBy {
            id
            displayName
            email
            maxProperties
          }
          batch {
            id
          }
          feed {
            id
            name
            dateReceived
            status
            supplierName
            pricePerUnitCents
            amountReceived {
              unit
              value
            }
            notes
          }
        }
      }
    }
  }
`;

export const create =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: Omit<MobAction, 'id'> }): Promise<MobAction> => {
    const input: AddMobActionInput = {
      propertyId: entity.propertyId,
      date: toISO8601(entity.date),
      note: entity.note ?? '',
      type: entity.type,
      mobIds: entity?.mobIds ?? [],
      count: entity.count,
      chemicalApplications: (
        entity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Chemicals
        ) as AppliedInventoryItemChemical[]
      ).map((a) => ({
        chemical: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
        whp: Number(a.whp),
        esi: Number(a.esi),
      })),
      feedApplications: (
        entity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Feed
        ) as AppliedInventoryItemFeed[]
      ).map((a) => ({
        feed: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
      })),
    };

    const response = await prelude.graphql({
      query: ADD_MOB_ACTION,
      variables: { input },
    });

    const mobAction = decodeMobAction(entity.propertyId)(
      response?.data?.addMobAction?.mobAction
    );

    return mobAction;
  };

// Update

interface EditMobActionInput {
  id: string;
  propertyId: string;
  date: any;
  type: string;
  mobIds?: string[] | null;
  count?: number | null;
  chemicalApplications: EditChemicalApplicationInput[];
  feedApplications?: EditFeedApplicationInput[] | null;
  note: string;
}

interface EditChemicalApplicationInput {
  chemical: string;
  quantity: ValueWithUnitInput;
  esi?: number | null;
  whp?: number | null;
}

interface EditFeedApplicationInput {
  feed: string;
  quantity: ValueWithUnitInput;
}

const EDIT_MOB_ACTION = `
  mutation EditMobAction($input: EditMobActionInput!) {
    editMobAction(input: $input) {
      mobAction {
        id
        type
        date
        title
        description
        note
        count
        createdBy {
          id
          displayName
          email
        }
        mobSnapshots {
          id
          type
          breed
          gender
          number
          classes
          ages
          paddockName
          paddockUUID
        }
        chemicalApplications {
          id
          esi
          whp
          quantity {
            unit
            value
          }
          batch {
            id
          }
          chemical {
            id
            batchId
            productName
          }
        }
        feedApplications {
          id
          quantity {
            unit
            value
          }
          batch {
            id
          }
          feed {
            id
            name
          }
        }
      }
    }
  }
`;

export const update =
  (prelude: ApiPrelude) =>
  async ({
    updatedEntity,
  }: {
    updatedEntity: MobAction;
  }): Promise<MobAction> => {
    const input: EditMobActionInput = {
      id: updatedEntity.id,
      propertyId: updatedEntity.propertyId,
      date: toISO8601(updatedEntity.date),
      type: updatedEntity.type,
      note: updatedEntity.note ?? '',
      mobIds: updatedEntity?.mobIds ?? [],
      count: updatedEntity.count,
      chemicalApplications: (
        updatedEntity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Chemicals
        ) as AppliedInventoryItemChemical[]
      ).map((a) => ({
        chemical: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
        esi: Number(a.esi),
        whp: Number(a.whp),
      })),
      feedApplications: (
        updatedEntity.appliedInventoryItems.filter(
          (a) => a.category === InventoryCategory.Feed
        ) as AppliedInventoryItemFeed[]
      ).map((a) => ({
        feed: a.inventoryItemId,
        batch: a.batchId,
        quantity: {
          unit: a.quantity.unit,
          value: a.quantity.value,
        },
      })),
    };

    const response = await prelude.graphql({
      query: EDIT_MOB_ACTION,
      variables: { input },
    });

    const mobAction = decodeMobAction(updatedEntity.propertyId)(
      response?.data?.editMobAction?.mobAction
    );

    return mobAction;
  };

// Delete

interface DeleteMobActionInput {
  propertyId: string;
  id: string;
}

const DELETE_MOB_ACTION = `
  mutation DeleteMobAction($input: DeleteMobActionInput!) {
    deleteMobAction(input: $input) {
      mobAction {
        id
      }
    }
  }
`;

export const remove =
  (prelude: ApiPrelude) =>
  async ({ entity }: { entity: MobAction }): Promise<void> => {
    const input: DeleteMobActionInput = {
      id: entity.id,
      propertyId: entity.propertyId,
    };

    await prelude.graphql({
      query: DELETE_MOB_ACTION,
      variables: { input },
    });
    return;
  };
