import mapboxgl from 'mapbox-gl';

import { MapStyle, MapTileSource } from '@mobble/models/src/model/MapStyle';

export interface MapBoxStylesItem {
  mapStyle: MapStyle;
  styleURL?: string;
  style?: mapboxgl.Style;
}

export const MAPBOX_STYLES: MapBoxStylesItem[] = [
  {
    mapStyle: MapStyle.Satellite,
    styleURL: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
  },
  {
    mapStyle: MapStyle.Outdoor,
    styleURL: 'mapbox://styles/mapbox/outdoors-v11',
  },
];

export const mapTileSourcesToMapboxStyles = (
  mapTileSources: MapTileSource[],
  googleSessionToken?: string
): MapBoxStylesItem[] => {
  if (!Array.isArray(mapTileSources)) {
    return [];
  }
  const parsedSources: MapBoxStylesItem[] = [];

  mapTileSources.forEach((source) => {
    const isGoogle = source.id === MapStyle.SatelliteGoogle;
    if (isGoogle && !googleSessionToken) {
      return;
    }

    const googleUrl = isGoogle
      ? source.tiles[0].concat(
          `?session=${googleSessionToken}&key=${source.properties?.key}`
        )
      : '';

    parsedSources.push({
      mapStyle: source.id as MapStyle,
      style: {
        name: source.id,
        version: 8,
        glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
        sources: {
          [source.id]: {
            type: source.type,
            tiles: isGoogle ? [googleUrl] : source.tiles,
            tileSize: source.tileSize,
          },
        },
        layers: [
          {
            id: source.id,
            type: source.type,
            source: source.id,
          },
        ] as mapboxgl.RasterLayer[],
      },
    });
  });

  return parsedSources;
};
