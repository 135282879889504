import React from 'react';

import { useI18n } from '@mobble/i18n';
import { getMaxUsersOfRolePerProperty } from '@mobble/models/src/model/Property';
import { findUsersOfRole, UserRole } from '@mobble/models/src/model/User';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import { MobbleConnectUserView } from '../../stories/Views/Settings/SettingsPropertyMobbleConnectUserView';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

export const MobbleConnectHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const role = route.params.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();
  const roleLabel = useRoleLabels()(role);

  return (
    <ScreenHeader
      title={roleLabel}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'settings.property.mobble_connect.title',
            defaultMessage: 'Mobble Connect',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USERS);
      }}
    />
  );
};

export const MobbleConnect: React.FC<ScreenRendererProps> = ({ route }) => {
  const mobbleConnectRole = route.params.role as UserRole;
  const linkTo = useLinking();
  const properties = useProperties();

  const maxUsersOfRole = getMaxUsersOfRolePerProperty(mobbleConnectRole);

  const users = findUsersOfRole(properties?.selected?.users || [])(
    mobbleConnectRole
  );
  const remainingSlots = maxUsersOfRole - users?.length;

  const onEditButtonClicked = (userId: string) =>
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER_EDIT, {
      role: mobbleConnectRole,
      userId,
    });

  const onInviteButtonClicked = () =>
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER_INVITE, {
      role: mobbleConnectRole,
    });

  return (
    <MobbleConnectUserView
      onEditButtonClicked={onEditButtonClicked}
      onInviteButtonClicked={onInviteButtonClicked}
      role={mobbleConnectRole}
      users={users}
      remainingSlots={remainingSlots}
      maxUsersOfRole={maxUsersOfRole}
    />
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USER,
  header: MobbleConnectHeader,
  component: MobbleConnect,
};
