import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';
import {
  getRainGaugeYearsAvailable,
  RainGauge,
} from '@mobble/models/src/model/RainGauge';
import { RainGaugeReading } from '@mobble/models/src/model/RainGaugeReading';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useProperties,
  useRainGauge,
  useRainGaugeReadings,
  useRainGauges,
} from '@mobble/store/src/hooks';
import { Color } from '@mobble/theme';

import { Tabs } from '@src/components';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useTabs } from '@src/hooks/useTabs';
import { toPath } from '@src/interfaces/Routing';
import { ChartRainGaugeMonthlyRainfall } from '@src/stories/Components/Charts/Components/ChartRainGaugeMonthlyRainfall';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { RainGaugeInfoTable } from '@src/stories/Views/RainGauge/RainGaugeInfoTable';
import { RainGaugeYearSelector } from '@src/stories/Views/RainGauge/RainGaugeYearSelector';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import { RainGaugeReadings } from './RainGaugeReadings';

import styles from './rainGaugeDetail.scss';

export interface RainGaugeDetail {
  type: 'info';
}

export interface RainGaugeReadings {
  type: 'history';
  userId: RainGauge['id'];
}

enum RainGaugeDetailMobileTab {
  Info = 'info',
  History = 'history',
}

export const RainGaugeDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const rainGauge = useRainGauge(rainGaugeId);
  const rainGauges = useRainGauges(properties.selected?.id);
  const goBack = useNavigateBack();

  const quickLinks = rainGauges.entities
    .map((g) => ({
      title: g.name,
      href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL, { rainGaugeId: g.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const title = rainGauge.entity
    ? rainGauge.entity.name
    : formatMessage({
        description: 'screen.title.rain-gauge',
        defaultMessage: 'Rain gauge',
      });

  return (
    <ScreenHeader
      title={title}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.rain-gauge',
            defaultMessage: 'Rain gauge',
          }),
          href: ROUTE_NAME.RAIN_GAUGES_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={[
        {
          label: formatMessage(
            {
              defaultMessage: 'Edit "{TITLE}"',
              description: 'screen.title.edit_x',
            },
            {
              TITLE: title,
            }
          ),
          icon: 'edit',
          href: toPath(ROUTE_NAME.RAIN_GAUGE_DETAIL_EDIT, { rainGaugeId }),
        },
      ]}
      onGoBack={goBack}
    />
  );
};

export const RainGaugeDetail: React.FC<ScreenRendererProps> = (props) => {
  const { rainGaugeId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const navigate = useNavigate();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const rainGauge = useRainGauge(rainGaugeId);
  const rainGaugeReadings = useRainGaugeReadings(propertyId, rainGaugeId);

  const curYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number>(curYear);
  const fetched = useRef<number[]>([curYear]);
  const availableYears = getRainGaugeYearsAvailable(rainGauge.entity);

  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: RainGaugeDetailMobileTab.Info,
  });

  useEffect(() => {
    if (rainGauge.entity) {
      availableYears.forEach((y) => {
        if (!fetched.current.includes(y)) {
          fetched.current.push(y);
          return rainGauge.getYearTotals(y).catch(() => {});
        }
      });
    }
  }, [rainGauge]);

  useEntitiesRefresher([rainGaugeReadings], propertyId);

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [
      properties.prelude,
      rainGauge.prelude,
      rainGaugeReadings.prelude,
    ],
    required: [properties.selected],
    notFound: [rainGauge.entity],
  });

  if (Prelude) {
    return Prelude;
  }

  const handleRainGaugeReadingClick = (rainGaugeReading: RainGaugeReading) => {
    navigate(
      toPath(ROUTE_NAME.RAIN_GAUGE_READING, {
        rainGaugeReadingId: rainGaugeReading.id,
        rainGaugeId,
      })
    );
  };

  const tabs = [
    {
      id: RainGaugeDetailMobileTab.Info,
      active: activeTab === RainGaugeDetailMobileTab.Info,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'Info',
        description: 'screen.title.rain-gauge_detail_info',
      }),
      content: (
        <>
          <div className={styles.chartMobile}>
            <RainGaugeYearSelector
              year={selectedYear}
              availableYears={availableYears}
              onChange={setSelectedYear}
            />
            <div className={styles.chartMobileWrapper}>
              <ChartRainGaugeMonthlyRainfall
                year={selectedYear}
                rainGauge={rainGauge.entity}
              />
            </div>
          </div>
          <div className={styles.info}>
            <RainGaugeInfoTable
              rainGauge={rainGauge.entity}
              rainGaugeReadings={rainGaugeReadings.entities}
              year={curYear}
            />
          </div>
        </>
      ),
    },
    {
      id: RainGaugeDetailMobileTab.History,
      active: activeTab === RainGaugeDetailMobileTab.History,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'History',
        description: 'screen.title.rain-gauge_detail_history',
      }),
      content: (
        <RainGaugeReadings
          rainGaugeReadings={rainGaugeReadings}
          properties={properties}
          onClick={handleRainGaugeReadingClick}
          className={styles.history}
        />
      ),
    },
  ];

  return (
    <div className={styles.rainGaugeDetail}>
      <div className={styles.chartDesktop}>
        <ChartRainGaugeMonthlyRainfall rainGauge={rainGauge.entity} />
      </div>

      <Tabs
        tabs={tabs}
        backgroundColor={Color.Blue600}
        showTabBar={showTabBar}
        onChange={handleTabChange}
        className={styles.tabs}
      />
    </div>
  );
};

export default {
  name: ROUTE_NAME.RAIN_GAUGE_DETAIL,
  header: RainGaugeDetailHeader,
  component: RainGaugeDetail,
};
