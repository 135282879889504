import Fuse from 'fuse.js';
import {
  type RawDate,
  fromRawDate,
  formatDate,
} from '@mobble/shared/src/core/Date';
import { type User } from './User';
import { type Property } from './Property';
import { SortDirection, SortOption, SortSetting } from './Sort';
import { type FilterItem, filterMatches, groupFilter } from './Filter';
import { type Paddock, findPaddock } from './Paddock';
import { Point } from './MapGeometry';

export interface Task {
  id: string;
  propertyId: Property['id'];
  title: string;
  description?: string;
  createdAt: RawDate;
  dueDate?: RawDate;
  status: string;
  paddockId?: string;
  location?: null | Point;
  assignedByUser?: User;
  assignedToUsers?: User[];
}

export interface TaskListPending {
  type: 'pending';
}

export interface TaskListMyTasks {
  type: 'my_tasks';
  userId: User['id'];
}

export interface TaskListCompleted {
  type: 'completed';
  userId?: User['id'];
}

export type TaskList = TaskListPending | TaskListMyTasks | TaskListCompleted;

export const sortByDueDate =
  (sortDirection: SortDirection) =>
  (a: Task, b: Task): number => {
    const f = (date?: RawDate) => (date ? fromRawDate(date).valueOf() : 0);

    if (!a.dueDate && !b.dueDate) {
      return sortDirection === SortDirection.Ascending
        ? f(a.createdAt) - f(b.createdAt)
        : f(b.createdAt) - f(a.createdAt);
    }

    if (!a.dueDate) {
      return 1;
    }

    if (!b.dueDate) {
      return -1;
    }

    return sortDirection === SortDirection.Ascending
      ? f(a.dueDate) - f(b.dueDate)
      : f(b.dueDate) - f(a.dueDate);
  };

export const sortByCreatedDate =
  (sortDirection: SortDirection) =>
  (a: Task, b: Task): number => {
    const f = (date?: RawDate) => (date ? fromRawDate(date).valueOf() : 0);

    return sortDirection === SortDirection.Ascending
      ? f(a.createdAt) - f(b.createdAt)
      : f(b.createdAt) - f(a.createdAt);
  };

export const findTask =
  (tasks: Task[]) =>
  (id: string): Task | undefined =>
    tasks.find((t) => t.id === id);

export const findPendingTasksForPaddock =
  (tasks: Task[]) => (paddockId: string) =>
    tasks?.filter((t) => t.paddockId === paddockId && t.status === 'pending');

export const getTasksList = (tasks: Task[], taskList: TaskList): Task[] => {
  return tasks.filter((a: Task) => {
    switch (taskList.type) {
      case 'pending':
        return a.status === 'pending';
      case 'my_tasks':
        return (
          a.status === 'pending' &&
          a.assignedToUsers?.some((u) => u.id === taskList.userId)
        );
      case 'completed':
        if (taskList.userId) {
          return (
            a.status === 'done' &&
            a.assignedToUsers?.some((u) => u.id === taskList.userId)
          );
        }
        return a.status === 'done';
    }
  });
};

export const filterTasks =
  (allPaddocks: Paddock[]) =>
  (taskList: TaskList) =>
  (tasks: Task[], filter?: FilterItem[]): Task[] => {
    const taskListTasks = getTasksList(tasks, taskList);

    if (!filter || filter.length === 0) {
      return taskListTasks;
    }
    const grouped = [...groupFilter(filter)];
    const searchQuery = filter.find((a) => a.group === 'search')?.filter;

    const prefilteredTasks =
      searchQuery && searchQuery.type === 'search'
        ? searchTasks(allPaddocks, taskListTasks, searchQuery.value)
        : taskListTasks;

    const result = prefilteredTasks.filter((task) =>
      grouped.every(([_, filters]) =>
        filters.some(tasksFilterItemMatchesTask(task))
      )
    );

    return result;
  };

export const searchTasks = (
  allPaddocks: Paddock[],
  tasks: Task[],
  searchQuery: string
): Task[] => {
  const fuse = new Fuse(
    tasks.map((t) => ({
      ...t,
      _dueDate: t.dueDate ? formatDate(t.dueDate) : 'no due date',
      _paddock: t.paddockId && findPaddock(allPaddocks)(t.paddockId),
    })),
    {
      keys: [
        { name: 'title', weight: 5 },
        { name: 'description', weight: 3 },
        { name: 'assignedByUser.displayName', weight: 2 },
        { name: 'assignedToUsers.displayName', weight: 3 },
        { name: '_paddock.name', weight: 1 },
        { name: '_paddock.type', weight: 1 },
        { name: '_dueDate', weight: 2 },
      ],
      threshold: 0.5,
      shouldSort: true,
    }
  );
  return fuse.search(searchQuery).map((a) => a.item);
};

export const tasksFilterItemMatchesTask =
  (task: Task) => (filterItem: FilterItem) => {
    const matches = filterMatches(filterItem.filter);

    switch (filterItem.group) {
      case 'task_status':
        return matches(task.status);
      case 'task_due_date':
        return (
          (task.dueDate && matches(fromRawDate(task.dueDate).valueOf())) || true
        );
      case 'task_assigned_to':
        return (
          task.assignedToUsers?.some((u) => matches(u.id) || matches(u.name)) ||
          false
        );
      case 'search':
        return true;
      default:
        return true;
    }
  };

//

export const availableSortOptions: SortOption[] = [
  {
    name: 'due_date_asc',
    settings: [{ direction: SortDirection.Descending, column: 'dueDate' }],
  },
];

export const sortAscendingBy = (column: string) => (a: Task, b: Task) => {
  switch (column) {
    case 'dueDate':
      return sortByDueDate(SortDirection.Ascending)(a, b);
  }
};

export const sortTasks = (
  tasks: Task[],
  sortSettings: SortSetting[]
): Task[] => {
  return tasks.sort((a, b) => {
    return sortSettings.reduce<number>((result, sortSetting) => {
      if (result === 0) {
        const asc = sortAscendingBy(sortSetting.column)(a, b) ?? result;
        if (sortSetting.direction === SortDirection.Descending) {
          return asc < 0 ? 1 : asc > 0 ? -1 : 0;
        }
        return asc;
      }
      return result;
    }, 0);
  });
};
