import { type FirebaseOptions, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  initializeAuth,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from 'firebase/auth';
import { posthog } from 'posthog-js';
import * as uuid from 'uuid';

import { User } from '@mobble/models/src/model/User';
import mobbleService, { type AuthProxy } from '@mobble/service';
import { sleep } from '@mobble/shared/src/core/Promise';

import { newRelicSetUser } from './interfaces/NewRelic';
import { getClientVersion } from './interfaces/Version';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCjwjUIufAibliTXM9XsM3PbvP1xGtgOdI',
  authDomain: 'mobble-production.firebaseapp.com',
  databaseURL: 'https://mobble-production.firebaseio.com',
  projectId: 'mobble-production',
  storageBucket: 'mobble-production.appspot.com',
  messagingSenderId: '531016214776',
  appId: '1:531016214776:web:be795c3853116e76',
  measurementId: 'G-C6E9REM3ZC',
};

// Initialise Firebase
const app = initializeApp(firebaseConfig);
const fbAuth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});

fbAuth.onAuthStateChanged((user) => {
  if (window.vgo) {
    window.vgo('setEmail', user?.email || null);
    window.vgo('process');
  }
});

if (
  process.env.REACT_APP_ENVIRONMENT === 'production' &&
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY
) {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    capture_pageview: false, // We capture this ourselves
  });
}

let cold = true;
const auth: AuthProxy = {
  getCurrentUser: () => {
    return new Promise((resolve, reject) => {
      sleep(cold && !fbAuth.currentUser ? 2000 : 0).then(() => {
        cold = false;
        if (fbAuth.currentUser) {
          return resolve(fbAuth.currentUser);
        }
        return reject(null);
      });
    });
  },
  signInWithCustomToken: (token) => signInWithCustomToken(fbAuth, token),
  createUserWithEmailAndPassword: (email, password) =>
    createUserWithEmailAndPassword(fbAuth, email, password),
  sendPasswordResetEmail: (email) => sendPasswordResetEmail(fbAuth, email),
  onAuthStateChanged: (cb) => {
    return fbAuth.onAuthStateChanged(cb);
  },
  signOut: () => fbAuth.signOut(),
};

const GRAPHQL_API_URI = process.env.REACT_APP_GRAPHQL_API_URI;
const REST_API_URI = process.env.REACT_APP_REST_API_URI;

mobbleService.configure({
  uri: GRAPHQL_API_URI,
  restUri: REST_API_URI,
  getIsOnline: () => {
    return Promise.resolve(true);
  },
  getClientVersion,
  getRequestId: () => Promise.resolve(`${uuid.v4()}`),
  getCustomHeaders: () => {
    return {};
  },
  auth,
  onUserAuthenticated: (user: User) => {
    newRelicSetUser(user);
  },
});
