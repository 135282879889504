import React from 'react';

import { InventoryCategory } from '@mobble/models/src';
import { InventoryItem } from '@mobble/models/src/model/InventoryItem';
import {
  InventoryItemBatch,
  InventoryItemBatchChemical,
  InventoryItemBatchFeed,
} from '@mobble/models/src/model/InventoryItemBatch';
import { toISO8601 } from '@mobble/shared/src/core/Date';
import {
  type QuantityOfMassVolume,
  Type,
  unitToQuantityType,
} from '@mobble/shared/src/core/Quantity';

import { FormBuilder, type FormBuilderProps } from '../Form/FormBuilder';
import { QuantityTypes } from '../Form/FormBuilderField';

export interface InventoryItemBatchEditFormProps<T extends InventoryItemBatch> {
  inventoryItemBatch: T;
  inventoryItem: InventoryItem;
  error?: string;
  loading?: boolean;
  onCancel: () => void;
  onTouched: (dirty: boolean) => void;
  onSubmit: (inventoryItemBatch: T) => void;
}

export const InventoryItemBatchEditForm: React.FC<
  InventoryItemBatchEditFormProps<InventoryItemBatch>
> = ({ inventoryItemBatch, ...props }) => {
  switch (inventoryItemBatch.category) {
    case InventoryCategory.Chemicals:
      return (
        <InventoryItemBatchChemicalEditForm
          inventoryItemBatch={inventoryItemBatch}
          {...props}
        />
      );
    case InventoryCategory.Feed:
      return (
        <InventoryItemBatchFeedEditForm
          inventoryItemBatch={inventoryItemBatch}
          {...props}
        />
      );
  }
};

export interface InventoryItemChemicalBatchCreateFormValues {
  chemicalItem: string;
  quantity: QuantityOfMassVolume;
  name: string;
  dateReceived: string;
  expiryDate: string;
  dateOfManufacture: string;
  notes: string;
}

export const InventoryItemBatchChemicalEditForm: React.FC<
  InventoryItemBatchEditFormProps<InventoryItemBatchChemical>
> = ({
  inventoryItem,
  inventoryItemBatch,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (
    formValues: InventoryItemChemicalBatchCreateFormValues
  ) => {
    onSubmit({
      id: inventoryItemBatch.id,
      inventoryItemId: inventoryItem.id,
      propertyId: inventoryItem.propertyId,

      quantity: formValues.quantity,

      dateReceived: formValues.dateReceived,
      created: new Date().toISOString(),

      status: inventoryItemBatch.status,
      notes: formValues.notes,

      category: InventoryCategory.Chemicals,
      name: formValues.name,
      dateOfManufacture: formValues.dateOfManufacture,
      dateExpiry: formValues.expiryDate,
    });
  };

  const quantityType = unitToQuantityType(inventoryItem.quantity.unit);
  let quantityInputType: QuantityTypes = 'quantity-mass-volume';
  switch (quantityType) {
    case Type.Mass:
      quantityInputType = 'quantity-mass';
      break;
    case Type.Volume:
      quantityInputType = 'quantity-volume';
      break;
    default:
      break;
  }

  const form: FormBuilderProps<InventoryItemChemicalBatchCreateFormValues> = {
    i18nRootKey:
      'inventory-item-batch.inventory-item-batch.chemicals.edit.form',
    flex: true,
    fields: [
      {
        name: 'chemicalItem',
        type: 'display',
        initialValue: inventoryItem.name,
      },
      {
        name: 'name',
        type: 'text',
        initialValue: inventoryItemBatch?.name ? inventoryItemBatch?.name : '',
        required: true,
      },
      {
        name: 'quantity',
        type: quantityInputType,
        initialValue: inventoryItemBatch.quantity,
        required: true,
        step: 0.01,
      },
      {
        name: 'dateReceived',
        type: 'date',
        initialValue:
          inventoryItemBatch.dateReceived &&
          toISO8601(inventoryItemBatch.dateReceived),
      },
      {
        name: 'expiryDate',
        type: 'date',
        initialValue:
          inventoryItemBatch.dateExpiry &&
          toISO8601(inventoryItemBatch.dateExpiry),
      },
      {
        name: 'dateOfManufacture',
        type: 'date',
        initialValue:
          inventoryItemBatch.dateOfManufacture &&
          toISO8601(inventoryItemBatch.dateOfManufacture),
      },
      {
        name: 'notes',
        type: 'textarea',
        initialValue: inventoryItemBatch.notes,
      },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};

export interface InventoryItemFeedBatchCreateFormValues {
  inventoryItem: string;
  quantity: QuantityOfMassVolume;
  supplierName: string;
  pricePerUnitCents: number;
  dateReceived: string;
  notes: string;
}

export const InventoryItemBatchFeedEditForm: React.FC<
  InventoryItemBatchEditFormProps<InventoryItemBatchFeed>
> = ({
  inventoryItem,
  inventoryItemBatch,
  error,
  loading,
  onCancel,
  onTouched,
  onSubmit,
}) => {
  const handleSubmit = (formValues: InventoryItemFeedBatchCreateFormValues) => {
    onSubmit({
      ...inventoryItemBatch,
      quantity: formValues.quantity,
      supplierName: formValues.supplierName,
      pricePerUnitCents: formValues.pricePerUnitCents * 100,
      dateReceived: formValues.dateReceived,
      notes: formValues.notes,
    });
  };

  const quantityType = unitToQuantityType(inventoryItem.quantity.unit);
  let quantityInputType: QuantityTypes = 'quantity-mass-volume';
  switch (quantityType) {
    case Type.Mass:
      quantityInputType = 'quantity-mass';
      break;
    case Type.Volume:
      quantityInputType = 'quantity-volume';
      break;
    default:
      break;
  }

  const form: FormBuilderProps<InventoryItemFeedBatchCreateFormValues> = {
    i18nRootKey: 'inventory-item-batch.inventory-item-batch.feed.edit.form',
    flex: true,
    fields: [
      {
        name: 'chemicalItem',
        type: 'display',
        initialValue: inventoryItem.name,
      },
      {
        name: 'supplierName',
        type: 'text',
        initialValue: inventoryItemBatch?.supplierName,
        required: true,
      },
      {
        name: 'quantity',
        type: quantityInputType,
        initialValue: inventoryItemBatch.quantity,
        required: true,
        step: 0.01,
      },
      {
        name: 'pricePerUnitCents',
        type: 'number',
        initialValue: inventoryItemBatch.pricePerUnitCents / 100,
      },
      {
        name: 'dateReceived',
        type: 'date',
        initialValue: inventoryItemBatch.dateReceived,
        required: true,
      },
      {
        name: 'notes',
        type: 'textarea',
        initialValue: inventoryItemBatch.notes,
      },
    ],
    error,
    loading,
    onSubmit: handleSubmit,
    onCancel,
    onTouched,
  };

  return <FormBuilder {...form} />;
};
