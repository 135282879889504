import React from 'react';

import { useI18n } from '@mobble/i18n';
import { type FilterItem } from '@mobble/models/src/model/Filter';
import { type SortSetting } from '@mobble/models/src/model/Sort';

import { Text } from '@src/components';
import { reactIntersperse } from '@src/interfaces/React';
import { Box } from '@src/stories/Components/Layout/Box';
import { Divider } from '@src/stories/Components/Layout/Divider';
import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Input } from '@src/stories/Components/UX/Input';
import { PillButton } from '@src/stories/Components/UX/PillButton';

import styles from './entitiesListHeader.scss';

export interface EntitiesListHeaderProps {
  title?: string;
  showTitle?: boolean;
  filter: FilterItem[];
  header?: null | JSX.Element | React.ReactNode;
  headerButtons?: {
    label: string;
    onClick: () => void;
    loading?: boolean;
  }[];

  onOpenFilter?: () => void;
  onClearFilter?: () => void;
  activeFilterCount?: number;

  onOpenSort?: () => void;

  searchQuery?: string;
  onSearch?: (query: string) => void;
}

export interface EntitiesListArgMeta {
  filter: FilterItem[];
  sort: SortSetting[];
}

export const EntitiesListHeader: React.FC<EntitiesListHeaderProps> = ({
  title,
  showTitle,
  header,

  onOpenFilter,
  onClearFilter,
  activeFilterCount,

  onOpenSort,

  searchQuery,
  onSearch,

  headerButtons,
}) => {
  const { formatMessage } = useI18n();
  const buttons = [];

  if (headerButtons) {
    headerButtons.forEach((hb, i) => {
      buttons.push(
        <PillButton
          key={`hb-${i}`}
          onClick={hb.onClick}
          label={hb.label}
          loading={hb.loading}
        />
      );
    });
  }

  if (onOpenSort) {
    buttons.push(
      <PillButton
        key="sort-button"
        onClick={onOpenSort}
        label={formatMessage({
          defaultMessage: 'Sort',
          description: 'entities.entities_list.sort.button.label',
        })}
      />
    );
  }
  if (onOpenFilter) {
    buttons.push(
      <PillButton
        key="filter-button"
        onClick={onOpenFilter}
        label={formatMessage({
          defaultMessage: 'Filter',
          description: 'entities.entities_list.filter.button.filter.label',
        })}
        count={activeFilterCount}
      />
    );

    if (onClearFilter && activeFilterCount && activeFilterCount > 0) {
      buttons.push(
        <PillButton
          key="clear-filter-button"
          onClick={onClearFilter}
          intent="destructive"
          label={formatMessage({
            defaultMessage: 'Clear',
            description: 'entities.entities_list.filter.button.clear.label',
          })}
        />
      );
    }
  }

  return (
    <>
      {showTitle && title?.length && (
        <Box className={styles.entitiesListHeaderTitle}>
          <Text tagName="h1" variant="card-title">
            {title}
          </Text>
        </Box>
      )}
      {buttons.length > 0 ? (
        <Box spacing={2}>
          <HStack>
            <Spacer flex />
            {reactIntersperse(<Spacer x={1} />, buttons)}
            <Spacer flex />
          </HStack>
        </Box>
      ) : null}

      {onSearch ? (
        <Box>
          <Input
            type="text"
            icon="search"
            showClear
            value={searchQuery}
            placeholder={formatMessage({
              defaultMessage: 'Search',
              description: 'entities.entities_list.filter.search.placeholder',
            })}
            onChange={onSearch}
          />
          <Divider />
        </Box>
      ) : null}

      {header}
    </>
  );
};
