import { type NavigateProps, useNavigate } from 'react-router';

import { toPath } from '../interfaces/Routing';
import { RouteName } from '../screens/config/types';

import { useAccessHelper } from './useAccessHelper';

export const useLinking = (): ((
  routeName: RouteName,
  params?: Record<string, string | number>,
  state?: NavigateProps['state']
) => void) => {
  const navigate = useNavigate();
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  return (
    routeName: RouteName,
    params?: Record<string, string | number>,
    state?: NavigateProps['state']
  ) => {
    if (!hasAccessToRoute(routeName)) {
      showNoAccessAlert();
      return;
    }
    const path = toPath(routeName, params);
    navigate(path, {
      state: {
        ...state,
        from: location.pathname,
      },
    });
  };
};
