import { Fragment, MouseEvent } from 'react';

import { type I18nContextProps } from '@mobble/i18n';
import { findMobs, toMobDisplayName } from '@mobble/models/src/model/Mob';
import { MobAction } from '@mobble/models/src/model/MobAction';
import { Paddock, paddockForMob } from '@mobble/models/src/model/Paddock';
import { arrayUnique } from '@mobble/shared/src/core/Array';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';
import { uniqueId } from '@mobble/shared/src/core/UniqueId';

import {
  appliedInventoryItemsToString,
  appliedInventoryItemsToTotalString,
} from '@src/helpers/appliedInventoryItemsToString';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  TableCellValue,
  TableColumn,
} from '@src/stories/Components/Layout/Table';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

export const makeMobActionTableColumns = ({
  paddocks,
  formatMessage,
}: {
  paddocks: Paddock[];
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn<TableCellValue, MobAction>[] => {
  const findPaddockForMob = paddockForMob(paddocks);

  // prevent row click event occuring when clicking on paddock link
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'mob_actions.table.heading.column.type',
      }),
      toValue: (mobAction) => mobAction.type,
    },
    {
      key: 'date',
      label: formatMessage({
        defaultMessage: 'Date',
        description: 'mob_actions.table.heading.column.date',
      }),
      totals: false,
      toValue: (mobAction) =>
        mobAction.date ? fromRawDate(mobAction.date).toDate().getTime() : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'created_by',
      label: formatMessage({
        defaultMessage: 'Recorded by',
        description: 'mob_actions.table.heading.column.created_by',
      }),
      toValue: (mobAction) => mobAction.createdBy.name,
    },
    {
      key: 'products',
      label: formatMessage({
        defaultMessage: 'Products',
        description: 'mob_actions.table.heading.column.products',
      }),
      toValue: (paddockAction) =>
        appliedInventoryItemsToString(
          paddockAction.appliedInventoryItems,
          formatMessage({
            defaultMessage: 'head',
            description: 'Livestock unit description',
          }),
          formatMessage
        ),
    },
    {
      key: 'applied_total',
      label: formatMessage({
        defaultMessage: 'Applied total',
        description: 'mob_actions.table.heading.column.applied_total',
      }),
      toValue: (paddockAction) =>
        appliedInventoryItemsToTotalString(
          paddockAction.appliedInventoryItems,
          formatMessage
        ),
    },
    {
      key: 'applied_to',
      label: formatMessage({
        defaultMessage: 'Applied to',
        description: 'mob_actions.table.heading.column.applied_to',
      }),
      toValue: (mobAction) =>
        arrayUnique(
          findMobs(mobAction.mobs)(mobAction.mobIds).map(
            (mob) => `${mob.size} ${toMobDisplayName(mob)}`
          )
        ).join(', '),
    },
    {
      key: 'classes',
      label: formatMessage({
        defaultMessage: 'Classes',
        description: 'mob_actions.table.heading.column.classes',
      }),
      toValue: (mobAction) =>
        arrayUnique(
          findMobs(mobAction.mobs)(mobAction.mobIds).reduce(
            (acc, mob) => [...acc, ...mob.classes],
            []
          )
        ).join(', '),
    },
    {
      key: 'paddocks',
      label: formatMessage({
        defaultMessage: 'Paddocks',
        description: 'mob_actions.table.heading.column.paddocks',
      }),
      totals: false,
      toValue: (mobAction) => {
        const mobsWithSnapshot = mobAction.mobs.filter((mob) => mob.snapshot);

        return arrayUnique(
          mobsWithSnapshot.map((mob) => mob.snapshot.paddockName)
        ).join(', ');
      },
      render: (_, mobAction) => {
        let paddocks = arrayUnique(
          mobAction.mobIds
            .map((mobId) => findPaddockForMob(mobId))
            .map((paddock) => paddock)
            .filter(Boolean)
        );

        const mobsWithSnapshot = mobAction.mobs.filter((mob) => mob.snapshot);
        if (!paddocks.length && mobsWithSnapshot.length) {
          paddocks = mobsWithSnapshot.map((mob) => ({
            id: mob.snapshot.paddockId,
            name: mob.snapshot.paddockName,
          }));
        }

        return paddocks.map((paddock, index) => {
          const WrapperComponent = paddock.id ? Clickable : Fragment;
          const wrapperProps = paddock.id
            ? {
                href: toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                  paddockId: paddock.id,
                }),
                onClick: handleClick,
              }
            : {};

          return (
            <Fragment key={uniqueId()}>
              <WrapperComponent {...wrapperProps}>
                <Text underline={!!paddock.id} variant="small">
                  {paddock.name}
                </Text>
              </WrapperComponent>
              {index < paddocks.length - 1 && <Text>, </Text>}
            </Fragment>
          );
        });
      },
    },
    {
      key: 'notes',
      enabled: false,
      label: formatMessage({
        defaultMessage: 'Notes',
        description: 'mob_actions.table.heading.column.notes',
      }),
      toValue: (mobAction) => mobAction.note,
    },
  ];
};
