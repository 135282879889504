import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useI18n } from '@mobble/i18n';

import { BlankSlate, Button } from '@src/components';

export interface ErrorScreenProps {
  title: string;
  error?: string;
  onRetry?: () => Promise<void>;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  title,
  error,
  onRetry,
}) => {
  const { formatMessage } = useI18n();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const header = document.getElementById('app-layout-header');
    if (header) {
      header.style.display = 'none';
    }
    return () => {
      if (header) {
        header.style.display = undefined;
      }
    };
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleRetry = () => {
    if (onRetry) {
      setLoading(true);
      onRetry().then(() => setLoading(false));
    }
  };

  const actions = (
    <>
      <Button
        icon="arrow-left"
        outline
        size="small"
        label={formatMessage({
          defaultMessage: 'Back',
          description: 'prelude.error.back.button.label',
        })}
        onClick={handleGoBack}
      />
      {onRetry && (
        <Button
          iconAfter="undo"
          outline
          loading={loading}
          label={formatMessage({
            defaultMessage: 'Retry',
            description: 'prelude.error.retry.button.label',
          })}
          onClick={handleRetry}
        />
      )}
    </>
  );

  return (
    <BlankSlate
      icon="error"
      intent="warning"
      title={title}
      text={error}
      actions={actions}
    />
  );
};
