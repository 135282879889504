import React, { useMemo, useRef } from 'react';

import { NewMapItem, OnClickMapItemEvent } from '@mobble/models/src/model/Map';
import { MAP_DETAILS } from '@mobble/models/src/model/MapDetail';
import { type BoundingBox } from '@mobble/models/src/model/MapGeometry';
import { useMapOverview } from '@mobble/shared/src/hooks/useMapOverview';
import {
  useMapSettings,
  useMobs,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { Box } from '@src/stories/Components/Layout/Box';
import { Map } from '@src/stories/Map';
import { MapPluginsListenersDispatchRef } from '@src/stories/Map/Context';
import { mapPluginTypesToArray } from '@src/stories/Map/Plugins';
import { mapAssetTypeToMode } from '@src/stories/Map/Plugins/Creator';

import { MapPortal } from './MapOverView/MapPortal';
import * as AddAsset from './MapOverView/Modes/AddAsset';
import * as AddPaddock from './MapOverView/Modes/AddPaddock';
import * as AddTask from './MapOverView/Modes/AddTask';
import { type MapOverviewState } from './MapOverView/SharedTypes';

import styles from './mapOverview.scss';

export type MapOverviewMode = MapOverviewState['mode'];

export interface MapOverviewProps {
  mode?: MapOverviewMode;
  centerOn?: BoundingBox;
  onResetMode: () => void;
  onClickMapItem: (ev: OnClickMapItemEvent) => void;
  onAddMapItem: (newMapItem: NewMapItem) => void;
}

export const MapOverview: React.FC<MapOverviewProps> = ({
  mode = 'standard',
  centerOn,
  onClickMapItem,
  onResetMode,
  onAddMapItem,
}) => {
  const { settings, update } = useMapSettings();
  const mapMobsFilter = settings.mapMobsFilter;
  const mapPortalContent = settings.portalContent;
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddocks = usePaddocks(propertyId);
  const mobs = useMobs(propertyId);
  const {
    state,
    updateState,
    handleEvent: onEvent,
    handleMapItemClick,
  } = useMapOverview();

  const stateRef = useRef<MapPluginsListenersDispatchRef>(null);

  const allMapOverviewStates: MapOverviewState[] = [
    {
      mode: 'standard',
      mapPlugins: 'standard',
      pointsState: { enabled: false },
      onSubmit: () => {},
    },
    {
      mode: 'add-task',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddTask.Header,
      footer: AddTask.Footer,
      pointsState: {
        enabled: true,
        options: {
          single: true,
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points[0]) {
          onAddMapItem({ type: 'task', point: points[0] });
        }
      },
    },
    {
      mode: 'add-asset',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddAsset.Header,
      footer: AddAsset.Footer,
      pointsState: {
        enabled: true,
        points: [],
        options: {
          single: mapAssetTypeToMode(state.selectedMapAssetType) === 'point',
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points) {
          onAddMapItem({
            type: 'asset',
            mapAssetType: state.selectedMapAssetType,
            points,
          });
        }
      },
    },
    {
      mode: 'add-paddock',
      mapPlugins: [...mapPluginTypesToArray('standard-restricted'), 'creator'],
      header: AddPaddock.Header,
      footer: AddPaddock.Footer,
      pointsState: {
        enabled: true,
        points: [],
        options: {
          mode: 'area',
          single: false,
          snap: true,
        },
      },
      onSubmit: () => {
        const { points } = stateRef.current.getState();
        if (points) {
          onAddMapItem({
            type: 'paddock',
            points,
          });
        }
      },
    },
  ];

  const overviewState = useMemo(() => {
    return (
      allMapOverviewStates.find((a) => a.mode === mode) ??
      allMapOverviewStates[0]
    );
  }, [mode, state.selectedMapAssetType]);

  const Header = overviewState.header ? overviewState.header : null;
  const Footer = overviewState.footer ? overviewState.footer : null;

  const mapStateComponentProps = {
    state,
    stateRef,
    updateState,
    onCancel: onResetMode,
    onSubmit: overviewState.onSubmit,
  };

  const map = useMemo(() => {
    return (
      <Map
        pointsState={overviewState.pointsState}
        stateRef={stateRef}
        name={centerOn ? undefined : 'map.overview'}
        types={overviewState.mapPlugins}
        onClickMapItem={handleMapItemClick}
        onEvent={onEvent}
        overrideMapProperties={{
          boundingBox: centerOn,
          mapDetails: state.overwrittenMapDetails ?? undefined,
        }}
      />
    );
  }, [stateRef, centerOn, overviewState]);

  return (
    <div className={styles.mapOverviewWrapper}>
      <MapPortal
        content={mapPortalContent}
        mapMobsFilter={mapMobsFilter}
        onClose={() => {
          update({
            portalContent: null,
          });
        }}
        onRefresh={() => {
          update({
            portalContent: null,
          });
          updateState({
            overwrittenMapDetails: [
              ...settings.mapDetails.filter((a) => a !== MAP_DETAILS.MOBS),
            ],
          });
          Promise.all([mobs.refresh(), paddocks.refresh()]).then(() => {
            updateState({
              overwrittenMapDetails: null,
            });
          });
        }}
        onClickMapItem={onClickMapItem}
      />

      {Header && <Header {...mapStateComponentProps} />}

      <Box
        className={[
          styles.mapWrapper,
          Header || Footer ? styles.withHeaderOrFooter : '',
        ]}
      >
        {map}
      </Box>

      {Footer && <Footer {...mapStateComponentProps} />}
    </div>
  );
};
