import React from 'react';
import * as Yup from 'yup';

import { useI18n } from '@mobble/i18n';
import { sortOptionsNameDistanceFromPaddock } from '@mobble/models/src/model/Paddock';
import { type PaddockGroup } from '@mobble/models/src/model/PaddockGroup';
import { type SortSetting } from '@mobble/models/src/model/Sort';

import { type ListSelectOption } from '@src/components/ListSelect';

import { FormBuilder, type FormBuilderProps } from '../../Form/FormBuilder';

export interface PaddockGroupEditFormProps {
  paddockGroup: PaddockGroup;
  error?: string;
  loading?: boolean;
  paddockOptions: ListSelectOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  onCancel: () => void;
  onSubmit: (formValues: PaddockGroupEditFormValues) => void;
}

export interface PaddockGroupEditFormValues {
  paddocks: string[];
}

export const PaddockGroupEditForm: React.FC<PaddockGroupEditFormProps> = ({
  paddockGroup,
  paddockOptions,
  sortPaddockOptions,
  error,
  loading,
  onCancel,
  onSubmit,
}) => {
  const { formatMessage } = useI18n();
  const [disabled, setDisabled] = React.useState(true);

  const form: FormBuilderProps<PaddockGroupEditFormValues> = {
    flex: true,
    i18nRootKey: 'paddocks.paddock_group.edit.form',
    fields: [
      {
        name: 'paddocks',
        type: 'select-multiple',
        options: paddockOptions,
        sortOptions: sortOptionsNameDistanceFromPaddock,
        defaultSortSettings: sortOptionsNameDistanceFromPaddock.find(
          (a) => a.name === 'distance_from_paddock'
        )?.settings,
        sortFunction: sortPaddockOptions,
        initialValue: paddockGroup.paddockIds,
        validation: Yup.array()
          .min(
            2,
            formatMessage({
              description:
                'paddocks.paddock_group.create.form.paddocks.validation.message',
              defaultMessage: 'Must select at least two Paddocks to connect.',
            })
          )
          .required(),
        allSelectable: false,
      },
    ],
    onChange: (values, errors) => {
      const hasErrors = Object.keys(errors).length > 0;
      const paddocksNotchanged =
        JSON.stringify(values.paddocks) ===
        JSON.stringify(paddockGroup.paddockIds);
      setDisabled(hasErrors || paddocksNotchanged);
    },
    disabled,
    error,
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
