import React from 'react';
import { defineMessages } from 'react-intl';
import { type FormikErrors } from 'formik';
import * as Yup from 'yup';

import { Color } from '@mobble/colors';
import { useI18n, useMessages } from '@mobble/i18n';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import {
  type ConfiguredPropertyType,
  ConfiguredPropertyTypeGroup,
  type ConfiguredPropertyTypeGroupCustom,
  getLivestockAttributes,
  getLivestockParentIdForValue,
} from '@mobble/models/src/model/Property';
import {
  livestockTypeToDefaultStockingRate,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import {
  type SortOption,
  type SortSetting,
} from '@mobble/models/src/model/Sort';
import { useSetting } from '@mobble/store/src/hooks';

import {
  type LabelLike,
  type ListSelectOption,
} from '@src/components/ListSelect';
import { getStockingUnitI18n } from '@src/stories/Components/Locale/LocaleStockingUnit';
import {
  FormBuilder,
  type FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';

export interface MobCreateWizardMobFormProps {
  paddockOptions: ListSelectOption[];
  paddockSortOptions: SortOption[];
  sortPaddockOptions: (
    options: ListSelectOption[],
    sortSettings: SortSetting[]
  ) => ListSelectOption[];
  propertyTypes: ConfiguredPropertyType[];
  formValues?: MobCreateWizardMobFormValues;
  onChange: (
    values: MobCreateWizardMobFormValues,
    errors: FormikErrors<MobCreateWizardMobFormValues>
  ) => void;
  onCreateCustomField: (
    group: ConfiguredPropertyTypeGroupCustom,
    livestockTypeId: string,
    label: string
  ) => Promise<void>;
}

export interface MobCreateWizardMobFormValues {
  paddock: string;
  type: string;
  breed: string;
  gender: string;
  ages: number[];
  classes: string[];
  size: number;
  stocking_rate: number;
}

const messages = defineMessages({
  addNewBreedType: {
    defaultMessage: 'Add new breed type',
    description:
      'mobs.mob.create.form.mob_form.breed.add_new.placeholder.label',
  },
  addNewGenderType: {
    defaultMessage: 'Add new gender type',
    description:
      'mobs.mob.create.form.mob_form.gender.add_new.placeholder.label',
  },
  addNewClassType: {
    defaultMessage: 'Add new class type',
    description:
      'mobs.mob.create.form.mob_form.classes.add_new.placeholder.label',
  },
  sizeValidation: {
    defaultMessage: 'Head must be a whole number',
    description: 'Head count number validation',
  },
  stockingRateValidation: {
    defaultMessage: 'Stocking rate must be a number',
    description: 'Stocking rate number validation',
  },
});

export const MobCreateWizardMobForm: React.FC<MobCreateWizardMobFormProps> = ({
  paddockOptions,
  paddockSortOptions,
  sortPaddockOptions,
  propertyTypes,
  formValues,
  onChange,
  onCreateCustomField,
}) => {
  const { formatMessage } = useI18n();
  const strings = useMessages(messages);
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  const getAgeColor = getLivestockAgeColor(propertyTypes, formValues?.type);
  const handleCreateCustomField =
    (type: ConfiguredPropertyTypeGroupCustom) =>
    (value: string): Promise<void> => {
      if (!formValues?.type) {
        return Promise.reject();
      }
      const liveStockTypeId = getLivestockParentIdForValue(
        formValues.type,
        propertyTypes
      );
      if (!liveStockTypeId) {
        return Promise.reject();
      }

      return onCreateCustomField(type, liveStockTypeId, value);
    };

  const formProps: FormBuilderProps<MobCreateWizardMobFormValues> = {
    i18nRootKey: 'mobs.mob.create.form.mob_form',
    footer: false,
    fields: [
      {
        name: 'paddock',
        type: 'select',
        initialValue: formValues?.paddock,
        options: paddockOptions,
        sortOptions: paddockSortOptions,
        sortFunction: sortPaddockOptions,
        required: true,
      },
      {
        name: 'type',
        type: 'select',
        initialValue: formValues?.type,
        sortFunction: (options) => {
          const fixedTypes: LabelLike[] = ['Cattle', 'Sheep', 'Goats', 'Other'];
          return options.sort((a, b) => {
            return fixedTypes.indexOf(a.label) - fixedTypes.indexOf(b.label);
          });
        },
        options: propertyTypes
          .filter((a) => a.group === ConfiguredPropertyTypeGroup.livestockType)
          .map((a) => ({
            label: a.label,
            value: a.type,
          })),
        required: true,
        onChange: (value) => {
          const livestockType = value.type as string;
          return {
            ...value,
            stocking_rate: livestockTypeToDefaultStockingRate(
              livestockType,
              stockingUnit
            ),
          };
        },
      },
      {
        name: 'breed',
        type: 'select',
        initialValue: formValues?.breed,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.breed,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        show: (values) => Boolean(values.type),
        required: true,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.breed),
          placeholder: strings.addNewBreedType,
        },
      },
      {
        name: 'gender',
        type: 'select',
        initialValue: formValues?.gender,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.gender,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ),
        show: (values) => Boolean(values.type),
        required: true,
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.gender),
          placeholder: strings.addNewGenderType,
        },
      },
      {
        name: 'ages',
        type: 'select-multiple',
        initialValue: formValues?.ages,
        options: (values) =>
          getLivestockAttributes(propertyTypes)(
            ConfiguredPropertyTypeGroup.tag,
            getLivestockParentIdForValue(values.type, propertyTypes)
          ).map((i) => ({
            ...i,
            color: getAgeColor(i.label) || Color.White,
          })),
        sortFunction: (options) =>
          options.sort((a: any, b: any) => b.label.localeCompare(a.label)),
        show: (values) => Boolean(values.type),
        required: true,
      },
      {
        name: 'classes',
        type: 'select-multiple',
        initialValue: formValues?.classes,
        options: (values) => {
          const parentId = propertyTypes.find(
            (a) => a.type === values.type
          )?.id;
          return propertyTypes
            .filter(
              (a) =>
                a.group === ConfiguredPropertyTypeGroup.class &&
                a.parentId === parentId
            )
            .map((a) => ({
              label: a.label,
              value: a.type,
            }));
        },
        show: (values) => Boolean(values.type),
        addNew: {
          onSubmit: handleCreateCustomField(ConfiguredPropertyTypeGroup.class),
          placeholder: strings.addNewClassType,
        },
      },
      {
        name: 'size',
        type: 'number',
        min: 1,
        required: true,
        show: (values) => Boolean(values.type),
        initialValue: formValues?.size,
        validation: Yup.number()
          .positive()
          .integer(strings.sizeValidation)
          .required(),
      },
      {
        name: 'stocking_rate',
        type: 'float',
        label: formatMessage(
          {
            defaultMessage: '{STOCKING_UNIT}/head',
            description: 'Stocking rate per head',
          },
          {
            STOCKING_UNIT: stockingUnitLabel,
          }
        ),
        show: (values) => Boolean(values.type),
        initialValue: formValues?.stocking_rate,
        required: true,
        validation: Yup.number()
          .min(0, strings.stockingRateValidation)
          .required(),
      },
    ],
    onSubmit: () => {},
    onChange,
  };

  return <FormBuilder {...formProps} />;
};
