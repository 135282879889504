import { useRootSelector, useRootDispatch } from './root';
import {
  MapState,
  getGoogleMapsSessionToken,
  getMapTileSources,
} from '../reducers/map';
import { useEffect } from 'react';
import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { useProperties } from './properties';
import {
  updateSettings,
  defaultState,
  type MapSettings,
} from '../reducers/map';

export interface UseMapSettingsResponse {
  settings: MapState;
  update: (values: Partial<MapState>) => void;
}

export const useMapSettings = (): UseMapSettingsResponse => {
  const dispatch = useRootDispatch();
  const settings = {
    ...defaultState,
    ...useRootSelector((state) => state.map),
  };

  const update = (values: Partial<MapSettings>) => {
    dispatch(updateSettings(values));
  };

  return {
    settings,
    update,
  };
};

export const useMapTileSources = () => {
  const dispatch = useRootDispatch();
  const properties = useProperties();
  const propertyId = properties.selected?.id;

  useEffect(() => {
    if (propertyId) {
      dispatch(getMapTileSources(propertyId));
    }
  }, [propertyId]);
};

export const useGoogleMapsSession = ({
  requestHeaders = {},
}: {
  requestHeaders?: Record<string, string>;
} = {}) => {
  const dispatch = useRootDispatch();
  const state = useRootSelector((s) => s.map);
  const { baseMaps, googleSession } = state;
  const googleKey = baseMaps?.find((s) => s.id === MapStyle.SatelliteGoogle)
    ?.properties?.key;

  useEffect(() => {
    const currentUnixTime = Math.floor(Date.now() / 1000);
    const noSession = !googleSession || googleSession?.session === '';
    const expiredSession = googleSession?.expiry
      ? Number(googleSession.expiry) < currentUnixTime
      : false;

    if (googleKey && (noSession || expiredSession)) {
      dispatch(getGoogleMapsSessionToken({ key: googleKey, requestHeaders }));
    }
  }, [googleKey, googleSession?.session, googleSession?.expiry]);
};
