import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import TabBar, { TabBarTab } from '@src/components/TabBar';
import { TabPanelProps, TabPanels } from '@src/components/TabPanel';

import styles from './Tabs.scss';
const cx = classNames.bind(styles);

export interface Tab {
  id: TabPanelProps['id'];
  label: TabBarTab['label'];
  content: TabPanelProps['children'];
  active: TabBarTab['active'];
}

/**
 * Tabs is a component that combines a TabBar and a TabPanels
 */
export interface TabsProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'onChange'> {
  /**
   * Array of tab configurations
   */
  tabs: Tab[];

  /**
   * Background color of the tab bar
   */
  backgroundColor?: Color;

  /**
   * Color of the active tab indicator
   */
  indicatorColor?: Color;

  /**
   * Whether to show the TabBar
   */
  showTabBar?: boolean;

  /**
   * Click handler for tab changes
   */
  onChange?: (value: string) => void;
}

/**
 * Tabs component that combines TabBar and TabPanel components
 */
const Tabs: React.FC<TabsProps> = ({
  tabs,
  backgroundColor,
  indicatorColor,
  onChange,
  showTabBar = true,
  className,
  ...others
}) => {
  const rootClasses = cx(
    {
      Tabs: true,
    },
    className
  );

  const tabBarTabs = tabs.map((tab) => ({
    label: tab.label,
    value: tab.id,
    active: tab.active,
    onClick: () => onChange?.(tab.id),
  }));

  const panels = tabs.map((tab) => ({
    id: tab.id,
    active: tab.active,
    disabled: !showTabBar,
    children: tab.content,
  }));

  return (
    <div className={rootClasses} {...others}>
      {showTabBar && (
        <TabBar
          tabs={tabBarTabs}
          backgroundColor={backgroundColor}
          indicatorColor={indicatorColor}
          onChange={onChange}
        />
      )}
      <TabPanels panels={panels} />
    </div>
  );
};

export default Tabs;
