import { defineMessages } from 'react-intl';
import { useMessages } from '@mobble/i18n';
import { getMobParamValues } from '@mobble/models/src/model/Mob';
import {
  ConfiguredPropertyTypeGroup,
  getUniqueConfiguredPropertyTypes,
} from '@mobble/models/src/model/Property';
import { useMobs, useProperties } from '@mobble/store/src/hooks';

const messages = defineMessages({
  paddock_type: {
    defaultMessage: 'Paddock type',
    description: 'paddocks.filter.sections.paddock_type.title',
  },
  livestock_type: {
    defaultMessage: 'Livestock type',
    description: 'paddocks.filter.sections.livestock_type.title',
  },
  age: {
    defaultMessage: 'Livestock ages',
    description: 'paddocks.filter.sections.age.title',
  },
  breed: {
    defaultMessage: 'Breed',
    description: 'paddocks.filter.sections.breed.title',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'paddocks.filter.sections.gender.title',
  },
  class: {
    defaultMessage: 'Class',
    description: 'paddocks.filter.sections.class.title',
  },
});

export const usePaddocksFilterItems = () => {
  const strings = useMessages(messages);
  const properties = useProperties();
  const mobs = useMobs(properties.selected?.id);

  const getFilterTypesFromConfiguredPropertyTypeGroup = (
    group: ConfiguredPropertyTypeGroup
  ) =>
    getUniqueConfiguredPropertyTypes(
      properties.selected ? [properties.selected] : []
    )(group).map((a) => ({
      value: a.type,
    }));

  const toMobParamValue = getMobParamValues(mobs.entities);

  return () => [
    {
      title: strings.paddock_type,
      group: 'paddock_type',
      type: 'select-multiple',
      data: getFilterTypesFromConfiguredPropertyTypeGroup(
        ConfiguredPropertyTypeGroup.paddockType
      ),
    },
    {
      title: strings.livestock_type,
      group: 'livestock_type',
      type: 'select-multiple',
      data: toMobParamValue('type'),
    },
    {
      title: strings.age,
      type: 'select-multiple',
      group: 'age',
      data: toMobParamValue('ages'),
    },
    {
      title: strings.breed,
      type: 'select-multiple',
      group: 'breed',
      data: toMobParamValue('breed'),
    },
    {
      title: strings.gender,
      type: 'select-multiple',
      group: 'gender',
      data: toMobParamValue('gender'),
    },
    {
      title: strings.class,
      type: 'select-multiple',
      group: 'class',
      data: toMobParamValue('classes'),
    },
  ];
};
