import React from 'react';

import { useI18n } from '@mobble/i18n';
import { Color } from '@mobble/theme';

import { Tabs } from '@src/components';
import { useTabs } from '@src/hooks/useTabs';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { SaleInfoTable } from '@src/stories/Views/Sale/SaleInfoTable';

import * as ROUTE_NAME from '../config/routeNames';
import { ScreenRendererProps } from '../config/types';
import { HistoricEvents } from '../HistoricEvents/HistoricEvents';

import { useSaleScreen, useSaleScreenHeader } from './useSaleScreen';

import styles from './saleDetail.scss';

enum SaleDetailMobileTab {
  Info = 'info',
  History = 'history',
}

export const SalesDetailHeader: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const props = useSaleScreenHeader(saleId);

  return <ScreenHeader {...props} />;
};

export const SalesDetail: React.FC<ScreenRendererProps> = ({ route }) => {
  const { saleId } = route.params;
  const { formatMessage } = useI18n();
  const { Prelude, sale, paddock, properties } = useSaleScreen(saleId);
  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: SaleDetailMobileTab.Info,
  });

  if (Prelude) {
    return Prelude;
  }

  const mobId = sale.entity.mob?.id;
  const tabs = [
    {
      id: SaleDetailMobileTab.Info,
      active: activeTab === SaleDetailMobileTab.Info,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'Info',
        description: 'screen.title.sale_detail_info',
      }),
      content: (
        <div className={styles.info}>
          <SaleInfoTable
            sale={sale.entity}
            paddock={paddock.entity}
            propertyTypes={properties.selected?.types}
          />
        </div>
      ),
    },
  ];

  if (mobId) {
    tabs.push({
      id: SaleDetailMobileTab.History,
      active: activeTab === SaleDetailMobileTab.History,
      disabled: !showTabBar,
      label: formatMessage({
        defaultMessage: 'History',
        description: 'screen.title.sale_detail_history',
      }),
      content: (
        <HistoricEvents
          title={formatMessage({
            defaultMessage: 'History',
            description: 'screen.title.mob_detail_history',
          })}
          extFilter={{ mobId }}
          className={styles.history}
        />
      ),
    });
  }

  return (
    <div className={styles.saleDetail}>
      <Tabs
        tabs={tabs}
        backgroundColor={Color.Purple500}
        showTabBar={showTabBar}
        onChange={handleTabChange}
        className={styles.tabs}
      />
    </div>
  );
};

export default {
  name: ROUTE_NAME.SALE_DETAIL,
  header: SalesDetailHeader,
  component: SalesDetail,
};
