import { useI18n } from '@mobble/i18n';
import { getLivestockAgeColor } from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyType } from '@mobble/models/src/model/Property';

import BadgeList from '@src/components/BadgeList';

export const useReportsRenderHelpers = ({
  propertyTypes,
}: {
  propertyTypes: ConfiguredPropertyType[];
}) => {
  const { formatMessage } = useI18n();

  const renderClasses = (arr: (string | number)[]) => {
    if (!arr.length) {
      return (
        <span>
          {formatMessage({
            defaultMessage: 'un-classed',
            description: 'Mob no classes label',
          })}
        </span>
      );
    }

    const badges = arr.map((a) => ({
      label: String(a),
    }));

    return (
      <BadgeList defaultColor={'var(--neutrals-bg-default)'} badges={badges} />
    );
  };

  const renderAges = (type: string, arr: (string | number)[]) => {
    const badges = arr.map((a) => ({
      label: String(a),
      color: getLivestockAgeColor(propertyTypes, type)(Number(a)),
    }));
    return <BadgeList badges={badges} />;
  };

  return { renderClasses, renderAges };
};
