import { I18nContextProps, useI18n } from '@mobble/i18n';
import { UserRole } from '@mobble/models/src/model/User';
import { useGetUser } from '@mobble/store/src/hooks/auth';

import { DialogContextProps, useDialog } from '@src/hooks/useDialog';

import { ACCESS_MATRIX } from '../screens/config/access';
import { RouteName } from '../screens/config/types';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';

export const alertNoAccess = (
  formatMessage: I18nContextProps['formatMessage'],
  roleLabel: string,
  alert: DialogContextProps['alert'],
  close: DialogContextProps['close']
) => {
  alert(
    formatMessage(
      {
        defaultMessage: 'Unable to view page with "{value}" permissions',
        description: 'User access denied alert title',
      },
      {
        value: roleLabel,
      }
    ),
    formatMessage({
      defaultMessage:
        'If you would like to access this page, please contact the owner or administrator of the property to request a change in your access permissions.',
      description: 'User access denied alert message',
    }),
    [
      {
        label: formatMessage({
          defaultMessage: 'Close',
          description: 'User access denied alert close button label',
        }),
        outline: true,
        onClick: () => {
          close();
        },
      },
    ]
  );
};

export const alertOwnerAccessOnly = (
  formatMessage: I18nContextProps['formatMessage'],
  alert: DialogContextProps['alert'],
  close: DialogContextProps['close']
) => {
  alert(
    formatMessage({
      defaultMessage: 'Access denied',
      description: 'Owner acccess only alert title',
    }),
    formatMessage({
      defaultMessage: 'Only the property owner can access this.',
      description: 'Modal user access owner only description',
    }),
    [
      {
        label:
          formatMessage({
            defaultMessage: 'Close',
            description: 'Modal user access owner only dismiss',
          }) ?? '',
        outline: true,
        onClick: () => {
          close();
        },
      },
    ]
  );
};

export const roleHasAccessToRoute =
  (role: UserRole) => (routeName: RouteName) => {
    return ACCESS_MATRIX[routeName].includes(role);
  };

export const useAccessHelper = () => {
  const { alert, close } = useDialog();
  const user = useGetUser();
  const { formatMessage } = useI18n();
  const formatRoleLabel = useRoleLabels();
  const role = user?.role ?? UserRole.Contractor;
  const hasAccessToRoute = roleHasAccessToRoute(role);
  const showNoAccessAlert = () => {
    alertNoAccess(formatMessage, formatRoleLabel(role), alert, close);
  };

  return {
    role,
    hasAccessToRoute,
    showNoAccessAlert,
  };
};

export const useHasAccess = (routeName: RouteName) => {
  const { hasAccessToRoute, showNoAccessAlert } = useAccessHelper();

  if (!hasAccessToRoute(routeName)) {
    showNoAccessAlert();
    return false;
  }

  return true;
};
