import React from 'react';

import { useI18n } from '@mobble/i18n/src';
import { StockingUnit } from '@mobble/models/src/model/Settings';
import { type PropertyStockingRate } from '@mobble/service/src/ext/property-stocking-rates';
import { fromRawDate } from '@mobble/shared/src/core/Date';
import { useSetting } from '@mobble/store/src/hooks';

import { ListSelect } from '@src/components';
import ChartContainer from '@src/components/ChartContainer';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';

import { Spacer } from '../../Layout/Spacer';
import { getStockingUnitI18n } from '../../Locale/LocaleStockingUnit';
import {
  useConvertStockingRatesData,
  useConvertStockingRatesDataUnits,
  useYearSelector,
} from '../converters/useConverStockingRatesData';
import { useGenerateLivestockHistoryChart } from '../generators/generateLivestockHistoryChart';

export type Mode = 'head' | 'stocking_rate' | 'area_stocking_rate';

const modeToRecordOption = {
  head: 'head',
  stocking_rate: 'DSE',
  area_stocking_rate: 'areaDSE',
};

const useModes = () => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  return [
    {
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'chart.historic-livestock.option.head',
      }),
      value: 'head',
    },
    {
      label: stockingUnitLabel,
      value: 'stocking_rate',
    },
    {
      label: formatMessage(
        {
          defaultMessage: '{STOCKING_UNIT}/Ha',
          description: 'chart.historic-livestock.option.area_stocking_rate',
        },
        {
          STOCKING_UNIT: stockingUnitLabel,
        }
      ),
      value: 'area_stocking_rate',
    },
  ];
};

export interface ChartLivestockHistoryProps {
  stockingRates: PropertyStockingRate[];
  loading?: boolean;
}

export const ChartLivestockHistory: React.FC<ChartLivestockHistoryProps> = ({
  stockingRates,
  loading,
}) => {
  const { formatMessage } = useI18n();
  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes();

  const years = [
    ...new Set(stockingRates.map((sr) => fromRawDate(sr.dateRaw).year())),
  ];

  const { year, setYear, yearOptions } = useYearSelector(years);

  const handleChangeYear = (years: (string | number)[]) =>
    setYear(Number(years[0]));

  const handleChangeMode = (option: (string | number)[]) =>
    setMode(option[0] as Mode);

  const stockingRateData = useConvertStockingRatesData({
    year,
    stockingRates,
    mode: modeToRecordOption[mode] as any,
  });
  const { data, dataAverage } =
    useConvertStockingRatesDataUnits(stockingRateData)(mode);

  const eChartData = useGenerateLivestockHistoryChart({
    data: stockingRates.length > 0 ? data : null,
    params: { dataAverage, year },
  });

  return (
    <ChartContainer
      title={formatMessage({
        defaultMessage: 'Historic Livestock',
        description: 'chart.historic-livestock.title',
      })}
      titleHref={toPath(ROUTE_NAME.REPORTS)}
      data={eChartData}
      loading={loading}
      headerOptions={
        <>
          {yearOptions && yearOptions.length > 1 ? (
            <ListSelect
              id="mode-select"
              size="small"
              options={yearOptions.map((option) => ({
                label: option.label,
                value: option.value,
                selected: option.selected,
              }))}
              onChange={handleChangeYear}
            />
          ) : null}
          <Spacer x={1} />
          <ListSelect
            id="mode-select"
            size="small"
            options={modeOptions.map((option) => ({
              label: option.label,
              value: option.value,
              selected: option.value === mode,
            }))}
            onChange={handleChangeMode}
          />
        </>
      }
    />
  );
};

export interface ChartPropertiesLivestockHistoryProps {
  loading?: boolean;
  stockingRates: PropertyStockingRate[];
}

export const ChartPropertiesLivestockHistory: React.FC<
  ChartPropertiesLivestockHistoryProps
> = ({ stockingRates, loading }) => {
  const { formatMessage } = useI18n();
  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes();

  const handleChangeMode = (option: (string | number)[]) =>
    setMode(option[0] as Mode);

  const stockingRateData = useConvertStockingRatesData({
    stockingRates,
    mode: modeToRecordOption[mode] as any,
  });

  const { data, dataAverage } =
    useConvertStockingRatesDataUnits(stockingRateData)(mode);

  const eChartData = useGenerateLivestockHistoryChart({
    data: stockingRates.length > 0 ? data : null,
    params: { dataAverage },
  });

  return (
    <ChartContainer
      title={formatMessage({
        defaultMessage: 'Historic Livestock',
        description: 'chart.historic-livestock.title',
      })}
      headerOptions={
        <ListSelect
          id="mode-select"
          size="small"
          options={modeOptions.map((option) => ({
            label: option.label,
            value: option.value,
            selected: option.value === mode,
          }))}
          onChange={handleChangeMode}
        />
      }
      data={eChartData}
      loading={loading}
    />
  );
};
