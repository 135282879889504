import React from 'react';

import { useI18n } from '@mobble/i18n';
import { FilterItem } from '@mobble/models/src/model/Filter';
import {
  availableSortOptions,
  findPaddock,
} from '@mobble/models/src/model/Paddock';
import { filterTasks, sortTasks, Task } from '@mobble/models/src/model/Task';
import { usePaddocks } from '@mobble/store/src/hooks/paddocks';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';

import { Box } from '@src/stories/Components/Layout/Box';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { makeTasksTableColumns } from '@src/stories/Views/Task/List/tasksTableColumns';
import { TaskCard } from '@src/stories/Views/Task/TaskCard';

export interface TasksViewerProps {
  title?: string;
  showTitle?: boolean;
  hideFixedFilters?: boolean;
  provider: Provider<Task>;
  renderEntityCard?: (mob: Task) => React.ReactNode;
}

export const TasksViewer: React.FC<TasksViewerProps> = ({
  title,
  showTitle,
  hideFixedFilters,
  provider,
  renderEntityCard,
}) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const paddocks = usePaddocks(properties.selected?.id);

  const baseViewerProps: EntitiesViewerProps<Task> = {
    provider,
    title,
    showTitle,
    hideFixedFilters,
    onEmpty: formatMessage({
      defaultMessage: 'There are no tasks to display',
      description: 'tasks.table.empty',
    }),
    onRefresh: undefined,
    renderEntityCard:
      renderEntityCard ??
      ((task) => (
        <Box spacing={1}>
          <TaskCard
            onClick={() => {}}
            task={task}
            paddock={
              task.paddockId
                ? findPaddock(paddocks.entities)(task.paddockId)
                : undefined
            }
          />
        </Box>
      )),
    tableColumns: makeTasksTableColumns({
      paddocks: paddocks.entities,
    }),
  };

  if (Array.isArray(provider)) {
    return (
      <EntitiesViewer key={properties.selected?.id} {...baseViewerProps} />
    );
  }

  const applyFilter = (p, filter: FilterItem[]) => {
    return filterTasks(paddocks.entities)({
      type: 'pending',
    })(provider.entities, filter);
  };
  const applySort = sortTasks;

  const extendedViewerProps: EntitiesViewerProps<Task> = {
    ...baseViewerProps,
    applyFilter,
    sortOptions: () => availableSortOptions,
    applySort,
    onRefresh: () => provider.refresh(),
  };

  return (
    <EntitiesViewer key={properties.selected?.id} {...extendedViewerProps} />
  );
};
