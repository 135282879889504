import React from 'react';

import { farmRoles } from '@src/screens/Auth/hooks/useGetCountryFromQueryParams';

import {
  FormBuilder,
  FormBuilderProps,
} from '@src/stories/Views/Form/FormBuilder';
import { Relations } from '@mobble/models/src/model/User';

export interface ReferFarmerFormProps {
  loading: boolean;
  onSubmit: (formValues: ReferFarmerFormValues) => void;
  onCancel: () => void;
  roleOptions: {
    label: string;
    value: string;
  }[];
}

export interface ReferFarmerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  relationship: Relations;
  role: string;
  farmName: string;
  message: string;
}

export const ReferFarmerForm: React.FC<ReferFarmerFormProps> = ({
  loading,
  onSubmit,
  onCancel,
  roleOptions,
}) => {
  const form: FormBuilderProps<ReferFarmerFormValues> = {
    flex: true,
    i18nRootKey: 'refer-farmer.refer-farmer-form.form',
    fields: [
      {
        name: 'firstName',
        type: 'text',
        required: true,
      },
      {
        name: 'lastName',
        type: 'text',
        required: true,
      },
      {
        name: 'email',
        type: 'email',
        required: true,
      },
      {
        name: 'relationship',
        type: 'select',
        options: roleOptions,
        required: true,
      },
      {
        name: 'role',
        type: 'select',
        options: farmRoles,
        required: true,
      },
      {
        name: 'farmName',
        type: 'text',
        required: true,
      },
      {
        name: 'message',
        type: 'textarea',
        required: true,
      },
    ],
    loading,
    onSubmit,
    onCancel,
  };

  return <FormBuilder {...form} />;
};
