import { CustomMapLayer } from '@mobble/models';
import { FilterItem, makeFilterEquals } from '@mobble/models/src/model/Filter';
import { MAP_CUSTOM_FEATURE } from '@mobble/models/src/model/MapDetail';
import { useMapSettings } from '@mobble/store/src/hooks';
import { useEffect } from 'react';

export const useCustomMapLayersFilter = ({
  selectedCustomMapLayersFilter,
  customMapLayers,
  onChange,
}: {
  selectedCustomMapLayersFilter: FilterItem[];
  customMapLayers: CustomMapLayer[];
  onChange: (filter: FilterItem[]) => void;
}) => {
  const settings = useMapSettings();
  const selectedCustomMapLayers = selectedCustomMapLayersFilter.map(
    (filter) => {
      return filter.filter.value as string;
    }
  );
  const options = customMapLayers.map((customMapLayer) => ({
    value: customMapLayer.id,
    label: customMapLayer.name,
    selected: selectedCustomMapLayers.includes(customMapLayer.id),
  }));

  const totalOptionsSelected = options.filter(
    (option) => option.selected
  ).length;

  const allSelected = options.every((option) => option.selected);
  const someSelected = options.some((option) => option.selected);

  const handleChange = (ev: (string | number)[]) => {
    const selected = ev;

    onChange(
      customMapLayers
        .filter((customMapLayer) => selected.includes(customMapLayer.id))
        .map((customMapLayer) => ({
          group: 'id',
          filter: makeFilterEquals(customMapLayer.id),
        }))
    );
  };

  useEffect(() => {
    const mapDetails =
      customMapLayers.length > 0
        ? [...settings.settings.mapDetails, MAP_CUSTOM_FEATURE]
        : settings.settings?.mapDetails.filter(
            (detail) => detail !== MAP_CUSTOM_FEATURE
          );

    settings.update({
      ...settings.settings,
      mapDetails: mapDetails as any[],
    });
  }, [JSON.stringify(selectedCustomMapLayersFilter)]);

  const handleToggle = () => {
    const values = allSelected
      ? []
      : customMapLayers.map((entity) => ({
          group: 'id',
          filter: makeFilterEquals(entity.id),
        }));

    onChange(values);
  };

  return {
    options,
    totalOptionsSelected,
    allSelected,
    someSelected,
    handleChange,
    handleToggle,
  };
};
