import { type I18nContextProps } from '@mobble/i18n';
import { InventoryCategory } from '@mobble/models/src';
import { AppliedInventoryItem } from '@mobble/models/src/model/AppliedInventoryItem';
import { AreaUnits } from '@mobble/shared/src/core/Quantity';

import { makeQuantityFormatMessageItem } from '@src/stories/Components/Locale/LocaleQuantity';

export const appliedInventoryItemsToString = (
  appliedInventoryItems: AppliedInventoryItem[],
  unit: AreaUnits | string,
  formatMessage: I18nContextProps['formatMessage']
) =>
  appliedInventoryItems
    .map((item) => {
      if (!item.quantity?.value) {
        return '';
      }
      return `${formatMessage(
        ...makeQuantityFormatMessageItem(item.quantity)
      )} ${
        item.category === InventoryCategory.Chemicals
          ? item.name
          : item.supplierName
      }/${unit}`;
    })
    .filter(Boolean)
    .join(', ');

export const appliedInventoryItemsToTotalString = (
  appliedInventoryItems: AppliedInventoryItem[],
  formatMessage: I18nContextProps['formatMessage']
) => {
  const hasMultipleItems = appliedInventoryItems.length > 1;

  return appliedInventoryItems
    .map((item) => {
      if (!item.totalApplied?.value) {
        return '';
      }
      return `${formatMessage(
        ...makeQuantityFormatMessageItem(item.totalApplied)
      )} ${
        hasMultipleItems
          ? item.category === InventoryCategory.Chemicals
            ? item.name
            : item.supplierName
          : ''
      }`;
    })
    .filter(Boolean)
    .join(', ');
};
