import React from 'react';
import { defineMessages } from 'react-intl';
import isEqual from 'lodash/isEqual';

import { useMessages } from '@mobble/i18n';
import { type BaseEntity } from '@mobble/models/src/model/BaseEntity';
import { type SortOption } from '@mobble/models/src/model/Sort';
import { EntitySliceFactoryProxyEntitiesResponse } from '@mobble/store/src/lib/entitySliceFactory';

import { InlineOption } from '@src/stories/Components/UX/InlineOption';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';

export interface EntitiesSortProps<Entity extends BaseEntity> {
  isOpen?: boolean;
  onClose: () => void;
  items: () => SortOption[];
  provider: EntitySliceFactoryProxyEntitiesResponse<Entity>;
}

const messages = defineMessages({
  'entities.entities_sort.title': {
    defaultMessage: 'Sorting options',
    description: 'entities.entities_sort.title',
  },

  'entities.entities_sort.options.distance_from_paddock.label': {
    defaultMessage: 'Distance from paddock',
    description: 'entities.entities_sort.options.distance_from_paddock.label',
  },
  'entities.entities_sort.options.distance.label': {
    defaultMessage: 'Distance from me',
    description: 'entities.entities_sort.options.distance.label',
  },
  'entities.entities_sort.options.head_asc.label': {
    defaultMessage: 'Head (least)',
    description: 'entities.entities_sort.options.head_asc.label',
  },
  'entities.entities_sort.options.head_desc.label': {
    defaultMessage: 'Head (most)',
    description: 'entities.entities_sort.options.head_desc.label',
  },
  'entities.entities_sort.options.name_asc.label': {
    defaultMessage: 'Name (A-Z)',
    description: 'entities.entities_sort.options.name_asc.label',
  },
  'entities.entities_sort.options.name_desc.label': {
    defaultMessage: 'Name (Z-A)',
    description: 'entities.entities_sort.options.name_desc.label',
  },
  'entities.entities_sort.options.name_yards_asc.label': {
    defaultMessage: 'Name (A-Z) (Yards first)',
    description: 'entities.entities_sort.options.name_yards_asc.label',
  },
  'entities.entities_sort.options.paddock_name_asc.label': {
    defaultMessage: 'Paddock name (A-Z)',
    description: 'entities.entities_sort.options.paddock_name_asc.label',
  },
  'entities.entities_sort.options.paddock_name_desc.label': {
    defaultMessage: 'Paddock name (Z-A)',
    description: 'entities.entities_sort.options.paddock_name_desc.label',
  },
  'entities.entities_sort.options.size_asc.label': {
    defaultMessage: 'Size (smallest)',
    description: 'entities.entities_sort.options.size_asc.label',
  },
  'entities.entities_sort.options.size_desc.label': {
    defaultMessage: 'Size (largest)',
    description: 'entities.entities_sort.options.size_desc.label',
  },
  'entities.entities_sort.options.days_grazed_asc.label': {
    defaultMessage: 'Days grazed/rested (ascending)',
    description: 'entities.entities_sort.options.days_grazed_asc.label',
  },
  'entities.entities_sort.options.days_grazed_desc.label': {
    defaultMessage: 'Days grazed/rested (descending)',
    description: 'entities.entities_sort.options.days_grazed_desc.label',
  },
  'entities.entities_sort.options.type.label': {
    defaultMessage: 'Type',
    description: 'entities.entities_sort.options.type.label',
  },
});

export function EntitiesSortComponent<Entity extends BaseEntity>({
  isOpen,
  onClose,
  items,
  provider,
}: EntitiesSortProps<Entity>) {
  const strings = useMessages(messages);

  const renderItem = (item: SortOption) => {
    return (
      <InlineOption
        type="checkbox"
        value={item.name}
        label={strings[`entities.entities_sort.options.${item.name}.label`]}
        selected={isEqual(item.settings, provider.sort)}
        onClick={() => {
          provider.setSortSetting(item.settings);
        }}
      />
    );
  };

  return (
    <ModalFlyUp
      isOpen={isOpen}
      onClose={() => onClose()}
      title={strings['entities.entities_sort.title']}
      listProps={{
        items: items(),
        keyExtractor: (item) => JSON.stringify(item),
        renderItem,
      }}
    />
  );
}

export const EntitiesSort = React.memo(EntitiesSortComponent, (a, b) => {
  return a.isOpen === b.isOpen && a.provider.sort === b.provider.sort;
}) as typeof EntitiesSortComponent;
