import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import {
  Casualty,
  findCasualtiesForPaddock,
} from '@mobble/models/src/model/Casualty';
import { polygonToArea } from '@mobble/models/src/model/MapGeometry';
import { findMob, findMobs, Mob } from '@mobble/models/src/model/Mob';
import { Paddock } from '@mobble/models/src/model/Paddock';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { findPaddockGeometry } from '@mobble/models/src/model/PaddockGeometry';
import {
  findPaddockGroupForPaddock,
  getPaddocksInSamePaddockGroupAsPaddock,
} from '@mobble/models/src/model/PaddockGroup';
import {
  findPaddockGroupedStockingRate,
  toTotals,
} from '@mobble/models/src/model/PaddockGroupedStockingRate';
import { findPaddockGroupGeometry } from '@mobble/models/src/model/PaddockGroupGeometry';
import {
  findPendingTasksForPaddock,
  Task,
} from '@mobble/models/src/model/Task';
import { useEntitiesRefresher } from '@mobble/shared/src/hooks/useEntitiesRefresher';
import {
  useCasualties,
  useMobs,
  usePaddock,
  usePaddockActions,
  usePaddockGeometries,
  usePaddockGroupedStockingRates,
  usePaddockGroupGeometries,
  usePaddockGroups,
  usePaddocks,
  usePaddockStockingRates,
  useProperties,
  useTasks,
} from '@mobble/store/src/hooks';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';

import { Alert, Tabs } from '@src/components';
import { useLinking } from '@src/hooks/useLinking';
import { useNavigateBack } from '@src/hooks/useNavigateBack';
import { useTabs } from '@src/hooks/useTabs';
import { toPath } from '@src/interfaces/Routing';
import { HistoricEvents } from '@src/screens/HistoricEvents/HistoricEvents';
import { ChartMonthlyCasualtiesByMob } from '@src/stories/Components/Charts/Components/ChartCasualtiesOverTime';
import { ChartLivestockHistory } from '@src/stories/Components/Charts/Components/ChartLivestockHistory';
import { ChartLivestockTotals } from '@src/stories/Components/Charts/Components/ChartLivestockTotals';
import { CasualtiesViewer } from '@src/stories/Views/Casualty/List/CasualtiesViewer';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import { MobsViewer } from '@src/stories/Views/Mob/List/MobsViewer';
import { PaddockInfoTable } from '@src/stories/Views/Paddock/PaddockInfoTable';
import { PaddockMap } from '@src/stories/Views/Paddock/PaddockMap';
import { PaddockActionsViewer } from '@src/stories/Views/PaddockAction/List/PaddockActionsViewer';
import { TasksViewer } from '@src/stories/Views/Task/List/TasksViewer';

import * as ROUTE_NAME from '../config/routeNames';
import { type ScreenRendererProps } from '../config/types';

import styles from './paddockDetail.scss';

enum PaddockDetailMobileTab {
  Info = 'info',
  Mobs = 'mobs',
  Tasks = 'tasks',
  History = 'history',
}

const messages = defineMessages({
  info: {
    defaultMessage: 'Info',
    description: 'screen.title.paddock_detail_info',
  },
  mobs: {
    defaultMessage: 'Mobs',
    description: 'screen.title.paddock_detail_mobs',
  },
  tasks: {
    defaultMessage: 'Tasks',
    description: 'screen.title.paddock_detail_tasks',
  },
  history: {
    defaultMessage: 'History',
    description: 'screen.title.paddock_detail_history',
  },
  paddockActions: {
    defaultMessage: 'Paddock actions',
    description: 'screen.title.summary_paddock_actions',
  },
  casualties: {
    defaultMessage: 'Casualties',
    description: 'mob_detail.casualties.title',
  },
  statusDeletedHeading: {
    defaultMessage: 'Paddock deleted',
    description: 'Deleted paddock status heading',
  },
  statusDeletedMessage: {
    defaultMessage: 'You are unable to edit deleted paddocks.',
    description: 'Deleted paddock status message',
  },
});

export const PaddockDetailHeader: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const { formatMessage } = useI18n();
  const goBack = useNavigateBack();
  const properties = useProperties();
  const paddock = usePaddock(paddockId);
  const paddocks = usePaddocks(
    paddock.entity?.propertyId ?? properties.selected?.id
  );
  const paddockName = paddock.entity?.name || '';

  const isDeleted = paddock.entity?.status === 'deleted';

  const quickLinks = paddocks.entities
    .map((p) => ({
      title: p.name,
      href: toPath(ROUTE_NAME.PADDOCK_DETAIL, { paddockId: p.id }),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ScreenHeader
      title={
        paddockName ||
        formatMessage({
          defaultMessage: 'Paddock details',
          description: 'screen.title.paddock_detail',
        })
      }
      breadcrumbs={[
        {
          title: formatMessage({
            defaultMessage: 'Paddocks',
            description: 'screen.title.paddocks',
          }),
          href: ROUTE_NAME.PADDOCKS_LIST,
        },
      ]}
      quickLinks={quickLinks}
      actions={
        !isDeleted
          ? [
              {
                label: formatMessage(
                  {
                    defaultMessage: 'Edit "{PADDOCK_NAME}"',
                    description: 'screen.title.paddock_edit',
                  },
                  {
                    PADDOCK_NAME: paddockName,
                  }
                ),
                icon: 'edit',
                href: toPath(ROUTE_NAME.PADDOCK_DETAIL_EDIT, { paddockId }),
              },
            ]
          : []
      }
      onGoBack={goBack}
    />
  );
};

export const PaddockDetail: React.FC<ScreenRendererProps> = (props) => {
  const { paddockId = '' } = props.route.params;
  const strings = useMessages(messages);
  const linkTo = useLinking();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const paddock = usePaddock(paddockId, propertyId);

  const casualties = useCasualties(propertyId);
  const mobs = useMobs(propertyId);
  const paddockActions = usePaddockActions(propertyId);
  const paddockGeometries = usePaddockGeometries(propertyId);
  const paddockGroupGeometries = usePaddockGroupGeometries(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockStockingRates = usePaddockStockingRates(propertyId, paddockId);
  const tasks = useTasks(propertyId);

  const { activeTab, showTabBar, handleTabChange } = useTabs({
    defaultTab: PaddockDetailMobileTab.Info,
  });

  const isDeleted = paddock.entity?.status === 'deleted';

  const paddockActionsOfPaddock: Provider<PaddockAction> = {
    ...paddockActions,
    entities: paddockActions.entities.filter((action) =>
      action.paddocks.find((paddock) => paddock.paddockId === paddockId)
    ),
  };

  const casualtiesForPaddock: Provider<Casualty> = {
    ...casualties,
    entities: findCasualtiesForPaddock(casualties.entities)(paddockId),
  };

  const tasksForPaddock: Provider<Task> = {
    ...tasks,
    entities: findPendingTasksForPaddock(tasks.entities)(paddockId),
  };

  const paddockGroup = findPaddockGroupForPaddock(paddockGroups.entities)(
    paddockId
  );
  const paddockGeometry = findPaddockGeometry(paddockGeometries.entities)(
    paddockId
  );

  const paddocksInSamePaddockGroup =
    paddock.entity && paddockGroups.entities
      ? getPaddocksInSamePaddockGroupAsPaddock(
          paddockGroups.entities,
          paddocks.entities
        )(paddockId)
      : null;

  const getMobs = findMobs(mobs.entities);

  const paddocksExcludingCurrent = paddocksInSamePaddockGroup
    ? paddocksInSamePaddockGroup.filter((pdk) => pdk.id !== paddockId)
    : [];

  const paddockGroupMobs = paddocksExcludingCurrent
    ? getMobs(paddocksExcludingCurrent.map((pdk) => pdk.mobs).flat())
    : null;

  const paddockGroupGeometry = paddockGroup
    ? findPaddockGroupGeometry(paddockGroupGeometries.entities)(paddockGroup.id)
    : null;

  const paddockTotalArea = paddockGeometry
    ? polygonToArea(paddockGeometry.polygon)
    : undefined;

  const mobsOnPaddock = {
    ...mobs,
    entities: paddock?.entity?.mobs.length
      ? ([
          ...(paddock.entity as Paddock).mobs
            .map(findMob(mobs.entities))
            .filter(Boolean),
          ...paddockGroupMobs,
        ] as Mob[])
      : [],
  };

  const paddockGroupedStockingRates = usePaddockGroupedStockingRates(
    properties.selected?.id
  );

  const paddockGroupedStockingRate = findPaddockGroupedStockingRate(
    paddockGroupedStockingRates.entities
  )(paddockId);

  const paddockGroupedStockingRateTotals = paddockGroupedStockingRate
    ? toTotals(paddockGroupedStockingRate)
    : null;

  const paddockMap = paddockGeometry ? (
    <PaddockMap
      propertyTypes={properties.selected?.types ?? []}
      paddocks={[paddock.entity as Paddock]}
      paddockGeometries={paddockGeometries.entities}
      paddockGroupGeometry={paddockGroupGeometry}
      mobs={mobs.entities}
      onMaximise={() => {
        linkTo(ROUTE_NAME.MAP_OVERVIEW, {
          goto: `paddock--${paddockId}`,
        });
      }}
    />
  ) : null;

  const HistoricEventsRendered = (
    <HistoricEvents
      title={strings['history']}
      extFilter={{ paddockId }}
      className={styles.history}
    />
  );

  const MobsViewerRendered = (
    <MobsViewer
      title={strings['mobs']}
      showTitle
      hideFixedFilters
      provider={mobsOnPaddock}
    />
  );

  const tabs = [
    {
      id: PaddockDetailMobileTab.Info,
      active: activeTab === PaddockDetailMobileTab.Info,
      disabled: !showTabBar,
      label: strings['info'],
      content: (
        <>
          {paddockGeometry && (
            <div className={styles.paddockMapMobile}>{paddockMap}</div>
          )}
          <div className={styles.info}>
            <Alert
              open={isDeleted}
              variant="warning"
              title={strings.statusDeletedHeading}
              message={strings.statusDeletedMessage}
              className={styles.alert}
            />
            <PaddockInfoTable
              propertyTypes={properties.selected?.types ?? []}
              paddock={paddock.entity as Paddock}
              mobs={mobs.entities}
              paddocksInSamePaddockGroup={paddocksExcludingCurrent}
              paddockGroupedStockingRateTotals={
                paddockGroupedStockingRateTotals
              }
              paddockTotalArea={paddockTotalArea}
            />
          </div>
        </>
      ),
    },
    {
      id: PaddockDetailMobileTab.Mobs,
      active: activeTab === PaddockDetailMobileTab.Mobs,
      disabled: !showTabBar,
      label: strings['mobs'],
      content: MobsViewerRendered,
    },
    {
      id: PaddockDetailMobileTab.Tasks,
      active: activeTab === PaddockDetailMobileTab.Tasks,
      disabled: !showTabBar,
      label: strings['tasks'],
      content: (
        <TasksViewer
          title={strings['tasks']}
          showTitle
          hideFixedFilters
          provider={tasksForPaddock}
        />
      ),
    },
    {
      id: PaddockDetailMobileTab.History,
      active: activeTab === PaddockDetailMobileTab.History,
      disabled: !showTabBar,
      label: strings['history'],
      content: HistoricEventsRendered,
    },
  ];

  useEntitiesRefresher(
    [
      // nested MobsViewer will refresh mobs
      casualties,
      paddockActions,
      paddockGeometries,
      paddockGroupGeometries,
      paddocks,
      paddock,
      paddockStockingRates,
      tasks,
    ],
    propertyId
  );

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude, paddock.prelude],
    required: [properties.selected],
    notFound: [paddock.entity],
    type: 'paddock',
  });

  if (Prelude) {
    return Prelude;
  }

  return (
    <div className={styles.paddockDetail}>
      {paddockGeometry && (
        <div className={styles.paddockMapDesktop}>{paddockMap}</div>
      )}

      <Tabs
        tabs={tabs}
        showTabBar={showTabBar}
        onChange={handleTabChange}
        className={styles.tabs}
      />

      <div className={styles.expandedData}>
        <div className={styles.charts}>
          <ChartLivestockTotals
            mobs={mobsOnPaddock.entities}
            loading={mobsOnPaddock.loading}
          />

          <ChartLivestockHistory
            stockingRates={paddockStockingRates.entities}
            loading={paddockStockingRates.loading}
          />
        </div>

        <div className={styles.paddockActionsTable}>
          <PaddockActionsViewer
            title={strings['paddockActions']}
            showTitle
            hideFixedFilters
            provider={paddockActionsOfPaddock}
          />
        </div>

        <div className={styles.paddockTabCasualties}>
          <CasualtiesViewer
            title={strings['casualties']}
            showTitle
            hideFixedFilters
            provider={casualtiesForPaddock}
          />
        </div>

        {casualtiesForPaddock.entities.length > 0 && (
          <div className={styles.paddockTabChartCasualties}>
            <ChartMonthlyCasualtiesByMob
              casualties={casualtiesForPaddock.entities}
              loading={casualtiesForPaddock.loading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default {
  name: ROUTE_NAME.PADDOCK_DETAIL,
  header: PaddockDetailHeader,
  component: PaddockDetail,
};
