export const toSlug = (value: string): string => {
  return trim(
    fromCamelCase(value)
      .toLowerCase()
      .replace(/[^\w\s]+/g, '')
      .replace(/ +/g, '_'),
    '_'
  );
};

export const trim = (str: string, char?: string) => {
  if (char) {
    return str
      .replace(new RegExp(`^${char}`), '')
      .replace(new RegExp(`${char}$`), '');
  }

  return str.trimStart().trimEnd();
};

export const ucFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const fromCamelCase = (value: string): string => {
  return ucFirst(
    value
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .trim()
  );
};

export const truncate = (str: string, length = 20): string => {
  return str.length > length ? `${str.substring(0, length)}...` : str;
};

export const hash = (item: any) => {
  return btoa(JSON.stringify(item));
};

export const stripSpecialCharacters = (str: string) => {
  return str
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, ' ')
    .trim();
};

export const floatOnly = (str: string) => {
  return str.replace(/[^0-9.]/g, '');
};

export const intOnly = (str: string) => {
  return str.replace(/[^0-9]/g, '');
};

export const base64encode = (str: string): string => {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);
  let binary = '';
  const len = uint8Array.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }
  return btoa(binary);
};

export const base64decode = (base64: string): string => {
  const binary = atob(base64);
  const len = binary.length;
  const uint8Array = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binary.charCodeAt(i);
  }
  const decoder = new TextDecoder();
  return decoder.decode(uint8Array);
};

export const replaceParams = (
  input: string,
  params: Record<string, string | number>
): string => {
  return Object.entries(params).reduce(
    (acc, [key, value]) =>
      acc.replace(new RegExp(`{${key}}`, 'g'), value.toString()),
    input
  );
};
