import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { Purchase } from '@mobble/models/src';
import { EntitiesFilterItem } from '@mobble/models/src/model/BaseEntity';
import { getMobParamValues } from '@mobble/models/src/model/Mob';
import { useProperties } from '@mobble/store/src/hooks/properties';
import { usePurchases } from '@mobble/store/src/hooks/purchases';
import { fromRawDate } from '../core/Date';

const messages = defineMessages({
  dateRange: {
    defaultMessage: 'Date range',
    description: 'purchases.filter.group.type.date_range.label',
  },
  breed: {
    defaultMessage: 'Breed',
    description: 'purchases.filter.group.breed.label',
  },
  gender: {
    defaultMessage: 'Gender',
    description: 'purchases.filter.group.gender.label',
  },
  number: {
    defaultMessage: 'Number',
    description: 'purchases.filter.group.number.label',
  },
});

export const usePurchasesFilterItems = () => {
  const strings = useMessages(messages);
  const properties = useProperties();
  const purchases = usePurchases(properties.selected?.id);

  const toMobParamValue = getMobParamValues(
    purchases.entities.map((e) => e.mob)
  );

  const years = Array.from(
    new Set(purchases.entities.map((entity) => fromRawDate(entity.date).year()))
  ).slice(0, 5);

  return (): EntitiesFilterItem<Purchase>[] => [
    {
      title: strings.dateRange,
      type: 'date-range',
      group: 'dateRange',
      meta: {
        fixed: true,
        years,
      },
    },
    {
      title: strings.breed,
      type: 'select-multiple',
      group: 'breed',
      data: toMobParamValue('breed'),
    },
    {
      title: strings.gender,
      type: 'select-multiple',
      group: 'gender',
      data: toMobParamValue('gender'),
    },
    {
      type: 'range',
      group: 'number',
      title: strings.number,
    },
  ];
};
