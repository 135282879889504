import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  filterPaddockActions,
  PaddockAction,
} from '@mobble/models/src/model/PaddockAction';
import { usePaddockActionsFilterItems } from '@mobble/shared/src/hooks/usePaddockActionsFilterItems';
import { useProperties, useSettings } from '@mobble/store/src/hooks';

import { useLinking } from '@src/hooks/useLinking';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import {
  EntitiesViewer,
  type EntitiesViewerProps,
} from '@src/stories/Views/Entities/EntitiesViewer';
import { type Provider } from '@mobble/store/src/lib/entitySliceFactory';

import { PaddockActionCard } from '../PaddockActionCard';

import { makePaddockActionsTableColumns } from './paddockActionsTableColumns';

export interface PaddockActionsViewerProps {
  title?: string;
  showTitle?: boolean;
  hideFixedFilters?: boolean;
  provider: Provider<PaddockAction>;
  renderEntityCard?: (casualty: PaddockAction) => React.ReactNode;
}

export const PaddockActionsViewer: React.FC<PaddockActionsViewerProps> = ({
  title,
  showTitle,
  hideFixedFilters,
  provider,
}) => {
  const { formatMessage } = useI18n();
  const properties = useProperties();
  const { settings } = useSettings();
  const { areaUnit } = settings;
  const linkTo = useLinking();

  const onSelectPaddockAction = (paddockAction: PaddockAction) => {
    linkTo(ROUTE_NAME.PADDOCK_ACTION_DETAIL, {
      paddockActionId: paddockAction.id,
    });
  };

  const baseViewerProps: EntitiesViewerProps<PaddockAction> = {
    provider,
    applyFilter: filterPaddockActions,
    title,
    showTitle,
    hideFixedFilters,
    onEmpty: formatMessage({
      defaultMessage: 'There are no paddock actions to display',
      description: 'paddock_actions.table.empty',
    }),
    onRefresh: undefined,
    onClickTableRow: onSelectPaddockAction,
    renderEntityCard: (item) => {
      return (
        <PaddockActionCard
          paddockAction={item}
          onClick={onSelectPaddockAction}
        />
      );
    },
    tableColumns: makePaddockActionsTableColumns({ areaUnit, formatMessage }),
  };

  if (Array.isArray(provider)) {
    return (
      <EntitiesViewer key={properties.selected?.id} {...baseViewerProps} />
    );
  }

  const filterItems = usePaddockActionsFilterItems();

  const extendedViewerProps: EntitiesViewerProps<PaddockAction> = {
    ...baseViewerProps,
    filterItems,
    onRefresh: () => provider.refresh(),
  };

  return (
    <EntitiesViewer key={properties.selected?.id} {...extendedViewerProps} />
  );
};
