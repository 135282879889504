import { useSearchParams } from 'react-router';

export const countries = [
  { value: 'au', label: 'Australia' },
  { value: 'nz', label: 'New Zealand' },
  { value: 'us', label: 'United States' },
  { value: 'uk', label: 'United Kingdom' },
  { value: 'ca', label: 'Canada' },
  { value: 'other', label: 'Other' },
];

export const farmRoles = [
  { value: 'owner-operator', label: 'Owner/Operator' },
  { value: 'owner-investor', label: 'Owner/Investor' },
  { value: 'manager', label: 'Manager' },
  { value: 'worker-farmhand', label: 'Worker or farm hand' },
  { value: 'farm-admin', label: 'Farm admin' },
  { value: 'hobby-farmer', label: 'Hobby farmer' },
  { value: 'family-member', label: 'Family member' },
  { value: 'no-role', label: 'No role' },
];

export const countriesAndCode = [
  { name: 'Afghanistan', phone: '93', value: 'af' },
  { name: 'Albania', phone: '355', value: 'al' },
  { name: 'Algeria', phone: '213', value: 'dz' },
  { name: 'American Samoa', phone: '1-684', value: 'as' },
  { name: 'Andorra', phone: '376', value: 'ad' },
  { name: 'Angola', phone: '244', value: 'ao' },
  { name: 'Anguilla', phone: '1-264', value: 'ai' },
  { name: 'Antarctica', phone: '672', value: 'aq' },
  { name: 'Antigua and Barbuda', phone: '1-268', value: 'ag' },
  { name: 'Argentina', phone: '54', value: 'ar' },
  { name: 'Armenia', phone: '374', value: 'am' },
  { name: 'Aruba', phone: '297', value: 'aw' },
  { name: 'Australia', phone: '61', value: 'au' },
  { name: 'Austria', phone: '43', value: 'at' },
  { name: 'Azerbaijan', phone: '994', value: 'az' },
  { name: 'Bahamas', phone: '1-242', value: 'bs' },
  { name: 'Bahrain', phone: '973', value: 'bh' },
  { name: 'Bangladesh', phone: '880', value: 'bd' },
  { name: 'Barbados', phone: '1-246', value: 'bb' },
  { name: 'Belarus', phone: '375', value: 'by' },
  { name: 'Belgium', phone: '32', value: 'be' },
  { name: 'Belize', phone: '501', value: 'bz' },
  { name: 'Benin', phone: '229', value: 'bj' },
  { name: 'Bermuda', phone: '1-441', value: 'bm' },
  { name: 'Bhutan', phone: '975', value: 'bt' },
  { name: 'Bolivia', phone: '591', value: 'bo' },
  { name: 'Bosnia and Herzegovina', phone: '387', value: 'ba' },
  { name: 'Botswana', phone: '267', value: 'bw' },
  { name: 'Brazil', phone: '55', value: 'br' },
  { name: 'British Indian Ocean Territory', phone: '246', value: 'io' },
  { name: 'British Virgin Islands', phone: '1-284', value: 'vg' },
  { name: 'Brunei', phone: '673', value: 'bn' },
  { name: 'Bulgaria', phone: '359', value: 'bg' },
  { name: 'Burkina Faso', phone: '226', value: 'bf' },
  { name: 'Burundi', phone: '257', value: 'bi' },
  { name: 'Cambodia', phone: '855', value: 'kh' },
  { name: 'Cameroon', phone: '237', value: 'cm' },
  { name: 'Canada', phone: '1', value: 'ca' },
  { name: 'Cape Verde', phone: '238', value: 'cv' },
  { name: 'Cayman Islands', phone: '1-345', value: 'ky' },
  { name: 'Central African Republic', phone: '236', value: 'cf' },
  { name: 'Chad', phone: '235', value: 'td' },
  { name: 'Chile', phone: '56', value: 'cl' },
  { name: 'China', phone: '86', value: 'cn' },
  { name: 'Christmas Island', phone: '61', value: 'cx' },
  { name: 'Cocos Islands', phone: '61', value: 'cc' },
  { name: 'Colombia', phone: '57', value: 'co' },
  { name: 'Comoros', phone: '269', value: 'km' },
  { name: 'Cook Islands', phone: '682', value: 'ck' },
  { name: 'Costa Rica', phone: '506', value: 'cr' },
  { name: 'Croatia', phone: '385', value: 'hr' },
  { name: 'Cuba', phone: '53', value: 'cu' },
  { name: 'Curacao', phone: '599', value: 'cw' },
  { name: 'Cyprus', phone: '357', value: 'cy' },
  { name: 'Czech Republic', phone: '420', value: 'cz' },
  { name: 'Democratic Republic of the Congo', phone: '243', value: 'cd' },
  { name: 'Denmark', phone: '45', value: 'dk' },
  { name: 'Djibouti', phone: '253', value: 'dj' },
  { name: 'Dominica', phone: '1-767', value: 'dm' },
  { name: 'Dominican Republic', phone: '1-809', value: 'do' },
  { name: 'East Timor', phone: '670', value: 'tl' },
  { name: 'Ecuador', phone: '593', value: 'ec' },
  { name: 'Egypt', phone: '20', value: 'eg' },
  { name: 'El Salvador', phone: '503', value: 'sv' },
  { name: 'Equatorial Guinea', phone: '240', value: 'gq' },
  { name: 'Eritrea', phone: '291', value: 'er' },
  { name: 'Estonia', phone: '372', value: 'ee' },
  { name: 'Ethiopia', phone: '251', value: 'et' },
  { name: 'Falkland Islands', phone: '500', value: 'fk' },
  { name: 'Faroe Islands', phone: '298', value: 'fo' },
  { name: 'Fiji', phone: '679', value: 'fj' },
  { name: 'Finland', phone: '358', value: 'fi' },
  { name: 'France', phone: '33', value: 'fr' },
  { name: 'French Polynesia', phone: '689', value: 'pf' },
  { name: 'Gabon', phone: '241', value: 'ga' },
  { name: 'Gambia', phone: '220', value: 'gm' },
  { name: 'Georgia', phone: '995', value: 'ge' },
  { name: 'Germany', phone: '49', value: 'de' },
  { name: 'Ghana', phone: '233', value: 'gh' },
  { name: 'Gibraltar', phone: '350', value: 'gi' },
  { name: 'Greece', phone: '30', value: 'gr' },
  { name: 'Greenland', phone: '299', value: 'gl' },
  { name: 'Grenada', phone: '1-473', value: 'gd' },
  { name: 'Guam', phone: '1-671', value: 'gu' },
  { name: 'Guatemala', phone: '502', value: 'gt' },
  { name: 'Guernsey', phone: '44-1481', value: 'gg' },
  { name: 'Guinea', phone: '224', value: 'gn' },
  { name: 'Guinea-Bissau', phone: '245', value: 'gw' },
  { name: 'Guyana', phone: '592', value: 'gy' },
  { name: 'Haiti', phone: '509', value: 'ht' },
  { name: 'Honduras', phone: '504', value: 'hn' },
  { name: 'Hong Kong', phone: '852', value: 'hk' },
  { name: 'Hungary', phone: '36', value: 'hu' },
  { name: 'Iceland', phone: '354', value: 'is' },
  { name: 'India', phone: '91', value: 'in' },
  { name: 'Indonesia', phone: '62', value: 'id' },
  { name: 'Iran', phone: '98', value: 'ir' },
  { name: 'Iraq', phone: '964', value: 'iq' },
  { name: 'Ireland', phone: '353', value: 'ie' },
  { name: 'Isle of Man', phone: '44-1624', value: 'im' },
  { name: 'Israel', phone: '972', value: 'il' },
  { name: 'Italy', phone: '39', value: 'it' },
  { name: 'Ivory Coast', phone: '225', value: 'ci' },
  { name: 'Jamaica', phone: '1-876', value: 'jm' },
  { name: 'Japan', phone: '81', value: 'jp' },
  { name: 'Jersey', phone: '44-1534', value: 'je' },
  { name: 'Jordan', phone: '962', value: 'jo' },
  { name: 'Kazakhstan', phone: '7', value: 'kz' },
  { name: 'Kenya', phone: '254', value: 'ke' },
  { name: 'Kiribati', phone: '686', value: 'ki' },
  { name: 'Kosovo', phone: '383', value: 'xk' },
  { name: 'Kuwait', phone: '965', value: 'kw' },
  { name: 'Kyrgyzstan', phone: '996', value: 'kg' },
  { name: 'Laos', phone: '856', value: 'la' },
  { name: 'Latvia', phone: '371', value: 'lv' },
  { name: 'Lebanon', phone: '961', value: 'lb' },
  { name: 'Lesotho', phone: '266', value: 'ls' },
  { name: 'Liberia', phone: '231', value: 'lr' },
  { name: 'Libya', phone: '218', value: 'ly' },
  { name: 'Liechtenstein', phone: '423', value: 'li' },
  { name: 'Lithuania', phone: '370', value: 'lt' },
  { name: 'Luxembourg', phone: '352', value: 'lu' },
  { name: 'Macau', phone: '853', value: 'mo' },
  { name: 'Macedonia', phone: '389', value: 'mk' },
  { name: 'Madagascar', phone: '261', value: 'mg' },
  { name: 'Malawi', phone: '265', value: 'mw' },
  { name: 'Malaysia', phone: '60', value: 'my' },
  { name: 'Maldives', phone: '960', value: 'mv' },
  { name: 'Mali', phone: '223', value: 'ml' },
  { name: 'Malta', phone: '356', value: 'mt' },
  { name: 'Marshall Islands', phone: '692', value: 'mh' },
  { name: 'Mauritania', phone: '222', value: 'mr' },
  { name: 'Mauritius', phone: '230', value: 'mu' },
  { name: 'Mayotte', phone: '262', value: 'yt' },
  { name: 'Mexico', phone: '52', value: 'mx' },
  { name: 'Micronesia', phone: '691', value: 'fm' },
  { name: 'Moldova', phone: '373', value: 'md' },
  { name: 'Monaco', phone: '377', value: 'mc' },
  { name: 'Mongolia', phone: '976', value: 'mn' },
  { name: 'Montenegro', phone: '382', value: 'me' },
  { name: 'Montserrat', phone: '1-664', value: 'ms' },
  { name: 'Morocco', phone: '212', value: 'ma' },
  { name: 'Mozambique', phone: '258', value: 'mz' },
  { name: 'Myanmar', phone: '95', value: 'mm' },
  { name: 'Namibia', phone: '264', value: 'na' },
  { name: 'Nauru', phone: '674', value: 'nr' },
  { name: 'Nepal', phone: '977', value: 'np' },
  { name: 'Netherlands', phone: '31', value: 'nl' },
  { name: 'Netherlands Antilles', phone: '599', value: 'an' },
  { name: 'New Caledonia', phone: '687', value: 'nc' },
  { name: 'New Zealand', phone: '64', value: 'nz' },
  { name: 'Nicaragua', phone: '505', value: 'ni' },
  { name: 'Niger', phone: '227', value: 'ne' },
  { name: 'Nigeria', phone: '234', value: 'ng' },
  { name: 'Niue', phone: '683', value: 'nu' },
  { name: 'North Korea', phone: '850', value: 'kp' },
  { name: 'Northern Mariana Islands', phone: '1-670', value: 'mp' },
  { name: 'Norway', phone: '47', value: 'no' },
  { name: 'Oman', phone: '968', value: 'om' },
  { name: 'Pakistan', phone: '92', value: 'pk' },
  { name: 'Palau', phone: '680', value: 'pw' },
  { name: 'Palestine', phone: '970', value: 'ps' },
  { name: 'Panama', phone: '507', value: 'pa' },
  { name: 'Papua New Guinea', phone: '675', value: 'pg' },
  { name: 'Paraguay', phone: '595', value: 'py' },
  { name: 'Peru', phone: '51', value: 'pe' },
  { name: 'Philippines', phone: '63', value: 'ph' },
  { name: 'Pitcairn', phone: '64', value: 'pn' },
  { name: 'Poland', phone: '48', value: 'pl' },
  { name: 'Portugal', phone: '351', value: 'pt' },
  { name: 'Puerto Rico', phone: '1-787, 1-939', value: 'pr' },
  { name: 'Qatar', phone: '974', value: 'qa' },
  { name: 'Republic of the Congo', phone: '242', value: 'cg' },
  { name: 'Reunion', phone: '262', value: 're' },
  { name: 'Romania', phone: '40', value: 'ro' },
  { name: 'Russia', phone: '7', value: 'ru' },
  { name: 'Rwanda', phone: '250', value: 'rw' },
  { name: 'Saint Barthelemy', phone: '590', value: 'bl' },
  { name: 'Saint Helena', phone: '290', value: 'sh' },
  { name: 'Saint Kitts and Nevis', phone: '1-869', value: 'kn' },
  { name: 'Saint Lucia', phone: '1-758', value: 'lc' },
  { name: 'Saint Martin', phone: '590', value: 'mf' },
  { name: 'Saint Pierre and Miquelon', phone: '508', value: 'sp' },
  { name: 'Saint Vincent and the Grenadines', phone: '1-784', value: 'sv' },
  { name: 'Samoa', phone: '685', value: 'sm' },
  { name: 'San Marino', phone: '378', value: 'sm' },
  { name: 'Sao Tome and Principe', phone: '239', value: 'st' },
  { name: 'Saudi Arabia', phone: '966', value: 'sa' },
  { name: 'Senegal', phone: '221', value: 'sn' },
  { name: 'Serbia', phone: '381', value: 'sr' },
  { name: 'Seychelles', phone: '248', value: 'sc' },
  { name: 'Sierra Leone', phone: '232', value: 'sl' },
  { name: 'Singapore', phone: '65', value: 'sg' },
  { name: 'Sint Maarten', phone: '1-721', value: 'sm' },
  { name: 'Slovakia', phone: '421', value: 'sk' },
  { name: 'Slovenia', phone: '386', value: 'si' },
  { name: 'Solomon Islands', phone: '677', value: 'si' },
  { name: 'Somalia', phone: '252', value: 'so' },
  { name: 'South Africa', phone: '27', value: 'sa' },
  { name: 'South Korea', phone: '82', value: 'sk' },
  { name: 'South Sudan', phone: '211', value: 'ss' },
  { name: 'Spain', phone: '34', value: 'sp' },
  { name: 'Sri Lanka', phone: '94', value: 'sl' },
  { name: 'Sudan', phone: '249', value: 'sd' },
  { name: 'Suriname', phone: '597', value: 'sr' },
  { name: 'Svalbard and Jan Mayen', phone: '47', value: 'sj' },
  { name: 'Swaziland', phone: '268', value: 'sz' },
  { name: 'Sweden', phone: '46', value: 'sw' },
  { name: 'Switzerland', phone: '41', value: 'sw' },
  { name: 'Syria', phone: '963', value: 'sy' },
  { name: 'Taiwan', phone: '886', value: 'tw' },
  { name: 'Tajikistan', phone: '992', value: 'tj' },
  { name: 'Tanzania', phone: '255', value: 'tz' },
  { name: 'Thailand', phone: '66', value: 'th' },
  { name: 'Togo', phone: '228', value: 'tg' },
  { name: 'Tokelau', phone: '690', value: 'tk' },
  { name: 'Tonga', phone: '676', value: 'to' },
  { name: 'Trinidad and Tobago', phone: '1-868', value: 'tt' },
  { name: 'Tunisia', phone: '216', value: 'tn' },
  { name: 'Turkey', phone: '90', value: 'tr' },
  { name: 'Turkmenistan', phone: '993', value: 'tm' },
  { name: 'Turks and Caicos Islands', phone: '1-649', value: 'tc' },
  { name: 'Tuvalu', phone: '688', value: 'tv' },
  { name: 'U.S. Virgin Islands', phone: '1-340', value: 'vi' },
  { name: 'Uganda', phone: '256', value: 'ug' },
  { name: 'Ukraine', phone: '380', value: 'ua' },
  { name: 'United Arab Emirates', phone: '971', value: 'ae' },
  { name: 'United Kingdom', phone: '44', value: 'uk' },
  { name: 'United States', phone: '1', value: 'us' },
  { name: 'Uruguay', phone: '598', value: 'uy' },
  { name: 'Uzbekistan', phone: '998', value: 'uz' },
  { name: 'Vanuatu', phone: '678', value: 'vu' },
  { name: 'Vatican', phone: '379', value: 'va' },
  { name: 'Venezuela', phone: '58', value: 've' },
  { name: 'Vietnam', phone: '84', value: 'vn' },
  { name: 'Wallis and Futuna', phone: '681', value: 'wf' },
  { name: 'Western Sahara', phone: '212', value: 'eh' },
  { name: 'Yemen', phone: '967', value: 'ye' },
  { name: 'Zambia', phone: '260', value: 'zm' },
  { name: 'Zimbabwe', phone: '263', value: 'zw' },
];

export const useGetCountryFromQueryParams = () => {
  const [queryParams] = useSearchParams();
  const countryCode = queryParams.get('country');

  const country = countriesAndCode.find((a) => a.value === countryCode);

  return country;
};
