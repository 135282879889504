import { defineMessages } from 'react-intl';

import { useMessages } from '@mobble/i18n';
import { EntitiesFilterItem } from '@mobble/models/src/model/BaseEntity';
import {
  eventTypes,
  eventTypeToColor,
  type HistoricEvent,
} from '@mobble/models/src/model/HistoricEvent';

const messages = defineMessages({
  eventType: {
    defaultMessage: 'Event type',
    description: 'historic_events.filter.group.event_type.label',
  },
  eventTypeCasualtyAddition: {
    defaultMessage: 'Casualty Addition',
    description: 'historic_events.event.event_type.CasualtyAddition',
  },
  eventTypeCasualtyDeletion: {
    defaultMessage: 'Casualty Deletion',
    description: 'historic_events.event.event_type.CasualtyDeletion',
  },
  eventTypeCasualtyEdit: {
    defaultMessage: 'Casualty Edit',
    description: 'historic_events.event.event_type.CasualtyEdit',
  },
  eventTypeChemicalAddition: {
    defaultMessage: 'Product Addition',
    description: 'historic_events.event.event_type.ChemicalAddition',
  },
  eventTypeChemicalBatchAddition: {
    defaultMessage: 'Product Batch Addition',
    description: 'historic_events.event.event_type.ChemicalBatchAddition',
  },
  eventTypeChemicalDeletion: {
    defaultMessage: 'Product Deletion',
    description: 'historic_events.event.event_type.ChemicalDeletion',
  },
  eventTypeChemicalEdit: {
    defaultMessage: 'Product Edit',
    description: 'historic_events.event.event_type.ChemicalEdit',
  },
  eventTypeChemicalBatchEdit: {
    defaultMessage: 'Product Batch Edit',
    description: 'historic_events.event.event_type.ChemicalBatchEdit',
  },
  eventTypeFeedAddition: {
    defaultMessage: 'Product Addition',
    description: 'historic_events.event.event_type.FeedAddition',
  },
  eventTypeFeedDeletion: {
    defaultMessage: 'Product Deletion',
    description: 'historic_events.event.event_type.FeedDeletion',
  },
  eventTypeFeedEdit: {
    defaultMessage: 'Product Edit',
    description: 'historic_events.event.event_type.FeedEdit',
  },
  eventTypeFeedFinished: {
    defaultMessage: 'Product Finished',
    description: 'historic_events.event.event_type.FeedFinished',
  },
  eventTypeMapAssetAddition: {
    defaultMessage: 'Map Asset Addition',
    description: 'historic_events.event.event_type.MapAssetAddition',
  },
  eventTypeMapAssetEdit: {
    defaultMessage: 'Map Asset Edit',
    description: 'historic_events.event.event_type.MapAssetEdit',
  },
  eventTypeMobActionAddition: {
    defaultMessage: 'Mob Action Addition',
    description: 'historic_events.event.event_type.MobActionAddition',
  },
  eventTypeMobActionDeletion: {
    defaultMessage: 'Mob Action Deletion',
    description: 'historic_events.event.event_type.MobActionDeletion',
  },
  eventTypeMobActionEdit: {
    defaultMessage: 'Mob Action Edit',
    description: 'historic_events.event.event_type.MobActionEdit',
  },
  eventTypeMobAddition: {
    defaultMessage: 'Mob Addition',
    description: 'historic_events.event.event_type.MobAddition',
  },
  eventTypeMobArchiving: {
    defaultMessage: 'Mob Archiving',
    description: 'historic_events.event.event_type.MobArchiving',
  },
  eventTypeMobDeletion: {
    defaultMessage: 'Mob Deletion',
    description: 'historic_events.event.event_type.MobDeletion',
  },
  eventTypeMobEdit: {
    defaultMessage: 'Mob Edit',
    description: 'historic_events.event.event_type.MobEdit',
  },
  eventTypeMobMerge: {
    defaultMessage: 'Mob Merge',
    description: 'historic_events.event.event_type.MobMerge',
  },
  eventTypeMobMove: {
    defaultMessage: 'Mob Move',
    description: 'historic_events.event.event_type.MobMove',
  },
  eventTypeMobMoveToProp: {
    defaultMessage: 'Mob Move To Property',
    description: 'historic_events.event.event_type.MobMoveToProp',
  },
  eventTypeMobSplit: {
    defaultMessage: 'Mob Split',
    description: 'historic_events.event.event_type.MobSplit',
  },
  eventTypeNaturalIncreaseAddition: {
    defaultMessage: 'Natural Increase Addition',
    description: 'historic_events.event.event_type.NaturalIncreaseAddition',
  },
  eventTypePaddockActionAddition: {
    defaultMessage: 'Paddock Action Addition',
    description: 'historic_events.event.event_type.PaddockActionAddition',
  },
  eventTypePaddockActionDeletion: {
    defaultMessage: 'Paddock Action Deletion',
    description: 'historic_events.event.event_type.PaddockActionDeletion',
  },
  eventTypePaddockActionEdit: {
    defaultMessage: 'Paddock Action Edit',
    description: 'historic_events.event.event_type.PaddockActionEdit',
  },
  eventTypePaddockAddition: {
    defaultMessage: 'Paddock Addition',
    description: 'historic_events.event.event_type.PaddockAddition',
  },
  eventTypePaddockDeletion: {
    defaultMessage: 'Paddock Deletion',
    description: 'historic_events.event.event_type.PaddockDeletion',
  },
  eventTypePaddockEdit: {
    defaultMessage: 'Paddock Edit',
    description: 'historic_events.event.event_type.PaddockEdit',
  },
  eventTypePaddockOpenGroupCreate: {
    defaultMessage: 'Open Gates',
    description: 'historic_events.event.event_type.PaddockOpenGroupCreate',
  },
  eventTypePropertyAddition: {
    defaultMessage: 'Property Addition',
    description: 'historic_events.event.event_type.PropertyAddition',
  },
  eventTypePurchaseAddition: {
    defaultMessage: 'Purchase Addition',
    description: 'historic_events.event.event_type.PurchaseAddition',
  },
  eventTypeRainGaugeAddition: {
    defaultMessage: 'Rain Gauge Addition',
    description: 'historic_events.event.event_type.RainGaugeAddition',
  },
  eventTypeRainGaugeDeletion: {
    defaultMessage: 'Rain Gauge Deletion',
    description: 'historic_events.event.event_type.RainGaugeDeletion',
  },
  eventTypeRainGaugeEdit: {
    defaultMessage: 'Rain Gauge Edit',
    description: 'historic_events.event.event_type.RainGaugeEdit',
  },
  eventTypeRainGaugeReadingAddition: {
    defaultMessage: 'Rain Gauge Reading Addition',
    description: 'historic_events.event.event_type.RainGaugeReadingAddition',
  },
  eventTypeRainGaugeReadingDeletion: {
    defaultMessage: 'Rain Gauge Reading Deletion',
    description: 'historic_events.event.event_type.RainGaugeReadingDeletion',
  },
  eventTypeRainGaugeReadingEdit: {
    defaultMessage: 'Rain Gauge Reading Edit',
    description: 'historic_events.event.event_type.RainGaugeReadingEdit',
  },
  eventTypeSaleAddition: {
    defaultMessage: 'Sale Addition',
    description: 'historic_events.event.event_type.SaleAddition',
  },
  eventTypeSaleDeletion: {
    defaultMessage: 'Sale Deletion',
    description: 'historic_events.event.event_type.SaleDeletion',
  },
  eventTypeSaleEdit: {
    defaultMessage: 'Sale Edit',
    description: 'historic_events.event.event_type.SaleEdit',
  },
  eventTypeTaskAddition: {
    defaultMessage: 'Task Addition',
    description: 'historic_events.event.event_type.TaskAddition',
  },
  eventTypeTaskDeletion: {
    defaultMessage: 'Task Deletion',
    description: 'historic_events.event.event_type.TaskDeletion',
  },
  eventTypeTaskEdit: {
    defaultMessage: 'Task Edit',
    description: 'historic_events.event.event_type.TaskEdit',
  },
  eventTypeFeedBatchAddition: {
    defaultMessage: 'Product Batch Addition',
    description: 'historic_events.event.event_type.FeedBatchAddition',
  },
  eventTypeFeedBatchEdit: {
    defaultMessage: 'Product Batch Edit',
    description: 'historic_events.event.event_type.FeedBatchEdit',
  },
});

export const useHistoricEventsFilterItems = () => {
  const strings = useMessages(messages);

  return (): EntitiesFilterItem<HistoricEvent>[] => [
    {
      type: 'select-multiple',
      group: 'event_type',
      title: strings.eventType,
      data: eventTypes.map((value) => ({
        value,
        label: strings[`eventType${value}`],
        color: eventTypeToColor(value),
      })),
    },
  ];
};
