import React from 'react';

import { MapEvent } from '@mobble/models/src/model/Map';
import {
  type Point,
  pointsToDistance,
  type Position,
} from '@mobble/models/src/model/MapGeometry';
import { type MapStyle } from '@mobble/models/src/model/MapStyle';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { MODAL_MOB_MOVE } from '@src/screens/config/routeNames';
import { Icon, IconName } from '@src/stories/Components/UI/Icon';
import { Marker } from '@src/stories/Mapbox/Marker';

import { type MapItemMobs } from './MapItemType';

import styles from './mob.scss';

export interface MobsProps {
  mapStyle: MapStyle;
  item: MapItemMobs;
  onEvent?: (event: MapEvent) => void;
}

export const getMobIcon = (mobTypes: string[]): IconName => {
  if (mobTypes.length === 0) {
    return 'animal-mixed';
  }

  if (mobTypes.length > 1) {
    return 'animal-mixed';
  }

  switch (mobTypes[0]) {
    case 'Sheep':
      return 'animal-sheep';
    case 'Cattle':
      return 'animal-cattle';
    case 'Goats':
      return 'animal-goat';
    default:
      return 'animal-mixed';
  }
};

export const Mobs: React.FC<MobsProps> = ({ item, onEvent }) => {
  // @TODO move this to a higher order component
  const { hasAccessToRoute } = useAccessHelper();
  const canMoveMobs = hasAccessToRoute(MODAL_MOB_MOVE);

  const icon = getMobIcon(item.meta.mobTypes);

  const coordinate = React.useRef<null | Position>(null);

  if (!item.visible) {
    return null;
  }

  const onDrag = (_, point: Point) => {
    coordinate.current = point.coordinates as Position;
  };

  const onDragEnd = (_, point: Point) => {
    const distance = pointsToDistance([item.point, point], 'm');
    if (distance.value > 10) {
      onEvent &&
        onEvent({
          type: 'movement',
          final: true,
          id: item.id,
          a: item.point,
          b: point,
        });
    } else {
      item.onClick && item.onClick();
    }
    coordinate.current = null;
  };

  const dragProps = canMoveMobs
    ? {
        draggable: true,
        resetOnDragEnd: true,
        onDrag,
        onDragEnd,
      }
    : {};

  const ageColors = [...item.meta.ageColors].slice(0, 4);

  const getXYForItemOnCirclePerimeter = (index: number) => {
    const distance = ageColors.length > 3 ? 14 : 12;

    const radius = 7;
    const xCenter = 10;
    const yCenter = 10;

    const angle = (340 - (360 / distance) * index - 90) * (Math.PI / 180);

    const x = radius * Math.cos(angle) + xCenter;
    const y = radius * Math.sin(angle) + yCenter;

    return { x, y };
  };

  const onClick = item.onClick
    ? (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        item.onClick();
        return false;
      }
    : undefined;

  return (
    <Marker
      id={`mob-${item.id}`}
      data={item.point}
      options={{
        anchor: 'bottom',
        offset: [0, -5],
      }}
      {...dragProps}
    >
      <div className={styles.mobMarkerContainer} onClick={onClick}>
        <svg width="60.418" height="68.608" viewBox="0 0 20.875 22.98">
          <path
            d="M10.557 1.246c-2.69.03-5.349 1.286-6.999 3.424-2.034 2.503-2.522 6.151-1.212 9.101.333.801.79 1.545 1.344 2.21-.002.795.104 1.57.818 2.06.694.446 1.512.394 2.322.355.116.054.233.106.352.155l3.416 4.466c1.18-1.49 2.278-3.08 3.506-4.512a8.2 8.2 0 0 0 .252-.109 82.558 82.558 0 0 0 1.607-.033c1.105-.197 1.675-1.354 1.547-2.398 1.231-1.493 1.994-3.374 2.054-5.32.298-4.076-2.637-8.07-6.587-9.077a8.832 8.832 0 0 0-2.419-.322z"
            fill="#FFFFFF"
          />

          <path
            d="M10.598 1.773a8.467 8.467 0 0 0-8.467 8.467 8.467 8.467 0 0 0 5.395 7.888l3.072 4.018 3.072-4.018a8.467 8.467 0 0 0 5.394-7.888 8.467 8.467 0 0 0-8.466-8.467z"
            fill="#5E35B1"
          />

          <circle cx="10.601" cy="10.287" r="7.541" fill="#FFFFFF" />

          {!item.meta?.isSafe ? (
            <circle cx={16} cy={3} r={2.5} fill="#FF0000" />
          ) : null}

          {ageColors.map((color, index) => {
            const { x, y } = getXYForItemOnCirclePerimeter(index);
            return (
              <g key={index}>
                <circle cx={x} cy={y} r={2} fill={'#FFFFFF'} />
                <circle cx={x} cy={y} r={1.5} fill={'#5E35B1'} />
                <circle cx={x} cy={y} r={1} fill={color} />
              </g>
            );
          })}

          {ageColors.length > 4
            ? (() => {
                return (
                  <path
                    x={0.3}
                    y={-1}
                    d="M 1.9440999,11.735206 H 2.600126 V 11.01284 h 0.3980383 v 0.722366 h 0.6560261 v 0.375925 H 2.9981643 v 0.722366 H 2.600126 V 12.111131 H 1.9440999 Z"
                    fill="#000"
                  />
                );
              })()
            : null}

          <rect
            width="12.736"
            height="5.158"
            x="4.234"
            y="12.738"
            fill="#5e35b1"
            ry="1.391"
          />
          <path
            d="M5.453 13.246c-.986.248-.625 1.44-.69 2.176-.012.682-.168 1.805.788 1.895 3.4.01 6.802.03 10.2-.01.984-.25.625-1.436.688-2.173.013-.682.168-1.808-.789-1.897-3.397-.009-6.8-.03-10.197.01z"
            fill="#FFFFFF"
          />
        </svg>
        <div className={styles.mobMarkerMobIconContainer}>
          <Icon className={styles.mobMarkerMobIcon} name={icon} size="normal" />
        </div>
        <div className={styles.mobMarkerMobCount}>
          {/* <Text variant="tiny" bold align="center" color={Color.Black}>
            {item.meta.size}
          </Text> */}
          {item.meta.size}
        </div>
      </div>
    </Marker>
  );
};
