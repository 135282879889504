import React, { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useLocation } from 'react-router';

import { useMessages } from '@mobble/i18n';
import { getUserActionsForScenario } from '@mobble/models/src/model/Intentions/Scenarios';
import { metaForUserActionType } from '@mobble/models/src/model/Intentions/UserActions';

import { useAccessHelper } from '@src/hooks/useAccessHelper';
import { useActiveScreens } from '@src/hooks/useActiveScreens';
import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import { Box } from '@src/stories/Components/Layout/Box';
import { Responsive } from '@src/stories/Components/Layout/Responsive';
import { type IconName } from '@src/stories/Components/UI/Icon';
import { ActionButton } from '@src/stories/Components/UX/ActionButton';
import {
  Actions,
  type ActionsOption,
} from '@src/stories/Components/UX/Actions';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { BottomMenuTabs } from '@src/stories/Views/Misc/BottomMenuTabs';

import {
  ACTION_BUTTON_ENABLED_FOR_USER_ROLES,
  MENU_ITEMS_FOR_ROLE,
} from '../config/appBottomTabBar';
import * as ROUTE_NAME from '../config/routeNames';
import { routeToScenario } from '../config/routeToScenario';
import { type RouteName } from '../config/types';
import { userActionToRoute } from '../config/userActionToRoute';

import styles from './appBottomTabBar.scss';

const NEVER_SHOW_ON_ROUTES = [ROUTE_NAME.CHATS_LIST];

const messages = defineMessages({
  'inventory_items-overview': {
    defaultMessage: 'Inventory items',
    description: 'scenario_type.inventory_items-overview.title',
  },
  'inventory_item-detail': {
    defaultMessage: 'Inventory Item',
    description: 'scenario_type.inventory_item-detail.title',
  },
  'map_assets-overview': {
    defaultMessage: 'Map assets',
    description: 'scenario_type.map_assets-overview.title',
  },
  'map-overview': {
    defaultMessage: 'Map',
    description: 'scenario_type.map-overview.title',
  },
  'mob-detail': {
    defaultMessage: 'Mob',
    description: 'scenario_type.mob-detail.title',
  },
  'mobs-overview': {
    defaultMessage: 'Mobs',
    description: 'scenario_type.mobs-overview.title',
  },
  'paddock-detail': {
    defaultMessage: 'Paddock',
    description: 'scenario_type.paddock-detail.title',
  },
  'paddocks-overview': {
    defaultMessage: 'Paddocks',
    description: 'scenario_type.paddocks-overview.title',
  },
  'rain_gauge-detail': {
    defaultMessage: 'Rain gauge',
    description: 'scenario_type.rain_gauge-detail.title',
  },
  'rain_gauges-overview': {
    defaultMessage: 'Rain gauges',
    description: 'scenario_type.rain_gauges-overview.title',
  },
  'settings-property-breeds': {
    defaultMessage: 'Breeds',
    description: 'scenario_type.settings-property-breeds.title',
  },
  'settings-property-classes': {
    defaultMessage: 'Classes',
    description: 'scenario_type.settings-property-classes.title',
  },
  'settings-property-genders': {
    defaultMessage: 'Genders',
    description: 'scenario_type.settings-property-genders.title',
  },
  'settings-property-mob_actions': {
    defaultMessage: 'Mob actions',
    description: 'scenario_type.settings-property-mob_actions.title',
  },
  'settings-property-paddock_actions': {
    defaultMessage: 'Paddock actions',
    description: 'scenario_type.settings-property-paddock_actions.title',
  },
  'settings-property-paddock_types': {
    defaultMessage: 'Paddock types',
    description: 'scenario_type.settings-property-paddock_types.title',
  },
  'settings-property-tags': {
    defaultMessage: 'Tags',
    description: 'scenario_type.settings-property-tags.title',
  },
  'settings-property-users-overview': {
    defaultMessage: 'Users',
    description: 'scenario_type.settings-property-users-overview.title',
  },
  'paddock_actions-overview': {
    defaultMessage: 'Paddock actions',
    description: 'scenario_type.paddock_actions-overview.title',
  },
  'mob_actions-overview': {
    defaultMessage: 'Mob actions',
    description: 'scenario_type.mob_actions-overview.title',
  },
  'tasks-overview': {
    defaultMessage: 'Tasks',
    description: 'scenario_type.tasks-overview.title',
  },
});

export const AppBottomTabBar: React.FC = () => {
  const { isActive, activeScreen, activeRouteParams } = useActiveScreens();
  const strings = useMessages(messages);
  const linkTo = useLinking();
  const location = useLocation();
  const { role, hasAccessToRoute } = useAccessHelper();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const menuItemsForRole =
    MENU_ITEMS_FOR_ROLE[role] ?? MENU_ITEMS_FOR_ROLE.default;
  const showActionButton = ACTION_BUTTON_ENABLED_FOR_USER_ROLES.includes(role);

  const items = menuItemsForRole.map((item) => ({
    label: item.label,
    icon: item.icon,
    routeName: item.route,
    isActive: isActive(...item.activeOnRoutes),
  }));

  const scenario = routeToScenario(activeScreen, activeRouteParams);
  const userActions = scenario ? getUserActionsForScenario(scenario) : [];
  const actions: ActionsOption[] = userActions.map((ua) => {
    const meta = metaForUserActionType(ua);
    const { route, params, state } = userActionToRoute(ua, activeRouteParams);
    return {
      icon: meta.icon as IconName,
      label: { key: meta.titleKey },
      description: { key: meta.descriptionKey },
      disabled: !hasAccessToRoute(route),
      href: toPath(route, params),
      state: state,
    };
  });

  const renderedActionButton = showActionButton && (
    <ActionButton
      isOpen={open}
      disabled={actions.length === 0}
      onClick={() => {
        setOpen(!open);
      }}
    />
  );

  const renderedActions = (
    <Actions
      options={actions}
      onClose={() => {
        setOpen(false);
      }}
    />
  );

  // Hide Action button on desktop when map overview form is active
  let actionButtonStyle = {};
  if (activeScreen === ROUTE_NAME.MAP_OVERVIEW && location.hash) {
    actionButtonStyle = { display: 'none' };
  }

  if (isActive(...NEVER_SHOW_ON_ROUTES) || !items) {
    return null;
  }

  return (
    <>
      <Responsive mobile>
        {() => (
          <>
            <BottomMenuTabs
              items={items}
              onNavigate={(path, params) => {
                linkTo(path as RouteName, params);
                setOpen(false);
              }}
              actionButton={renderedActionButton}
            />
            <ModalFlyUp
              title={scenario ? strings[scenario] : ''}
              isOpen={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              {renderedActions}
            </ModalFlyUp>
          </>
        )}
      </Responsive>
      <Responsive desktop>
        {actions.length
          ? () => (
              <Box className={styles.desktop} style={actionButtonStyle}>
                <Box className={styles.desktopActionButtonContainer}>
                  {renderedActionButton}
                </Box>
                <Box
                  className={[
                    styles.desktopActions,
                    open ? styles.desktopActionsOpen : null,
                  ]}
                >
                  {renderedActions}
                </Box>
              </Box>
            )
          : () => null}
      </Responsive>
    </>
  );
};
