import { type MapAsset } from '@mobble/models/src/model/MapAsset';
import mobbleService from '@mobble/service';
import { SortDirection } from '@mobble/models/src/model/Sort';
import { type FilterPerProperty } from '../lib/filter';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { SortPerProperty } from '../lib/sort';

export interface MapAssetsState {
  entities: MapAsset[];
  extStatus: ExtStatusPerActionPerParent;
  filter: FilterPerProperty;
  sort: SortPerProperty;
}

export const defaultState: MapAssetsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<MapAssetsState, MapAsset>({
  name: 'mapAssets',
  initialState: defaultState,
  reducers: {},
  toParentId: ({ propertyId }: MapAsset) => propertyId,
  defaultSort: [{ direction: SortDirection.Ascending, column: 'name' }],
  onGetAll: mobbleService.api.mapAssets.get,
  onFind: mobbleService.api.mapAssets.find,
  onCreate: mobbleService.api.mapAssets.create,
  onUpdate: mobbleService.api.mapAssets.update,
  onDelete: mobbleService.api.mapAssets.remove,
});

export const {
  thunkGetAll,
  thunkFind,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
