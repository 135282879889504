import { I18nContextProps } from '@mobble/i18n/src';
import { PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { formatDate, fromRawDate, RawDate } from '@mobble/shared/src/core/Date';
import { AreaUnits } from '@mobble/shared/src/core/Quantity';

import {
  appliedInventoryItemsToString,
  appliedInventoryItemsToTotalString,
} from '@src/helpers/appliedInventoryItemsToString';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { TableColumn } from '@src/stories/Components/Layout/Table';
import { Text } from '@src/stories/Components/UI/Text';
import { Clickable } from '@src/stories/Components/UX/Clickable';

import styles from './paddockActionsTableColumns.scss';

export const makePaddockActionsTableColumns = ({
  areaUnit,
  formatMessage,
}: {
  areaUnit: AreaUnits;
  formatMessage: I18nContextProps['formatMessage'];
}): TableColumn[] => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return [
    {
      key: 'type',
      label: formatMessage({
        defaultMessage: 'Type',
        description: 'paddock_actions.table.heading.column.type',
      }),
      toValue: (paddockActions) => paddockActions.type,
    },
    {
      key: 'date',
      label: formatMessage({
        defaultMessage: 'Date',
        description: 'paddock_actions.table.heading.column.date',
      }),
      totals: false,
      toValue: (paddockActions) =>
        paddockActions.date
          ? fromRawDate(paddockActions.date).toDate().getTime()
          : null,
      valueToString: (value) =>
        value ? formatDate(fromRawDate(value as RawDate)) : '',
    },
    {
      key: 'created_by',
      label: formatMessage({
        defaultMessage: 'Created By',
        description: 'paddock_actions.table.heading.column.created_by',
      }),
      toValue: (paddockActions) => paddockActions.createdBy.name,
    },
    {
      key: 'products',
      label: formatMessage({
        defaultMessage: 'Products',
        description: 'paddock_actions.table.heading.column.products',
      }),
      toValue: (paddockActions) =>
        appliedInventoryItemsToString(
          paddockActions.appliedInventoryItems,
          areaUnit,
          formatMessage
        ),
    },
    {
      key: 'applied_total',
      label: formatMessage({
        defaultMessage: 'Applied Total',
        description: 'paddock_actions.table.heading.column.applied_total',
      }),
      toValue: (paddockActions) =>
        appliedInventoryItemsToTotalString(
          paddockActions.appliedInventoryItems,
          formatMessage
        ),
    },
    {
      key: 'applied_to',
      label: formatMessage({
        defaultMessage: 'Applied To',
        description: 'paddock_actions.table.heading.column.applied_to',
      }),
      toValue: (paddockAction: PaddockAction) =>
        paddockAction.paddocks.map((paddock) => paddock.name).join(', '),
      render: (_, paddockAction) => {
        return (
          <div className={styles.appliedTo}>
            {paddockAction?.paddocks.map((paddock) => (
              <Clickable
                key={paddock.paddockId}
                onClick={handleClick}
                href={toPath(ROUTE_NAME.PADDOCK_DETAIL, {
                  paddockId: paddock.paddockId,
                })}
              >
                <Text underline variant="small">
                  {paddock.name}
                </Text>
              </Clickable>
            ))}
          </div>
        );
      },
    },
    {
      key: 'notes',
      label: formatMessage({
        defaultMessage: 'Notes',
        description: 'paddock_actions.table.heading.column.notes',
      }),
      totals: false,
      toValue: (paddockActions) => paddockActions.note,
    },
  ];
};
