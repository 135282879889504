import { SortSetting } from '@mobble/models';
import { type Property } from '@mobble/models/src/model/Property';

export type SortPerProperty = Record<Property['id'], SortSetting[]>;

export const reduce = (
  state: any,
  propertyId: Property['id'],
  sortSettings: SortSetting[]
): SortPerProperty => {
  state = JSON.parse(JSON.stringify(state ?? {}));

  if (typeof state === 'undefined') {
    state = {};
  }
  if (typeof state[propertyId] === 'undefined') {
    state[propertyId] = [];
  }

  return {
    ...state,
    [propertyId]: sortSettings,
  };
};
