import React from 'react';
import { defineMessages } from 'react-intl';

import { useI18n, useMessages } from '@mobble/i18n';
import { FilterItem } from '@mobble/models';
import {
  MAP_DETAILS_GROUPED,
  type MapDetail,
  type MapDetails,
} from '@mobble/models/src/model/MapDetail';
import { type MapStyle } from '@mobble/models/src/model/MapStyle';
import { useCustomMapLayersFilter } from '@mobble/shared/src/hooks/useCustomMapLayersFilterItems';
import { useMobsFilter } from '@mobble/shared/src/hooks/useMobsFilterItems';
import { useCustomMapLayers } from '@mobble/store/src/hooks';
import { useProperties } from '@mobble/store/src/hooks/properties';

import Button from '@src/components/Button';
import ListSelect, { ListSelectButton } from '@src/components/ListSelect';
import Text from '@src/components/Text';
import { useMapStyles } from '@src/hooks/useMapStyles';
import { MediaQuerySize, useMediaQuery } from '@src/hooks/useMediaQuery';
import {
  downloadKML,
  useGetGeoJSONFromGeometries,
} from '@src/screens/Settings/hooks/useKML';
import { Toggle } from '@src/stories/Components/UI/Toggle';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { EntitiesFilter } from '@src/stories/Views/Entities/EntitiesFilter';

import { SettingsMapStyleButton } from './SettingsMapStyleButton';

import styles from './settingsMapModal.scss';

const messages = defineMessages({
  'map.map_options_modal.title': {
    description: 'map.map_options_modal.title',
    defaultMessage: 'Map options',
  },
  'map.map_style.label': {
    description: 'map.map_style.label',
    defaultMessage: 'Map type',
  },
  'map.map_options_modal.paddocks.heading': {
    description: 'map.map_options_modal.paddocks.heading',
    defaultMessage: 'Paddocks',
  },
  'map.map_options_modal.paddocks.select.placeholder': {
    description: 'map.map_options_modal.paddocks.select.placeholder',
    defaultMessage: 'Select paddock details to display on map',
  },
  'map.map_options_modal.paddocks.toggle.label': {
    description: 'map.map_options_modal.paddocks.toggle.label',
    defaultMessage: 'Toggle visibility of paddock details',
  },
  'map.map_options_modal.mobs.heading': {
    description: 'map.map_options_modal.mobs.heading',
    defaultMessage: 'Mobs',
  },
  'map.map_options_modal.mobs.select.placeholder': {
    description: 'map.map_options_modal.mobs.select.placeholder',
    defaultMessage: 'Apply mob filters to map',
  },
  'map.map_options_modal.mobs.toggle.label': {
    description: 'map.map_options_modal.mobs.toggle.label',
    defaultMessage: 'Toggle visibility of mobs',
  },
  'map.map_options_modal.tasks.heading': {
    description: 'map.map_options_modal.tasks.heading',
    defaultMessage: 'Tasks',
  },
  'map.map_options_modal.tasks.select.placeholder': {
    description: 'map.map_options_modal.tasks.select.placeholder',
    defaultMessage: 'Select tasks to display on map',
  },
  'map.map_options_modal.tasks.toggle.label': {
    description: 'map.map_options_modal.tasks.toggle.label',
    defaultMessage: 'Toggle visibility of tasks',
  },
  'map.map_options_modal.map_assets.heading': {
    description: 'map.map_options_modal.map_assets.heading',
    defaultMessage: 'Map Assets',
  },
  'map.map_options_modal.map_assets.select.placeholder': {
    description: 'map.map_options_modal.map_assets.select.placeholder',
    defaultMessage: 'Select assets to display on map',
  },
  'map.map_options_modal.map_assets.toggle.label': {
    description: 'map.map_options_modal.map_assets.toggle.label',
    defaultMessage: 'Toggle visibility of map assets',
  },
  'map.map_options_modal.download.heading': {
    description: 'map.map_options_modal.download.heading',
    defaultMessage: 'Download',
  },
  'map.map_options_modal.download.button.label': {
    description: 'map.map_options_modal.download.button.label',
    defaultMessage: 'Download Property KML File',
  },

  'map_detail.map_assets__bore.label': {
    description: 'map_detail.map_assets__bore.label',
    defaultMessage: 'Bore',
  },
  'map_detail.map_assets__dam.label': {
    description: 'map_detail.map_assets__dam.label',
    defaultMessage: 'Dam',
  },
  'map_detail.map_assets__electric-fence-unit.label': {
    description: 'map_detail.map_assets__electric-fence-unit.label',
    defaultMessage: 'Electric fence unit',
  },
  'map_detail.map_assets__electric-fence.label': {
    description: 'map_detail.map_assets__electric-fence.label',
    defaultMessage: 'Electric fence',
  },
  'map_detail.map_assets__feeder.label': {
    description: 'map_detail.map_assets__feeder.label',
    defaultMessage: 'Feeder',
  },
  'map_detail.map_assets__gate.label': {
    description: 'map_detail.map_assets__gate.label',
    defaultMessage: 'Gate',
  },
  'map_detail.map_assets__hazard.label': {
    description: 'map_detail.map_assets__hazard.label',
    defaultMessage: 'Hazard',
  },
  'map_detail.map_assets__other-line.label': {
    description: 'map_detail.map_assets__other-line.label',
    defaultMessage: 'Other line',
  },
  'map_detail.map_assets__other-point.label': {
    description: 'map_detail.map_assets__other-point.label',
    defaultMessage: 'Other point',
  },
  'map_detail.map_assets__pipeline.label': {
    description: 'map_detail.map_assets__pipeline.label',
    defaultMessage: 'Pipeline',
  },
  'map_detail.map_assets__road.label': {
    description: 'map_detail.map_assets__road.label',
    defaultMessage: 'Road',
  },
  'map_detail.map_assets__shed.label': {
    description: 'map_detail.map_assets__shed.label',
    defaultMessage: 'Shed',
  },
  'map_detail.map_assets__silo.label': {
    description: 'map_detail.map_assets__silo.label',
    defaultMessage: 'Silo',
  },
  'map_detail.map_assets__tank.label': {
    description: 'map_detail.map_assets__tank.label',
    defaultMessage: 'Tank',
  },
  'map_detail.map_assets__water-trough.label': {
    description: 'map_detail.map_assets__water-trough.label',
    defaultMessage: 'Water trough',
  },
  'map_detail.mobs.label': {
    description: 'map_detail.mobs.label',
    defaultMessage: 'Mobs',
  },
  'map_detail.paddock_boundaries.label': {
    description: 'map_detail.paddock_boundaries.label',
    defaultMessage: 'Paddock boundaries',
  },
  'map_detail.paddock_groups.label': {
    description: 'map_detail.paddock_groups.label',
    defaultMessage: 'Open gates',
  },
  'map_detail.paddock_labels.label': {
    description: 'map_detail.paddock_labels.label',
    defaultMessage: 'Paddock names',
  },
  'map_detail.tasks__others.label': {
    description: 'map_detail.tasks__others.label',
    defaultMessage: 'Others',
  },
  'map_detail.tasks__user.label': {
    description: 'map_detail.tasks__user.label',
    defaultMessage: 'Yours',
  },
  'map.map_options_modal.custom_map_layers.heading': {
    description: 'map_detail.custom_map_layers.label',
    defaultMessage: 'Custom map layer',
  },
  'map_detail.custom_map_layers.label': {
    description: 'map_detail.custom_map_layers.label',
    defaultMessage: 'Custom map layer',
  },
  'map.map_options_modal.custom_map_layers.select.placeholder': {
    description: 'map.map_options_modal.custom_map_layers.select.placeholder',
    defaultMessage: 'Apply custom map layer filters to map',
  },
  'map.map_options_modal.custom_map_layers.toggle.label': {
    description: 'map.map_options_modal.custom_map_layers.toggle.label',
    defaultMessage: 'Toggle visibility of custom map layer',
  },
});

export interface SettingsMapModalProps {
  visible?: boolean;
  selectedMapDetails: MapDetail[];
  selectedMapStyle: MapStyle;
  selectedMobsFilter: FilterItem[];
  selectedCustomMapLayersFilter: FilterItem[];
  onChangeMapDetails: (mapDetails: MapDetails) => void;
  onChangeMapStyle: (mapStyle: MapStyle) => void;
  onChangeMobsFilter: (filter: FilterItem[]) => void;
  onChangeCustomMapLayersFilter: (filter: FilterItem[]) => void;
  onClose: () => void;
}

export const SettingsMapModal: React.FC<SettingsMapModalProps> = ({
  visible,
  selectedMapStyle,
  selectedMapDetails = [],
  selectedMobsFilter = [],
  selectedCustomMapLayersFilter = [],
  onChangeMapDetails,
  onChangeMapStyle,
  onChangeMobsFilter,
  onChangeCustomMapLayersFilter,
  onClose,
}) => {
  const strings = useMessages(messages);
  const { formatMessage } = useI18n();
  const size = useMediaQuery();
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const {
    mobs,
    mobsFilterItems,
    applyFilter,
    mobsFilter,
    filterIsOpen,
    setFilterIsOpen,
    handleClearFilter,
    handleSetFilter,
    handleToggleFilter,
  } = useMobsFilter({
    propertyId: propertyId,
    initialState: selectedMobsFilter,
    onChange: onChangeMobsFilter,
  });

  const customMapLayers = useCustomMapLayers(propertyId);
  const displayCustomMapLayersOption = Boolean(customMapLayers.entities.length);

  const {
    options: customMapLayersOptions,
    totalOptionsSelected: totalCustomMapLayersSelected,
    allSelected: allCustomMapLayersSelected,
    someSelected: someCustomMapLayersSelected,
    handleChange: handleChangeCustomMapLayersFilter,
    handleToggle: handleToggleCustomMapLayersFilter,
  } = useCustomMapLayersFilter({
    selectedCustomMapLayersFilter,
    customMapLayers: customMapLayers.entities,
    onChange: onChangeCustomMapLayersFilter,
  });

  const geoJsonObject = useGetGeoJSONFromGeometries(propertyId);
  const { mapStyleIds: mapStyles } = useMapStyles();
  const mobsDetailsItem = MAP_DETAILS_GROUPED.MOBS[0];
  const mobsVisible = selectedMapDetails.includes(mobsDetailsItem);
  const isLarge =
    size === MediaQuerySize.Large || size === MediaQuerySize.XLarge;

  const handleClose = () => {
    setFilterIsOpen(false);
    onClose();
  };

  const handleMobsToggleChange = () => {
    onChangeMapDetails(
      mobsVisible
        ? selectedMapDetails.filter((smp) => smp !== mobsDetailsItem)
        : [...selectedMapDetails, mobsDetailsItem]
    );
  };

  const makeSelectionComponent = ({
    key,
    mapDetails,
  }: {
    key: string;
    mapDetails: MapDetail[];
  }) => {
    const options = mapDetails.map((mapDetail) => ({
      value: mapDetail,
      label: strings[`map_detail.${mapDetail}.label`],
      selected: selectedMapDetails.includes(mapDetail),
    }));
    const totalOptionsSelected = options.filter(
      (option) => option.selected
    ).length;

    const allSelected = options.every((option) => option.selected);
    const someSelected = options.some((option) => option.selected);

    const handleChange = (ev: (string | number)[]) => {
      const selected = ev as MapDetail[];

      onChangeMapDetails([
        ...selectedMapDetails.filter((smp) => !mapDetails.includes(smp)),
        ...selected,
      ]);
    };

    const handleToggle = () => {
      const values = allSelected ? [] : mapDetails;

      onChangeMapDetails([
        ...selectedMapDetails.filter((smp) => !mapDetails.includes(smp)),
        ...values,
      ]);
    };

    return (
      <div key={key} className={styles.optionGroup}>
        <header>
          <Text tagName="h2" variant="body" bold>
            {strings[`map.map_options_modal.${key}.heading`]}
          </Text>

          <Toggle
            id={`${key}-toggle`}
            aria-label={strings[`map.map_options_modal.${key}.toggle.label`]}
            checked={allSelected}
            indeterminate={!allSelected && someSelected}
            onChange={handleToggle}
          />
        </header>

        <ListSelect
          id="map-details"
          multiple
          selectionLabel={formatMessage(
            {
              description: 'map.map_options_modal.select.selection',
              defaultMessage:
                '{COUNT, plural, one {{COUNT} of {TOTAL} selected} other {{COUNT} of {TOTAL} selected}}',
            },
            {
              COUNT: totalOptionsSelected,
              TOTAL: mapDetails.length,
            }
          )}
          placeholder={
            strings[`map.map_options_modal.${key}.select.placeholder`]
          }
          onChange={handleChange}
          options={options}
        />
      </div>
    );
  };

  const selectionComponents = [
    makeSelectionComponent({
      key: 'paddocks',
      mapDetails: MAP_DETAILS_GROUPED.PADDOCKS,
    }),
    <div key="mobsFilter" className={styles.optionGroup}>
      <header>
        <Text tagName="h2" variant="body" bold>
          {strings['map.map_options_modal.mobs.heading']}
        </Text>

        <Toggle
          id="mobs-toggle"
          aria-label={strings['map.map_options_modal.mobs.toggle.label']}
          checked={mobsVisible}
          indeterminate={mobsVisible && mobsFilter.length > 0}
          onChange={handleMobsToggleChange}
        />
      </header>

      <ListSelectButton
        id="mobs-filter-button"
        disabled={!mobsVisible}
        isOpen={filterIsOpen}
        hasSelectedOptions={mobsFilter.length > 0}
        defaultLabel={formatMessage(
          {
            description: 'map.map_options_modal.mobs_filter.selection',
            defaultMessage: '{COUNT} filters applied',
          },
          {
            COUNT: mobsFilter.length,
          }
        )}
        onToggleExpanded={() => setFilterIsOpen(!filterIsOpen)}
      />
      <EntitiesFilter
        title={strings['map.map_options_modal.mobs.select.placeholder']}
        isOpen={filterIsOpen}
        onClose={() => setFilterIsOpen(false)}
        items={mobsFilterItems}
        entities={mobs}
        filter={mobsFilter}
        applyFilter={applyFilter}
        clearFilter={handleClearFilter}
        setFilter={handleSetFilter}
        toggleFilter={handleToggleFilter}
      />
    </div>,
    makeSelectionComponent({
      key: 'tasks',
      mapDetails: MAP_DETAILS_GROUPED.TASKS,
    }),
    makeSelectionComponent({
      key: 'map_assets',
      mapDetails: MAP_DETAILS_GROUPED.MAP_ASSETS,
    }),
    ...(displayCustomMapLayersOption
      ? [
          <div key="customMapLayersFilter" className={styles.optionGroup}>
            <header>
              <Text tagName="h2" variant="body" bold>
                {strings['map.map_options_modal.custom_map_layers.heading']}
              </Text>

              <Toggle
                id="custom-map-layers-toggle"
                aria-label={
                  strings[
                    'map.map_options_modal.custom_map_layers.toggle.label'
                  ]
                }
                checked={allCustomMapLayersSelected}
                indeterminate={
                  !allCustomMapLayersSelected && someCustomMapLayersSelected
                }
                onChange={handleToggleCustomMapLayersFilter}
              />
            </header>

            <ListSelect
              id="map-details"
              multiple
              selectionLabel={formatMessage(
                {
                  description: 'map.map_options_modal.select.selection',
                  defaultMessage:
                    '{COUNT, plural, one {{COUNT} of {TOTAL} selected} other {{COUNT} of {TOTAL} selected}}',
                },
                {
                  COUNT: totalCustomMapLayersSelected,
                  TOTAL: customMapLayers.entities.length,
                }
              )}
              placeholder={
                strings[
                  'map.map_options_modal.custom_map_layers.select.placeholder'
                ]
              }
              onChange={handleChangeCustomMapLayersFilter}
              options={customMapLayersOptions}
            />
          </div>,
        ]
      : []),
  ];

  return (
    <ModalFlyUp
      title={strings['map.map_options_modal.title']}
      isOpen={visible}
      isModal={isLarge ? false : true}
      drawerPosition={isLarge ? 'right' : 'bottom'}
      onClose={handleClose}
      className={styles.settingsMapModal}
    >
      <section>
        {onChangeMapStyle ? (
          <div className={styles.optionGroup}>
            <header>
              <Text tagName="h2" variant="body" bold>
                {strings['map.map_style.label']}
              </Text>
            </header>
            <ul className={styles.mapTypesList}>
              {mapStyles.map((mapStyle) => (
                <li key={mapStyle}>
                  <SettingsMapStyleButton
                    mapStyle={mapStyle}
                    selected={selectedMapStyle === mapStyle}
                    onClick={() => {
                      onChangeMapStyle(mapStyle);
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {selectionComponents}

        <div className={styles.optionGroup}>
          <header>
            <Text tagName="h2" variant="body" bold>
              {strings['map.map_options_modal.download.heading']}
            </Text>
          </header>
          <Button
            intent="secondary"
            outline
            label={strings['map.map_options_modal.download.button.label']}
            flex
            onClick={() =>
              geoJsonObject
                ? downloadKML({
                    geoJsonObject,
                    fileName: properties.selected.name,
                    documentName: properties.selected.name,
                  })
                : null
            }
          />
        </div>
      </section>
    </ModalFlyUp>
  );
};
