import React from 'react';

import { LivestockType } from '@mobble/models/src/model/Mob';
import { ConfiguredPropertyTypeGroup } from '@mobble/models/src/model/Property';
import { base64encode } from '@mobble/shared/src/core/String';
import { useSettingsPropertyClassesTypesView } from '@mobble/shared/src/hooks/useSettingsPropertyClassesTypesView';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import {
  type NavigationGroupProps,
  NavigationList,
} from '@src/stories/Components/UX/NavigationList';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';

import * as ROUTE_NAME from '../config/routeNames';
import {
  type RootStackParamList,
  type ScreenRendererProps,
} from '../config/types';

const toClassTypesPath = (classTypes: {
  group: ConfiguredPropertyTypeGroup;
  livestockType?: LivestockType;
}) =>
  toPath(ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES_GROUP, {
    classTypes: base64encode(JSON.stringify(classTypes)),
  });

export const SettingsPropertyClassesTypesHeader: React.FC<
  ScreenRendererProps
> = () => {
  const linkTo = useLinking();
  const { strings } = useSettingsPropertyClassesTypesView();

  return (
    <ScreenHeader
      title={strings.classesTypesAndTags}
      breadcrumbs={[
        {
          title: strings.settings,
          href: ROUTE_NAME.SETTINGS_LIST,
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.SETTINGS_LIST);
      }}
    />
  );
};

export const SettingsPropertyClassesTypes: React.FC<
  ScreenRendererProps
> = () => {
  const { strings } = useSettingsPropertyClassesTypesView();

  const navigationItems: NavigationGroupProps[] = [
    {
      title: strings.toc_types,
      items: [
        {
          title: strings.toc_paddock_types,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.paddockType,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_livestock_types,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.livestockType,
          }) as keyof RootStackParamList,
        },
      ],
    },
    {
      title: strings.toc_breeds,
      items: [
        {
          title: strings.toc_sheep_breeds,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: LivestockType.Sheep,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_cattle_breeds,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: LivestockType.Cattle,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_goats_breeds,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: LivestockType.Goats,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_other_breeds,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.breed,
            livestockType: LivestockType.Other,
          }) as keyof RootStackParamList,
        },
      ],
    },
    {
      title: strings.toc_genders,
      items: [
        {
          title: strings.toc_sheep_genders,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: LivestockType.Sheep,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_cattle_genders,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: LivestockType.Cattle,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_goats_genders,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: LivestockType.Goats,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_other_genders,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.gender,
            livestockType: LivestockType.Other,
          }) as keyof RootStackParamList,
        },
      ],
    },
    {
      title: strings.toc_classes,
      items: [
        {
          title: strings.toc_sheep_classes,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: LivestockType.Sheep,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_cattle_classes,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: LivestockType.Cattle,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_goats_classes,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: LivestockType.Goats,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_other_classes,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.class,
            livestockType: LivestockType.Other,
          }) as keyof RootStackParamList,
        },
      ],
    },
    {
      title: strings.toc_tags,
      items: [
        {
          title: strings.toc_sheep_tags,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: LivestockType.Sheep,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_cattle_tags,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: LivestockType.Cattle,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_goats_tags,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: LivestockType.Goats,
          }) as keyof RootStackParamList,
        },
        {
          title: strings.toc_other_tags,
          href: toClassTypesPath({
            group: ConfiguredPropertyTypeGroup.tag,
            livestockType: LivestockType.Other,
          }) as keyof RootStackParamList,
        },
      ],
    },
  ];

  return <NavigationList items={navigationItems} />;
};

export default {
  name: ROUTE_NAME.SETTINGS_PROPERTY_CLASSES_TYPES,
  header: SettingsPropertyClassesTypesHeader,
  component: SettingsPropertyClassesTypes,
};
