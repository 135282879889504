import React from 'react';
import isEqual from 'lodash/isEqual';

import { type PaddockAction } from '@mobble/models/src/model/PaddockAction';
import { Color } from '@mobble/theme';

import { Spacer } from '@src/stories/Components/Layout/Spacer';
import { HStack } from '@src/stories/Components/Layout/Stack';
import { Avatar } from '@src/stories/Components/UI/Avatar';
import { Text } from '@src/stories/Components/UI/Text';
import { TimelineEvent } from '@src/stories/Components/UI/TimelineEvent';
import { Clickable } from '@src/stories/Components/UX/Clickable';

interface PaddockActionCardProps {
  paddockAction: PaddockAction;
  last?: boolean;
  onClick: (paddockAction: PaddockAction) => void;
}

export const PaddockActionCard: React.FC<PaddockActionCardProps> = ({
  paddockAction,
  onClick,
  last,
}) => {
  return (
    <Clickable flex href={() => onClick(paddockAction)} fullWidth>
      <TimelineEvent
        title={paddockAction.title}
        date={paddockAction.date}
        last={last}
        color={Color.Green}
      >
        <Text color={Color.DarkGrey}>{paddockAction.description}</Text>
        <Spacer y={1} />
        <HStack>
          <Avatar size="tiny" name={paddockAction.createdBy.name} />
          <Spacer x={0.5} />
          <Text color={Color.Grey} variant="tiny">
            {paddockAction.createdBy.name}
          </Text>
        </HStack>
      </TimelineEvent>
    </Clickable>
  );
};

export const MemoisedPaddockActionCard = React.memo(
  PaddockActionCard,
  (prev, next) => {
    return (
      isEqual(prev.paddockAction, next.paddockAction) && prev.last === next.last
    );
  }
);
