import React from 'react';

import { useI18n } from '@mobble/i18n';
import { type Casualty } from '@mobble/models/src/model/Casualty';
import { fromRawDate } from '@mobble/shared/src/core/Date';

import { ListSelect } from '@src/components';
import ChartContainer from '@src/components/ChartContainer';
import { generateCasualtiesHistoryChart } from '@src/stories/Components/Charts/generators/generateCasualtiesHistoryChart';
import { casualtiesChartDataConverter } from '@src/stories/Components/Charts/converters/casualtiesChartDataConverter';

export interface ChartMonthlyCasualtiesByMobProps {
  casualties: Casualty[];
  loading?: boolean;
}

export const ChartMonthlyCasualtiesByMob: React.FC<
  ChartMonthlyCasualtiesByMobProps
> = ({ casualties, loading }) => {
  const { formatMessage } = useI18n();
  const curYear = new Date().getFullYear();
  const [year, setYear] = React.useState<number>(curYear);

  const handleChangeYear = (years: (string | number)[]) =>
    setYear(Number(years[0]));

  const casualtyYears = [
    ...new Set(casualties.map((c) => fromRawDate(c.date).year())),
  ];
  const minYear = Math.min(...casualtyYears) ?? curYear;
  const yearOptions = new Array(curYear - minYear + 1)
    .fill(null)
    .map((_, i) => {
      const value = minYear + i;
      return {
        value,
        label: String(value),
        selected: value === year,
      };
    });

  const data = casualtiesChartDataConverter({ casualties, year, curYear });
  const eChartData = generateCasualtiesHistoryChart({ data });

  return (
    <ChartContainer
      title={
        formatMessage({
          defaultMessage: 'Monthly Casualties by Mob',
          description: 'chart.casualties-over-time.title',
        }) as string
      }
      headerOptions={
        <ListSelect
          id="mode-select"
          size="small"
          options={yearOptions.map((option) => ({
            label: option.label,
            value: option.value,
            selected: option.selected,
          }))}
          onChange={handleChangeYear}
        />
      }
      data={eChartData}
      loading={loading}
    />
  );
};
