import mapboxgl from 'mapbox-gl';

import { MapStyle } from '@mobble/models/src/model/MapStyle';
import { useRootSelector } from '@mobble/store/src';

import {
  MAPBOX_STYLES,
  MapBoxStylesItem,
  mapTileSourcesToMapboxStyles,
} from '@src/stories/Map/config';

export const useMapStyles = () => {
  const mapRoot = useRootSelector((s) => s.map);
  const { baseMaps, overlays, googleSession } = mapRoot;
  const baseStyles = mapTileSourcesToMapboxStyles(
    baseMaps,
    googleSession?.session
  );

  // merge static mapbox styles with dynamic map tile sources
  const allItems = [...MAPBOX_STYLES];
  if (baseStyles.length) {
    allItems.splice(1, 0, ...baseStyles);
  }

  const { sources, layers } = getAvailableMapSourcesAndLayers(allItems);

  return {
    mapStyles: allItems,
    mapStyleIds: allItems.map((style) => style.mapStyle),
    baseMaps,
    overlays,
    googleSession,
    sources,
    layers,
  };
};

export const getStyleUrlForMapStyle = (
  mapboxStyles: MapBoxStylesItem[],
  mapStyle: MapStyle
): MapBoxStylesItem['styleURL'] => {
  const style = mapboxStyles.find((s) => s.mapStyle === mapStyle);
  return style?.styleURL || mapboxStyles[0].styleURL;
};

export interface MapboxSourceAndLayers {
  sources: mapboxgl.RasterSource[];
  layers: mapboxgl.RasterLayer[];
}

export const getAvailableMapSourcesAndLayers = (
  styles: MapBoxStylesItem[]
): MapboxSourceAndLayers => {
  const sources: mapboxgl.RasterSource[] = [];
  const layers: mapboxgl.RasterLayer[] = [];

  styles.forEach((styleItem) => {
    if (styleItem.style) {
      const sourceId = Object.keys(styleItem.style.sources)[0];
      const sourceData = styleItem.style.sources[
        sourceId
      ] as mapboxgl.RasterSource;
      sources.push({
        id: sourceId,
        tiles: sourceData.tiles,
        tileSize: sourceData.tileSize,
      } as mapboxgl.RasterSource);

      const layerId = styleItem.style.layers[0].id;
      const layerData = styleItem.style.layers[0] as mapboxgl.RasterLayer;
      layers.push({
        id: layerId,
        type: layerData.type,
        source: layerData.source,
      } as mapboxgl.RasterLayer);
    }
  });

  return {
    sources,
    layers,
  };
};
