import { useI18n } from '@mobble/i18n/src';
import {
  stockingRateToDSE,
  StockingUnit,
} from '@mobble/models/src/model/Settings';
import { ListSelect } from '@src/components';
import ChartContainer from '@src/components/ChartContainer';
import { useSetting } from '@src/context/settings';
import { Mode } from 'fs';
import React from 'react';
import {
  Datum,
  generateLivestockPieChart,
} from '../generators/generateLivestockPieChart';
import { Mob } from '@mobble/models/src';
import { getStockingUnitI18n } from '../../Locale/LocaleStockingUnit';

const makeData = (mobs: Mob[], toValue: (mob: Mob) => number): Datum[] => {
  const map = new Map<string, Datum>();
  const mobToDataLabel = (mob: Mob) => `${mob.breed} ${mob.gender}`;

  mobs.forEach((mob) => {
    const id = mobToDataLabel(mob);
    const value = Number(toValue(mob).toFixed(1));
    let entry = map.get(id);

    // Update existing entry
    if (entry) {
      const newValue = Number((entry.value += value).toFixed(1));
      entry.value = newValue;
      entry.label = `${newValue} ${id}`;
    } else {
      entry = {
        id,
        value,
        label: `${value} ${id}`,
      };
    }

    map.set(id, entry);
  });

  return [...map.entries()].map(([, entry]) => entry);
};

const makeSizeData = (mobs: Mob[]): Datum[] =>
  makeData(mobs, (mob) => mob.size);

const useModes = (stockingUnit: StockingUnit) => {
  const { formatMessage } = useI18n();
  const stockingUnitLabel = formatMessage(getStockingUnitI18n(stockingUnit));

  return [
    {
      label: formatMessage({
        defaultMessage: 'Head',
        description: 'chart.livestock-totals.option.head',
      }),
      value: 'head',
    },
    {
      label: stockingUnitLabel,
      value: 'stocking_rate',
    },
  ];
};

const makeDSEData =
  (stockingUnit: StockingUnit) =>
  (mobs: Mob[]): Datum[] =>
    makeData(
      mobs,
      (mob) => stockingRateToDSE(stockingUnit)(mob.DSE) * mob.size
    );

const makeOther = (data: Datum[], otherLabel: string): Datum => {
  const total = data.reduce((acc, datum) => acc + datum.value, 0);
  return {
    id: 'other',
    label: `${total} ${otherLabel}`,
    value: total,
  };
};

const MAX_LIVESTOCK_GROUPS = 15;

export interface ChartLivestockTotalsProps {
  mobs: Mob[];
  loading?: boolean;
}

export const ChartLivestockTotals: React.FC<ChartLivestockTotalsProps> = ({
  mobs,
  loading,
}) => {
  const { formatMessage } = useI18n();
  const stockingUnit = useSetting('stockingUnit') as StockingUnit;

  const [mode, setMode] = React.useState<Mode>('head');
  const modeOptions = useModes(stockingUnit);

  const data = React.useMemo(() => {
    if (!mobs) {
      return [];
    }

    switch (mode) {
      case 'head':
        return makeSizeData(mobs);
      case 'stocking_rate':
        return makeDSEData(stockingUnit)(mobs);
    }
  }, [mode, mobs]).sort((a, b) => b.value - a.value);

  const slicedData = data.slice(0, MAX_LIVESTOCK_GROUPS);
  const otherData = makeOther(
    data.slice(MAX_LIVESTOCK_GROUPS),
    formatMessage({
      defaultMessage: 'Other',
      description: 'chart.livestock-totals.legend.other',
    })
  );

  if (otherData.value > 0) {
    slicedData.push(otherData);
  }

  return (
    <ChartContainer
      title={formatMessage({
        defaultMessage: 'Livestock Totals',
        description: 'chart.livestock-totals.title',
      })}
      headerOptions={
        <ListSelect
          id="mode-select"
          size="small"
          options={modeOptions.map((option) => ({
            label: option.label,
            value: option.value,
            selected: option.value === mode,
          }))}
          onChange={(option) => setMode(option[0] as Mode)}
        />
      }
      data={generateLivestockPieChart({ data: slicedData })}
      loading={loading}
    />
  );
};
