import React from 'react';

import { useI18n } from '@mobble/i18n';
import {
  checkHasMobbleConnectRolesBeenFilled,
  getPropertiesWhereUserHasAdminAccess,
  Property,
} from '@mobble/models/src/model/Property';
import {
  checkEmailIsValid,
  findUserOfEmail,
  relationshipToRole,
  UserRole,
} from '@mobble/models/src/model/User';
import { useGetUser } from '@mobble/store/src/hooks/auth';
import { useProperties } from '@mobble/store/src/hooks/properties';

import { useLinking } from '@src/hooks/useLinking';
import { toPath } from '@src/interfaces/Routing';
import * as ROUTE_NAME from '@src/screens/config/routeNames';
import { ScreenRendererProps } from '@src/screens/config/types';
import { EntitySliceFactoryPrelude } from '@src/stories/Views/Misc/EntitySliceFactoryPrelude';
import { ScreenHeader } from '@src/stories/Views/Misc/ScreenHeader';
import {
  MobbleConnectInviteUserForm,
  MobbleConnectInviteUserFormValues,
} from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectInviteUserForm';
import { useRoleLabels } from '@mobble/shared/src/hooks/useRoleLabels';
import { useGetInvitationMeta } from '@src/hooks/useInvitations';
import {
  Suggestions,
  SuggestionStatus,
  useLocalStorageSuggestions,
} from '../Dashboard/hooks/useDashboardSuggestions';
import { SettingsPropertyMobbleConnectInviteSuggestedUser } from '@src/stories/Views/Settings/SettingsPropertyMobbleConnectInviteSuggestedUser';
import { Box } from '@src/stories/Components/Layout/Box';

export const MobbleConnectInviteUserHeader: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const mobbleConnectRole = route.params.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  const roleLabel = useRoleLabels()(mobbleConnectRole);

  return (
    <ScreenHeader
      title={formatMessage(
        {
          description: 'settings.property.mobble_connect.invite_user.title',
          defaultMessage: 'Invite {ROLE}',
        },
        {
          ROLE: roleLabel ?? mobbleConnectRole,
        }
      )}
      breadcrumbs={[
        {
          title: formatMessage({
            description: 'screen.title.settings',
            defaultMessage: 'Settings',
          }),
          href: ROUTE_NAME.SETTINGS_LIST,
        },
        {
          title: formatMessage({
            description: 'settings.property.mobble_connect.title',
            defaultMessage: 'Mobble Connect',
          }),
          href: ROUTE_NAME.MOBBLE_CONNECT_USERS,
        },
        {
          title: roleLabel,
          href: toPath(ROUTE_NAME.MOBBLE_CONNECT_USER, {
            role: mobbleConnectRole,
          }),
        },
      ]}
      onGoBack={() => {
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role: mobbleConnectRole,
        });
      }}
    />
  );
};

const useGetSuggestedUser = ({
  mobbleConnectRole,
  email,
  setEmail,
}: {
  mobbleConnectRole: UserRole;
  email: string;
  setEmail: (email: string) => void;
}) => {
  const properties = useProperties();
  const invitationMeta = useGetInvitationMeta();
  const localStorageSuggestions = useLocalStorageSuggestions();

  const isInviterAlreadyInProperty = properties.selected?.users.some(
    (user) => user.email === invitationMeta?.invitersEmail
  );

  const inviterRelationship = invitationMeta
    ? relationshipToRole(invitationMeta.invitersRelationship)
    : undefined;

  const userSuggestionAvailable =
    mobbleConnectRole === inviterRelationship &&
    email !== invitationMeta?.invitersEmail &&
    !isInviterAlreadyInProperty &&
    localStorageSuggestions?.suggestions?.inviter === SuggestionStatus.active &&
    invitationMeta?.invitersEmail;

  const user = {
    name: invitationMeta?.invitersName,
    role: inviterRelationship,
  };

  const handleInviteSuggestedUser = () =>
    setEmail(invitationMeta?.invitersEmail);

  const handleDismissUserSuggestion = () =>
    localStorageSuggestions?.removeSuggestion(Suggestions.inviter);

  return {
    user,
    userSuggestionAvailable,
    handleInviteSuggestedUser,
    handleDismissUserSuggestion,
  };
};

export const MobbleConnectInviteUser: React.FC<ScreenRendererProps> = ({
  route,
}) => {
  const role = route.params?.role as UserRole;
  const { formatMessage } = useI18n();
  const linkTo = useLinking();

  const [email, setEmail] = React.useState<string>('');
  const [formLoading, setFormLoading] = React.useState(false);

  const me = useGetUser();
  const properties = useProperties();

  const {
    user: suggestedUser,
    userSuggestionAvailable,
    handleInviteSuggestedUser,
    handleDismissUserSuggestion,
  } = useGetSuggestedUser({
    mobbleConnectRole: role,
    email,
    setEmail,
  });

  const Prelude = EntitySliceFactoryPrelude({
    preludes: [properties.prelude],
    required: [properties.selected],
  });

  const property = properties.selected as Property;

  const propertiesWithAdminAccess = getPropertiesWhereUserHasAdminAccess(
    properties.entities
  )(me?.id ?? '');

  // filter out properties where the user role has been filled
  const propertiesWhereUserRoleNotFilled = propertiesWithAdminAccess.filter(
    (p) => !checkHasMobbleConnectRolesBeenFilled(p.users)(role)
  );

  // filter out properties where the user is already part of
  const propertiesUserIsNotPartOf = propertiesWhereUserRoleNotFilled.filter(
    (p) => !findUserOfEmail(p.users)(email)
  );

  const isUserAlreadyPartOfProperty = Boolean(
    findUserOfEmail(property.users)(email)
  );

  const propertyOptions = propertiesUserIsNotPartOf.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  if (Prelude) {
    return Prelude;
  }

  const infoMessage =
    email && isUserAlreadyPartOfProperty
      ? formatMessage({
          description:
            'settings.property.mobble_connect.invite_user.form.user.exists.message',
          defaultMessage: 'User is already part of the current property',
        })
      : null;

  const handleCancel = () => {
    linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
      role,
    });
  };

  const handleSubmit = ({
    email,
    propertyIds,
  }: MobbleConnectInviteUserFormValues) => {
    setFormLoading(true);

    properties
      .addUser({
        propertyIds,
        organisationId: property.organisationId,
        role,
        email,
      })
      .then(() => {
        setFormLoading(false);
        linkTo(ROUTE_NAME.MOBBLE_CONNECT_USER, {
          role,
        });
      });
  };

  const disabled = isUserAlreadyPartOfProperty || !checkEmailIsValid(email);

  return (
    <>
      {userSuggestionAvailable && (
        <>
          <SettingsPropertyMobbleConnectInviteSuggestedUser
            name={suggestedUser.name}
            role={suggestedUser.role}
            onInvite={handleInviteSuggestedUser}
            onClose={handleDismissUserSuggestion}
          />

          <Box spacing={2} />
        </>
      )}
      <MobbleConnectInviteUserForm
        loading={formLoading}
        properties={propertyOptions}
        emailErrorMessage={infoMessage}
        initialValues={{
          email: email,
          propertyIds: [property.id],
        }}
        disabled={disabled}
        onChange={({ email: e }) => setEmail(e)}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default {
  name: ROUTE_NAME.MOBBLE_CONNECT_USER_INVITE,
  header: MobbleConnectInviteUserHeader,
  component: MobbleConnectInviteUser,
};
