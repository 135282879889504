import { roundNumberTo } from '@mobble/shared/src/core/Number';
import { EChartData } from '@src/components/EChart/EChart';
import { chartColors } from '../config';

export interface Datum {
  id: string;
  label: string;
  value: number;
}

interface LivestockPieChartGeneratorParams {
  data: Datum[];
}

export const generateLivestockPieChart = ({
  data,
}: LivestockPieChartGeneratorParams) => {
  if (!data || data.length === 0) {
    return null;
  }

  const preparedData = data.map((datum, i) => {
    return {
      value: Number(roundNumberTo(0)(datum.value)),
      name: datum.label,
      itemStyle: {
        color: chartColors[i],
        borderWidth: 3,
        borderColor: chartColors[i],
      },
    };
  });

  const eChartData: EChartData = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      itemGap: 5,
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '90%'],
        avoidLabelOverlap: false,
        label: {
          position: 'inner',
          formatter: '{c}',
        },
        labelLine: {
          show: false,
        },
        data: preparedData,
      },
    ],
  };

  return eChartData;
};
