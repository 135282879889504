import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';

import { useMessages } from '@mobble/i18n';
import { FilterItem } from '@mobble/models/src/model/Filter';
import {
  MapPortalContent,
  OnClickMapItemEvent,
} from '@mobble/models/src/model/Map';
import { findMob, findMobs, type Mob } from '@mobble/models/src/model/Mob';
import { findPaddock } from '@mobble/models/src/model/Paddock';
import { getPaddocksInSamePaddockGroupAsPaddock } from '@mobble/models/src/model/PaddockGroup';
import {
  useMobs,
  usePaddockGroups,
  usePaddocks,
  useProperties,
} from '@mobble/store/src/hooks';

import { usePaddockCardFactory } from '@src/hooks/usePaddockCardFactory';
import { ModalFlyUp } from '@src/stories/Components/UX/ModalFlyUp';
import { MapAssetCard } from '@src/stories/Views/MapAsset/MapAssetCard';
import { MobsSelectListModal } from '@src/stories/Views/Paddock/MobsSelectListModal';
import { PaddockMoveMobFormQuick } from '@src/stories/Views/Paddock/PaddockMoveMobFormQuick';
import { TaskCard } from '@src/stories/Views/Task/TaskCard';

import { CustomMapLayerCard } from '../../CustomMapLayer/CustomMapLayerCard';

export interface MapPortalModal {
  content: MapPortalContent;
  mapMobsFilter: FilterItem[];
  onClickMapItem: (ev: OnClickMapItemEvent) => void;
  onRefresh: () => void;
  onClose: () => void;
}

const messages = defineMessages({
  paddock: {
    defaultMessage: 'Paddock',
    description: 'paddocks.paddock.detail.title',
  },
  mobs: {
    defaultMessage: 'Mobs',
    description: 'paddocks.paddock.mobs.title',
  },
  mob: {
    defaultMessage: 'Mob',
    description: 'mobs.mob.detail.title',
  },
  task: {
    defaultMessage: 'Task',
    description: 'tasks.task.detail.title',
  },
  'map-asset': {
    defaultMessage: 'Map asset',
    description: 'map_assets.map_asset.detail.title',
  },
  'move-mob': {
    defaultMessage: 'Move mob',
    description: 'paddocks.paddock.move_mob.form.quick.title',
  },
  'custom-map-layer': {
    defaultMessage: 'Custom map layer',
    description: 'custom_map_layers.custom_map_layer.detail.title',
  },
});

const MapPortalComponent: React.FC<MapPortalModal> = ({
  content,
  mapMobsFilter = [],
  onClickMapItem,
  onRefresh,
  onClose,
}) => {
  const strings = useMessages(messages);
  const paddockCardFactory = usePaddockCardFactory({});
  const properties = useProperties();
  const propertyId = properties.selected?.id;
  const mobs = useMobs(propertyId);
  const paddocks = usePaddocks(propertyId);
  const paddockGroups = usePaddockGroups(propertyId);
  const [selectedMobs, setSelectedMobs] = useState<string[]>([]);
  const getMobs = findMobs(mobs.entities);

  const selectedPaddockDetails =
    content?.type === 'mobs'
      ? (() => {
          const paddock = content?.paddock;

          const paddocksInSamePaddockGroup =
            paddock && paddockGroups.entities
              ? getPaddocksInSamePaddockGroupAsPaddock(
                  paddockGroups.entities,
                  paddocks.entities
                )(paddock.id)
              : null;

          const paddocksExcludingCurrent = paddocksInSamePaddockGroup
            ? paddocksInSamePaddockGroup.filter((pdk) => pdk.id !== paddock.id)
            : [];

          const paddockGroupMobs = paddocksExcludingCurrent
            ? getMobs(flatMap(paddocksExcludingCurrent.map((pdk) => pdk.mobs)))
            : null;

          const mobsOnPaddock = paddock?.mobs
            .map(findMob(mobs.entities))
            .filter(Boolean) as Mob[];

          return {
            paddockGroupMobs,
            mobsOnPaddock,
            paddock,
          };
        })()
      : null;

  if (!content) {
    return null;
  }

  const handleCloseModal = () => {
    setSelectedMobs([]);
    onClose();
  };

  const handleClickMob = (mob: Mob) => {
    if (selectedPaddockDetails?.paddock) {
      onClickMapItem({
        type: 'mob',
        paddock: selectedPaddockDetails?.paddock,
        mob,
      });
      setSelectedMobs([]);
    }
  };

  const handleSelectMob = (mobIds: Mob['id'][]) => {
    setSelectedMobs(mobIds);
  };

  const renderContent = () => {
    const sharedProps = {
      isOpen: true,
      title: strings[content.type],
      onClose: handleCloseModal,
    };

    switch (content.type) {
      case 'paddock': {
        const paddock = findPaddock(paddocks.entities)(content?.paddock.id);

        return (
          <ModalFlyUp
            {...sharedProps}
            content={paddockCardFactory(paddock, () => {
              onClickMapItem({
                type: 'paddock',
                paddock,
              });
            })}
          />
        );
      }
      case 'mobs':
        return (
          <MobsSelectListModal
            {...sharedProps}
            paddockId={selectedPaddockDetails.paddock.id}
            selectedMobs={selectedMobs}
            mobsFilter={mapMobsFilter}
            onSelectMob={handleSelectMob}
            onClickMob={handleClickMob}
            onClose={handleCloseModal}
          />
        );
      case 'task':
        return (
          <ModalFlyUp
            {...sharedProps}
            content={
              <TaskCard
                task={content.task}
                onClick={(task) => {
                  onClickMapItem({
                    type: 'task',
                    task,
                  });
                }}
              />
            }
          />
        );
      case 'map-asset':
        return (
          <ModalFlyUp
            {...sharedProps}
            content={
              <MapAssetCard
                mapAsset={content.mapAsset}
                onClick={(mapAsset) => {
                  onClickMapItem({
                    type: 'map-asset',
                    mapAsset,
                  });
                }}
              />
            }
          />
        );
      case 'move-mob':
        return (
          <ModalFlyUp {...sharedProps}>
            <PaddockMoveMobFormQuick
              fromPropertyId={content.fromPaddock.propertyId}
              fromPaddockId={content.fromPaddock.id}
              toPaddockId={content.toPaddock.id}
              onReady={onRefresh}
            />
          </ModalFlyUp>
        );
      case 'custom-map-layer':
        return (
          <ModalFlyUp
            {...sharedProps}
            content={
              content.customMapLayer ? (
                <CustomMapLayerCard
                  customMapLayer={content.customMapLayer}
                  onClick={(customMapLayer) => {
                    onClickMapItem({
                      type: 'custom-map-layer',
                      customMapLayer,
                    });
                  }}
                />
              ) : null
            }
          />
        );
      default:
        console.warn('Unhandled content', content);
        return null;
    }
  };

  return renderContent();
};

export const MapPortal = React.memo(MapPortalComponent, (a, b) => {
  return isEqual(a.content, b.content);
});
