import mobbleService from '@mobble/service';
import { type ExtStatusPerActionPerParent } from '../lib/ExtStatus';
import { entitySliceFactory } from '../lib/entitySliceFactory';
import { MobbleSubscription } from '@mobble/models/src/model/Subscriptions';

export interface SunbscriptionsState {
  extStatus: ExtStatusPerActionPerParent;
  entities: MobbleSubscription[];
  filter: {};
  sort: {};
}

export const defaultState: SunbscriptionsState = {
  entities: [],
  extStatus: {},
  filter: {},
  sort: {},
};

const entitySlice = entitySliceFactory<SunbscriptionsState>({
  name: 'subscriptions',
  initialState: defaultState,
  toParentId: () => 'me',
  onGetAll: mobbleService.api.organisation.getSubscriptions,
  onCreate: () => Promise.reject(),
  onUpdate: () => Promise.reject(),
  onDelete: () => Promise.reject(),
});

export const {
  thunkGetAll,
  thunkCreate,
  thunkUpdate,
  thunkDelete,
  //
  proxyUseEntities,
  proxyUseEntity,
} = entitySlice;
export const { flush, updateFilter } = entitySlice.slice.actions;
export const { reducer } = entitySlice.slice;

export default entitySlice.slice.reducer;
