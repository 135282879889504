import React from 'react';

import { useI18n } from '@mobble/i18n';

import { Button } from '@src/components';

import styles from './signoutForm.scss';

export interface SettingsSignOutFormProps {
  onSignOut: () => void;
}

export const SettingsSignOutForm: React.FC<SettingsSignOutFormProps> = ({
  onSignOut,
}) => {
  const { formatMessage } = useI18n();

  return (
    <div className={styles.form}>
      <Button
        flex
        icon="sign-out"
        label={formatMessage({
          defaultMessage: 'Sign out',
          description: 'settings.sign_out.button.label',
        })}
        intent="destructive"
        onClick={onSignOut}
      />
    </div>
  );
};
