import React from 'react';

import { Icon, type IconName } from '@mobble/icon';

import { type LabelLike } from '@src/components/ListSelect';

import { Box } from '../Layout/Box';

import { InlineOption } from './InlineOption';
import { ModalFlyUp } from './ModalFlyUp';

import styles from './modalSelect.scss';

export interface ModalSelectProps {
  title?: string;
  additionalHeader?: React.ReactNode;
  options: ModalSelectOption[];
  visible?: boolean;
  onCancel: () => void;
  onChange: (value: string) => void;
}

export interface ModalSelectOption {
  label: LabelLike;
  value: string;
  disabled?: boolean;
  selected?: boolean;
  icon?: IconName;
  iconColor?: string;
}

// @deprecated
export const ModalSelect: React.FC<ModalSelectProps> = ({
  title,
  additionalHeader,
  options,
  onChange,
  onCancel,
  visible = false,
}) => {
  const renderOption = (option: ModalSelectOption) => {
    const handleClick = () => {
      onChange(option.value.toString());
      onCancel();
    };

    const labelExtra = option.icon ? (
      <Box spacing={0.5} className={styles.labelExtra}>
        <Icon name={option.icon} color={option.iconColor} />
      </Box>
    ) : undefined;

    return (
      <InlineOption
        key={option.value}
        labelExtra={labelExtra}
        {...option}
        onClick={handleClick}
      />
    );
  };

  return (
    <ModalFlyUp
      isOpen={visible}
      title={title}
      additionalHeader={additionalHeader}
      onClose={onCancel}
      listProps={{
        items: options,
        renderItem: (item) => renderOption(item as ModalSelectOption),
      }}
    />
  );
};
