import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames/bind';

import { Color } from '@mobble/theme';

import styles from './SubscriptionBanner.scss';

import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import Dialog from '../Dialog';
import Button from '../Button';
import { useMessages } from '@mobble/i18n/src';
import { defineMessages } from 'react-intl';
import {
  billingRegionToPhoneNumber,
  CONTACT_EMAIL,
  SubscriptionDisabledStatus,
  toContactEmailURL,
  toContactPhoneURL,
} from '@mobble/models/src/model/Subscriptions';
import { BillingRegion } from '@mobble/models/src/model/User';

const messages = defineMessages({
  'banner-title-trial-ended': {
    description: 'alert.trial_ended',
    defaultMessage: 'Your trial has ended',
  },
  'banner-title-subscription-expired': {
    description: 'alert.subscription_expired',
    defaultMessage: 'Your subscription has expired',
  },
  'banner-title-inactive-property': {
    description: 'alert.inactive_property',
    defaultMessage: 'Inactive Property',
  },

  'title-trial-ended': {
    description: 'alert.trial_ended',
    defaultMessage: 'Your 21-day trial of Mobble had ended',
  },
  'title-subscription-expired': {
    description: 'alert.subscription_expired',
    defaultMessage: 'Your subscription has expired',
  },
  'title-inactive-property': {
    description: 'alert.inactive_property',
    defaultMessage: 'The property is inactive',
  },

  'message-trial-ended': {
    description: 'alert.trial_ended.message',
    defaultMessage:
      "Thank you for trialing Mobble! To keep using Mobble, you can subscribe via the Billing Page. Still have questions? Give us a call or send us an email to discuss what's right for you and your farm.",
  },
  'message-subscription-expired': {
    description: 'alert.subscription_expired.message',
    defaultMessage:
      'To continue using Mobble check unpaid invoices in your inbox and the status via the billing page. Still having issues? Give us a call or send through an email.',
  },
  'message-inactive-property': {
    description: 'alert.inactive_property.message',
    defaultMessage:
      'The property is inactive due to an expired subscription, contact the property owner to keep using Mobble. Still having issues? Give us a call or send through an email.',
  },

  'button-billing': {
    description: 'alert.button.billing',
    defaultMessage: 'Billing Page',
  },
  'button-close': {
    description: 'alert.button.close',
    defaultMessage: 'Close',
  },
});

export interface SubscriptionBannerProps
  extends ComponentPropsWithoutRef<'div'> {
  /**
   * The alert status to display. I.E. trial ended, subscription expired, inactive property.
   */
  alertStatus: SubscriptionDisabledStatus;

  /**
   * The billing region of the user.
   */
  billingRegion: BillingRegion;

  /**
   * The user's role in the subscription.
   */
  isOwner: boolean;

  /**
   * The state of the dialog.
   */
  isOpen: boolean;

  /**
   * Set the state of the dialog
   */
  setIsOpen: (isOpen: boolean) => void;

  /**
   * Navigate to the billing page
   */
  handleNavigateToBillingPage: () => void;
}

/**
 * SubscriptionBanner displays a status if a user's subscription is disabled.
 */
const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
  alertStatus,
  billingRegion,
  isOwner,

  isOpen,
  setIsOpen,
  handleNavigateToBillingPage,
}) => {
  const strings = useMessages(messages);

  if (!alertStatus) {
    return null;
  }

  const bannerText = strings[`banner-title-${alertStatus}`];
  const title = strings[`title-${alertStatus}`];
  const message = strings[`message-${alertStatus}`];

  const contactEmail = CONTACT_EMAIL;
  const phoneNumber = billingRegionToPhoneNumber(billingRegion);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const buttonLabel = isOwner
    ? strings['button-billing']
    : strings['button-close'];

  const handleClickButton = () => {
    if (isOwner) {
      handleNavigateToBillingPage();
    }
    close();
  };

  return (
    <>
      <button className={styles.banner} onClick={open}>
        <Text className={styles.bannerText}>{bannerText}</Text>
        <Icon name="chevron-right" color={Color.White} />
      </button>
      <Dialog isOpen={isOpen} isModal={true} onRequestClose={close}>
        <div className={styles.dialogContainer}>
          <Text tagName="h2" variant="larger" bold>
            {title}
          </Text>

          <Text tagName="p">{message}</Text>

          <Text
            tagName="a"
            className={styles.contactText}
            href={toContactEmailURL(contactEmail)}
          >
            {contactEmail}
          </Text>

          <Text
            tagName="a"
            className={styles.contactText}
            href={toContactPhoneURL(phoneNumber)}
          >
            {phoneNumber}
          </Text>

          <div className={styles.buttonContainer}>
            <Button
              label={buttonLabel}
              color={isOwner ? Color.Green : Color.Grey}
              size="small"
              onClick={handleClickButton}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SubscriptionBanner;
